import { Components, Theme } from '@mui/material';

import globalStyles from '../global.styles';

const style: Components<Omit<Theme, 'components'>> = {
  MuiTextField: {
    styleOverrides: {
      root: () => ({
        backgroundColor: globalStyles.mainColors.whiteColor,

        label: {
          '.MuiInputLabel-asterisk': {
            color: globalStyles.mainColors.redColor,
          },
        },
      }),
    },
  },
};

export default style;
