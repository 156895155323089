import { ICreateStripeProduct } from '@a-type/dtos';
import { Switch } from '@a-type/ui/components';
import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

interface PlanFormProps {
  onChange: (key: keyof ICreateStripeProduct, value: boolean | number | string) => void;
  plan: ICreateStripeProduct;
}

const MAX_PRICE = 99999999; // 999,999.99

export const PlanForm: React.FC<PlanFormProps> = ({ onChange, plan }: PlanFormProps) => {
  const calculateMaxCreditsPerPackage = (pricePerCredit: number) => {
    return Math.floor(MAX_PRICE / 100 / pricePerCredit) || 2;
  };

  const calculateMaxPricePerCredit = (creditsPerPackage: number) => {
    return Math.floor((MAX_PRICE / 100 / creditsPerPackage) * 1000) / 1000 || 0.001;
  };

  const [maxCreditsPerPackage, setMaxCreditsPerPackage] = useState<number>(
    calculateMaxCreditsPerPackage(plan.pricePerCredit),
  );

  const [maxPricePerCredit, setMaxPricePerCredit] = useState<number>(
    calculateMaxPricePerCredit(plan.creditsPerPackage),
  );

  useEffect(() => {
    setMaxCreditsPerPackage(calculateMaxCreditsPerPackage(plan.pricePerCredit));
  }, [plan.pricePerCredit]);

  useEffect(() => {
    setMaxPricePerCredit(calculateMaxPricePerCredit(plan.creditsPerPackage));
  }, [plan.creditsPerPackage]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <TextField
            error={!plan.name}
            inputProps={{ maxLength: 50 }}
            label="Name"
            onChange={(e) => onChange('name', e.target.value)}
            value={plan.name}
          />

          <Switch
            checked={plan.isMostPopular}
            label="Most Popular"
            onChange={(e) => onChange('isMostPopular', e.target.checked)}
            sx={{
              ml: '1px',
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <TextField
            error={
              plan.creditsPerPackage < 2 ||
              plan.creditsPerPackage % 1 !== 0 ||
              plan.creditsPerPackage > maxCreditsPerPackage
            }
            helperText={`Min: 2, Max: ${maxCreditsPerPackage}`}
            inputProps={{
              max: maxCreditsPerPackage,
              min: 2,
              step: 1,
            }}
            label="Extra Credits per Package"
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              onChange(
                'creditsPerPackage',
                value > maxCreditsPerPackage ? maxCreditsPerPackage : value,
              );
            }}
            type="number"
            value={plan.creditsPerPackage}
          />

          <TextField
            error={plan.pricePerCredit < 0.001 || plan.pricePerCredit > maxPricePerCredit}
            helperText={`Min: 0.001, Max: ${maxPricePerCredit}`}
            inputProps={{ max: maxPricePerCredit, min: 0.001, step: 0.001 }}
            label="Price for Extra Credits"
            onChange={(e) => {
              const value = parseFloat(parseFloat(e.target.value).toFixed(3));
              onChange('pricePerCredit', value > maxPricePerCredit ? maxPricePerCredit : value);
            }}
            type="number"
            value={plan.pricePerCredit}
          />
        </Box>
      </Box>

      <TextField
        inputProps={{ maxLength: 500 }}
        label="Description"
        multiline
        onChange={(e) => onChange('description', e.target.value)}
        rows={3}
        value={plan.description ?? ''}
      />
    </Box>
  );
};
