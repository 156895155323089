import { IStripePaymentMethod } from '@a-type/dtos';
import {
  Dialog,
  PaymentMethods,
  SubscriptionDetails,
  SubscriptionStatus,
} from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AccountPageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { pageContentLoad, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import {
  useCancelSubscriptionMutation,
  useDeleteCardMutation,
  useGetActiveSubscriptionQuery,
  useGetCardsQuery,
  useSetDefaultCardMutation,
  useUpdateSubscriptionPaymentMethodMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddPaymentMethod } from './components/addPaymentMethod/AddPaymentMethod.component';
import { PaymentMethod } from './components/paymentMethod/PaymentMethod.component';

export const AccountPlanAndBillingPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: subscription, isLoading: isSubscriptionLoading } = useGetActiveSubscriptionQuery();
  const { data: cards, isLoading: isCardsLoading } = useGetCardsQuery();
  const [cancelSubscription, { isLoading: isCancelSubscriptionLoading }] =
    useCancelSubscriptionMutation();
  const [updatePaymentMethod, { isLoading: isUpdatePaymentMethodLoading }] =
    useUpdateSubscriptionPaymentMethodMutation();
  const [setDefaultCard, { isLoading: isSetDefaultCardLoading }] = useSetDefaultCardMutation();
  const [deleteCard, { isLoading: isDeleteCardLoading }] = useDeleteCardMutation();
  const [openCancelSubscriptionDialog, setOpenCancelSubscriptionDialog] = useState(false);
  const [openUpdatePaymentMethodDialog, setOpenUpdatePaymentMethodDialog] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<null | string>(null);
  const [isAddingCard, setIsAddingCard] = useState(false);

  useEffect(() => {
    dispatch(
      pageContentLoad(
        !isSubscriptionLoading &&
          !isCardsLoading &&
          !isSetDefaultCardLoading &&
          !isDeleteCardLoading &&
          !isCancelSubscriptionLoading &&
          !isUpdatePaymentMethodLoading,
      ),
    );
  }, [
    isSubscriptionLoading,
    isCardsLoading,
    isSetDefaultCardLoading,
    isDeleteCardLoading,
    isCancelSubscriptionLoading,
    isUpdatePaymentMethodLoading,
  ]);

  const handleSetDefaultCard = async (paymentMethod: IStripePaymentMethod) => {
    await setDefaultCard(paymentMethod.id);
    dispatch(snackbarSuccessMessage('Default card updated successfully'));
  };

  const handleRemoveCard = async (paymentMethodId: string) => {
    await deleteCard(paymentMethodId);
    dispatch(snackbarSuccessMessage('Card removed successfully'));
  };

  const handleCancelSubscription = async () => {
    await cancelSubscription();
    dispatch(snackbarSuccessMessage('Subscription cancelled successfully'));
    setOpenCancelSubscriptionDialog(false);
  };

  const handleUpdatePaymentMethod = async () => {
    if (!subscription || !selectedPaymentMethod) return;

    await updatePaymentMethod({ id: subscription.id, paymentMethodId: selectedPaymentMethod });
    dispatch(snackbarSuccessMessage('Payment method updated successfully'));
    setOpenUpdatePaymentMethodDialog(false);
  };

  return (
    <AccountPageLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
        }}
      >
        <Box
          sx={{
            background: globalStyles.mainColors.whiteColor,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            height: 'auto',
            minHeight: '100%',
            p: 4,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 24,
                  fontWeight: 700,
                }}
              >
                Subscription Plan
              </Typography>

              {subscription && <SubscriptionStatus subscription={subscription} />}
            </Box>

            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
                justifyContent: 'flex-end',
              }}
            >
              {subscription && subscription.status === 'past_due' && (
                <Button
                  color="secondary"
                  onClick={() => setOpenUpdatePaymentMethodDialog(true)}
                  variant="text"
                >
                  Retry Payment
                </Button>
              )}

              {subscription && !subscription.cancelAtPeriodEnd && (
                <>
                  <Button
                    color="secondary"
                    onClick={() => setOpenCancelSubscriptionDialog(true)}
                    variant="text"
                  >
                    Cancel Subscription
                  </Button>

                  <Button
                    color="primary"
                    onClick={() => navigate(AppRoutes.AccountUpdatePlanPage)}
                    variant="text"
                  >
                    Change Plan
                  </Button>
                </>
              )}
            </Box>
          </Box>

          {subscription ? (
            <SubscriptionDetails subscription={subscription} />
          ) : (
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: globalStyles.mainColors.whiteSmokeColor,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                justifyContent: 'center',
                py: 8,
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 20,
                  fontWeight: 400,
                }}
              >
                There is no active subscription.
              </Typography>

              <Button
                color="primary"
                onClick={() => navigate(AppRoutes.SubscribePage)}
                sx={{
                  width: 200,
                }}
                variant="contained"
              >
                Select Plan
              </Button>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            background: globalStyles.mainColors.whiteColor,
            borderRadius: 2,
            height: 'auto',
            minHeight: '100%',
            p: 4,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between',
              mb: 3,
            }}
          >
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              Payment Methods
            </Typography>

            {cards && cards.length < 10 && <AddPaymentMethod variant="text" />}
          </Box>

          {cards && cards.length > 0 ? (
            cards.map((card) => (
              <PaymentMethod
                key={card.id}
                onRemove={() => handleRemoveCard(card.id)}
                onSetDefault={() => handleSetDefaultCard(card)}
                paymentMethod={card}
              />
            ))
          ) : (
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: globalStyles.mainColors.whiteSmokeColor,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                justifyContent: 'center',
                py: 8,
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 20,
                  fontWeight: 400,
                }}
              >
                There is no payment method.
              </Typography>

              <AddPaymentMethod variant="contained" />
            </Box>
          )}
        </Box>
      </Box>

      <Dialog
        cancelText="No"
        okText="Yes, Cancel Subscription"
        onClose={() => setOpenCancelSubscriptionDialog(false)}
        onOk={handleCancelSubscription}
        open={openCancelSubscriptionDialog}
        title="Cancel Subscription"
      >
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          We&apos;re sorry to see you go!
          <br />
          Your subscription will be canceled at the end of your billing period on
        </Typography>
        <br />
        <br />
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          Are you sure you want to continue?
        </Typography>
      </Dialog>

      <Dialog
        cancelText="Cancel"
        disableCancel={isUpdatePaymentMethodLoading || isAddingCard}
        disableOk={isUpdatePaymentMethodLoading || isAddingCard}
        okText="Update Payment Method"
        onClose={() => setOpenUpdatePaymentMethodDialog(false)}
        onOk={handleUpdatePaymentMethod}
        open={openUpdatePaymentMethodDialog}
        size="sm"
        title="Retry Payment"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
            }}
          >
            Select a payment method to update your subscription.
            <br />
            We will retry the payment with the new payment method.
          </Typography>

          <Divider />

          <PaymentMethods
            onAddPaymentMethod={(show) => {
              setIsAddingCard(show);
            }}
            onSelectPaymentMethod={(payment) => {
              setSelectedPaymentMethod(payment.id);
            }}
          />
        </Box>
      </Dialog>
    </AccountPageLayout>
  );
};
