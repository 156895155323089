import { IList } from '@a-type/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ListState {
  list: IList | null;
  uploadedCsvFile: File | null;
  uploadedCsvFileData?: {
    csvDelimiter?: string;
    examples: Record<
      string,
      {
        data: string;
        key: string;
      }[]
    >;
    headers: string[];
    numberOfRow?: number;
  } | null;
}

const initialState: ListState = {
  list: null,
  uploadedCsvFile: null,
  uploadedCsvFileData: null,
};

const listSlice = createSlice({
  initialState,
  name: 'list',
  reducers: {
    resetList() {
      return { ...initialState };
    },
    setList(state, action: PayloadAction<IList>) {
      return {
        ...state,
        list: action.payload,
      };
    },
    setListUploadedCsvFile(state, action: PayloadAction<File | null>) {
      return {
        ...state,
        uploadedCsvFile: action.payload,
      };
    },
    setListUploadedCsvFileData(
      state,
      action: PayloadAction<{
        csvDelimiter?: string;
        examples: Record<
          string,
          {
            data: string;
            key: string;
          }[]
        >;
        headers: string[];
        numberOfRow?: number;
      } | null>,
    ) {
      return {
        ...state,
        uploadedCsvFileData: action.payload,
      };
    },
  },
});

export const { resetList, setList, setListUploadedCsvFile, setListUploadedCsvFileData } =
  listSlice.actions;
export default listSlice.reducer;
