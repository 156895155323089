export enum AppRoutes {
  AccountDetailsPage = '/account',
  AccountPaymentsHistoryPage = '/account/payments-history',
  AccountPlanBillingPage = '/account/plan-billing',
  AccountUpdatePlanPage = '/account/update-plan',
  Activity = '/activity',

  AdminAppendDataPage = '/admin-panel/append-data',
  AdminCombinedSearchPage = '/admin-panel/combined-search',
  AdminDataSourcesPage = '/admin-panel/data-sources',
  AdminFilterGroupsPage = '/admin-panel/filter-groups',
  AdminPaymentsPage = '/admin-panel/payments',
  AdminPlansPage = '/admin-panel/plans',
  AdminSettingsPage = '/admin-panel/settings',
  AdminUsersPage = '/admin-panel/users',
  AdminViewsPage = '/admin-panel/views',

  BuyListPage = '/buy-list',
  BuyMoreDataPage = '/buy-more-data',
  ConfirmEmailPage = '/confirm-email',
  CountsCreate = '/counts/create',
  CountsList = '/counts',
  CountsUpdate = '/counts/update',
  DataSourcesPage = '/data-sources',

  ForgetPasswordPage = '/forget-password',
  HelpPage = '/help',
  HomePage = '/',

  Lists = '/lists',
  LoginPage = '/login',
  NotFoundPage = '*',

  RegistrationPage = '/registration',
  ResetPasswordPage = '/reset-password',
  SubscribePage = '/subscribe',
}
