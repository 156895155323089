import { IFilterGroup } from '@a-type/interfaces';
import { Dialog, DragAndDrop } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import {
  useGetFilterGroupsInUseQuery,
  useGetFilterGroupsQuery,
  useUpdateFilterGroupsMutation,
} from '@a-type/ui/stores/apis';
import { generateObjectId } from '@a-type/ui/utils/object-id.utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';

import { FilterGroupItem } from './components/FilterGroupItem.component';

export const FilterGroupPage: FC = () => {
  const dispatch = useDispatch();
  const { data: filterGroupsData, isLoading: isLoadingFilterGroups } = useGetFilterGroupsQuery();
  const { data: filterGroupsInUseData, isLoading: isLoadingFilterGroupsInUse } =
    useGetFilterGroupsInUseQuery();
  const [
    updateFilterGroupsMutation,
    { data: updatedFilterGroups, isLoading: isLoadingUpdateFilterGroups, isSuccess: isUpdated },
  ] = useUpdateFilterGroupsMutation();

  const [selected, setSelected] = useState<null | string>(null);
  const [filterGroups, setFilterGroups] = useState<IFilterGroup[]>([]);
  const [filterGroupsInUse, setFilterGroupsInUse] = useState<string[]>([]);
  const [isValid, setIsValid] = useState(true);

  const endRef = useRef<HTMLDivElement>(null);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteFilterGroup, setDeleteFilterGroup] = useState<IFilterGroup | null>(null);

  /**
   * Load filter groups from the API
   */
  useEffect(() => {
    setFilterGroups([...(filterGroupsData ?? [])]);
  }, [filterGroupsData]);

  /**
   * Reset filter groups when updated
   */
  useEffect(() => {
    if (isUpdated && updatedFilterGroups) {
      setFilterGroups(updatedFilterGroups);
    }
  }, [isUpdated, updatedFilterGroups]);

  /**
   * Load filter groups in use from the API
   */
  useEffect(() => {
    setFilterGroupsInUse(filterGroupsInUseData || []);
  }, [filterGroupsInUseData]);

  /**
   * Dispatch page content load action
   */
  useEffect(() => {
    dispatch(
      pageContentLoad(
        !isLoadingFilterGroups && !isLoadingFilterGroupsInUse && !isLoadingUpdateFilterGroups,
      ),
    );
  }, [isLoadingFilterGroups, isLoadingFilterGroupsInUse, isLoadingUpdateFilterGroups]);

  useEffect(() => {
    if (scrollToBottom && endRef.current) {
      // wait 500ms for the next render to scroll to the bottom
      setTimeout(() => {
        endRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 100);

      setScrollToBottom(false);
    }
  }, [scrollToBottom]);

  // Validate filter group
  const validateFilterGroup = (filterGroup: IFilterGroup) => {
    return (
      filterGroup.name !== '' &&
      filterGroup.sortOrder !== undefined &&
      filterGroup.code.length <= 50 &&
      /^\w+$/.test(filterGroup.code) &&
      filterGroup.name.length <= 50 &&
      (!filterGroup.description || filterGroup.description.length <= 500)
    );
  };

  const validateFilterGroupCode = (filterGroup: IFilterGroup) => {
    return (
      // Check if code is not empty
      filterGroup.code !== '' &&
      // Check if code contains only alphanumeric characters and underscores
      /^\w+$/.test(filterGroup.code) &&
      // Check if all filter groups in use are in filter groups
      filterGroups.filter((c) => c.code === filterGroup.code).length === 1
    );
  };

  const validate = (fg: IFilterGroup[]) => {
    return (
      fg.every(
        (filterGroup) => validateFilterGroup(filterGroup) && validateFilterGroupCode(filterGroup),
      ) &&
      // Check if all filter groups in use are in filter groups
      filterGroupsInUse.every((c) => fg.some((filterGroup) => filterGroup.code === c)) &&
      // Check if all filter groups have unique code
      fg.every((filterGroup) => fg.filter((c) => c.code === filterGroup.code).length === 1)
    );
  };

  // Validate filter groups
  useEffect(() => {
    setIsValid(validate(filterGroups));
  }, [filterGroups]);

  // Add new filter group
  const handleNewFilterGroup = () => {
    if (validate(filterGroups) === false) return;

    const id = generateObjectId();
    setFilterGroups([
      ...filterGroups,
      {
        _id: id,
        code: '',
        name: '',
        sortOrder: filterGroups.length + 1,
      } as IFilterGroup,
    ]);

    setSelected(id);
    setScrollToBottom(true);
  };

  // Call the update filter group mutation
  const handleFilterGroupUpdate = () => {
    setSelected(null);
    updateFilterGroupsMutation(filterGroups);
  };

  // Update filter group field
  const updateFilterGroupField = (id: string, field: string, value: number | string) => {
    setFilterGroups(
      filterGroups.map((filterGroup) => {
        if (filterGroup._id === id) {
          return {
            ...filterGroup,
            [field]: value,
          } as IFilterGroup;
        }

        return filterGroup;
      }),
    );
  };

  // Delete filter group
  const onDeleteFilterGroup = (filterGroup: IFilterGroup) => {
    setDeleteFilterGroup(filterGroup);
    setDialogOpen(true);
  };

  const deleteFilterGroupHandler = (id: string) => {
    setSelected(null);
    let sortOrder = 0;
    const newFilterGroups = filterGroups
      .filter((filterGroup) => filterGroup._id !== id)
      .map((filterGroup) => {
        sortOrder += 1;
        return {
          ...filterGroup,
          sortOrder,
        } as IFilterGroup;
      });

    setFilterGroups(newFilterGroups);
    if (validate(newFilterGroups)) {
      updateFilterGroupsMutation(newFilterGroups);
    }
    setDialogOpen(false);
    setDeleteFilterGroup(null);
  };

  return (
    <>
      <AdminPageLayout container>
        <Box
          sx={{
            display: 'flex',

            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              pb: 1,
            }}
          >
            <Typography sx={{ fontSize: 24, fontWeight: 800, pl: 2 }}>Filter Groups</Typography>
            <Button
              disabled={!isValid}
              onClick={handleNewFilterGroup}
              startIcon={<AddCircleIcon />}
            >
              New Filter Group
            </Button>
          </Box>

          <DragAndDrop
            items={filterGroups}
            renderItem={(filterGroup, index, p, s) => (
              <FilterGroupItem
                draggableProps={p.draggableProps}
                dragHandleProps={p.dragHandleProps}
                dragRef={p.innerRef}
                filterGroup={filterGroup}
                inUse={filterGroupsInUse.includes(filterGroup.code)}
                isCodeValid={validateFilterGroupCode(filterGroup)}
                isDragging={s.isDragging}
                isValid={validateFilterGroup(filterGroup)}
                onDeleteFilterGroup={onDeleteFilterGroup}
                selected={selected}
                setSelected={setSelected}
                updateFilterGroupField={updateFilterGroupField}
              />
            )}
            setItems={setFilterGroups}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              pt: 1,
            }}
          >
            <Button
              disabled={isValid === false}
              onClick={handleFilterGroupUpdate}
              variant="contained"
            >
              Update Filter Groups
            </Button>
          </Box>
          <div ref={endRef} />
        </Box>
      </AdminPageLayout>

      <Dialog
        cancelText="Cancel"
        okText="Delete"
        onClose={() => setDialogOpen(false)}
        onOk={() => {
          if (deleteFilterGroup) {
            deleteFilterGroupHandler(deleteFilterGroup._id);
          }
        }}
        open={dialogOpen}
        title="Delete Filter Group"
      >
        <Typography
          sx={{
            fontSize: 16,
          }}
        >
          Are you sure you want to delete &quot;{deleteFilterGroup?.name}&quot; filter group?
        </Typography>
      </Dialog>
    </>
  );
};

export default FilterGroupPage;
