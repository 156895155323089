import { UserStatus } from '@a-type/enums';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import {
  authAccessLocalStorageWorker,
  authRefreshLocalStorageWorker,
  userLocalStorageWorker,
} from '@a-type/ui/services';
import { logout, snackbarErrorMessage } from '@a-type/ui/stores/actions';
import {
  useGetActiveSubscriptionQuery,
  useGetUserInfoQuery,
  useLogoutMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { AccountIconComponent } from './account-icon.component';

export const NavigationComponent = () => {
  const { baseInfo, isLogin } = useSelector((state) => state.auth);
  const { data: userInfo } = useGetUserInfoQuery(undefined, { skip: !isLogin });
  useGetActiveSubscriptionQuery(undefined, { skip: !isLogin });
  const [logoutUser] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const onLogout = async () => {
    try {
      await logoutUser();
      userLocalStorageWorker.delete();
      authAccessLocalStorageWorker.delete();
      authRefreshLocalStorageWorker.delete();
      dispatch(logout());
      setAnchorEl(null);
      navigate(AppRoutes.LoginPage);
    } catch (error: any) {
      dispatch(snackbarErrorMessage(error.message));
    }
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'avatar' : undefined;

  const handleClose = (e: any) => {
    if (e.target.value === 'on' || e.type === 'mouseleave') return;
    setAnchorEl(null);
  };

  const linkList = [
    {
      name: 'Home',
      url: AppRoutes.HomePage,
    },
    {
      auth: true,
      name: 'Counts',
      url: AppRoutes.CountsList,
    },
    {
      auth: true,
      name: 'Lists',
      url: AppRoutes.Lists,
    },
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: globalStyles.mainColors.whiteColor,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {linkList
          .filter((link) => (link.auth ? isLogin : true))
          .map((link) => {
            return (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? 'active-link nav-link' : 'nav-link';
                }}
                data-qa={`mainpage-navlink-${link.name.toLowerCase().replace(' ', '')}`}
                key={`nav-link-${link.name.toLowerCase().replace(' ', '')}`}
                onClick={() => {
                  if (location.pathname !== AppRoutes.HomePage) {
                    dispatch({ type: 'RESET' });
                  }
                }}
                to={link.url}
              >
                <Typography
                  component="div"
                  gutterBottom
                  sx={{
                    color: globalStyles.navigation.textColor,
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '16px',
                    margin: 0,
                    padding: 2,
                  }}
                  variant="h6"
                >
                  {link.name}
                </Typography>
              </NavLink>
            );
          })}
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {isLogin && userInfo && userInfo.status !== UserStatus.BLOCKED && (
          <AccountIconComponent
            anchorEl={anchorEl}
            baseInfo={baseInfo}
            handleClick={handleClick}
            handleClose={handleClose}
            id={id}
            isLogin={isLogin}
            onLogout={onLogout}
            open={open}
            userInfo={userInfo}
          />
        )}
      </Box>
    </Box>
  );
};
