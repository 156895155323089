import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Typography } from '@mui/material';

const DataSourceTableHeaderComponent = () => {
  return (
    <>
      <Box sx={{ borderBottom: '1px solid #E6E6E6' }} />
      <Box sx={{ display: 'flex', pb: 2, pt: 2.5 }}>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 11,
            pl: 2,
            textTransform: 'uppercase',
            width: '15%',
          }}
        >
          Name
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 11,
            textAlign: 'center',
            textTransform: 'uppercase',
            width: '10%',
          }}
        >
          Type
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 11,
            textAlign: 'center',
            textTransform: 'uppercase',
            width: '10%',
          }}
        >
          Status
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 11,
            textAlign: 'end',
            textTransform: 'uppercase',
            width: '15%',
          }}
        >
          Upload Progress
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 11,
            textAlign: 'end',
            textTransform: 'uppercase',
            width: '10%',
          }}
        >
          Fields
        </Typography>

        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 11,
            textAlign: 'end',
            textTransform: 'uppercase',
            width: '10%',
          }}
        >
          Filters
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 11,
            textAlign: 'end',
            textTransform: 'uppercase',
            width: '15%',
          }}
        >
          Total Records
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 11,
            pr: 2,
            textAlign: 'end',
            textTransform: 'uppercase',
            width: '15%',
          }}
        >
          Pricing Plans
        </Typography>
      </Box>
    </>
  );
};
export default DataSourceTableHeaderComponent;
