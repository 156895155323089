import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import {
  authAccessLocalStorageWorker,
  authRefreshLocalStorageWorker,
  userLocalStorageWorker,
} from '@a-type/ui/services';
import { logout, snackbarErrorMessage } from '@a-type/ui/stores/actions';
import { useLogoutMutation } from '@a-type/ui/stores/apis';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type BlockedUserComponentTypes = {
  fullName: string;
};

const BlockedUserComponent = (props: BlockedUserComponentTypes) => {
  const { fullName } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoutUser] = useLogoutMutation();

  const onLogout = async () => {
    try {
      await logoutUser();
      userLocalStorageWorker.delete();
      authAccessLocalStorageWorker.delete();
      authRefreshLocalStorageWorker.delete();
      dispatch(logout());
      navigate(AppRoutes.LoginPage);
    } catch (error: any) {
      dispatch(snackbarErrorMessage(error.message));
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          fontSize: '16px',
          height: '50%',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Box sx={{ mb: 1, ml: 1, mt: 1 }}>
          <Box sx={{ mx: 2, my: 3 }}>
            <Typography sx={{ fontSize: '32px' }}>
              Unfortunately, the user {fullName} is blocked. Please, contact the admin.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ m: 2 }}>
        <Button
          onClick={() => {
            onLogout();
          }}
          sx={{
            margin: 1,
            width: 500,
          }}
          variant="contained"
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box
              sx={{
                fontSize: '16px',
                textTransform: 'none',
              }}
            >
              Logout
            </Box>
          </Box>
        </Button>
      </Box>
    </Container>
  );
};

export default BlockedUserComponent;
