import { FilterMode } from '@a-type/enums';
import { IDataSourceField } from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCount } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Box, Button, ButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';

export const styles = {
  activeFilterButton: {
    backgroundColor: globalStyles.mainColors.blueColor,
    borderColor: globalStyles.mainColors.blueColor,
    color: globalStyles.mainColors.whiteColor,
  },
  filterButton: {
    '&:hover': {
      backgroundColor: globalStyles.mainColors.blueColor,
      borderColor: globalStyles.mainColors.blueColor,
      color: globalStyles.mainColors.whiteColor,
    },
    borderColor: globalStyles.mainColors.headTableGreyColor,
    borderRadius: '8px',
    color: globalStyles.mainColors.headTableGreyColor,
    lineHeight: '1',
    px: '12px',
    py: '4px',
    'span:first-of-type': {
      mr: '4px',
    },
  },
};

export interface SelectedValuesControlProps {
  field: IDataSourceField;
}

const SelectedValuesControl = (props: SelectedValuesControlProps) => {
  const { field } = props;
  const { count } = useSelector((state) => state.count);
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.ADD);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!count || !count.filters || !count.filters[field.name!]) return;

    setFilterMode(count.filters[field.name!].mode);
  }, [count]);

  const handleClearFilter = () => {
    if (!count || !count.filters) return;

    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name!]: {
            ...count.filters[field.name!],
            values: [],
          },
        },
      }),
    );
  };

  const handleFilterMode = (mode: FilterMode) => {
    if (!count || !count.filters) return;

    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name!]: {
            ...count.filters[field.name!],
            mode,
          },
        },
      }),
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <ButtonGroup>
        <Button
          onClick={() => handleFilterMode(FilterMode.ADD)}
          size="small"
          startIcon={<CheckCircleOutlineIcon />}
          sx={[
            {
              ...styles.filterButton,
              pl: '12px',
              pr: '16px',
            },
            filterMode === FilterMode.ADD && styles.activeFilterButton,
          ]}
        >
          Add
        </Button>
        <Button
          onClick={() => handleFilterMode(FilterMode.OMIT)}
          size="small"
          startIcon={<NotInterestedIcon sx={{ rotate: '90deg' }} />}
          sx={[
            {
              ...styles.filterButton,
              pl: '16px',
              pr: '12px',
            },
            filterMode === FilterMode.OMIT && styles.activeFilterButton,
          ]}
        >
          Omit
        </Button>
      </ButtonGroup>
      <Button
        onClick={() => handleClearFilter()}
        size="small"
        startIcon={<CancelOutlinedIcon />}
        sx={{
          ...styles.filterButton,
        }}
        variant="outlined"
      >
        Clear
      </Button>
    </Box>
  );
};

export default SelectedValuesControl;
