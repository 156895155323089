import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { changeSubscriptionActivatedPopup } from '@a-type/ui/stores/actions';
import { useGetActiveSubscriptionQuery } from '@a-type/ui/stores/apis';
import { Typography } from '@mui/material';

import { Dialog } from '../dialog/Dialog.component';

export const SubscriptionActivatedDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state) => state.auth);
  const { isSubscriptionActivatedPopupActive } = useSelector((state) => state.appMain);
  const { data: subscription } = useGetActiveSubscriptionQuery(undefined, {
    skip: !isLogin,
  });

  return (
    <Dialog
      okText="Ok"
      onClose={() => dispatch(changeSubscriptionActivatedPopup(false))}
      onOk={() => dispatch(changeSubscriptionActivatedPopup(false))}
      open={isSubscriptionActivatedPopupActive}
      title="Subscription Activated"
    >
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '25px',
        }}
      >
        Congratulation!
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '25px',
        }}
      >
        Your subscription has been successfully activated with the&nbsp;
        <Typography
          component="span"
          sx={{
            fontWeight: 500,
          }}
        >
          {subscription?.name}
        </Typography>
        &nbsp;plan.
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '25px',
        }}
      >
        <Typography
          component="span"
          sx={{
            fontWeight: 500,
          }}
        >
          {subscription?.credits} credits
        </Typography>
        &nbsp; are available to you till&nbsp;
        {subscription?.currentPeriodEnd && (
          <Typography
            component="span"
            sx={{
              fontWeight: 500,
            }}
          >
            {new Date(subscription?.currentPeriodEnd).toLocaleDateString()}
          </Typography>
        )}
        .
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '25px',
        }}
      >
        The next payments will be charged automatically.
      </Typography>
    </Dialog>
  );
};
