import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Step, StepButton, Stepper } from '@mui/material';

import { DATA_SOURCE_TABS, DataSourceTypes } from '../utils/DataSourceDetailsProps';

const DataSourceDetailsStepsNavigation = (props: any) => {
  const { activeStep, setActiveStep, setStepsHistory, stepsHistory } = props;

  const checkHistoryHandler = (currentSelectedItem: DataSourceTypes) => {
    return stepsHistory.find((steps: DataSourceTypes) => steps.value === currentSelectedItem.value);
  };

  return (
    <Box sx={{ pb: 2, pt: 2, width: '50%' }}>
      <Stepper activeStep={activeStep}>
        {DATA_SOURCE_TABS.map((tabItem: DataSourceTypes, i: number) => {
          const existItem = checkHistoryHandler(tabItem);

          return (
            <Step
              disabled={!!existItem}
              key={tabItem.title}
              onClick={() => {
                if (!existItem) return;
                setStepsHistory([...stepsHistory, tabItem]);
                setActiveStep(i);
              }}
              sx={{ color: 'red', cursor: 'pointer' }}
            >
              <StepButton
                sx={{
                  '& .MuiStepIcon-text': {
                    fill: globalStyles.mainColors.whiteColor,
                  },
                }}
              >
                {tabItem.title}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default DataSourceDetailsStepsNavigation;
