import { IList, IVersiumAppend } from '@a-type/interfaces';
import { GridPagination } from '@a-type/ui/components';
import { useQuery } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import { listService } from '@a-type/ui/services';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import {
  useGetListsQuery,
  useGetUserAppendDataQuery,
  useGetUserDataSourcesQuery,
} from '@a-type/ui/stores/apis';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  ListsEmptyComponent,
  ListsHeaderComponent,
  ListsTableHeaderComponent,
  ListsTableItemComponent,
} from './components';

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 25;

export const ListsPage = () => {
  const query = useQuery();
  const [lists, setLists] = useState<IList[]>([]);
  const [pagination, setPagination] = useState({
    limit: DEFAULT_LIMIT,
    page: DEFAULT_PAGE,
  });
  const [activeTab, setActiveTab] = useState(query.get('tab') ?? 'purchased');
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [appendDataUtils, setAppendDataUtils] = useState<IVersiumAppend[]>([]);
  const dispatch = useDispatch();
  const { data: dataSources } = useGetUserDataSourcesQuery();
  const { data: appendDataUtilsData } = useGetUserAppendDataQuery();
  const { data: listsData, isLoading: isListsLoading } = useGetListsQuery({
    limit: pagination.limit,
    manualUpload: activeTab === 'uploaded',
    page: pagination.page,
  });

  useEffect(() => {
    if (appendDataUtilsData) {
      setAppendDataUtils(appendDataUtilsData);
    }
  }, [appendDataUtilsData]);

  useEffect(() => {
    if (listsData) {
      setLists(listsData.items);
      setCount(listsData.count);
      setPages(listsData.pages);
    }
  }, [listsData]);

  useEffect(() => {
    dispatch(pageContentLoad(!isListsLoading));
  }, [isListsLoading]);

  useEffect(() => {
    if (query.get('tab')) {
      switch (query.get('tab')) {
        case 'purchased':
          setActiveTab('purchased');
          break;
        case 'uploaded':
          setActiveTab('uploaded');
          break;
        default:
          setActiveTab('purchased');
          break;
      }
    }

    setPagination({
      limit: DEFAULT_LIMIT,
      page: DEFAULT_PAGE,
    });
  }, [query]);

  const canAppendData = (list: IList) => {
    return appendDataUtils.some(
      (appendData) => appendData.isActive && appendData.targetType === list.targetType,
    );
  };

  const canBuyMoreData = (list: IList) => {
    return (list && dataSources?.some((source) => source._id === list.dataSourceId)) || false;
  };

  const showCSVData = async (list: IList) => {
    await listService.export(list._id!, list.name);
  };

  return (
    <PageLayout container>
      <ListsHeaderComponent />
      {!lists?.length ? (
        <ListsEmptyComponent />
      ) : (
        <>
          <ListsTableHeaderComponent />
          {lists.map((list: IList, index: number) => {
            return (
              <ListsTableItemComponent
                canAppendData={canAppendData(list)}
                canBuyMoreData={canBuyMoreData(list)}
                index={index}
                key={list._id}
                list={list}
                showCSVData={showCSVData}
              />
            );
          })}
          <GridPagination
            count={count}
            limit={pagination.limit}
            onChangePagination={(page, limit) => {
              setPagination({ limit, page });
            }}
            page={pagination.page}
            pages={pages}
          />
        </>
      )}
    </PageLayout>
  );
};
