import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { AxlePeopleLookupType, DataSourceUpdateStrategy } from '@a-type/enums';
import {
  IAxleField,
  IBaseLookup,
  ICount,
  IDataSource,
  IFieldMetadata,
  IInsight,
} from '@a-type/interfaces';
import axios, { AxiosResponse } from 'axios';

class DataSourcesService {
  constructor(public config: any) {}

  async buildLookups(id: string) {
    try {
      const url = `${this.config.baseUrl}/${id}${this.config.patch.buildLookups}`;
      const searchResponse = await axios.post(url, {
        id,
      });

      return searchResponse;
    } catch (error: any) {
      return error.response;
    }
  }

  async count(
    data: IDataSource,
    counts: ICount,
    signal?: AbortSignal,
  ): Promise<AxiosResponse<number, any>> {
    try {
      const url = `${this.baseUrl(data)}/${data._id}${this.config.patch.count}`;
      const result = await axios.post<number>(url, counts, { signal });
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  // File
  async createFile(dataSource: IDataSource, file: any): Promise<AxiosResponse<any, any>> {
    try {
      const formData = new FormData();
      const url = `${this.config.baseUrl}`;

      formData.append('file', file, file.name);
      formData.append('dataSource', JSON.stringify(dataSource));

      const uploadResponse = await axios.post(url, formData, {
        headers: {
          'Content-Type': file.type,
        },
      });

      return uploadResponse;
    } catch (error: any) {
      return error.response;
    }
  }

  async delete(id: string) {
    try {
      const url = `${this.config.baseUrl}/${id}`;
      const deleteResponse = await axios.delete(url);
      return deleteResponse;
    } catch (error: any) {
      return error.response;
    }
  }

  async fieldsMetadata(
    data: IDataSource,
    fields: string[],
  ): Promise<AxiosResponse<{ [key: string]: IFieldMetadata }, any>> {
    try {
      const url = `${this.baseUrl(data)}/${data._id}${this.config.patch.fieldsMetadata}`;
      const result = await axios.post<{ [key: string]: IFieldMetadata }>(url, fields);
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  async getAll(): Promise<AxiosResponse<IDataSource[], any>> {
    try {
      const url = `${this.config.baseUrl}${this.config.patch.getAll}`;
      const result = await axios.get<IDataSource[]>(url);
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  // API
  async getApiFields(data: IDataSource): Promise<AxiosResponse<IAxleField[], any>> {
    try {
      const url = `${this.config.baseUrlApi}/${data.apiType}/${data._id}${this.config.patch.apiFiels}`;
      const result = await axios.get<IAxleField[]>(url);
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  async getById(id: string): Promise<AxiosResponse<IDataSource, any>> {
    try {
      const url = `${this.config.baseUrl}/${id}`;
      const result = await axios.get<IDataSource>(url);
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  async getExamples(id: string): Promise<AxiosResponse<any[], any>> {
    try {
      const url = `${this.config.baseUrl}/${id}${this.config.patch.examples}`;
      const searchResponse = await axios.get(url);

      return searchResponse;
    } catch (error: any) {
      return error.response;
    }
  }

  async getFilterComponents(): Promise<AxiosResponse<IDataSourceFilterComponentDto[], any>> {
    try {
      const url = `${this.config.dictinaryUrl}${this.config.patch.filtersComponents}`;
      const result = await axios.get<IDataSourceFilterComponentDto[]>(url);
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  async getLookupsMapping(
    data: IDataSource,
  ): Promise<AxiosResponse<{ [key: string]: AxlePeopleLookupType }, any>> {
    try {
      const url = `${this.config.baseUrlApi}/${data.apiType}/${data._id}${this.config.patch.lookupsMapping}`;
      const result = await axios.get<{ [key: string]: AxlePeopleLookupType }>(url);
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  async indexStatus(id: string): Promise<
    AxiosResponse<
      {
        indexes: string[];
        isRequiedBuildLookup: boolean;
        isRequiredReindex: boolean;
        operations: {
          field: string;
          id: string;
          progress: {
            done: number;
            total: number;
          };
        }[];
        status: string;
      },
      any
    >
  > {
    try {
      const url = `${this.config.baseUrl}/${id}${this.config.patch.indexStatus}`;
      const searchResponse = await axios.get(url);

      return searchResponse;
    } catch (error: any) {
      return error.response;
    }
  }

  async insights(data: IDataSource, counts: ICount): Promise<AxiosResponse<IInsight, any>> {
    try {
      const url = `${this.baseUrl(data)}/${data._id}${this.config.patch.insights}`;
      const result = await axios.post<IInsight>(url, counts);
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  async lookup(
    data: IDataSource,
    field: string,
    query: string | undefined = undefined,
    preFetchAll = false,
    signal?: AbortSignal,
  ): Promise<AxiosResponse<IBaseLookup[], any>> {
    try {
      let url = `${this.baseUrl(data)}/${data._id}${this.config.patch.lookup}/${field}`;
      const queryParam = [
        ...(query ? [`query=${query}`] : []),
        ...(preFetchAll ? [`preFetchAll=${preFetchAll}`] : []),
      ];
      url = `${url}?${queryParam.join('&')}`;

      const result = await axios.get<IBaseLookup[]>(url, { signal });
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  async refreshDatasource(data: IDataSource): Promise<AxiosResponse<any, any>> {
    try {
      const url = `${this.baseUrl(data)}/${data._id}${this.config.patch.refreshDatasource}`;
      const result = await axios.post<any>(url);
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  async reindex(id: string) {
    try {
      const url = `${this.config.baseUrl}/${id}${this.config.patch.indexUpdate}`;
      const searchResponse = await axios.post(url, {
        id,
      });

      return searchResponse;
    } catch (error: any) {
      return error.response;
    }
  }

  async update(data: IDataSource): Promise<AxiosResponse<IDataSource, any>> {
    try {
      const url = `${this.baseUrl(data)}/${data._id}${this.config.patch.update}`;
      const { description, imageUrl, name, targetType } = data;
      const result = await axios.put<IDataSource>(url, {
        description,
        imageUrl,
        name,
        targetType,
      });
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  async updateFile(
    dataSource: IDataSource,
    file: any,
    updateStrategy: DataSourceUpdateStrategy,
  ): Promise<AxiosResponse<any, any>> {
    try {
      const url = `${this.config.baseUrl}/${dataSource._id}`;
      const formData = new FormData();

      if (file) formData.append('file', file, file.name);
      formData.append('dataSource', JSON.stringify(dataSource));
      formData.append('updateStrategy', updateStrategy);

      const response = await axios.put(url, formData, {
        headers: {
          'Content-Type': file?.type ?? 'application/json',
        },
      });
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async updateImage(data: IDataSource, imageUrl: string): Promise<AxiosResponse<IDataSource, any>> {
    try {
      const url = `${this.baseUrl(data)}/${data._id}${this.config.patch.updateImage}`;
      const result = await axios.put<IDataSource>(url, { url: imageUrl });
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  async updateMapping(data: IDataSource): Promise<AxiosResponse<IDataSource, any>> {
    try {
      const url = `${this.baseUrl(data)}/${data._id}${this.config.patch.updateMapping}`;
      const result = await axios.put<IDataSource>(url, data.fields);
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  async updatePricing(data: IDataSource): Promise<AxiosResponse<IDataSource, any>> {
    try {
      const url = `${this.baseUrl(data)}/${data._id}${this.config.patch.updatePricing}`;
      const result = await axios.put<IDataSource>(url, data.pricing);
      return result;
    } catch (error: any) {
      return error.response;
    }
  }

  private baseUrl(data: IDataSource) {
    if (data.isApi) return `${this.config.baseUrlApi}/${data.apiType}`;

    if (data.isView) return `${this.config.baseUrlView}`;

    return `${this.config.baseUrl}`;
  }
}

const userConfig = {
  baseUrl: `${process.env.REACT_APP_API_HOST}/data-sources`,
  baseUrlApi: `${process.env.REACT_APP_API_HOST}/data-sources-api`,
  baseUrlView: `${process.env.REACT_APP_API_HOST}/data-sources-view`,
  dictinaryUrl: `${process.env.REACT_APP_API_HOST}/dictionaries`,
  patch: {
    // API
    apiFiels: '/fields',
    buildLookups: '/build-lookups',
    count: '/count',
    examples: '/examples',
    fieldsMetadata: '/fields-metadata',
    filtersComponents: '/filter-components/',
    // Data Sources
    getAll: '',
    // File
    indexStatus: '/index-status',
    indexUpdate: '/index-update',
    insights: '/insights',
    lookup: '/lookup',

    lookupsMapping: '/lookups-mapping',
    refreshDatasource: '/refresh-datasource',
    update: '/update',
    updateImage: '/update-image',

    updateMapping: '/update-mapping',
    updatePricing: '/update-pricing',
  },
};

export const dataSourcesService = new DataSourcesService(userConfig);
export default dataSourcesService;
