import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { changeExtraCreditPopup } from '@a-type/ui/stores/actions';
import { useGetUserBalanceQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Avatar,
  Box,
  Card,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const AccountIconComponent = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: creditsBalance = 0 } = useGetUserBalanceQuery();
  const { anchorEl, baseInfo, handleClick, handleClose, id, isLogin, onLogout, open, userInfo } =
    props;

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      // eslint-disable-next-line no-bitwise
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';
    for (i = 0; i < 3; i += 1) {
      // eslint-disable-next-line no-bitwise
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  };

  const stringAvatar = (firstName: string, lastName: string) => {
    let avatar = 'AU';

    if (firstName && lastName) {
      avatar = firstName.charAt(0) + lastName.charAt(0);
    } else if (firstName) {
      avatar = firstName.length > 1 ? firstName.slice(0, 2) : firstName;
    } else if (lastName) {
      avatar = lastName.length > 1 ? lastName.slice(0, 2) : lastName;
    }

    return {
      children: avatar,
      sx: {
        bgcolor: stringToColor(`${firstName} ${lastName}`),
        fontSize: '15px',
      },
    };
  };

  const showShortName = (name: string, numberOfSymbols: number) => {
    const shortName = name.slice(0, numberOfSymbols);
    return name.length > numberOfSymbols ? `${shortName}...` : shortName;
  };

  return (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>
      {isLogin && (
        <Box
          aria-describedby={id}
          onClick={(e) => {
            if (open) {
              handleClose(e);
            } else {
              handleClick(e);
            }
          }}
          onMouseLeave={handleClose}
          sx={{
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ pr: 0.7 }}>
            {userInfo.imageUrl ? (
              <Avatar alt="avatar" src={userInfo.imageUrl} sx={{ height: 40, width: 40 }} />
            ) : (
              <Avatar alt="avatar" {...stringAvatar(userInfo.firstName, userInfo.lastName)} />
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box
              sx={{
                color: globalStyles.mainColors.blackColor,
                display: 'flex',
                flexDirection: 'column',
                gap: 0.25,
                marginInline: 1,
              }}
            >
              <Typography
                component="div"
                gutterBottom
                sx={{ fontSize: '14px', fontWeight: 700, margin: 0 }}
                variant="h6"
              >
                {showShortName(userInfo.fullName, 20)}
              </Typography>
              <Typography
                component="div"
                sx={{
                  color: globalStyles.navigation.currencyText,
                  fontSize: '12px',
                  fontWeight: 500,
                  margin: 0,
                  mt: -0.5,
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: globalStyles.mainColors.greenColor,
                    fontSize: '12px',
                    fontWeight: 500,
                  }}
                >
                  {creditsBalance.toLocaleString()}&nbsp;
                </Typography>
                Credits
              </Typography>
            </Box>
            <Card
              sx={{
                alignItems: 'center',
                background: globalStyles.mainColors.whiteColor,
                borderRadius: 0,
                boxShadow: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {open ? (
                <ArrowDropUpIcon
                  sx={{
                    background: globalStyles.mainColors.whiteColor,
                    borderRadius: 100,
                    color: globalStyles.navigation.arrowIcon,
                    fontSize: '30px',
                  }}
                />
              ) : (
                <ArrowDropDownIcon
                  sx={{
                    background: globalStyles.mainColors.whiteColor,
                    borderRadius: 10,
                    color: globalStyles.navigation.arrowIcon,
                    fontSize: '30px',
                  }}
                />
              )}
            </Card>
          </Box>

          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            id={id}
            onClose={handleClose}
            open={open}
            sx={{
              mt: '14px',
            }}
          >
            <Box>
              <List>
                {baseInfo?.role.includes('admin') && (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        dispatch({ type: 'RESET' });

                        navigate(AppRoutes.AdminUsersPage);
                      }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          color: globalStyles.navigation.textColor,
                          fontSize: '14px',
                          fontWeight: '500',
                          lineHeight: '24px',
                          margin: 0,
                        }}
                        variant="h6"
                      >
                        Admin page
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                )}
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      dispatch({ type: 'RESET' });
                      navigate(AppRoutes.AccountDetailsPage);
                    }}
                  >
                    <Typography
                      component="div"
                      gutterBottom
                      sx={{
                        color: globalStyles.navigation.textColor,
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '24px',
                        margin: 0,
                      }}
                      variant="h6"
                    >
                      Account Settings
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => dispatch(changeExtraCreditPopup(true))}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        color: globalStyles.navigation.textColor,
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '24px',
                        marginRight: 5,
                      }}
                    >
                      Balance
                    </Typography>

                    <Typography
                      component="span"
                      sx={{
                        color: globalStyles.navigation.currencyText,
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          color: globalStyles.mainColors.greenColor,
                          fontSize: '12px',
                          fontWeight: '500',
                        }}
                      >
                        {creditsBalance.toLocaleString()}&nbsp;
                      </Typography>
                      Credits
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      dispatch({ type: 'RESET' });
                      onLogout();
                    }}
                  >
                    <Typography
                      component="div"
                      gutterBottom
                      sx={{
                        color: globalStyles.navigation.redColor,
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '24px',
                        margin: 0,
                      }}
                      variant="h6"
                    >
                      Log out
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Popover>
        </Box>
      )}
    </Box>
  );
};
