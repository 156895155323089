import { ITokens } from '@a-type/dtos';
import { jwtDecode, JwtPayload } from 'jwt-decode';

import {
  authAccessLocalStorageWorker,
  authRefreshLocalStorageWorker,
  userLocalStorageWorker,
} from './local-storage.service';

export class JwtDecodeService {
  static deleteUserFromStorage() {
    userLocalStorageWorker.delete();
    authAccessLocalStorageWorker.delete();
    authRefreshLocalStorageWorker.delete();
  }

  static saveUserFromJwt(tokens: ITokens) {
    const token: string = tokens.access_token;
    const { exp, iat, ...user } = jwtDecode<JwtPayload>(token);
    userLocalStorageWorker.create(user as any);
    authAccessLocalStorageWorker.create(tokens.access_token);
    authRefreshLocalStorageWorker.create(tokens.refresh_token);
  }
}

export default JwtDecodeService;
