import { AppendType } from '@a-type/enums';
import { IVersiumAppend } from '@a-type/interfaces';
import globalStyles from '@a-type/ui/styles/global.styles';
import CheckCircle from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';

export interface AppendDataSelectorProps {
  appendData: IVersiumAppend | null;
  appendTypes: AppendType[];
  onSubTypeSelect: (typeId: string, subTypeId: string) => void;
  onTypeSelect: (typeId: string, checked: boolean) => void;
  selectedSubTypes: { [key: string]: string };
  selectedTypes: string[];
}

export const AppendDataSelector: React.FC<AppendDataSelectorProps> = ({
  appendData,
  appendTypes,
  onSubTypeSelect,
  onTypeSelect,
  selectedSubTypes,
  selectedTypes,
}: AppendDataSelectorProps) => {
  const show = useMemo(() => {
    return (
      appendData &&
      (appendData.types.length > 1 ||
        (appendData.types[0].subTypes && appendData.types[0].subTypes.length > 1))
    );
  }, [appendData]);

  const alreadyBought = (typeId: string, subTypeId?: string) => {
    const type = appendData?.types.find((t) => t._id === typeId);
    if (!type) return false;

    if (!subTypeId) {
      // If no sub types and type is in appendTypes, then it's already bought
      if (!type.subTypes || type.subTypes.length === 0) {
        return appendTypes.includes(type.code);
      }

      // If type has sub types, then check if every of the sub types are in appendTypes
      return type.subTypes.every((st) => appendTypes.includes(st.code));
    }

    const subType = type?.subTypes?.find((st) => st._id === subTypeId);
    return subType ? appendTypes.includes(subType.code) : false;
  };

  return show && appendData ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '100%',
      }}
    >
      <Typography sx={{ fontSize: 17, fontWeight: 700 }}>Select Output Type</Typography>

      {appendData.types.map((type) => {
        const typeAlreadyBought = alreadyBought(type._id);

        return (
          <Box
            key={type._id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <FormControlLabel
              control={<Checkbox size="small" />}
              disabled={typeAlreadyBought}
              key={type._id}
              label={
                <Box
                  sx={{
                    alignItems: 'baseline',
                    display: 'flex',
                    gap: 1,
                    mt: 0.25,
                  }}
                >
                  <Typography
                    sx={{
                      color: typeAlreadyBought
                        ? globalStyles.mainColors.grey74Color
                        : globalStyles.mainColors.sootyColor,
                      fontSize: 16,
                      fontWeight: '500',
                    }}
                  >
                    {type.name}
                  </Typography>

                  {(!type.subTypes || type.subTypes.length === 0) && (
                    <>
                      <Typography
                        sx={{
                          color: typeAlreadyBought
                            ? globalStyles.mainColors.grey74Color
                            : globalStyles.mainColors.sootyColor,
                          fontSize: 14,
                          fontWeight: '500',
                        }}
                      >
                        (
                        <Typography
                          component="span"
                          sx={{
                            color: typeAlreadyBought
                              ? globalStyles.mainColors.grey74Color
                              : globalStyles.mainColors.greenColor,
                            fontSize: 14,
                            fontWeight: '500',
                          }}
                        >
                          {type.price} Credits&nbsp;
                        </Typography>
                        / Match)
                      </Typography>

                      {type.description && (
                        <Tooltip placement="top" title={type.description}>
                          <InfoOutlinedIcon
                            fontSize="small"
                            sx={{
                              alignSelf: 'flex-start',
                              color: typeAlreadyBought
                                ? globalStyles.mainColors.grey74Color
                                : globalStyles.navigation.textColor,
                              mt: 0.25,
                            }}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}

                  {typeAlreadyBought && (
                    <Tooltip
                      placement="top"
                      title="You already have this data in your list. You can't buy it again."
                    >
                      <CheckCircle
                        fontSize="small"
                        sx={{
                          alignSelf: 'flex-start',
                          color: globalStyles.mainColors.greenColor,
                          mt: 0.25,
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              }
              onChange={(e, checked) => onTypeSelect(type._id, checked)}
              value={selectedTypes.includes(type._id)}
            />

            {selectedTypes.includes(type._id) && type.subTypes && type.subTypes.length > 1 && (
              <RadioGroup
                onChange={(e) => onSubTypeSelect(type._id, e.target.value)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  pl: 4,
                }}
                value={selectedSubTypes[type._id] || ''}
              >
                {type.subTypes.map((subType) => {
                  const subTypeAlreadyBought = alreadyBought(type._id, subType._id);

                  return (
                    <FormControlLabel
                      control={<Radio size="small" />}
                      disabled={subTypeAlreadyBought}
                      key={subType._id}
                      label={
                        <Box
                          sx={{
                            alignItems: 'baseline',
                            display: 'flex',
                            gap: 1,
                            mt: 0.25,
                          }}
                        >
                          <Typography
                            sx={{
                              color: subTypeAlreadyBought
                                ? globalStyles.mainColors.grey74Color
                                : globalStyles.mainColors.sootyColor,
                              component: 'span',
                              fontSize: '16spx',
                              fontWeight: '500',
                            }}
                          >
                            {subType.name}
                          </Typography>
                          <Typography
                            sx={{
                              color: subTypeAlreadyBought
                                ? globalStyles.mainColors.grey74Color
                                : globalStyles.mainColors.sootyColor,
                              fontSize: '14px',
                              fontWeight: '500',
                            }}
                          >
                            (
                            <Typography
                              component="span"
                              sx={{
                                color: subTypeAlreadyBought
                                  ? globalStyles.mainColors.grey74Color
                                  : globalStyles.mainColors.greenColor,
                                fontSize: '14px',
                                fontWeight: '500',
                              }}
                            >
                              {subType.price} Credits&nbsp;
                            </Typography>
                            / Match)
                          </Typography>

                          {subType.description && (
                            <Tooltip placement="top" title={subType.description}>
                              <InfoOutlinedIcon
                                fontSize="small"
                                sx={{
                                  alignSelf: 'flex-start',
                                  color: subTypeAlreadyBought
                                    ? globalStyles.mainColors.grey74Color
                                    : globalStyles.navigation.textColor,
                                  mt: 0.25,
                                }}
                              />
                            </Tooltip>
                          )}

                          {subTypeAlreadyBought && (
                            <Tooltip
                              placement="top"
                              title="You already have this data in your list. You can't buy it again."
                            >
                              <CheckCircle
                                fontSize="small"
                                sx={{
                                  alignSelf: 'flex-start',
                                  color: globalStyles.mainColors.greenColor,
                                  mt: 0.25,
                                }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      }
                      value={subType._id}
                    />
                  );
                })}
              </RadioGroup>
            )}
          </Box>
        );
      })}
    </Box>
  ) : null;
};

export default AppendDataSelector;
