import { IAppendDataRequest } from '@a-type/dtos';
import { AppendMode, AppendType, FieldMetadataType, MatchType } from '@a-type/enums';
import { IDocument, IVersiumAppend } from '@a-type/interfaces';
import { Dialog } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { listService } from '@a-type/ui/services';
import {
  pageContentLoad,
  resetList,
  setList,
  snackbarErrorMessage,
} from '@a-type/ui/stores/actions';
import {
  useAppendDataMutation,
  useGetDocumentByIdWithNavigationQuery,
  useGetUserAppendDataQuery,
  useGetUserBalanceQuery,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AppendDataSelector } from './components';
import { AppendDataCard } from './components/AppendDataCard.component';

const accentColors = ['#EB2F96', '#9C27B0', '#13C2C2', '#FFC107', '#FF5722'];

export const AppendDataPage = () => {
  const { documentId, listId } = useParams<{ documentId: string; listId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list } = useSelector((state) => state.list);
  const { data: creditsBalance = 0 } = useGetUserBalanceQuery();
  const { data: appendDataUtilsData, isLoading: isLoadingAppendDataUtils } =
    useGetUserAppendDataQuery();
  const [appendDataMutation, { isLoading: isAppendingData, isSuccess: isAppendingDataSuccess }] =
    useAppendDataMutation();
  const { data: documentData, isFetching: isDocumentLoading } =
    useGetDocumentByIdWithNavigationQuery(
      {
        documentId: documentId!,
        listId: listId!,
      },
      {
        skip: !listId || !documentId,
      },
    );
  const [document, setDocument] = useState<IDocument | null>(null);
  const [appendDataUtils, setAppendDataUtils] = useState<IVersiumAppend[]>([]);
  const [selectedAppendData, setSelectedAppendData] = useState<IVersiumAppend | null>(null);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedSubTypes, setSelectedSubTypes] = useState<{ [key: string]: string }>({});
  const [matchType, setMatchType] = useState<MatchType>(MatchType.INDIVIDUAL_MATCHES);
  const [appendMode, setAppendMode] = useState<AppendMode>(AppendMode.APPEND);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [typesWithPrice, setTypesWithPrice] = useState<{ name: string; price: number }[]>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const [isEnoughCredits, setIsEnoughCredits] = useState<boolean>(false);

  /**
   * Dispatch page content load action
   */
  useEffect(() => {
    dispatch(pageContentLoad(!isLoadingAppendDataUtils && !isAppendingData && !isDocumentLoading));
  }, [isLoadingAppendDataUtils, isAppendingData, isDocumentLoading]);

  /**
   * Redirect to list page after append data is successful
   */
  useEffect(() => {
    if (isAppendingDataSuccess) {
      if (documentId) {
        navigate(`${AppRoutes.Lists}/${list?._id}/tax-assessor/${documentId}`); // Redirect to document page after append data
      } else {
        navigate(`${AppRoutes.Lists}/${list?._id}`); // Redirect to list page after append data
      }
    }
  }, [isAppendingDataSuccess]);

  /**
   * Get append data utils
   */
  useEffect(() => {
    if (appendDataUtilsData) {
      setAppendDataUtils(appendDataUtilsData);
    }
  }, [appendDataUtilsData]);

  /**
   * Set document data
   */
  useEffect(() => {
    if (documentData) {
      setDocument(documentData.document);
    }
  }, [documentData]);

  /**
   * Set selected types, after append data is selected
   */
  useEffect(() => {
    if (selectedAppendData) {
      // Clear selected types and subtypes
      setSelectedSubTypes({});

      // If append data has only one type and no subtypes, select it
      if (
        selectedAppendData.types.length === 1 &&
        (!selectedAppendData.types[0].subTypes || selectedAppendData.types[0].subTypes.length === 0)
      ) {
        setSelectedTypes([selectedAppendData.types[0]._id]);
      } else {
        setSelectedTypes([]);
      }
    }
  }, [selectedAppendData]);

  useEffect(() => {
    if (totalPrice > creditsBalance) {
      setIsEnoughCredits(false);
    } else {
      setIsEnoughCredits(true);
    }
  }, [creditsBalance, totalPrice]);

  const handleTypeChecked = (typeId: string) => {
    setSelectedTypes([...selectedTypes, typeId]);
  };

  const handleTypeUnchecked = (typeId: string) => {
    setSelectedTypes(selectedTypes.filter((type) => type !== typeId));
  };

  /**
   * Handle subtype select
   * @param typeId Type id
   * @param subTypeId Subtype id
   */
  const handleSubTypeSelect = (typeId: string, subTypeId: string) => {
    setSelectedSubTypes({
      ...selectedSubTypes,
      [typeId]: subTypeId,
    });
  };

  const appendDataHandler = async () => {
    if (!list || !isValid) return;

    appendDataMutation({
      data: {
        appendMode,
        id: selectedAppendData!._id,
        matchType,
        subTypes: selectedSubTypes,
        types: selectedTypes,
      } as IAppendDataRequest,
      documentId,
      id: list._id,
    });
  };

  useEffect(() => {
    if (selectedAppendData) {
      if (
        selectedTypes.length === 1 &&
        selectedAppendData.types.length === 1 &&
        (!selectedAppendData.types[0].subTypes || selectedAppendData.types[0].subTypes.length === 0)
      ) {
        setIsValid(true);
      } else if (selectedTypes.length > 0) {
        setIsValid(
          !selectedTypes.some((type) => {
            const tp = selectedAppendData.types.find((t) => t._id === type);
            return selectedSubTypes[type] === undefined && tp?.subTypes && tp.subTypes.length > 0;
          }),
        );
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  }, [selectedAppendData, selectedTypes, selectedSubTypes]);

  useEffect(() => {
    if (selectedAppendData && list && selectedTypes.length > 0) {
      const types = selectedAppendData.types.reduce<{ name: string; price: number }[]>(
        (acc, type) => {
          // if type is selected, add price
          if (selectedTypes.includes(type._id)) {
            // If type has subtypes, add price for each selected subtype
            if (type.subTypes && type.subTypes.length > 0 && selectedSubTypes[type._id]) {
              const subType = type.subTypes.find((s) => selectedSubTypes[type._id] === s._id);
              if (subType) {
                return [...acc, { name: type.name, price: subType.price }];
              }
            }

            return [...acc, { name: type.name, price: type.price }];
          }

          return acc;
        },
        [],
      );
      const pricePerRecord = types.reduce((acc, type) => acc + +type.price, 0);

      setTypesWithPrice(types);
      setTotalPrice(documentId ? pricePerRecord : pricePerRecord * list.totalRecords);
    }
  }, [selectedAppendData, selectedTypes, selectedSubTypes]);

  const selectedFields = useMemo(() => {
    return Object.values(list?.fieldMetadata || {})
      .filter((f) => f?.metadataType)
      .map((f) => f.metadataType as FieldMetadataType);
  }, [list]);

  const alreadyBought = (append: IVersiumAppend) => {
    const types = document?.appendTypes || list?.appendTypes || [];

    if (types.length === 0) {
      return false;
    }

    return append.types.every((type) => {
      if (type.subTypes && type.subTypes.length > 0) {
        return type.subTypes.every((subType) => types.includes(subType.code) || false);
      }

      return types.includes(type.code) || false;
    });
  };

  const checkRequiredFieldGroups = (type: FieldMetadataType[][], inputs: FieldMetadataType[]) => {
    return type.some((group) => {
      return group.every((field) => inputs.includes(field));
    });
  };

  const notEnoughInputs = (append: IVersiumAppend) => {
    const inputs = Object.keys(list?.fieldMetadata || {})
      .map((key) => list?.fieldMetadata![key])
      .filter((field) => field?.metadataType)
      .map((field) => field!.metadataType!);

    return !append.types.every((type) => {
      if (type.subTypes && type.subTypes.length > 0) {
        return type.subTypes.some((subType) => {
          return checkRequiredFieldGroups(subType.requiredFieldGroups, inputs);
        });
      }

      return checkRequiredFieldGroups(type.requiredFieldGroups, inputs);
    });
  };

  /**
   * Get list by id
   * @param id
   */
  const getListById = async (id: string) => {
    const response = await listService.getById(id);
    if (response.status === 200) {
      dispatch(setList(response.data));
      return response.data;
    }
    dispatch(snackbarErrorMessage('List not found'));
    navigate(AppRoutes.HomePage);
    return null;
  };

  /**
   * Reset list and load list by id
   */
  useEffect(() => {
    if (!listId) {
      return;
    }

    dispatch(resetList());
    dispatch(pageContentLoad(false));

    if (!listId) {
      dispatch(snackbarErrorMessage('List not found'));
      navigate(AppRoutes.HomePage);
      dispatch(pageContentLoad(true));
    } else {
      const loadList = async () => {
        await getListById(listId);
        dispatch(pageContentLoad(true));
      };

      loadList();
    }
  }, [listId]);

  return (
    <PageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: '1px solid #E0E0E0',
            display: 'flex',
            justifyContent: 'space-between',
            pb: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 4,
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
              }}
            >
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeftOutlinedIcon />
              </IconButton>
              <Typography
                sx={{ color: globalStyles.mainColors.sootyColor, fontSize: 24, fontWeight: 700 }}
              >
                Append Data
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              gap: 2,
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                minWidth: 160,
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              disabled={!isValid || !isEnoughCredits}
              onClick={() => {
                setShowConfirmationDialog(true);
              }}
              sx={{
                minWidth: 160,
              }}
              variant="contained"
            >
              Append Data
            </Button>
          </Box>
        </Box>

        {appendDataUtils.length > 0 && !isLoadingAppendDataUtils && (
          <>
            <Box
              sx={{
                display: 'flex',
                gap: 12,
                mb: 2,
                px: 2,
              }}
            >
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: 17, fontWeight: 700 }}>
                  Do you want the best match per house or all matches?
                </Typography>
                <RadioGroup
                  onChange={(e) => setMatchType(e.target.value as MatchType)}
                  value={matchType}
                >
                  <FormControlLabel
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        sx={{
                          color: globalStyles.mainColors.sootyColor,
                          fontSize: '14px',
                          fontWeight: '400',
                        }}
                      >
                        Individual matches
                      </Typography>
                    }
                    value={MatchType.INDIVIDUAL_MATCHES}
                  />
                  <FormControlLabel
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        sx={{
                          color: globalStyles.mainColors.sootyColor,
                          fontSize: '14px',
                          fontWeight: '400',
                        }}
                      >
                        Household matches
                      </Typography>
                    }
                    value={MatchType.HOUSE_HOLD_MATCHES}
                  />
                </RadioGroup>
              </FormControl>

              {documentId === undefined && (
                <FormControl
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 17, fontWeight: 700 }}>
                    Do you want to append or create new?
                  </Typography>
                  <RadioGroup
                    onChange={(e) => setAppendMode(e.target.value as AppendMode)}
                    value={appendMode}
                  >
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label={
                        <Typography
                          sx={{
                            color: globalStyles.mainColors.sootyColor,
                            fontSize: '14px',
                            fontWeight: '400',
                          }}
                        >
                          No, keep all my rows
                        </Typography>
                      }
                      value={AppendMode.APPEND}
                    />
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label={
                        <Typography
                          sx={{
                            color: globalStyles.mainColors.sootyColor,
                            fontSize: '14px',
                            fontWeight: '400',
                          }}
                        >
                          Create new list based on matches
                        </Typography>
                      }
                      value={AppendMode.CREATE_NEW}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                px: 2,
                width: '100%',
              }}
            >
              <Typography sx={{ fontSize: 17, fontWeight: 700 }}>
                What data should we try to append?
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gap: 3,
                  gridTemplateColumns: 'repeat(auto-fill, minmax(287px, 1fr))',
                  mb: 2,
                  width: '100%',
                }}
              >
                {appendDataUtils
                  .filter(
                    (appendData) =>
                      appendData.isActive && appendData.targetType === list?.targetType,
                  )
                  .filter(
                    (appendData) =>
                      !documentId ||
                      appendData.types.some((t) => t.code !== AppendType.B2C_ONLINE_AUDIENCE),
                  )
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((appendData, index) => (
                    <AppendDataCard
                      accentColor={accentColors[index % accentColors.length]}
                      alreadyBought={alreadyBought(appendData)}
                      appendData={appendData}
                      key={appendData._id}
                      notEnoughInputs={notEnoughInputs(appendData)}
                      onClick={() => setSelectedAppendData(appendData)}
                      selected={selectedAppendData?._id === appendData._id}
                      selectedFields={selectedFields}
                    />
                  ))}
              </Box>
              <AppendDataSelector
                appendData={selectedAppendData}
                appendTypes={document?.appendTypes || list?.appendTypes || []}
                onSubTypeSelect={handleSubTypeSelect}
                onTypeSelect={(typeId, checked) =>
                  checked ? handleTypeChecked(typeId) : handleTypeUnchecked(typeId)
                }
                selectedSubTypes={selectedSubTypes}
                selectedTypes={selectedTypes}
              />
            </Box>
          </>
        )}

        {appendDataUtils.length === 0 && !isLoadingAppendDataUtils && (
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: globalStyles.mainColors.whiteSmokeColor,
              borderRadius: '6px',
              display: 'flex',
              flexGrow: 1,
              height: 500,
              justifyContent: 'center',
            }}
          >
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 20,
                fontStyle: 'italic',
                fontWeight: 400,
              }}
            >
              There are no available append data.
            </Typography>
          </Box>
        )}
      </Box>

      <Dialog
        cancelText="No"
        disableOk={!isEnoughCredits}
        okText="Yes, Append Data"
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
        onOk={appendDataHandler}
        open={showConfirmationDialog}
        title="Final Confirmation"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography component="span" sx={{ fontSize: 16, fontWeight: 400 }}>
              We are going to try to append the following information to your{' '}
              {documentId ? 'document' : 'list'}:
            </Typography>
            {typesWithPrice.map((type) => (
              <Typography component="span" key={type.name} sx={{ fontSize: 16, fontWeight: 700 }}>
                {type.name} (
                <Typography
                  component="span"
                  sx={{ color: globalStyles.mainColors.greenColor, fontSize: 16, fontWeight: 700 }}
                >
                  {type.price} Credit&nbsp;
                </Typography>
                / Match)
              </Typography>
            ))}

            {documentId ? (
              <Typography component="span" sx={{ fontSize: 16, fontWeight: 400 }}>
                For adding data to your document, it could cost{' '}
                <Typography
                  component="span"
                  sx={{ color: globalStyles.mainColors.greenColor, fontSize: 16, fontWeight: 700 }}
                >
                  {totalPrice} Credits.
                </Typography>
              </Typography>
            ) : (
              <Typography component="span" sx={{ fontSize: 16, fontWeight: 400 }}>
                With a maximum number of{' '}
                <Typography component="span" sx={{ fontSize: 16, fontWeight: 700 }}>
                  {list?.totalRecords}
                </Typography>{' '}
                records in your list, it could cost{' '}
                <Typography
                  component="span"
                  sx={{ color: globalStyles.mainColors.greenColor, fontSize: 16, fontWeight: 700 }}
                >
                  {totalPrice} Credits.
                </Typography>
              </Typography>
            )}
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
            Are you sure you want to proceed?
          </Typography>
        </Box>
      </Dialog>
    </PageLayout>
  );
};
