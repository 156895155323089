import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { AxlePeopleLookupType } from '@a-type/enums';
import { IDataSource } from '@a-type/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DataSourceState {
  currentDataSource: IDataSource | null;
  dataSources: IDataSource[];
  examples: any[];
  filtersComponents: IDataSourceFilterComponentDto[];
  lookupsMapping: { [key: string]: AxlePeopleLookupType };
  uploadedCsvFile: any | null;
}

const initialState: DataSourceState = {
  currentDataSource: null,
  dataSources: [],
  examples: [],
  filtersComponents: [],
  lookupsMapping: {},
  uploadedCsvFile: null,
};

const dataSourceSlice = createSlice({
  initialState,
  name: 'dataSource',
  reducers: {
    resetDataSource(state) {
      return {
        ...state,
        currentDataSource: null,
        examples: [],
        lookupsMapping: {},
        uploadedCsvFile: null,
      };
    },
    setCurrentDataSource(state, action: PayloadAction<IDataSource>) {
      return {
        ...state,
        currentDataSource: action.payload,
      };
    },
    setDataSources(state, action: PayloadAction<IDataSource[]>) {
      return {
        ...state,
        dataSources: action.payload,
      };
    },
    setExamples(state, action: PayloadAction<any[]>) {
      return {
        ...state,
        examples: action.payload,
      };
    },
    setFiltersComponents(state, action: PayloadAction<IDataSourceFilterComponentDto[]>) {
      return {
        ...state,
        filtersComponents: action.payload,
      };
    },
    setLookupsMapping(state, action: PayloadAction<{ [key: string]: AxlePeopleLookupType }>) {
      return {
        ...state,
        lookupsMapping: action.payload,
      };
    },
    setUploadedCsvFile(state, action: PayloadAction<any>) {
      return {
        ...state,
        uploadedCsvFile: action.payload,
      };
    },
  },
});

export const {
  resetDataSource,
  setCurrentDataSource,
  setDataSources,
  setExamples,
  setFiltersComponents,
  setLookupsMapping,
  setUploadedCsvFile,
} = dataSourceSlice.actions;

export default dataSourceSlice.reducer;
