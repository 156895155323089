import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, ListItem, Typography } from '@mui/material';
import { FC } from 'react';

import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';

interface ITaxAssessorsDocumentFieldProps {
  field: ITaxAssessorsDocumentField;
}

export const TaxAssessorsDocumentField: FC<ITaxAssessorsDocumentFieldProps> = ({
  field,
}: ITaxAssessorsDocumentFieldProps) => {
  return (
    <ListItem>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          width: '100%',
        }}
      >
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            flexBasis: '240px',
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {field.label}
        </Typography>
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.blackColor,
            flexGrow: 1,
            fontSize: 16,
          }}
        >
          {field.value}
        </Typography>
      </Box>
    </ListItem>
  );
};
