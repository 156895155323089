import { DataSourceFieldComponentType, DataSourceFieldDataType } from '@a-type/enums';
import { IDataSourceField, IDataSourceFieldOption } from '@a-type/interfaces';
import { Switch } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { snackbarErrorMessage } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Button, Chip, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export interface DataSourceDetailsFieldConfiguratorFilterOptionsProps {
  field: IDataSourceField;
  updateFieldValueHandler: (key: string, value: any) => void;
}

const DataSourceDetailsFieldConfiguratorFilterOptions = (
  props: DataSourceDetailsFieldConfiguratorFilterOptionsProps,
) => {
  const { field, updateFieldValueHandler } = props;
  const dispatch = useDispatch();

  const [addOption, setAddOption] = useState(false);
  const [newOptionId, setNewOptionId] = useState('');
  const [newOptionValue, setNewOptionValue] = useState('');
  const [newOptionLabel, setNewOptionLabel] = useState('');
  const [newOptionFromValue, setNewOptionFromValue] = useState('');
  const [newOptionToValue, setNewOptionToValue] = useState('');
  const [isFromToValueValid, setIsFromToValueValid] = useState(false);

  const clearNewOptionFields = () => {
    setNewOptionId('');
    setNewOptionValue('');
    setNewOptionLabel('');
    setNewOptionFromValue('');
    setNewOptionToValue('');
  };

  useEffect(() => {
    // if field is boolean and options are not 2, add missing options
    if (field.dataType === DataSourceFieldDataType.BOOLEAN && field.options?.length !== 2) {
      const options = field.options || [];

      if (!options.some((option) => option.value === 'true')) {
        options.push({ _id: 'true', label: 'Yes', value: 'true' });
      }

      if (!options.some((option) => option.value === 'false')) {
        options.push({ _id: 'false', label: 'No', value: 'false' });
      }

      updateFieldValueHandler('options', options);
    }
  }, [field]);

  const updateOption = (option: IDataSourceFieldOption) => {
    const newOptions = field.options!.map((o) => {
      if (o._id === option._id) {
        return option;
      }
      return o;
    });
    updateFieldValueHandler('options', newOptions);
  };

  const addNewOption = () => {
    if (field.dataType === DataSourceFieldDataType.STRING && !newOptionValue) {
      dispatch(snackbarErrorMessage('Value is required'));
      return;
    }

    if (
      field.dataType === DataSourceFieldDataType.NUMBER &&
      !newOptionFromValue &&
      !newOptionToValue
    ) {
      dispatch(snackbarErrorMessage('From Value or To Value is required'));
      return;
    }

    if (
      field.dataType === DataSourceFieldDataType.NUMBER &&
      newOptionFromValue &&
      newOptionToValue &&
      Number(newOptionFromValue) > Number(newOptionToValue)
    ) {
      dispatch(snackbarErrorMessage('From Value should be less than To Value'));
      return;
    }

    if (!newOptionLabel) {
      dispatch(snackbarErrorMessage('Label is required'));
      return;
    }

    const newOption = {
      from: newOptionFromValue,
      label: newOptionLabel,
      to: newOptionToValue,
      value: newOptionValue,
    } as IDataSourceFieldOption;

    if (newOptionId) {
      newOption._id = newOptionId;
      updateOption(newOption);
    } else {
      newOption._id = new Date().getTime().toString();
      const newOptions = [...(field.options || []), newOption];
      updateFieldValueHandler('options', newOptions);
    }
    clearNewOptionFields();
    setAddOption(false);
  };

  const deleteOption = (id: string) => {
    const newOptions = field.options!.filter((option) => option._id !== id);
    updateFieldValueHandler('options', newOptions);
  };

  const editOption = (option: IDataSourceFieldOption) => {
    setNewOptionId(option._id || '');
    setNewOptionValue(option.value || '');
    setNewOptionFromValue(option.from || '');
    setNewOptionToValue(option.to || '');
    setNewOptionLabel(option.label || '');
    setAddOption(true);
  };

  // Update Label base on value
  const generateQuantityLabel = (value: number) => {
    if (Math.abs(value) < 1000) {
      return value;
    }
    if (Math.abs(value) < 1000000 && value % 1000 === 0) {
      return `${value / 1000}K`;
    }
    if (Math.abs(value) < 1000000000 && value % 1000000 === 0) {
      return `${value / 1000000}M`;
    }
    if (value % 1000000000 === 0) {
      return `${value / 1000000000}B`;
    }
    return value;
  };

  const getCheapLabel = (option: IDataSourceFieldOption) => {
    if (field.dataType === DataSourceFieldDataType.STRING) {
      return `${option.label} (${option.value})`;
    }
    if (field.dataType === DataSourceFieldDataType.NUMBER) {
      if (option.from && option.to) {
        return `${option.label} (${option.from} - ${option.to})`;
      }
      if (option.from) {
        return `${option.label} (From ${option.from})`;
      }
      if (option.to) {
        return `${option.label} (Up to ${option.to})`;
      }
    }
    if (field.dataType === DataSourceFieldDataType.BOOLEAN) {
      return `${option.label} (${option.value})`;
    }
    return '';
  };

  const valueToLabel = () => {
    if (
      field.dataType === DataSourceFieldDataType.NUMBER &&
      (newOptionFromValue || newOptionToValue)
    ) {
      if (!newOptionFromValue) {
        setNewOptionLabel(`Up to ${generateQuantityLabel(Number(newOptionToValue))}`);
      } else if (!newOptionToValue) {
        setNewOptionLabel(`From ${generateQuantityLabel(Number(newOptionFromValue))}`);
      } else {
        if (Number(newOptionFromValue) === Number(newOptionToValue)) {
          setNewOptionLabel(generateQuantityLabel(Number(newOptionFromValue)).toString());
          return;
        }

        setNewOptionLabel(
          `${generateQuantityLabel(Number(newOptionFromValue))} - ${generateQuantityLabel(
            Number(newOptionToValue),
          )}`,
        );
      }
    }
    if (field.dataType === DataSourceFieldDataType.STRING && newOptionValue) {
      setNewOptionLabel(newOptionValue);
    }
  };

  useEffect(() => {
    if (!newOptionFromValue && !newOptionToValue) {
      setIsFromToValueValid(false);
    } else if (
      newOptionFromValue &&
      newOptionToValue &&
      Number(newOptionFromValue) > Number(newOptionToValue)
    ) {
      setIsFromToValueValid(false);
    } else {
      setIsFromToValueValid(true);
    }
  }, [newOptionFromValue, newOptionToValue]);

  const sortOptions = (options: IDataSourceFieldOption[]) => {
    return [...options].sort((a, b) => {
      if (a.value && b.value) {
        return a.value.localeCompare(b.value);
      }

      if (a.from && b.from) {
        return Number(a.from) - Number(b.from);
      }

      if (a.to && b.to) {
        return Number(a.to) - Number(b.to);
      }

      return 0;
    });
  };

  return (
    <>
      {field.componentType === DataSourceFieldComponentType.MULTI_SELECT && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, pt: 1 }}>
          <Switch
            checked={field.prefetchAllValues}
            label="Prefetch all values"
            onChange={() => {
              updateFieldValueHandler('prefetchAllValues', !field.prefetchAllValues);
            }}
          />
          <Typography>
            Prefetch all values will load all possible values for the field in the multi-select
            component. However, it&apos;s limited to 500 values. If the field has more than 500
            values, the user will not be able to see all of them.
          </Typography>
        </Box>
      )}

      {field.componentType === DataSourceFieldComponentType.OPTION_SELECT && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, pt: 1 }}>
          {field.dataType === DataSourceFieldDataType.STRING && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Switch
                checked={field.isFreeText}
                label={field.isFreeText ? 'Key word search' : 'Exact match'}
                onChange={() => {
                  updateFieldValueHandler('isFreeText', !field.isFreeText);
                }}
              />
              <Typography>
                {field.isFreeText
                  ? 'Key word search will allow the user to search for any part of the value.'
                  : 'Exact match will allow the user to search for the exact value.'}
              </Typography>
            </Box>
          )}

          {!addOption && field.dataType !== DataSourceFieldDataType.BOOLEAN && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Button
                onClick={() => {
                  setAddOption(true);
                }}
                startIcon={<AddCircleIcon />}
              >
                Add New Option
              </Button>
            </Box>
          )}

          {addOption && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 1 }}>
              {field.dataType === DataSourceFieldDataType.STRING && (
                <TextField
                  error={!newOptionValue}
                  label={field.isFreeText ? 'Key Words' : 'Value'}
                  onChange={(e) => {
                    setNewOptionValue(e.target.value);
                  }}
                  placeholder={field.isFreeText ? 'Key Words' : 'Value'}
                  size="small"
                  sx={{ background: globalStyles.mainColors.whiteColor, flexBasis: '40%' }}
                  value={newOptionValue}
                  variant="outlined"
                />
              )}

              {field.dataType === DataSourceFieldDataType.NUMBER && (
                <TextField
                  error={!isFromToValueValid}
                  label="From Value"
                  onChange={(e) => {
                    setNewOptionFromValue(e.target.value);
                  }}
                  placeholder="From Value"
                  size="small"
                  sx={{ background: globalStyles.mainColors.whiteColor, flexBasis: '20%', mr: 1 }}
                  type="number"
                  value={newOptionFromValue}
                  variant="outlined"
                />
              )}

              {field.dataType === DataSourceFieldDataType.NUMBER && (
                <TextField
                  error={!isFromToValueValid}
                  label="To Value"
                  onChange={(e) => {
                    setNewOptionToValue(e.target.value);
                  }}
                  placeholder="To Value"
                  size="small"
                  sx={{ background: globalStyles.mainColors.whiteColor, flexBasis: '20%' }}
                  type="number"
                  value={newOptionToValue}
                  variant="outlined"
                />
              )}
              {field.dataType !== DataSourceFieldDataType.BOOLEAN && (
                <IconButton
                  onClick={() => {
                    valueToLabel();
                  }}
                  sx={{ color: globalStyles.mainColors.blueColor }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              )}
              <TextField
                error={!newOptionLabel}
                label="Label"
                onChange={(e) => {
                  setNewOptionLabel(e.target.value);
                }}
                placeholder="Label"
                size="small"
                sx={{ background: globalStyles.mainColors.whiteColor, flexGrow: 1 }}
                value={newOptionLabel}
                variant="outlined"
              />
              <Box sx={{ display: 'flex' }}>
                <IconButton
                  onClick={() => {
                    addNewOption();
                  }}
                  sx={{ color: globalStyles.mainColors.greenColor, pl: 1 }}
                >
                  <CheckCircleIcon />
                </IconButton>
                {field.dataType !== DataSourceFieldDataType.BOOLEAN && (
                  <IconButton
                    onClick={() => {
                      clearNewOptionFields();
                      setAddOption(false);
                    }}
                    sx={{ color: globalStyles.mainColors.redColor }}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          )}

          {field.options && field.options.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {sortOptions(field.options!).map((option) => (
                <Chip
                  deleteIcon={
                    <Tooltip title={`Remove ${option.label} option field?`}>
                      <Box
                        onClick={(e) => {
                          deleteOption(option._id!);
                          e.stopPropagation();
                        }}
                        sx={{
                          alignItems: 'center',
                          background: globalStyles.mainColors.stainlessStellSecondColor,
                          borderRadius: 50,
                          color: globalStyles.mainColors.whiteColor,
                          display: 'flex',
                          height: 15,
                          justifyContent: 'center',
                          opacity: 0.7,
                          width: 15,
                        }}
                      >
                        <CloseIcon
                          sx={{
                            color: globalStyles.mainColors.whiteColor,
                            height: 15,
                            width: 15,
                          }}
                        />
                      </Box>
                    </Tooltip>
                  }
                  key={option._id!}
                  label={getCheapLabel(option)}
                  onClick={() => {
                    editOption(option);
                  }}
                  onDelete={
                    field.dataType === DataSourceFieldDataType.BOOLEAN ? undefined : () => {}
                  }
                  sx={{
                    background: globalStyles.mainColors.blackColor,
                    color: globalStyles.mainColors.whiteColor,
                    cursor: 'pointer',
                    margin: 1,
                  }}
                />
              ))}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default DataSourceDetailsFieldConfiguratorFilterOptions;
