import { ILogin } from '@a-type/dtos';
import { FormCheckbox, FormInput, FormPasswordInput } from '@a-type/ui/components';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import globalStyles from '@a-type/ui/styles/global.styles';
import { East } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

interface LoginFormProps {
  loading?: boolean;
  onSubmit: (values: ILogin) => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  loading = false,
  onSubmit,
}: LoginFormProps) => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<ILogin>();

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3.5,
          width: '440px',
        }}
      >
        <FormInput control={control} label="Email" name="email" required type="email" />
        <FormPasswordInput
          control={control}
          disablePasswordStrength
          label="Password"
          name="password"
        />

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            mt: -1,
          }}
        >
          <FormCheckbox control={control} label="Remember me" name="rememberMe" />

          <Typography
            component="a"
            onClick={() => {
              navigate(AppRoutes.ForgetPasswordPage);
            }}
            sx={{
              color: globalStyles.mainColors.blueColor,
              cursor: 'pointer',
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            Forgot password?
          </Typography>
        </Box>

        <LoadingButton loading={loading} sx={{ mt: 1 }} type="submit" variant="contained">
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.whiteColor,
                fontSize: 16,
                fontWeight: 700,
                wordBreak: 'break-word',
              }}
            >
              Login
            </Typography>
            <East sx={{ fontSize: '20px' }} />
          </Box>
        </LoadingButton>
      </Box>
    </form>
  );
};
