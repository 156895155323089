import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useConfirmMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Box, Container, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const ConfirmEmailPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { confirmAccountToken } = useParams();
  const [success, setSuccess] = useState(false);
  const [confirm, { data, error, isLoading }] = useConfirmMutation();

  useEffect(() => {
    if (confirmAccountToken && !success && !isLoading) {
      confirm({ token: confirmAccountToken });
    }
  }, [confirmAccountToken]);

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      dispatch(snackbarSuccessMessage('Email confirmed successfully'));
      setSuccess(true);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      dispatch(snackbarErrorMessage(getError(error) ?? 'Error while confirming email'));
      navigate(AppRoutes.LoginPage);
    }
  }, [error]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: success ? 'flex-start' : 'center',
        textAlign: 'center',
      }}
    >
      {success && (
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: globalStyles.mainColors.whiteColor,
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mt: 4,
            p: 4,
            width: '100%',
          }}
        >
          <Typography
            component="span"
            gutterBottom
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: '32px',
              fontWeight: '600',
              wordWrap: 'break-word',
            }}
          >
            Email Confirmed - Your Registration Is Now Under Review
          </Typography>
          <Divider
            sx={{
              backgroundColor: globalStyles.mainColors.gainsboroColor,
              height: '1px',
              width: '100%',
            }}
          />
          <Typography
            component="span"
            gutterBottom
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: '24px',
              fontWeight: '400',
              mt: 1,
              wordWrap: 'break-word',
            }}
          >
            Thank you for confirming your email address! Your registration on{' '}
            <strong>A Type Data</strong> is now complete, and your account is currently under review
            by our admin team.
          </Typography>
        </Box>
      )}
    </Container>
  );
};
