import { DataSourceFieldDataType } from '@a-type/enums';
import { IFieldMetadata } from '@a-type/interfaces';
import { GridColType } from '@mui/x-data-grid';

export class RowsAndColumnsBuilderMethods {
  static readonly access = (path: string, object: any) => {
    return path.split('.').reduce((o, i) => {
      if (o) {
        // check is array - remap array items
        if (Array.isArray(o)) {
          return o.map((item: any) => item[i]);
        }

        return o[i];
      }

      return undefined;
    }, object);
  };

  fieldsNames: { [key: string]: IFieldMetadata } = {};

  static getColumnType(type: DataSourceFieldDataType): GridColType {
    switch (type) {
      case DataSourceFieldDataType.BOOLEAN:
        return 'boolean';
      case DataSourceFieldDataType.DATE:
        return 'date';
      case DataSourceFieldDataType.NUMBER:
        return 'number';
      case DataSourceFieldDataType.STRING:
        return 'string';
      default:
        return 'string';
    }
  }

  static parseValue(value: any, type: DataSourceFieldDataType) {
    if (type === DataSourceFieldDataType.DATE) {
      try {
        return new Date(value);
      } catch {
        return value;
      }
    }

    if (type === DataSourceFieldDataType.NUMBER) {
      try {
        if (Number.isNaN(Number(value))) return value;
        return Number(value);
      } catch {
        return value;
      }
    }

    return value;
  }

  static removeForbiddenColumnName(field: string) {
    const listOfForbiddenFields = [
      'id',
      'latitude',
      'longitude',
      'location',
      'value',
      'label',
      'count',
      'updatedAt',
      'createdAt',
      'buyingDate',
      'family_id',
      'person_id',
      'infogroup_id',
      'ownerId',

      'creator',
      'square_footage',
      'estimated_location_employee_count',
      '__v',
      '_id',
    ];
    if (listOfForbiddenFields.some((forbiddenField: string) => forbiddenField === field))
      return true;
    return false;
  }

  static renderValue(value: any, type: DataSourceFieldDataType) {
    try {
      switch (type) {
        case DataSourceFieldDataType.ARRAY:
          return (value || []).join(' || ');
        case DataSourceFieldDataType.BOOLEAN:
          return value ? 'Yes' : 'No';
        case DataSourceFieldDataType.DATE:
          return new Date(value).toLocaleString();
        case DataSourceFieldDataType.NUMBER:
          return value.toLocaleString();
        default:
          // check if value is boolean
          if (value === true) return 'Yes';
          if (value === false) return 'No';

          return value;
      }
    } catch {
      return value;
    }
  }

  renderCell(params: any) {
    const value = RowsAndColumnsBuilderMethods.access(params.field, params.row);
    const type = this.fieldsNames[params.field].dataType;
    if (!value) return value;

    // check if value is array
    if (Array.isArray(value)) {
      return value
        .filter((v: any) => v)
        .map((v: any) => (type ? RowsAndColumnsBuilderMethods.renderValue(v, type) : v))
        .join(' || ');
    }
    if (type) {
      return RowsAndColumnsBuilderMethods.renderValue(value, type);
    }

    return value;
  }
}

export default RowsAndColumnsBuilderMethods;
