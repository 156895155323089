import { IFilterModel } from '@a-type/interfaces';
import { useSelector } from '@a-type/ui/hooks';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';

export interface BuyListSummaryTableProps {
  recordsToBuy: number;
}

export type BuyListSummaryTableRow = {
  name: string;
  price: number;
  quantity: number;
  total: number;
};

const BuyListSummaryTableComponent = (props: BuyListSummaryTableProps) => {
  const { recordsToBuy } = props;
  const { list } = useSelector((state) => state.list);
  const [rows, setRows] = useState<BuyListSummaryTableRow[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    if (!list) return;

    const r = [
      {
        name: list.pricingPlanName,
        price: list.pricingPlanPrice,
        quantity: recordsToBuy,
        total: recordsToBuy * list.pricingPlanPrice!,
      } as BuyListSummaryTableRow,
      ...Object.keys(list.filters || {})
        .filter((key) => list.filters![key].values!.length > 0)
        .map((key) => list.filters![key]!)
        .sort((a: IFilterModel, b: IFilterModel) => a.sortOrder! - b.sortOrder!)
        .map((filter: IFilterModel) => {
          return {
            name: filter.name,
            price: filter.price!,
            quantity: recordsToBuy,
            total: recordsToBuy * filter.price!,
          } as BuyListSummaryTableRow;
        }),
    ];
    setRows(r);
    setTotal(r.reduce((acc, row) => acc + row.total, 0));
  }, [list, recordsToBuy]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '11px',
                fontWeight: 700,
                lineHeight: '12px',
                textTransform: 'uppercase',
              }}
              width="50%"
            >
              Data type
            </TableCell>
            <TableCell
              align="right"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '11px',
                fontWeight: 700,
                lineHeight: '12px',
                textTransform: 'uppercase',
              }}
              width="20%"
            >
              Quantity
            </TableCell>
            <TableCell
              align="right"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '11px',
                fontWeight: 700,
                lineHeight: '12px',
                textTransform: 'uppercase',
              }}
              width="5%"
            >
              Price
            </TableCell>
            <TableCell
              align="right"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '11px',
                fontWeight: 700,
                lineHeight: '12px',
                textTransform: 'uppercase',
              }}
              width="25%"
            >
              Total, Credits
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ 'td,th': { border: 0 } }}>
              <TableCell component="th" scope="row" sx={{ fontSize: 16 }}>
                {row.name}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: 16 }}>
                {row.quantity}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: 16 }}>
                {row.price}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: 16 }}>
                {row.total}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter
          sx={{
            borderTop: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
          }}
        >
          <TableRow>
            <TableCell
              colSpan={3}
              sx={{ color: globalStyles.mainColors.sootyColor, fontSize: 16, fontWeight: 800 }}
            >
              Total
            </TableCell>
            <TableCell
              align="right"
              sx={{ color: globalStyles.mainColors.greenColor, fontSize: 16, fontWeight: 800 }}
            >
              {total}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default BuyListSummaryTableComponent;
