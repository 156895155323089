import { FC, useEffect, useRef } from 'react';

interface ITaxAssessorsDocumentMapProps {
  coordinates: [number, number];
}

export const TaxAssessorsDocumentMap: FC<ITaxAssessorsDocumentMapProps> = ({
  coordinates,
}: ITaxAssessorsDocumentMapProps) => {
  const googleMapRef = useRef(null);
  let googleMap: google.maps.Map | null = null;
  let marker: google.maps.marker.AdvancedMarkerElement | null = null;

  useEffect(() => {
    if (!coordinates) return;
    if (!googleMapRef.current) return;

    if (googleMap === null) {
      googleMap = new window.google.maps.Map(googleMapRef.current, {
        center: { lat: coordinates[0], lng: coordinates[1] },
        disableDefaultUI: true,
        mapId: 'TAX_ASSESSORS_MAP',
        zoom: 18,
      });
    }

    if (marker === null) {
      marker = new window.google.maps.marker.AdvancedMarkerElement({
        map: googleMap,
        position: { lat: coordinates[0], lng: coordinates[1] },
      });
    }
  }, [coordinates, googleMapRef]);

  return (
    <div
      id="map"
      ref={googleMapRef}
      style={{ borderRadius: '8px', height: 'auto', minHeight: '352px', minWidth: '422px' }}
    />
  );
};
