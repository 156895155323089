import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import {
  authAccessLocalStorageWorker,
  authRefreshLocalStorageWorker,
  userLocalStorageWorker,
} from '@a-type/ui/services';
import {
  logout,
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useDeleteUserMutation, useLogoutMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface IDeleteUserProps {
  setShow: (show: boolean) => void;
  show: boolean;
}

export const DeleteUser: FC<IDeleteUserProps> = ({ setShow, show }: IDeleteUserProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutUser, { isLoading: logoutUserLoading }] = useLogoutMutation();
  const [deleteUser, { isLoading: deleteUserLoading }] = useDeleteUserMutation();

  useEffect(() => {
    dispatch(pageContentLoad(!deleteUserLoading && !logoutUserLoading));
  }, [deleteUserLoading, logoutUserLoading]);

  const handleDelete = async () => {
    const result = await deleteUser();

    if (!result.error) {
      dispatch(snackbarSuccessMessage('User deleted successfully'));
      await logoutUser();

      userLocalStorageWorker.delete();
      authAccessLocalStorageWorker.delete();
      authRefreshLocalStorageWorker.delete();

      dispatch(logout());
      navigate(AppRoutes.LoginPage);
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while deleting user'));
    }

    dispatch(pageContentLoad(true));
    setShow(false);
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Yes, Delete"
      onCancel={() => setShow(false)}
      onClose={() => setShow(false)}
      onOk={handleDelete}
      open={show}
      title="Delete User"
    >
      <Typography
        component="span"
        sx={{
          color: globalStyles.mainColors.sootyColor,
          fontSize: 16,
          fontWeight: 400,
        }}
      >
        Are you sure you want to delete your account? This action cannot be undone.
      </Typography>
    </Dialog>
  );
};
