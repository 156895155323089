import { HttpStatus } from '@a-type/ui/types';
import axios, { AxiosError } from 'axios';

import { JwtDecodeService } from './jwt-decode.service';
import {
  authAccessLocalStorageWorker,
  authRefreshLocalStorageWorker,
  userLocalStorageWorker,
} from './local-storage.service';

const SetupInterceptors = async (props: any) => {
  const { logoutHandler } = props;
  const refreshUrl = `${process.env.REACT_APP_API_HOST}/auth/refresh`;
  const loginUrl = `${process.env.REACT_APP_API_HOST}/auth/login`;

  const logout = async () => {
    userLocalStorageWorker.delete();
    authAccessLocalStorageWorker.delete();
    authRefreshLocalStorageWorker.delete();
    logoutHandler();
  };

  // indicates if the access token is being refreshed
  let isRefreshing = false;
  // queue to store failed requests
  let failedQueue: {
    reject: (error: any) => void;
    resolve: (arg: any) => void;
  }[] = [];

  /**
   * Process the queue of failed requests
   * @param error The error to reject the requests with
   */
  const processQueue = (error?: Error) => {
    failedQueue.forEach((item) => {
      // if there is an error reject the request
      if (error) {
        item.reject(error);
      } else {
        item.resolve({});
      }
    });

    failedQueue = [];
  };

  // Add a request interceptor
  axios.interceptors.request.use(
    (config: any) => {
      // get access token from local storage
      const accessToken = authAccessLocalStorageWorker.read();

      // check if the request is not a refresh request and the access token is not empty
      // then add the access token to the request headers
      if (!isRefreshing && accessToken && config.url !== refreshUrl) {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        };
      }

      // get refresh token from local storage
      const refreshToken = authRefreshLocalStorageWorker.read();
      if (!refreshToken) {
        return config;
      }
      if (config.url === refreshUrl) {
        // check if the request is a refresh request
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${refreshToken}`,
          },
        };
      }

      return config;
    },
    (error: Error) => {
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err: AxiosError) => {
      // get the original request from the error
      const { config, response } = err;

      // check if the response and the config are not empty
      if (!response || !config) {
        return Promise.reject(err);
      }

      // check if the error is 401 and the original request is login
      if (response.status === HttpStatus.UNAUTHORIZED && config.url === loginUrl) {
        // return the error if the login request failed
        return Promise.reject(err);
      }

      // check if the error is 401 and the original request is a refresh request
      if (response.status === HttpStatus.UNAUTHORIZED && config.url === refreshUrl) {
        // delete the user from local storage and logout if the refresh token is expired
        JwtDecodeService.deleteUserFromStorage();
        processQueue(err);
        isRefreshing = false;
        logout();
      }
      // check if the error is 401 and the original request is not a retry request
      else if (response.status === HttpStatus.UNAUTHORIZED && config._retry !== true) {
        // check if the access token is being refreshed
        if (isRefreshing) {
          // return a promise to retry the request after the access token has been refreshed
          return new Promise((resolve, reject) => {
            failedQueue.push({ reject, resolve });
          })
            .then(() => {
              // retry the request
              return axios(config);
            })
            .catch((e: Error) => {
              // return the error if the request failed
              return Promise.reject(e);
            });
        }

        // set the _retry property to true to indicate that the request has been retried
        config._retry = true;
        // set isRefreshing to true to indicate that the access token is srarting to refresh
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          // refresh the access token
          axios
            .post(refreshUrl)
            .then((r) => {
              // save the user from the new access token
              JwtDecodeService.saveUserFromJwt(r.data);
              // set isRefreshing to false to indicate that the access token has been refreshed
              isRefreshing = false;
              // retry the request
              resolve(axios(config));
              // process the queue of failed requests
              processQueue();
            })
            .catch((e: Error) => {
              // set isRefreshing to false to indicate that the access token has not been refreshed
              isRefreshing = false;
              // return the error if the access token refresh failed
              reject(e);
              // process the queue of failed requests
              processQueue(e);
            });
        });
      }

      return Promise.reject(err);
    },
  );
};

export default SetupInterceptors;
