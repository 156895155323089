import { SxProps, TextField, Theme } from '@mui/material';
import { AnimationEvent, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Control, Controller, FieldValues, RegisterOptions, Validate } from 'react-hook-form';

type IFormInputProps<TModel extends FieldValues> = {
  control: Control<TModel>;
  label: string;
  max?: number;
  maxLength?: number;
  min?: number;
  multiline?: boolean;
  name: string;
  onChange?: (e: any) => void;
  required?: boolean;
  rows?: number;
  rules?: Record<string, Validate<string, any>>;
  step?: number;
  sx?: SxProps<Theme>;
  type?: string;
};

const validateRequired = (value: any) =>
  (value.toString().trim() !== '' && value !== undefined && value !== null) || 'Field is required';

const validateEmail = (value: any) => /\S+@\S+\.\S+/.test(value) || 'Invalid email';

const validateMax = (max: number) => (value: any) => value <= max || `Maximum value is ${max}`;

const validateMin = (min: number) => (value: any) => value >= min || `Minimum value is ${min}`;

const validateTel = (value: any) => /^\+?\d{1,15}$/.test(value) || 'Invalid phone number';

const buildValidation = (
  type: string,
  required?: boolean,
  max?: number,
  min?: number,
  rules?: Record<string, Validate<string, any>>,
):
  | Omit<
      RegisterOptions<FieldValues, string>,
      'disabled' | 'setValueAs' | 'valueAsDate' | 'valueAsNumber'
    >
  | undefined => {
  const validations: Record<string, Validate<string, any>> = {
    required: required ? validateRequired : () => true,
    ...{
      email: type === 'email' ? validateEmail : () => true,
      max: max !== undefined ? validateMax(max) : () => true,
      min: min !== undefined ? validateMin(min) : () => true,
      tel: type === 'tel' ? validateTel : () => true,
    },
    ...rules,
  };

  return { validate: validations };
};

export const FormInput = <TModel extends FieldValues>({
  control,
  label,
  max,
  maxLength = 200,
  min,
  multiline = false,
  name,
  onChange,
  required = false,
  rows = 3,
  rules = {},
  step,
  sx,
  type = 'text',
}: IFormInputProps<TModel>) => {
  const [hasValue, setHasValue] = useState(false);

  // For re-usability, I made this a function that accepts a useState set function
  // and returns a handler for each input to use, since you have at least two
  // TextFields to deal with.
  const makeAnimationStartHandler =
    (stateSetter: Dispatch<SetStateAction<boolean>>) =>
    (e: AnimationEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const target = e.target as HTMLInputElement | HTMLTextAreaElement;
      const autofilled = !!target?.matches('*:-webkit-autofill');
      if (e.animationName === 'mui-auto-fill') {
        stateSetter(autofilled);
      }

      if (e.animationName === 'mui-auto-fill-cancel') {
        stateSetter(autofilled);
      }
    };

  // count of sings after dot
  const fixed = useMemo(() => {
    if (step === undefined) {
      return 0;
    }

    const parts = step.toString().split('.');

    return parts.length > 1 ? parts[1].length : 0;
  }, [step]);

  return (
    <Controller
      control={control as Control<FieldValues>}
      defaultValue=""
      name={name}
      render={({ field: { onChange: onFormChange, value }, fieldState: { error } }) => (
        <TextField
          error={!!error}
          fullWidth
          helperText={error?.message}
          InputLabelProps={{
            shrink: Boolean(hasValue || value || value === 0),
          }}
          inputProps={{
            max: type === 'number' ? max : undefined,
            maxLength,
            min: type === 'number' ? min : undefined,
            onAnimationStart: makeAnimationStartHandler(setHasValue),
            step: type === 'number' ? step : undefined,
          }}
          label={label}
          multiline={multiline}
          onChange={(e) => {
            if (type === 'tel') {
              e.target.value = e.target.value
                // Allow only digits and plus sign
                .replace(/[^\d+]/g, '')
                // replace plus sign anywhere except the beginning of the string
                .replace(/^\+?(\d{1,15}).*/, '+$1');
            }

            if (type === 'number') {
              e.target.value = e.target.value.replace(/[^0-9.]/g, '');

              // handle max and min
              if (max !== undefined && +e.target.value > max) {
                e.target.value = max.toString();
              }

              if (min !== undefined && +e.target.value < min) {
                e.target.value = min.toString();
              }

              if (step !== undefined) {
                e.target.value = (Math.round(+e.target.value / step) * step).toFixed(fixed);
              }
            }

            onFormChange(e);
            onChange?.(e);
          }}
          required={required}
          rows={rows}
          sx={sx}
          type={type}
          value={value}
        />
      )}
      rules={buildValidation(type, required, max, min, rules)}
    />
  );
};
