import { IBaseLookup, ICount, IInsight } from '@a-type/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICountsState {
  count?: ICount;
  initLookup: { [key: string]: IBaseLookup[] };
  insights?: IInsight;
  selectedNarrowSearch: string[];
}

const initialState: ICountsState = {
  initLookup: {},
  selectedNarrowSearch: [],
};

const countSlice = createSlice({
  initialState,
  name: 'count',
  reducers: {
    resetCountState() {
      return { ...initialState };
    },
    setCount(state, action: PayloadAction<ICount>) {
      return {
        ...state,
        count: action.payload,
      };
    },
    setCountTotalRecords(state, action: PayloadAction<number>) {
      return {
        ...state,
        count: {
          ...state.count,
          totalRecords: action.payload,
        } as ICount,
      };
    },
    setInitLookup(state, action: PayloadAction<{ key: string; lookup: IBaseLookup[] }>) {
      return {
        ...state,
        initLookup: {
          ...state.initLookup,
          [action.payload.key]: action.payload.lookup,
        },
      };
    },
    setInsights(state, action: PayloadAction<IInsight | undefined>) {
      return {
        ...state,
        insights: action.payload,
      };
    },
    setNarrowSearch(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        selectedNarrowSearch: action.payload,
      };
    },
  },
});

export const {
  resetCountState,
  setCount,
  setCountTotalRecords,
  setInitLookup,
  setInsights,
  setNarrowSearch,
} = countSlice.actions;

export default countSlice.reducer;
