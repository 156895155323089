import { IUserInfo } from '@a-type/dtos';
import { UserRoles, UserStatus } from '@a-type/enums';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  BlockOutlined,
  ManageAccountsOutlined,
  MonetizationOnOutlined,
  Person,
  VerifiedUserOutlined,
} from '@mui/icons-material';
import { Box, Button, Chip, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { ApproveUser } from '../approve-user/approve-user.component';
import { BlockUser } from '../block-user/block-user.component';
import { ChangeUserRole } from '../change-user-role/change-user-role.component';
import { TopUpBalance } from '../top-up-balance/top-up-balance.component';

interface UserCardProps {
  user: IUserInfo;
}

export const UserCard: FC<UserCardProps> = ({ user }: UserCardProps) => {
  const [showApproveUser, setShowApproveUser] = useState(false);
  const [showBlockUser, setShowBlockUser] = useState(false);
  const [showChangeUserRole, setShowChangeUserRole] = useState(false);
  const [showTopUpBalance, setShowTopUpBalance] = useState(false);

  const getStatusColor = (status: UserStatus) => {
    switch (status) {
      case UserStatus.ACTIVE:
        return globalStyles.mainColors.greenColor;
      case UserStatus.BLOCKED:
        return globalStyles.mainColors.redColor;
      case UserStatus.PENDING:
        return globalStyles.mainColors.blueColor;
      default:
        return globalStyles.mainColors.yellowColor;
    }
  };

  const getStatusLabel = (status: UserStatus) => {
    switch (status) {
      case UserStatus.ACTIVE:
        return 'Active';
      case UserStatus.BLOCKED:
        return 'Blocked';
      case UserStatus.PENDING:
        return 'Pending';
      default:
        return 'Unknown';
    }
  };

  const getRoleLabel = (role: UserRoles) => {
    switch (role) {
      case UserRoles.ADMIN:
        return 'Admin';
      case UserRoles.SUPERADMIN:
        return 'Super Admin';
      case UserRoles.USER:
        return 'User';
      default:
        return 'Unknown';
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 4,
      }}
    >
      <Box
        sx={{
          height: '132px',
          width: '132px',
        }}
      >
        {user.imageUrl ? (
          <img
            alt={user.fullName}
            src={user.imageUrl}
            style={{
              borderRadius: '100%',
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          <Box
            sx={{
              alignItems: 'center',
              bgcolor: globalStyles.mainColors.whiteSmokeColor,
              borderRadius: '100%',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Person
              sx={{
                color: globalStyles.mainColors.whiteColor,
                fontSize: 90,
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 28,
                fontWeight: 700,
              }}
            >
              {user.fullName}
            </Typography>

            {user.status && (
              <Chip
                label={getStatusLabel(user.status)}
                size="small"
                sx={{
                  backgroundColor: getStatusColor(user.status),
                  color: globalStyles.mainColors.whiteColor,
                  fontSize: 13,
                  fontWeight: 400,
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 2,
            }}
          >
            {user.createdAt && (
              <Typography
                component="span"
                sx={{
                  color: globalStyles.navigation.textColor,
                  display: 'flex',
                  fontSize: 12,
                  fontWeight: 400,
                  gap: 1,
                  textTransform: 'uppercase',
                }}
              >
                Created:&nbsp;
                <Typography
                  component="span"
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {new Date(user.createdAt).toLocaleDateString()}
                </Typography>
              </Typography>
            )}

            {user.lastLogin && (
              <Typography
                component="span"
                sx={{
                  color: globalStyles.navigation.textColor,
                  display: 'flex',
                  fontSize: 12,
                  fontWeight: 400,
                  gap: 1,
                  textTransform: 'uppercase',
                }}
              >
                Last seen:
                <Typography
                  component="span"
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {new Date(user.lastLogin).toLocaleDateString()}
                </Typography>
              </Typography>
            )}
          </Box>
        </Box>

        {user.role && (
          <Typography
            component="span"
            sx={{
              color: globalStyles.navigation.textColor,
              fontSize: 20,
              fontWeight: 500,
            }}
          >
            {getRoleLabel(user.role)}
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mt: 2,
          }}
        >
          {user.status === UserStatus.ACTIVE && (
            <Button
              onClick={() => setShowTopUpBalance(true)}
              size="small"
              startIcon={<MonetizationOnOutlined />}
              variant="outlined"
            >
              Top Up Balance
            </Button>
          )}

          {user.status === UserStatus.ACTIVE && (
            <Button
              onClick={() => setShowChangeUserRole(true)}
              size="small"
              startIcon={<ManageAccountsOutlined />}
              variant="outlined"
            >
              Change Role
            </Button>
          )}

          {user.status !== UserStatus.ACTIVE && (
            <Button
              color="success"
              onClick={() => setShowApproveUser(true)}
              size="small"
              startIcon={<VerifiedUserOutlined />}
              variant="outlined"
            >
              {user.status === UserStatus.PENDING ? 'Approve' : 'Activate'}
            </Button>
          )}

          {user.status !== UserStatus.BLOCKED && (
            <Button
              color="error"
              onClick={() => setShowBlockUser(true)}
              size="small"
              startIcon={<BlockOutlined />}
              variant="outlined"
            >
              {user.status === UserStatus.PENDING ? 'Decline' : 'Block User'}
            </Button>
          )}
        </Box>
      </Box>

      {showApproveUser && (
        <ApproveUser setShow={setShowApproveUser} show={showApproveUser} user={user} />
      )}

      {showBlockUser && <BlockUser setShow={setShowBlockUser} show={showBlockUser} user={user} />}

      {showTopUpBalance && (
        <TopUpBalance setShow={setShowTopUpBalance} show={showTopUpBalance} user={user} />
      )}

      {showChangeUserRole && (
        <ChangeUserRole setShow={setShowChangeUserRole} show={showChangeUserRole} user={user} />
      )}
    </Box>
  );
};
