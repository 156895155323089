import { useSelector } from '@a-type/ui/hooks';
import { useGetUserBalanceQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Slider, Typography } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';

export interface BuyListFormRecordsSliderProps {
  pricePerItem: number;
  recordsToBuy: number;
  setRecordsToBuy: (recordsToBuy: number) => void;
}

const BuyListFormRecordsSliderComponent = (props: BuyListFormRecordsSliderProps) => {
  const alreadyBuyRef = useRef<any>(); // reference for range input for counting width and other actual data of current input field
  const canBuyRef = useRef<any>(); // reference for range input for counting width and other actual data of current input field
  const canNotBuyRef = useRef<any>(); // reference for range input for counting width and other actual data of current input field

  const { data: creditsBalance = 0 } = useGetUserBalanceQuery();
  const { list } = useSelector((state) => state.list);

  const { pricePerItem, recordsToBuy, setRecordsToBuy } = props;

  const [totalAmount, setTotalAmount] = useState<number>(0); // total amount of records
  const [alreadyBuy, setAlreadyBuy] = useState<{ amount: number; percentage: number }>({
    amount: 0,
    percentage: 0,
  }); // already buy amount and percentage state
  const [canNotBuy, setCanNotBuy] = useState<{ amount: number; percentage: number }>({
    amount: 0,
    percentage: 0,
  }); // amount and percentage state that you can`t buy
  const [steps, setSteps] = useState<number>(0); // how many percentage need for 1 record
  const [currentValue, setCurrentValue] = useState<{ amount: number; percentage: number }>({
    amount: 0,
    percentage: 0,
  }); // current amount and percentage value

  useEffect(() => {
    if (!list) return;

    if (creditsBalance && pricePerItem) {
      const availableRecords = list.avilableRecords!;
      const alreadyBuyAmount = list.totalRecords;
      const totalRecordsAmount = alreadyBuyAmount + availableRecords;
      setTotalAmount(totalRecordsAmount);
      setAlreadyBuy({
        amount: alreadyBuyAmount,
        percentage: (100 * alreadyBuyAmount) / totalRecordsAmount,
      });

      const canBuyAmount = Math.floor(creditsBalance / pricePerItem);
      const canNotBuyAmount = canBuyAmount > availableRecords ? 0 : availableRecords - canBuyAmount;

      setCanNotBuy({
        amount: canNotBuyAmount,
        percentage: (100 * canNotBuyAmount) / totalRecordsAmount,
      });

      const maxAvailableRecords = availableRecords - canNotBuyAmount;
      if (recordsToBuy > maxAvailableRecords || recordsToBuy === 0) {
        setRecordsToBuy(maxAvailableRecords);
      }
    }
  }, [list, pricePerItem, creditsBalance]);

  useEffect(() => {
    if (totalAmount) {
      const records = +recordsToBuy + alreadyBuy.amount;
      setCurrentValue({
        amount: records,
        percentage: (100 * records) / totalAmount,
      });
    }
  }, [recordsToBuy, totalAmount]);

  useEffect(() => {
    if (canBuyRef.current && totalAmount && canNotBuy && alreadyBuy) {
      const canBuy = {
        amount: Math.abs(totalAmount - alreadyBuy.amount - canNotBuy.amount),
        percentage: Math.abs(100 - alreadyBuy.percentage - canNotBuy.percentage),
      };

      if (canBuy.amount <= 100) {
        setSteps(1);
      } else {
        const alreadyBuyWidth = alreadyBuyRef?.current?.getBoundingClientRect()?.width || 0;
        const canNotBuyWidth = canNotBuyRef?.current?.getBoundingClientRect()?.width || 0;
        const canBuyWidth =
          canBuyRef.current.childNodes[0].getBoundingClientRect().width -
          alreadyBuyWidth -
          canNotBuyWidth;

        setSteps(canBuyWidth / canBuy.amount);
      }
    }
  }, [canBuyRef, alreadyBuyRef, canNotBuyRef, totalAmount, canNotBuy, alreadyBuy]);

  const onSaveSliderChangeHandler = (value: number, eventType: string) => {
    if (!value) {
      setRecordsToBuy(0);
      return;
    }

    const valueToAmount = Math.round((value * totalAmount) / 100);
    if (valueToAmount < alreadyBuy.amount || value < alreadyBuy.percentage) {
      setRecordsToBuy(0);
      return;
    }

    if (valueToAmount > totalAmount - canNotBuy.amount || value > 100 - canNotBuy.percentage) {
      setRecordsToBuy(totalAmount - canNotBuy.amount - alreadyBuy.amount);
      return;
    }

    if (eventType === 'change') {
      setCurrentValue({
        amount: valueToAmount,
        percentage: value,
      });
    } else {
      setRecordsToBuy(valueToAmount - alreadyBuy.amount);
    }
  };

  const valueLabelFormat = (amount: number): ReactNode => {
    const value = Math.abs(amount - alreadyBuy.amount);
    const price = value * pricePerItem;
    return (
      <>
        <Typography
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 18,
            fontWeight: 800,
            textAlign: 'center',
            userSelect: 'none',
          }}
        >
          {new Intl.NumberFormat().format(value)}
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.tranquilPondColor,
            fontSize: 12,
            fontWeight: 500,
            mb: 1,
            textAlign: 'center',
            userSelect: 'none',
          }}
        >
          {new Intl.NumberFormat().format(price)}
        </Typography>
      </>
    );
  };

  return alreadyBuy && currentValue ? (
    <Box>
      <Box
        sx={{
          pt: 8,
        }}
      >
        <Box
          data-qa="summarystep-slider-buymore"
          sx={{
            display: 'flex',
            justifyContent: alreadyBuy?.percentage > 0 ? 'space-between' : 'flex-end',
            mb: '-17px',
            position: 'relative',
            width: '100%',
          }}
        >
          {alreadyBuy?.percentage > 0 ? (
            <Box
              ref={alreadyBuyRef}
              sx={{
                background: globalStyles.mainColors.blueGrayColor,
                borderRadius: '2px 0px 0px 2px',
                borderRight: '4px solid #fff',
                boxSizing: 'border-box',
                height: 7,
                width: `${alreadyBuy?.percentage}%`,
                zIndex: '1',
              }}
            />
          ) : null}
          {canNotBuy?.percentage > 0 ? (
            <Box
              ref={canNotBuyRef}
              sx={{
                background: globalStyles.mainColors.lightUnaviableRangeGray,
                borderLeft: '4px solid #fff',
                borderRadius: '0px 2px 2px 0px',
                boxSizing: 'border-box',
                height: 7,
                width: `${canNotBuy?.percentage}%`,
                zIndex: '1',
              }}
            />
          ) : null}
        </Box>

        <div ref={canBuyRef}>
          <Slider
            aria-label="Restricted values"
            defaultValue={0}
            max={100}
            onChange={(e, v) => onSaveSliderChangeHandler(Array.isArray(v) ? v[0] : v, 'change')}
            onChangeCommitted={(e, v) =>
              onSaveSliderChangeHandler(Array.isArray(v) ? v[0] : v, 'commit')
            }
            step={steps}
            sx={{
              '& .MuiSlider-thumb': {
                background: globalStyles.mainColors.whiteColor,
                border: `7px solid ${globalStyles.mainColors.blueColor}`,
                height: 25,
                width: 25,
                zIndex: '2',
              },
              '& .MuiSlider-valueLabel': {
                backgroundColor: 'transparent',
              },
              height: '7px',
              mathin: '0px',
              padding: '0px',
            }}
            value={currentValue?.percentage}
            valueLabelDisplay="on"
            valueLabelFormat={() => valueLabelFormat(currentValue.amount)}
          />
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '16px',
          position: 'relative',
          width: '100%',
        }}
      >
        <Box>
          <Typography
            sx={{ color: globalStyles.mainColors.sootyColor, fontSize: 18, fontWeight: 800 }}
          >
            Records
          </Typography>
          <Typography
            sx={{
              color: globalStyles.mainColors.tranquilPondColor,
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            Credits
          </Typography>
        </Box>

        <Box>
          {totalAmount && list && alreadyBuy ? (
            <>
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 18,
                  fontWeight: 800,
                  textAlign: 'right',
                }}
              >
                {new Intl.NumberFormat().format(Math.abs(totalAmount - alreadyBuy.amount))}
              </Typography>
              <Typography
                sx={{
                  color: globalStyles.mainColors.tranquilPondColor,
                  fontSize: 12,
                  fontWeight: 500,
                  textAlign: 'right',
                }}
              >
                {new Intl.NumberFormat().format(
                  Math.abs(totalAmount - alreadyBuy.amount) * pricePerItem,
                )}
              </Typography>
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default BuyListFormRecordsSliderComponent;
