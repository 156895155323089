import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import globalStyles from '@a-type/ui/styles/global.styles';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { Box, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ActivityHeaderComponent = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 2,
          pb: 2,
          top: 2,
        }}
      >
        <IconButton
          onClick={() => {
            navigate(`${AppRoutes.HomePage}`);
          }}
        >
          <ChevronLeftOutlinedIcon />
        </IconButton>
        <Typography
          sx={{ color: globalStyles.mainColors.sootyColor, fontSize: 24, fontWeight: 800 }}
        >
          All Activity
        </Typography>
      </Box>

      <Box sx={{ borderBottom: '1px solid #E6E6E6' }} />
    </>
  );
};

export default ActivityHeaderComponent;
