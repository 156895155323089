import { AppendType, FieldMetadataGroup } from '@a-type/enums';
import { IDocument, IFieldMetadata, IList } from '@a-type/interfaces';
import { ActionButton } from '@a-type/ui/components';
import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { listService } from '@a-type/ui/services';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RowsAndColumnsBuilderMethods } from '../../../utils/RowsAndColumnsBuilder.utils';
import { TaxAssessorsDocumentFields } from '../../enums/tax-assessors-document-fields.enum';
import { TaxAssessorsDocumentLabels } from '../../enums/tax-assessors-document-labels.enum';
import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';
import { TaxAssessorsDocumentCardField } from './tax-assessors-document-field-card.component';
import { TaxAssessorsDocumentPermits } from './tax-assessors-document-permits.component';
import { TaxAssessorsDocumentRecorders } from './tax-assessors-document-recorders.component';

interface ITaxAssessorsDocumentDetailsProps {
  document: IDocument;
  list: IList;
}

const TaxProperties = [
  TaxAssessorsDocumentFields.TaxMarketValueTotal,
  TaxAssessorsDocumentFields.TaxMarketValueLand,
  TaxAssessorsDocumentFields.TaxMarketValueImprovements,
  TaxAssessorsDocumentFields.TaxMarketImprovementsPerc,
  TaxAssessorsDocumentFields.TaxAssessedValueTotal,
  TaxAssessorsDocumentFields.TaxAssessedValueLand,
  TaxAssessorsDocumentFields.TaxAssessedValueImprovements,
  TaxAssessorsDocumentFields.TaxAssessedImprovementsPerc,
  TaxAssessorsDocumentFields.TaxBilledAmount,
  TaxAssessorsDocumentFields.TaxFiscalYear,
  TaxAssessorsDocumentFields.TaxRateArea,
  TaxAssessorsDocumentFields.TaxYearAssessed,
];

enum TaxAssessorsTabs {
  ADDITIONAL_INFO = 'Additional Info',
  CONTACTS = 'Contacts',
  DEMOGRAPHICS = 'Demographics',
  PERMITS = 'Building Permits',
  RECORDERS = 'Recorders',
  TAX_INFO = 'Tax Info',
}

const CONTACT_APPEND_TYPES = [
  AppendType.ADDRESS,
  AppendType.PHONE,
  AppendType.EMAIL,
  AppendType.EMAIL_MULTIPLE,
  AppendType.PHONE_MOBILE,
  AppendType.PHONE_MULTIPLE,
];
const DEMOGRAPHIC_APPEND_TYPES = [
  AppendType.DEMOGRAPHIC,
  AppendType.FINANCIAL,
  AppendType.LIFESTYLE,
  AppendType.POLITICAL,
];

export const TaxAssessorsDocumentDetails = ({
  document,
  list,
}: ITaxAssessorsDocumentDetailsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [taxProperties, setTaxProperties] = useState<ITaxAssessorsDocumentField[]>([]);
  const [demographicsProperties, setDemographicsProperties] = useState<
    ITaxAssessorsDocumentField[]
  >([]);
  const [contactsProperties, setContactsProperties] = useState<ITaxAssessorsDocumentField[]>([]);
  const [properties, setProperties] = useState<ITaxAssessorsDocumentField[]>([]);
  const [permits, setPermits] = useState<any[]>([]);
  const [recorders, setRecorders] = useState<any[]>([]);
  const [tab, setTab] = useState<TaxAssessorsTabs>(TaxAssessorsTabs.PERMITS);

  const prepareProperties = (items: ITaxAssessorsDocumentField[]): ITaxAssessorsDocumentField[] => {
    return items
      .toSorted((a, b) => a.sortOrder - b.sortOrder)
      .map((field) => ({
        ...field,
        value: RowsAndColumnsBuilderMethods.renderValue(field.value, field.type),
      }))
      .map((field) => {
        let { value } = field;

        switch (field.units) {
          case '$':
            value = `$${value}`;
            break;
          case '%':
            value = `${value}%`;
            break;
          default:
            break;
        }

        return {
          ...field,
          value,
        };
      });
  };

  const getProperties = (
    fields: {
      [key: string]: IFieldMetadata;
    },
    data: any,
    group?: FieldMetadataGroup,
  ) => {
    return Object.keys(fields)
      .filter((key) => fields[key].metadataGroup === group)
      .filter((key) => !fields[key].type)
      .map((key) => {
        const { dataType, displayName, sortOrder, units } = fields[key];
        const value = RowsAndColumnsBuilderMethods.access(key, data);

        if (value) {
          return {
            key,
            label: displayName ?? key,
            sortOrder,
            type: dataType,
            units,
            value,
          };
        }

        return undefined;
      })
      .filter((field) => field !== undefined);
  };

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    setPermits(document.data.permits ?? []);
    setRecorders(document.data.recorders ?? []);

    if (!list.fieldMetadata) {
      setProperties([]);
      setTaxProperties([]);
      return;
    }

    setTaxProperties(
      prepareProperties(
        TaxProperties.filter((field) => list.fieldMetadata![field]).map((field) => ({
          key: field,
          label: TaxAssessorsDocumentLabels[field],
          sortOrder: list.fieldMetadata![field].sortOrder,
          type: list.fieldMetadata![field].dataType,
          units: list.fieldMetadata![field].units,
          value: RowsAndColumnsBuilderMethods.access(field, document.data),
        })),
      ),
    );

    setDemographicsProperties(
      prepareProperties(
        getProperties(list.fieldMetadata, document.data, FieldMetadataGroup.DEMOGRAPHICS),
      ),
    );

    setContactsProperties(
      prepareProperties(
        getProperties(list.fieldMetadata, document.data, FieldMetadataGroup.CONTACT),
      ),
    );

    const fields = Object.keys(TaxAssessorsDocumentFields);
    setProperties(
      prepareProperties(
        getProperties(list.fieldMetadata, document.data).filter(
          (field) => !fields.includes(field.key),
        ),
      ),
    );
  }, [document]);

  const downloadPermitsCSV = async () => {
    if (!list) return;
    if (!document) return;

    dispatch(pageContentLoad(false));
    await listService.exportTaxAssessorsPermits(list._id, document._id, 'permits.csv');
    dispatch(pageContentLoad(true));
  };

  const downloadRecordersCSV = async () => {
    if (!list) return;
    if (!document) return;

    dispatch(pageContentLoad(false));
    await listService.exportTaxAssessorsRecorders(list._id, document._id, 'recorders.csv');
    dispatch(pageContentLoad(true));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 450 }}>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Tabs
          onChange={(event, v) => setTab(v)}
          sx={{
            borderBottom: 1,
            borderColor: globalStyles.mainColors.gainsboroColor,
          }}
          value={tab}
        >
          <Tab
            label={`${TaxAssessorsTabs.PERMITS} (${permits.length})`}
            sx={{ color: globalStyles.navigation.textColor }}
            value={TaxAssessorsTabs.PERMITS}
          />
          <Tab
            label={`${TaxAssessorsTabs.RECORDERS} (${recorders.length})`}
            sx={{ color: globalStyles.navigation.textColor }}
            value={TaxAssessorsTabs.RECORDERS}
          />
          <Tab
            label={`${TaxAssessorsTabs.CONTACTS} (${contactsProperties.length})`}
            sx={{ color: globalStyles.navigation.textColor }}
            value={TaxAssessorsTabs.CONTACTS}
          />
          <Tab
            label={`${TaxAssessorsTabs.DEMOGRAPHICS} (${demographicsProperties.length})`}
            sx={{ color: globalStyles.navigation.textColor }}
            value={TaxAssessorsTabs.DEMOGRAPHICS}
          />
          <Tab
            label={`${TaxAssessorsTabs.ADDITIONAL_INFO} (${properties.length})`}
            sx={{ color: globalStyles.navigation.textColor }}
            value={TaxAssessorsTabs.ADDITIONAL_INFO}
          />
          <Tab
            label={`${TaxAssessorsTabs.TAX_INFO} (${taxProperties.length})`}
            sx={{ color: globalStyles.navigation.textColor }}
            value={TaxAssessorsTabs.TAX_INFO}
          />
        </Tabs>

        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 8,
          }}
        >
          {tab === TaxAssessorsTabs.PERMITS && (
            <ActionButton
              disabled={permits.length === 0}
              icon={<FileDownloadOutlined />}
              label={ButtonDictionaryNames.DOWNLOAD_DOCUMENT}
              onClick={(e) => {
                e.stopPropagation();
                downloadPermitsCSV();
              }}
            />
          )}

          {tab === TaxAssessorsTabs.RECORDERS && (
            <ActionButton
              disabled={recorders.length === 0}
              icon={<FileDownloadOutlined />}
              label={ButtonDictionaryNames.DOWNLOAD_DOCUMENT}
              onClick={(e) => {
                e.stopPropagation();
                downloadRecordersCSV();
              }}
            />
          )}
        </Box>
      </Box>

      {tab === TaxAssessorsTabs.ADDITIONAL_INFO && (
        <>
          {properties.length === 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 24,
                  fontWeight: 500,
                }}
              >
                There are no information to display.
              </Typography>
            </Box>
          )}

          {properties.length > 0 && (
            <Box
              sx={{
                display: 'grid',
                gap: 3,
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                mt: 3,
              }}
            >
              {properties.map((property) => (
                <TaxAssessorsDocumentCardField field={property} key={property.key} />
              ))}
            </Box>
          )}
        </>
      )}

      {tab === TaxAssessorsTabs.TAX_INFO && (
        <>
          {taxProperties.length === 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 24,
                  fontWeight: 500,
                }}
              >
                There are no tax information to display.
              </Typography>
            </Box>
          )}

          {taxProperties.length > 0 && (
            <Box
              sx={{
                display: 'grid',
                gap: 3,
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                mt: 3,
              }}
            >
              {taxProperties.map((property) => (
                <TaxAssessorsDocumentCardField field={property} key={property.key} />
              ))}
            </Box>
          )}
        </>
      )}

      {tab === TaxAssessorsTabs.DEMOGRAPHICS && (
        <>
          {demographicsProperties.length === 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 24,
                  fontWeight: 500,
                }}
              >
                There are no demographics information to display.
              </Typography>

              {!DEMOGRAPHIC_APPEND_TYPES.every(
                (type) => document.appendTypes?.includes(type) === true,
              ) &&
                document.appendTypes?.includes(AppendType.FULL_DEMOGRAPHIC) === false && (
                  <>
                    <Typography
                      sx={{
                        color: globalStyles.mainColors.sootyColor,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      You can append demographics information to this document.
                    </Typography>
                    <Button
                      onClick={() =>
                        navigate(`${AppRoutes.Lists}/${list._id}/append/${document._id}`)
                      }
                      sx={{ mt: 2, width: 200 }}
                      variant="contained"
                    >
                      Append Data
                    </Button>
                  </>
                )}
            </Box>
          )}

          {demographicsProperties.length > 0 && (
            <Box
              sx={{
                display: 'grid',
                gap: 3,
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                mt: 3,
              }}
            >
              {demographicsProperties.map((property) => (
                <TaxAssessorsDocumentCardField field={property} key={property.key} />
              ))}
            </Box>
          )}
        </>
      )}

      {tab === TaxAssessorsTabs.CONTACTS && (
        <>
          {contactsProperties.length === 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 24,
                  fontWeight: 500,
                }}
              >
                There are no contact information to display.
              </Typography>

              {!CONTACT_APPEND_TYPES.every(
                (type) => document.appendTypes?.includes(type) === true,
              ) && (
                <>
                  <Typography
                    sx={{
                      color: globalStyles.mainColors.sootyColor,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    You can append contact information to this document.
                  </Typography>
                  <Button
                    onClick={() =>
                      navigate(`${AppRoutes.Lists}/${list._id}/append/${document._id}`)
                    }
                    sx={{ mt: 2, width: 200 }}
                    variant="contained"
                  >
                    Append Data
                  </Button>
                </>
              )}
            </Box>
          )}

          {contactsProperties.length > 0 && (
            <Box
              sx={{
                display: 'grid',
                gap: 3,
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                mt: 3,
              }}
            >
              {contactsProperties.map((property) => (
                <TaxAssessorsDocumentCardField field={property} key={property.key} />
              ))}
            </Box>
          )}
        </>
      )}

      {tab === TaxAssessorsTabs.PERMITS && (
        <>
          {permits.length === 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 24,
                  fontWeight: 500,
                }}
              >
                There are no building permits information to display.
              </Typography>
            </Box>
          )}

          {permits.length > 0 && <TaxAssessorsDocumentPermits permits={permits} />}
        </>
      )}

      {tab === TaxAssessorsTabs.RECORDERS && (
        <>
          {recorders.length === 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 24,
                  fontWeight: 500,
                }}
              >
                There are no recorders information to display.
              </Typography>
            </Box>
          )}

          {recorders.length > 0 && <TaxAssessorsDocumentRecorders recorders={recorders} />}
        </>
      )}
    </Box>
  );
};
