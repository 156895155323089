import globalStyles from '@a-type/ui/styles/global.styles';
import { Typography } from '@mui/material';
import { FC } from 'react';

import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';

interface ITaxAssessorsDocumentFieldCardProps {
  field: ITaxAssessorsDocumentField;
}

export const TaxAssessorsDocumentCardField: FC<ITaxAssessorsDocumentFieldCardProps> = ({
  field,
}: ITaxAssessorsDocumentFieldCardProps) => {
  return (
    <Typography
      sx={{
        color: globalStyles.navigation.textColor,
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        fontWeight: 500,
      }}
    >
      {field.label}
      <Typography
        component="span"
        sx={{
          color: globalStyles.mainColors.sootyColor,
          fontSize: 20,
          fontWeight: 700,
        }}
      >
        {field.value ?? '-'}
      </Typography>
    </Typography>
  );
};
