import { Components, Theme } from '@mui/material';

import globalStyles from '../global.styles';

const style: Components<Omit<Theme, 'components'>> = {
  MuiAccordion: {
    styleOverrides: {
      root: () => ({
        '&.Mui-expanded': {
          margin: 0,
        },
        '&:before': {
          display: 'none',
        },
        border: `1px solid ${globalStyles.mainColors.veryLightGrayColor}`,
        borderRadius: 1,
        boxShadow: 'none',
      }),
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: () => ({
        borderBottom: `none`,
        padding: '0',
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: () => ({
        '&.Mui-expanded': {
          borderBottom: `1px solid ${globalStyles.mainColors.veryLightGrayColor}`,
          minHeight: 56,
        },
        backgroundColor: globalStyles.mainColors.whiteColor,
        borderBottom: `none`,
        minHeight: 56,
        padding: '0 16px',
      }),
    },
  },
};

export default style;
