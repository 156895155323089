import { useSelector } from '@a-type/ui/hooks';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box } from '@mui/material';

import CountsNarrowSearchHeader from './components/CountsNarrowSearchHeader.components';
import CountsNarrowSearchList from './components/narrow/CountsNarrowSearchList.components';
import CountsSummary from './components/summary/CountsSummary.components';

const CountsNarrowSearch = () => {
  const { count } = useSelector((state) => state.count);

  return (
    (count && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            borderBottom: `1px solid ${globalStyles.unActiveSideTabColor.color}`,
            pb: 2,
          }}
        >
          <CountsNarrowSearchHeader />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              flexBasis: '65%',
              flexGrow: 1,
              pb: 1,
              pr: 3,
              pt: 3,
            }}
          >
            <CountsNarrowSearchList />
          </Box>
          <Box
            sx={{
              borderLeft: `1px solid ${globalStyles.unActiveSideTabColor.color}`,
              flexBasis: '35%',
              flexShrink: 0,
              maxWidth: '500px',
              minWidth: '350px',
              pb: 1,
              pl: 3,
              pt: 3,
            }}
          >
            <CountsSummary disabled />
          </Box>
        </Box>
      </Box>
    )) ||
    null
  );
};

export default CountsNarrowSearch;
