import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';
import { Box, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';

import { resort } from './drag-and-drop.helper';
import { IDraggable } from './drag-and-drop.interface';

/**
 * Props for the DragAndDrop component.
 */
interface DragAndDropProps<T extends IDraggable> {
  /**
   * Optional function to get the key of the item.
   * @param item - The item to get the key for.
   * @returns The key of the item.
   */
  getKey?: (item: T) => string;

  /**
   * The list of items to be rendered and sorted.
   */
  items: T[];

  /**
   * Function to render each item.
   * @param item - The item to be rendered.
   * @param index - The index of the item.
   * @param provided - The provided properties for the draggable item.
   * @param snapshot - The state snapshot of the draggable item.
   * @returns The rendered item.
   */
  renderItem: (
    item: T,
    index: number,
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
  ) => JSX.Element;

  /**
   * Function to update the list of items.
   * @param items - The updated list of items.
   */
  setItems: (items: T[]) => void;

  /**
   * Optional styling properties to be applied to the container.
   */
  sx?: SxProps;
}

/**
 * DragAndDrop component for handling drag-and-drop functionality.
 */
export const DragAndDrop = <T extends IDraggable>({
  getKey = (item) => item._id,
  items,
  renderItem,
  setItems,
  sx = {},
}: DragAndDropProps<T>): JSX.Element => {
  const [sortedItems, setSortedItems] = useState<T[]>(items);

  useEffect(() => {
    setSortedItems([...items].sort((a, b) => a.sortOrder - b.sortOrder));
  }, [items]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const source = sortedItems[result.source.index];
    const destination = sortedItems[result.destination.index];

    const newItems = resort(sortedItems, source, destination, getKey);
    setSortedItems(newItems);
    setItems(newItems);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        ...sx,
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Box
              sx={{
                height: '100%',
                overflow: 'auto',
                width: '100%',
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {sortedItems.map((item, index) => (
                <Draggable draggableId={getKey(item)} index={index} key={getKey(item)}>
                  {(p, s) => renderItem(item, index, p, s)}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
