import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { DataSourceFieldComponentType } from '@a-type/enums';
import { IAxleField, IDataSourceField } from '@a-type/interfaces';
import { Dialog } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { snackbarErrorMessage } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Autocomplete, Box, Chip, TextField, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

export interface DataSourceDetailsAddNewApiFieldDialogProps {
  addFieldHandler: (field: IDataSourceField) => void;
  addNewFieldDialog: boolean;
  fields: IAxleField[];
  setAddNewFieldDialog: (value: boolean) => void;
}

const DataSourceDetailsAddNewApiFieldDialog = ({
  addFieldHandler,
  addNewFieldDialog,
  fields,
  setAddNewFieldDialog,
}: DataSourceDetailsAddNewApiFieldDialogProps) => {
  const { currentDataSource, filtersComponents, lookupsMapping } = useSelector(
    (state) => state.dataSource,
  );
  const [selectedField, setSelectedField] = useState<IAxleField | null>(null);
  const dispatch = useDispatch();

  const addField = () => {
    if (!currentDataSource) return;

    // check if field already exist
    const isFieldExist = currentDataSource.fields.find(
      (field: IDataSourceField) => field.name === selectedField?.name,
    );
    if (isFieldExist) {
      dispatch(
        snackbarErrorMessage('Field already added to the data source. Please select another field'),
      );
      return;
    }

    const field = {
      componentType:
        filtersComponents
          .filter(
            (item: IDataSourceFilterComponentDto) =>
              selectedField?.dataType && item.dataTypes?.includes(selectedField?.dataType),
          )
          .find(
            (item: IDataSourceFilterComponentDto) =>
              item.type !== DataSourceFieldComponentType.MULTI_SELECT ||
              Object.keys(lookupsMapping).some((key) => key === selectedField?.name),
          )?.type ?? '',
      dataType: selectedField?.dataType,
      description: selectedField?.description,
      displayName: selectedField?.displayName,
      filterGroup: '',
      isFilter: false,
      isGroupedBy: false,
      name: selectedField?.name,
      prefetchAllValues: false,
      price: 0,
      sortOrder:
        Math.max(...currentDataSource.fields.map((f: IDataSourceField) => f.sortOrder)) + 1,
      units: '',
    } as IDataSourceField;

    addFieldHandler(field);
    setSelectedField(null);
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Add Field"
      onCancel={() => {
        setSelectedField(null);
        setAddNewFieldDialog(false);
      }}
      onClose={() => {
        setSelectedField(null);
        setAddNewFieldDialog(false);
      }}
      onOk={addField}
      open={addNewFieldDialog}
      title="Select the fields you want to show in your data source"
    >
      <Box>
        <Autocomplete
          getOptionLabel={(option) => option.displayName}
          id="new-field-select"
          onChange={(e, v: any) => {
            setSelectedField(v);
          }}
          options={fields}
          renderInput={(params: any) => (
            <TextField key={`${params.name}`} label="Available fields" {...params} />
          )}
          renderOption={(p, option) => {
            return (
              <Box
                component="li"
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: 3,
                  width: '100%',
                }}
                {...p}
                key={option.name}
              >
                <Typography
                  sx={{
                    flexGrow: 1,
                    fontSize: 16,
                    fontWeight: option.hasAccess ? 500 : 300,
                  }}
                >
                  {option.displayName}
                </Typography>

                {option.hasAccess && (
                  <Tooltip placement="top" title="Has access to fetch data from the API">
                    <CheckCircleRoundedIcon
                      sx={{ color: globalStyles.mainColors.greenColor, fontSize: 20 }}
                    />
                  </Tooltip>
                )}
              </Box>
            );
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option: any, index) => {
              const tp = getTagProps({ index });
              return (
                <Chip
                  className={tp.className}
                  data-tag-index={tp['data-tag-index']}
                  disabled={tp.disabled}
                  key={tp.key}
                  label={option}
                  onDelete={tp.onDelete}
                  tabIndex={tp.tabIndex}
                />
              );
            })
          }
          sx={{
            background: globalStyles.mainColors.whiteColor,
            borderRadius: 1,
            color: globalStyles.mainColors.blackColor,
          }}
          value={selectedField}
        />
      </Box>

      {selectedField && (
        <>
          {selectedField.hasAccess && (
            <Typography
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                fontSize: 16,
                fontWeight: 700,
                gap: 1,
                pt: 2,
              }}
            >
              <Tooltip placement="top" title="Has access to fetch data from the API">
                <CheckCircleRoundedIcon
                  sx={{ color: globalStyles.mainColors.greenColor, fontSize: 20 }}
                />
              </Tooltip>
              Has Access
            </Typography>
          )}

          <Box
            sx={{
              columnGap: 4,
              display: 'flex',
              flexFlow: 'row wrap',
              justifyContent: 'flex-start',
              pt: 2,
              rowGap: 2,
            }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
              Selected field
              <Typography
                component="span"
                sx={{
                  display: 'block',
                  fontSize: 16,
                }}
              >
                {selectedField?.displayName}
              </Typography>
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
              Api field name
              <Typography
                component="span"
                sx={{
                  display: 'block',
                  fontSize: 16,
                }}
              >
                {selectedField?.name}
              </Typography>
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
              Type
              <Typography
                component="span"
                sx={{
                  display: 'block',
                  fontSize: 16,
                }}
              >
                {selectedField?.dataType}
              </Typography>
            </Typography>
          </Box>

          <Typography sx={{ fontSize: 16, fontWeight: 700, pt: 2 }}>
            Description
            <Typography
              component="span"
              sx={{
                display: 'block',
                fontSize: 16,
              }}
            >
              {selectedField?.description}
            </Typography>
          </Typography>
        </>
      )}
    </Dialog>
  );
};

export default DataSourceDetailsAddNewApiFieldDialog;
