import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppMainState {
  isExtraCreditPopupActive: boolean;
  isSubscriptionActivatedPopupActive: boolean;
  pageContentLoad: boolean;
  snackbarErrorMessage: null | string;
  snackbarInfoMessage: null | string;
  snackbarSuccessMessage: null | string;
}

const initialState: AppMainState = {
  isExtraCreditPopupActive: false,
  isSubscriptionActivatedPopupActive: false,
  pageContentLoad: true,
  snackbarErrorMessage: null,
  snackbarInfoMessage: null,
  snackbarSuccessMessage: null,
};

const appMain = createSlice({
  initialState,
  name: 'appMain',
  reducers: {
    changeExtraCreditPopup(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isExtraCreditPopupActive: action.payload,
      };
    },
    changeSubscriptionActivatedPopup(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isSubscriptionActivatedPopupActive: action.payload,
      };
    },
    pageContentLoad(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        pageContentLoad: action.payload,
      };
    },
    setAppDefaultState() {
      return {
        ...initialState,
      };
    },
    snackbarErrorMessage(state, action: PayloadAction<null | string>) {
      return {
        ...state,
        snackbarErrorMessage: action.payload,
      };
    },
    snackbarInfoMessage(state, action: PayloadAction<null | string>) {
      return {
        ...state,
        snackbarInfoMessage: action.payload,
      };
    },
    snackbarSuccessMessage(state, action: PayloadAction<null | string>) {
      return {
        ...state,
        snackbarSuccessMessage: action.payload,
      };
    },
  },
});

export const {
  changeExtraCreditPopup,
  changeSubscriptionActivatedPopup,
  pageContentLoad,
  setAppDefaultState,
  snackbarErrorMessage,
  snackbarInfoMessage,
  snackbarSuccessMessage,
} = appMain.actions;
export default appMain.reducer;
