import { IRegister } from '@a-type/dtos';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { snackbarErrorMessage, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { useRegisterMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Box, Container, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RegisterForm } from './components/register-form/register-form.component';

export const RegisterPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [register, { isLoading }] = useRegisterMutation();
  const [success, setSuccess] = useState(false);

  const registerHandler = async (values: IRegister) => {
    if (success) {
      return;
    }

    const response = await register(values);
    if (response.data) {
      dispatch(snackbarSuccessMessage('Check your email - we have sent you a confirmation email'));
      setSuccess(true);
      return;
    }

    if (response.error) {
      dispatch(snackbarErrorMessage(getError(response.error) ?? 'Error while registering'));
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start',
        overflow: 'auto',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: success ? 'flex-start' : 'center',
          py: success ? 4 : 8,
        }}
      >
        {success && (
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: globalStyles.mainColors.whiteColor,
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              justifyContent: 'flex-start',
              p: 4,
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Typography
              component="span"
              gutterBottom
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '32px',
                fontWeight: '600',
                wordWrap: 'break-word',
              }}
            >
              Thank you for registering on A Type Data!
            </Typography>
            <Divider
              sx={{
                backgroundColor: globalStyles.mainColors.gainsboroColor,
                height: '1px',
                width: '100%',
              }}
            />
            <Typography
              component="span"
              gutterBottom
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '24px',
                fontWeight: '400',
                mt: 1,
                wordWrap: 'break-word',
              }}
            >
              To complete your registration, please go to your email inbox and click the button in
              the confirmation email we just sent you.
            </Typography>
            <Typography
              component="span"
              gutterBottom
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '24px',
                fontWeight: '400',
                mt: 2,
                wordWrap: 'break-word',
              }}
            >
              Once you confirm your email address, your account will be reviewed by our admin team
              for approval.
            </Typography>
          </Box>
        )}

        {!success && (
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
                width: '100%',
              }}
            >
              <Typography component="div" gutterBottom sx={{ fontSize: '32px', fontWeight: '900' }}>
                Registration
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography
                  component="span"
                  gutterBottom
                  sx={{ fontSize: '16px', fontWidth: '400' }}
                >
                  Have account?
                </Typography>
                <Typography
                  component="span"
                  gutterBottom
                  onClick={() => {
                    navigate(AppRoutes.LoginPage);
                  }}
                  sx={{
                    color: globalStyles.mainColors.blueColor,
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWidth: '400',
                  }}
                >
                  Login now
                </Typography>
              </Box>
            </Box>
            <RegisterForm loading={isLoading} onSubmit={registerHandler} />
          </Box>
        )}
      </Container>
    </Box>
  );
};
