import { IStripePaymentHistory } from '@a-type/dtos';
import { useDispatch } from '@a-type/ui/hooks';
import { AccountPageLayout } from '@a-type/ui/layout';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetPaymentHistoryQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Button, Chip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import AmexCardIcon from '../../assets/icons/american-express.svg';
import DefaultCardIcon from '../../assets/icons/card-generic.svg';
import DinersCardIcon from '../../assets/icons/diners.svg';
import DiscoverCardIcon from '../../assets/icons/discover.svg';
import JCBCardIcon from '../../assets/icons/jcb.svg';
import MasterCardIcon from '../../assets/icons/master_card_icon.svg';
import UnionPayCardIcon from '../../assets/icons/unionpay.svg';
import VisaCardIcon from '../../assets/icons/visa_card_icon.svg';

export const AccountPaymentHistoryPage: React.FC = () => {
  const dispatch = useDispatch();
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const { data, isFetching, isLoading } = useGetPaymentHistoryQuery({ cursor });
  const [items, setItems] = useState<IStripePaymentHistory[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    if (data) {
      setItems((prev) => {
        const map = new Map(prev.map((item) => [item.id, item]));
        return [...prev, ...data.items.filter((item) => !map.has(item.id))];
      });
      setTotal(data.count);
    }
  }, [data]);

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading && !isFetching));
  }, [isLoading, isFetching]);

  const handleLoadMore = () => {
    if (data?.cursor) {
      setCursor(data.cursor);
    }
  };

  const getBrand = (type: string) => {
    switch (type) {
      case 'amex':
        return <img alt="AmexCard" height="32px" src={AmexCardIcon} width="32px" />;
      case 'diners':
        return <img alt="DinersCard" height="32px" src={DinersCardIcon} width="32px" />;
      case 'discover':
        return <img alt="DiscoverCard" height="32px" src={DiscoverCardIcon} width="32px" />;
      case 'jcb':
        return <img alt="JCBCard" height="32px" src={JCBCardIcon} width="32px" />;
      case 'mastercard':
        return <img alt="MasterCard" height="20px" src={MasterCardIcon} width="32px" />;
      case 'unionpay':
        return <img alt="UnionPayCard" height="32px" src={UnionPayCardIcon} width="32px" />;
      case 'visa':
        return <img alt="VisaCard" height="28px" src={VisaCardIcon} width="32px" />;
      default:
        return <img alt="DefaultCard" height="32px" src={DefaultCardIcon} width="32px" />;
    }
  };

  return (
    <AccountPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 900,
            }}
            variant="h1"
          >
            Payment History
          </Typography>

          {total > 0 && (
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              Total payments: {total}
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%',
          }}
        >
          <Box
            sx={{
              borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
              display: 'flex',
              pb: 2,
              pt: 2.5,
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 12,
                fontWeight: 700,
                textTransform: 'uppercase',
                width: '20%',
              }}
            >
              Date
            </Typography>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 12,
                fontWeight: 700,
                textTransform: 'uppercase',
                width: '40%',
              }}
            >
              Details
            </Typography>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 12,
                fontWeight: 700,
                textTransform: 'uppercase',
                width: '15%',
              }}
            >
              Payment Method
            </Typography>

            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 12,
                fontWeight: 700,
                textTransform: 'uppercase',
                width: '15%',
              }}
            >
              Status
            </Typography>

            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 12,
                fontWeight: 700,
                textTransform: 'uppercase',
                width: '10%',
              }}
            >
              Total
            </Typography>
          </Box>
        </Box>

        {items.map((item) => (
          <Box
            key={item.id}
            sx={{
              display: 'flex',
            }}
          >
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 14,
                fontWeight: 400,
                textOverflow: 'ellipsis',
                textWrap: 'wrap',
                width: '20%',
              }}
            >
              {new Date(item.date).toLocaleString()}
            </Typography>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 14,
                fontWeight: 400,
                width: '40%',
              }}
            >
              {item.description}
            </Typography>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                width: '15%',
              }}
            >
              {item.cardLast4 && (
                <>
                  {getBrand(item.cardBrand ?? '')}

                  <Typography
                    component="span"
                    sx={{
                      color: globalStyles.mainColors.sootyColor,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    **** {item.cardLast4}
                  </Typography>
                </>
              )}
            </Box>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 14,
                fontWeight: 400,
                width: '15%',
              }}
            >
              <>
                {item.status === 'succeeded' && (
                  <Chip
                    color="success"
                    label="Succeeded"
                    size="small"
                    sx={{
                      borderColor: globalStyles.mainColors.greenColor,
                      color: globalStyles.mainColors.greenColor,
                    }}
                    variant="outlined"
                  />
                )}

                {item.status === 'failed' && (
                  <Chip
                    color="error"
                    label="Failed"
                    size="small"
                    sx={{
                      borderColor: globalStyles.mainColors.redColor,
                      color: globalStyles.mainColors.redColor,
                    }}
                    variant="outlined"
                  />
                )}

                {item.status === 'canceled' && (
                  <Chip
                    color="warning"
                    label="Canceled"
                    size="small"
                    sx={{
                      borderColor: globalStyles.mainColors.yellowColor,
                      color: globalStyles.mainColors.yellowColor,
                    }}
                    variant="outlined"
                  />
                )}

                {item.status === 'processing' && (
                  <Chip
                    color="info"
                    label="Processing"
                    size="small"
                    sx={{
                      borderColor: globalStyles.mainColors.blueColor,
                      color: globalStyles.mainColors.blueColor,
                    }}
                    variant="outlined"
                  />
                )}

                {item.status === 'pending' && (
                  <Chip
                    color="warning"
                    label="Pending"
                    size="small"
                    sx={{
                      borderColor: globalStyles.mainColors.yellowColor,
                      color: globalStyles.mainColors.yellowColor,
                    }}
                    variant="outlined"
                  />
                )}

                {item.status === 'refunded' && (
                  <Chip
                    color="info"
                    label="Refunded"
                    size="small"
                    sx={{
                      borderColor: globalStyles.mainColors.sootyColor,
                      color: globalStyles.mainColors.sootyColor,
                    }}
                    variant="outlined"
                  />
                )}

                {item.status === 'requires_payment_method' && (
                  <Chip
                    color="warning"
                    label="Requires Payment Method"
                    size="small"
                    sx={{
                      borderColor: globalStyles.mainColors.yellowColor,
                      color: globalStyles.mainColors.yellowColor,
                    }}
                    variant="outlined"
                  />
                )}

                {item.status === 'requires_action' && (
                  <Chip
                    color="warning"
                    label="Requires Action"
                    size="small"
                    sx={{
                      borderColor: globalStyles.mainColors.yellowColor,
                      color: globalStyles.mainColors.yellowColor,
                    }}
                    variant="outlined"
                  />
                )}

                {item.status === 'requires_confirmation' && (
                  <Chip
                    color="warning"
                    label="Requires Confirmation"
                    size="small"
                    sx={{
                      borderColor: globalStyles.mainColors.yellowColor,
                      color: globalStyles.mainColors.yellowColor,
                    }}
                    variant="outlined"
                  />
                )}

                {item.status === 'requires_capture' && (
                  <Chip
                    color="warning"
                    label="Requires Capture"
                    size="small"
                    sx={{
                      borderColor: globalStyles.mainColors.yellowColor,
                      color: globalStyles.mainColors.yellowColor,
                    }}
                    variant="outlined"
                  />
                )}
              </>
            </Typography>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 14,
                fontWeight: 400,
                width: '10%',
              }}
            >
              ${item.amount}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pb: 2,
          pt: 2,
        }}
      >
        {items.length < total && data?.cursor && (
          <Button onClick={handleLoadMore}>Load More</Button>
        )}
      </Box>
    </AccountPageLayout>
  );
};
