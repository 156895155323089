import { IFilterGroup } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const filterGroupApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    getFilterGroups: builder.query<IFilterGroup[], void>({
      providesTags: [{ id: 'LIST', type: 'FilterGroup' as const }],
      query: () => ({ url: '/admin/filter-groups/' }),
    }),
    getFilterGroupsInUse: builder.query<string[], void>({
      providesTags: [{ id: 'IN-USE', type: 'FilterGroup' as const }],
      query: () => ({ url: '/admin/filter-groups/in-use' }),
    }),
    updateFilterGroups: builder.mutation<IFilterGroup[], IFilterGroup[]>({
      invalidatesTags: [
        { id: 'LIST', type: 'FilterGroup' as const },
        { id: 'IN-USE', type: 'FilterGroup' as const },
      ],
      query: (data) => ({
        data,
        method: 'POST',
        url: `/admin/filter-groups/`,
      }),
    }),
  }),
});

export const {
  useGetFilterGroupsInUseQuery,
  useGetFilterGroupsQuery,
  useUpdateFilterGroupsMutation,
} = filterGroupApi;
