import { IUserInfo } from '@a-type/dtos';
import { UserStatus } from '@a-type/enums';
import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useActivateUserMutation, useApproveUserMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Typography } from '@mui/material';
import { FC, useEffect } from 'react';

interface AprroveUserProps {
  setShow: (show: boolean) => void;
  show: boolean;
  user: IUserInfo;
}

export const ApproveUser: FC<AprroveUserProps> = ({ setShow, show, user }: AprroveUserProps) => {
  const dispatch = useDispatch();
  const [approveUser, { isLoading: approveUserLoading }] = useApproveUserMutation();
  const [activateUser, { isLoading: activateUserLoading }] = useActivateUserMutation();

  useEffect(() => {
    dispatch(pageContentLoad(!approveUserLoading && !activateUserLoading));
  }, [approveUserLoading, activateUserLoading]);

  const handleApprove = async () => {
    const result = await approveUser(user._id);

    if (result.data) {
      dispatch(snackbarSuccessMessage('User approved successfully'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while approving user'));
    }

    setShow(false);
  };

  const handleActivate = async () => {
    const result = await activateUser(user._id);

    if (result.data) {
      dispatch(snackbarSuccessMessage('User activated successfully'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while activating user'));
    }

    setShow(false);
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText={user.status === UserStatus.PENDING ? 'Yes, Approve' : 'Yes, Activate'}
      onCancel={() => setShow(false)}
      onClose={() => setShow(false)}
      onOk={user.status === UserStatus.PENDING ? handleApprove : handleActivate}
      open={show}
      title={user.status === UserStatus.PENDING ? 'Approve User' : 'Activate User'}
    >
      <Typography
        component="span"
        sx={{
          color: globalStyles.mainColors.sootyColor,
          fontSize: 16,
          fontWeight: 400,
        }}
      >
        Are you sure you want to {user.status === UserStatus.PENDING ? 'approve ' : 'activate '}
        user&nbsp;
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {user.fullName}
        </Typography>
        ?
      </Typography>
    </Dialog>
  );
};
