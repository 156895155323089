import {
  IDataSourceField,
  IFilterDateRangeValue,
  IFilterModel,
  IFilterStringRangeValue,
} from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCount } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import { LabelsUtils } from '@a-type/ui/utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';

import SelectedValuesControl from './SelectedValuesControl.component';

export interface DateRangeSelectFilterProps {
  field: IDataSourceField;
}

const DateRangeSelectFilter = (props: DateRangeSelectFilterProps) => {
  const { field } = props;
  const { count } = useSelector((state) => state.count);
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState<IFilterDateRangeValue[]>([]);

  const MAX_RANGE_COUNT = 5;

  // Initialize filter
  useEffect(() => {
    if (!count || !count.filters) return;

    if (!count.filters[field.name!]) {
      const filter = {
        _id: field.name!,
        mode: 'add',
        name: field.displayName!,
        price: field.price,
        sortOrder: field.sortOrder,
        type: field.dataType!,
        units: field.units,
        values: [],
      } as IFilterModel;
      dispatch(setCount({ ...count, filters: { ...count.filters, [field.name!]: filter } }));
    } else if (count.filters[field.name!].values.length !== selectedOptions.length) {
      setSelectedOptions(
        count.filters[field.name!].values.map((x) => {
          const value = x as IFilterStringRangeValue;
          return {
            _id: value._id || new Date().getTime().toString(),
            from: value.from ? new Date(value.from) : null,
            label: value.label,
            to: value.to ? new Date(value.to) : null,
          } as IFilterDateRangeValue;
        }),
      );
    }
  }, [count]);

  const noAvailableRangeAmount = (): boolean => {
    return selectedOptions.length >= MAX_RANGE_COUNT;
  };

  const addRange = () => {
    if (noAvailableRangeAmount()) return;

    setSelectedOptions([
      ...selectedOptions,
      {
        _id: new Date().getTime().toString(),
        from: null,
        label: '',
        to: null,
      } as IFilterDateRangeValue,
    ]);
  };

  const removeRange = (index: number) => {
    setSelectedOptions(selectedOptions.filter((_, i) => i !== index));
  };

  const updateRange = (index: number, key: 'from' | 'to', value?: Date | null) => {
    const currentOption = selectedOptions[index];

    let newFrom = key === 'from' ? value : currentOption.from;
    let newTo = key === 'to' ? value : currentOption.to;

    if (newFrom && newTo && newFrom > newTo) {
      if (key === 'from') newTo = null;
      newFrom = null;
    }

    const label = LabelsUtils.getDateRangeLabel(newFrom, newTo);

    setSelectedOptions(
      selectedOptions.map((option, i) =>
        i === index ? { ...option, from: newFrom, label, to: newTo } : option,
      ),
    );
  };

  const formatedDate = (date: Date) => {
    try {
      const offset = date.getTimezoneOffset();
      date.setMinutes(date.getMinutes() - offset);
      return date.toISOString().slice(0, 10);
    } catch {
      return null;
    }
  };

  const updateFilter = (values: IFilterDateRangeValue[]) => {
    if (!count || !count.filters || !count.filters[field.name!]) return;

    // Validate date ranges
    if (
      values.some(
        (x) =>
          (x.from && Number.isNaN(x.from!.getTime())) ||
          (x.to && Number.isNaN(x.to!.getTime())) ||
          (!!x.from && !!x.to && new Date(x.from).getTime() > new Date(x.to).getTime()) ||
          (!x.from && !x.to),
      )
    )
      return;

    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name!]: {
            ...count.filters[field.name!],
            values: values.map(
              (x) =>
                ({
                  _id: x._id,
                  from: x.from ? formatedDate(x.from) : null,
                  label: x.label,
                  to: x.to ? formatedDate(x.to) : null,
                }) as IFilterStringRangeValue,
            ),
          },
        },
      }),
    );
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => updateFilter(selectedOptions), 500);
    return () => clearTimeout(timeOutId);
  }, [selectedOptions]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: 'auto',
        justifyContent: 'space-between',
        p: 1.5,
        width: '100%',
      }}
    >
      <SelectedValuesControl field={field} />

      {selectedOptions.map((option, index) => (
        <Box
          key={`date-range-${option._id}`}
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <DatePicker
            maxDate={option.to ? option.to : undefined}
            onChange={(date) => updateRange(index, 'from', date)}
            slotProps={{
              textField: {
                error: option.from === null && option.to === null,
                label: 'From',
                size: 'small',
                sx: {
                  flexGrow: 1,
                },
                variant: 'outlined',
              },
            }}
            value={option.from}
          />

          <DatePicker
            minDate={option.from ? option.from : undefined}
            onChange={(date) => updateRange(index, 'to', date)}
            slotProps={{
              textField: {
                error: option.from === null && option.to === null,
                label: 'To',
                size: 'small',
                sx: {
                  flexGrow: 1,
                },
                variant: 'outlined',
              },
            }}
            value={option.to}
          />

          <IconButton onClick={() => removeRange(index)} sx={{ m: 0, p: 0.5 }}>
            <CancelIcon
              sx={{
                color: globalStyles.mainColors.grey74Color,
                fontSize: '18px',
              }}
            />
          </IconButton>
        </Box>
      ))}

      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Button
          color="info"
          disabled={noAvailableRangeAmount()}
          onClick={addRange}
          size="small"
          startIcon={<AddCircleIcon />}
        >
          Add a range
        </Button>
      </Box>
    </Box>
  );
};

export default DateRangeSelectFilter;
