import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { ReactNode } from 'react';

interface DialogProps {
  cancelText?: string;
  children: ReactNode;
  disableCancel?: boolean;
  disableOk?: boolean;
  fullScreen?: boolean;
  okText?: string;
  onCancel?: () => void;
  onClose: () => void;
  onOk?: () => void;
  open: boolean;
  size?: 'lg' | 'md' | 'sm';
  title: string;
}

const Transition = React.forwardRef(function Transition(
  props: {
    children: React.ReactElement<any, any>;
  } & TransitionProps,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Dialog = ({
  cancelText,
  children,
  disableCancel = false,
  disableOk = false,
  fullScreen = false,
  okText = 'Ok',
  onCancel = () => {},
  onClose,
  onOk,
  open,
  size = 'md',
  title,
}: DialogProps) => {
  const getWitdh = () => {
    switch (size) {
      case 'lg':
        return 960;
      case 'md':
        return 600;
      case 'sm':
        return 440;
      default:
        return 600;
    }
  };

  return (
    <MuiDialog
      fullScreen={fullScreen}
      fullWidth
      keepMounted
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: fullScreen ? 0 : 4,
        },
        '& .MuiDialog-paperWidthSm': {
          width: getWitdh(),
        },
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>{title}</DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          color: (theme) => theme.palette.grey[500],
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent
        dividers
        sx={{
          p: 2,
        }}
      >
        {children}
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        {cancelText ? (
          <Button
            disabled={disableCancel}
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
              onClose();
            }}
            size="small"
            sx={{ minWidth: 104 }}
            variant="outlined"
          >
            {cancelText}
          </Button>
        ) : null}

        {onOk && okText ? (
          <Button
            disabled={disableOk}
            onClick={() => {
              if (onOk) {
                onOk();
              }
            }}
            size="small"
            sx={{ minWidth: 104 }}
            variant="contained"
          >
            {okText}
          </Button>
        ) : null}
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
