import {
  IChangePassword,
  IDataSourceShort,
  IUpdateImageUrl,
  IUpdateUserAddress,
  IUpdateUserContact,
  IUserInfo,
} from '@a-type/dtos';
import { IVersiumAppend } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const accountApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    changePassword: builder.mutation<void, IChangePassword>({
      query: (data) => ({
        data,
        method: 'POST',
        url: `/account/change-password`,
      }),
    }),
    deleteUser: builder.mutation<void, void>({
      invalidatesTags: [{ id: 'INFO', type: 'Account' as const }],
      query: () => ({ method: 'POST', url: '/account/delete' }),
    }),
    getCountTotalCount: builder.query<number, void>({
      providesTags: [{ id: 'TOTAL_COUNT', type: 'Count' as const }],
      query: () => ({ url: '/account/count-count' }),
    }),
    getListTotalCount: builder.query<number, void>({
      providesTags: [{ id: 'TOTAL_COUNT', type: 'List' as const }],
      query: () => ({ url: '/account/list-count' }),
    }),
    getUserAppendData: builder.query<IVersiumAppend[], void>({
      providesTags: [{ id: 'LIST', type: 'AppendData/User' as const }],
      query: () => ({ url: '/account/append-data' }),
    }),
    getUserBalance: builder.query<number, void>({
      providesTags: [{ id: 'CREDIT_BALANCE', type: 'Account' as const }],
      query: () => ({ url: '/account/balance' }),
    }),
    getUserDataSources: builder.query<IDataSourceShort[], void>({
      providesTags: [{ id: 'LIST', type: 'DataSource/User' as const }],
      query: () => ({ url: '/account/data-sources' }),
    }),
    getUserInfo: builder.query<IUserInfo, void>({
      providesTags: [{ id: 'INFO', type: 'Account' as const }],
      query: () => ({ url: '/account' }),
    }),
    updateAddressInfo: builder.mutation<IUserInfo, IUpdateUserAddress>({
      invalidatesTags: [{ id: 'INFO', type: 'Account' as const }],
      query: (data) => ({
        data,
        method: 'POST',
        url: `/account/address`,
      }),
    }),
    updateContactInfo: builder.mutation<IUserInfo, IUpdateUserContact>({
      invalidatesTags: [{ id: 'INFO', type: 'Account' as const }],
      query: (data) => ({
        data,
        method: 'POST',
        url: `/account/contact`,
      }),
    }),
    updateImage: builder.mutation<IUserInfo, IUpdateImageUrl>({
      invalidatesTags: [{ id: 'INFO', type: 'Account' as const }],
      query: (data) => ({
        data,
        method: 'POST',
        url: '/account/image',
      }),
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useDeleteUserMutation,
  useGetUserAppendDataQuery,
  useGetUserBalanceQuery,
  useGetUserDataSourcesQuery,
  useGetUserInfoQuery,
  useUpdateAddressInfoMutation,
  useUpdateContactInfoMutation,
  useUpdateImageMutation,
} = accountApi;
