export enum ButtonDictionaryNames {
  ADD = 'Add',
  ADD_CUSTOM_FIELD = 'Add custom field',
  ADD_FIELD = 'Add field',
  ADD_ITEM = 'Add item',
  ADD_NEW_FIELD = 'Add new field',
  APPEND_DATA = 'Append Data',
  BUILD_LIST = 'Build List',
  BUY_LIST = 'Buy List',
  BUY_MORE_RECORDS = 'Buy More Records',
  CANCEL = 'Cancel',
  CLEAR_DATA = 'Clear data',
  CLEAR_SELECTED_LOCATION = 'Clear selected location',
  CLOSE = 'Close',
  CONFIRM = 'Confirm',
  CONFIRM_PURCHASE = 'Confirm Purchase',
  CONTACT_US = 'Contact Us',
  DELETE = 'Delete',
  DOWNLOAD_DOCUMENT = 'Download CSV',
  DOWNLOAD_LIST = 'Download List',
  DOWNLOAD_ONLINE_AUDIENCE = 'Download Online Audience CSV',
  EDIT = 'Edit',
  EDIT_LIST = 'Edit List',
  FIELDS = 'Fields',
  GENERATE_REPORT = 'Generate report',
  NO = 'No',
  OK = 'Ok',
  PREVIEW_DATA_SET = 'Preview Data',
  PURCHASE_FIELDS = 'Purchase fields',
  REGISTER = 'Register',
  REMOVE = 'Remove',
  RERUN_COUNT = 'Rerun Count',
  SAVE = 'Save',
  SAVE_CHANGES = 'Save changes',
  SAVE_COUNT = 'Save Count',
  SAVE_PLAN = 'Save plan',
  TO_HOME_PAGE = 'Go to Home page',
  UPLOAD_CSV = 'Upload CSV',
  UPLOAD_IMAGE = 'Upload Image',
  YES = 'Yes',
}

export default ButtonDictionaryNames;
