import apiMiddleware from './api-middleware';

export const imagesApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    uploadImage: builder.mutation<
      string,
      {
        base64: string;
        name: string;
      }
    >({
      query: ({ base64, name }) => ({
        data: { base64 },
        method: 'PUT',
        url: `/images/upload-image/${name}`,
      }),
    }),
  }),
});

export const { useUploadImageMutation } = imagesApi;
