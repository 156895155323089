import { ISettings } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const settingsApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<ISettings, void>({
      providesTags: [{ id: 'LIST', type: 'Settings' as const }],
      query: () => ({ url: '/admin/settings' }),
    }),
    updateSettings: builder.mutation<ISettings, ISettings>({
      invalidatesTags: [{ id: 'LIST', type: 'Settings' as const }],
      query: (data) => ({
        data,
        method: 'POST',
        url: `/admin/settings`,
      }),
    }),
  }),
});

export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi;
