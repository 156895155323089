import { IList } from '@a-type/interfaces';
import Adroll from '@a-type/ui/assets/images/AdRoll.png';
import { ActionButton, InsightCard } from '@a-type/ui/components';
import { listService } from '@a-type/ui/services';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import FacebookIcon from '@mui/icons-material/Facebook';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box } from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';

interface ListDocumentsOnlineAudienceComponentProps {
  list: IList;
}

interface OnlineAudienceInsight {
  code: string;
  icon: ReactNode;
  insight: string;
  title: string;
}

export const ListDocumentsOnlineAudienceComponent: FC<
  ListDocumentsOnlineAudienceComponentProps
> = ({ list }: ListDocumentsOnlineAudienceComponentProps) => {
  const [insights, setInsights] = useState<OnlineAudienceInsight[]>([]);

  useEffect(() => {
    if (!list || !list.onlineAudianceStats) return;

    const result: OnlineAudienceInsight[] = [];

    if (list.onlineAudianceStats.facebook > 0)
      result.push({
        code: 'facebook',
        icon: (
          <FacebookIcon
            sx={{
              color: '#3b5998',
              fontSize: '36px',
            }}
          />
        ),
        insight: `${((list.onlineAudianceStats.facebook / list.totalRecords!) * 100).toFixed(0)}%`,
        title: 'Facebook',
      });

    if (list.onlineAudianceStats.google > 0)
      result.push({
        code: 'google',
        icon: (
          <GoogleIcon
            sx={{
              color: '#4285f4',
              fontSize: '36px',
            }}
          />
        ),
        insight: `${((list.onlineAudianceStats.google / list.totalRecords!) * 100).toFixed(0)}%`,
        title: 'Google',
      });

    if (list.onlineAudianceStats.linkedin > 0)
      result.push({
        code: 'linkedin',
        icon: <LinkedInIcon sx={{ color: '#0077b5', fontSize: '36px' }} />,
        insight: `${((list.onlineAudianceStats.linkedin / list.totalRecords!) * 100).toFixed(0)}%`,
        title: 'LinkedIn',
      });

    if (list.onlineAudianceStats.generic > 0)
      result.push({
        code: 'generic',
        icon: <AdsClickIcon sx={{ color: '#000000', fontSize: '36px' }} />,
        insight: `${((list.onlineAudianceStats.generic / list.totalRecords!) * 100).toFixed(0)}%`,
        title: 'Generic',
      });

    if (list.onlineAudianceStats.adroll > 0)
      result.push({
        code: 'adroll',
        icon: <img alt="Adroll" height="28px" src={Adroll} width="28px" />,
        insight: `${((list.onlineAudianceStats.adroll / list.totalRecords!) * 100).toFixed(0)}%`,
        title: 'Adroll',
      });

    if (list.onlineAudianceStats.original > 0)
      result.push({
        code: 'original',
        icon: <AlternateEmailIcon sx={{ color: '#000000', fontSize: '36px' }} />,
        insight: `${((list.onlineAudianceStats.original / list.totalRecords!) * 100).toFixed(0)}%`,
        title: 'Original',
      });

    setInsights(result);
  }, [list]);

  const downloadHandler = async (type: string) => {
    await listService.exportOnlineAudienceByType(list._id!, list.name!, type);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 3,
        gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
        mt: 4,
      }}
    >
      {insights.map((insight) => (
        <InsightCard
          action={
            <ActionButton
              icon={<FileDownloadOutlinedIcon />}
              label="Download"
              onClick={(e) => {
                e.stopPropagation();
                downloadHandler(insight.code);
              }}
            />
          }
          border
          icon={insight.icon}
          insight={insight.insight}
          key={insight.code}
          title={insight.title}
        />
      ))}
    </Box>
  );
};
