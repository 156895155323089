export type DataSourceTypes = {
  keyName: DATA_SOURCE_TABS_ENUM;
  title: string;
  value: number;
};

export type DataSourceDetailsProps = {
  currentStep: DataSourceTypes;
  setBasicInfoValid: (value: boolean) => void;
  setMappingValid: (value: boolean) => void;
  setPricingValid: (value: boolean) => void;
};

export enum DATA_SOURCE_TABS_ENUM {
  BASIC_DETAILS = 'basic_details',
  MAPPING = 'mapping',
  PRICING = 'pricing',
}

export const DATA_SOURCE_TABS: DataSourceTypes[] = [
  {
    keyName: DATA_SOURCE_TABS_ENUM.BASIC_DETAILS,
    title: 'Basic Details',
    value: 1,
  },
  {
    keyName: DATA_SOURCE_TABS_ENUM.MAPPING,
    title: 'Mapping',
    value: 2,
  },
  {
    keyName: DATA_SOURCE_TABS_ENUM.PRICING,
    title: 'Pricing',
    value: 3,
  },
];
