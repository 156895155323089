import { useDispatch } from '@a-type/ui/hooks';
import { changeExtraCreditPopup } from '@a-type/ui/stores/actions';
import { useGetUserBalanceQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Button, Typography } from '@mui/material';

const BuyListFormWalletComponent = () => {
  const { data: creditsBalance = 0 } = useGetUserBalanceQuery();
  const dispatch = useDispatch();

  const changeActivePayment = () => {
    dispatch(changeExtraCreditPopup(true));
  };
  return (
    <Box
      sx={{
        alignContent: 'center',
        background: globalStyles.mainColors.lightFog,
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'space-between',
        outline: `1px solid ${globalStyles.mainColors.lightUnaviableRangeGray}`,
        p: 3,
      }}
    >
      <Box>
        <Typography
          sx={{ color: globalStyles.mainColors.sootyColor, fontSize: 24, fontWeight: '900' }}
        >
          Wallet Balance
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.tranquilPondColor,
            fontSize: 17,
            fontWeight: '500',
          }}
        >
          {creditsBalance.toLocaleString()} credits available
        </Typography>
      </Box>
      <Button data-qa="summary-btn-buymorecredits" onClick={changeActivePayment}>
        Buy more credits
      </Button>
    </Box>
  );
};

export default BuyListFormWalletComponent;
