import {
  DataSourceFieldComponentType,
  DataSourceFieldDataType,
  FieldMetadataType,
} from '@a-type/enums';
import { IDataSourceField } from '@a-type/interfaces';
import { useDispatch } from '@a-type/ui/hooks';
import { snackbarErrorMessage } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import MdClear from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import InfoIcon from '@mui/icons-material/Info';
import LoupeIcon from '@mui/icons-material/Loupe';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import DataSourceDetailsFieldConfiguratorFilter from '../../DataSourceDetailsFieldConfiguratorFilter.component';
import DataSourceDetailsApiFieldConfiguratorControls from './DataSourceDetailsApiFieldConfiguratorControls.component';

const metadataTypes = Object.values(FieldMetadataType);

export interface DataSourceDetailsApiFieldConfiguratorProps {
  components: { [key: string]: string };
  deleteFieldHandler: (field: string) => void;
  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  dragRef: (element: HTMLElement | null) => void;
  field: IDataSourceField;
  groups: { [key: string]: string };

  // dictionary for filter components
  hasAccessApiFields: { [key: string]: boolean };
  includedInReport: string[];
  isDragDisabled: boolean;
  // drag and drop
  isDragging: boolean;

  selectedField: null | string;
  selectedMetadataTypes: FieldMetadataType[];
  setSelectedField: (value: null | string) => void;
  updateFieldHandler: (field: IDataSourceField) => void;
  updateFieldIsValidHandler: (field: string, isValid: boolean) => void;
}

const DataSourceDetailsApiFieldConfigurator = ({
  components,
  deleteFieldHandler,
  draggableProps,
  dragHandleProps,
  dragRef,
  field,
  groups,
  hasAccessApiFields,
  includedInReport,
  isDragDisabled,
  isDragging,
  selectedField,
  selectedMetadataTypes,
  setSelectedField,
  updateFieldHandler,
  updateFieldIsValidHandler,
}: DataSourceDetailsApiFieldConfiguratorProps) => {
  const dispatch = useDispatch();
  const [editField, setEditField] = useState<IDataSourceField>({ ...field });
  const [fieldIsValid, setFieldIsValid] = useState<boolean>(true);

  useEffect(() => {
    if (!editField) return;

    updateFieldIsValidHandler(editField.name!, fieldIsValid);
  }, []);

  // update sorting order
  useEffect(() => {
    if (field.sortOrder !== editField.sortOrder) {
      setEditField((prev) => {
        return {
          ...prev,
          sortOrder: field.sortOrder,
        };
      });
    }
  }, [field]);

  useEffect(() => {
    if (selectedField !== editField.name) return () => {};

    const timeOutId = setTimeout(() => {
      updateFieldIsValidHandler(editField.name!, fieldIsValid);
    }, 250);

    return () => clearTimeout(timeOutId);
  }, [fieldIsValid]);

  useEffect(() => {
    if (selectedField !== editField.name) return () => {};

    const timeOutId = setTimeout(() => {
      updateFieldHandler(editField);
    }, 250);

    return () => clearTimeout(timeOutId);
  }, [editField]);

  const updateFieldValueHandler = (key: string, value: any) => {
    setEditField((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    const valid =
      editField.ignore === true ||
      (!!editField.name && // check if name is not empty
        !!editField.displayName && // check if displayName is not empty
        editField.price !== undefined && // check if price is not undefined
        editField.price.toString() !== '' && // check if price is not empty
        editField.price >= 0 && // check if price is greater than or equal to 0
        (editField.isFilter === false || (!!editField.filterGroup && !!editField.componentType)) && // check if isFilter is false or filterGroup and componentType are not empty
        (editField.isFilter === false ||
          editField.componentType !== DataSourceFieldComponentType.OPTION_SELECT ||
          (!!editField.options && editField.options.length > 0))); // check if isFilter is false or componentType is OPTION_SELECT and options is not empty

    setFieldIsValid(valid);
  }, [editField]);

  function getFieldBackgroundColor() {
    if (selectedField === editField.name) {
      return globalStyles.mainColors.whiteColor;
    }
    if (fieldIsValid === false) {
      return globalStyles.mainColors.lightRed;
    }
    return globalStyles.mainColors.whiteColor;
  }

  const clearIcon = () => (
    <IconButton
      onClick={() => {
        updateFieldValueHandler('metadataType', undefined);
      }}
      size="small"
      sx={{
        '&:hover': {
          backgroundColor: 'transparent',
        },
        marginRight: '8px',
        padding: 0,
      }}
    >
      <MdClear fontSize="small" />
    </IconButton>
  );

  return (
    <Accordion
      ref={dragRef}
      {...draggableProps}
      expanded={selectedField === editField.name}
      key={editField.name}
      onChange={() => {
        if (isDragging) return;
        setSelectedField(editField.name!);
      }}
      sx={{
        background: getFieldBackgroundColor(),
        border:
          fieldIsValid === false
            ? `1px solid ${globalStyles.mainColors.redColor}`
            : `0.5px solid ${globalStyles.mainColors.veryLightGrayColor}`,
      }}
    >
      <AccordionSummary
        onClick={(e) => {
          if (isDragging) return;
          e.stopPropagation();

          if (selectedField === editField.name) {
            setSelectedField(null);
          } else {
            setSelectedField(editField.name!);
          }
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
            }}
          >
            <Button
              {...dragHandleProps}
              disabled={isDragDisabled}
              startIcon={
                <DragIndicatorIcon
                  sx={{
                    color: globalStyles.mainColors.grayColor,
                    fontSize: 20,
                  }}
                />
              }
              sx={{
                '.MuiButton-startIcon': {
                  marginRight: 0,
                },
                borderRadius: 2,
                cursor: 'grab',
                height: 30,
                minWidth: 30,
                px: 1,
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontSize: 15,
                  mt: 0.25,
                }}
              >
                {editField.sortOrder}
              </Typography>
            </Button>

            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.blackColor,
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              {editField.displayName}
            </Typography>

            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.grayColor,
                display: 'block',
                fontSize: 12,
              }}
            >
              ({editField.name})
            </Typography>

            {hasAccessApiFields[editField.name!] && (
              <Tooltip placement="top" title="Has access to fetch data from the API">
                <IconButton sx={{ height: 30, width: 30 }}>
                  <CheckCircleRoundedIcon
                    sx={{ color: globalStyles.mainColors.greenColor, fontSize: 20 }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
              justifyContent: 'flex-end',
            }}
          >
            {editField.metadataType && (
              <Chip color="secondary" label={editField.metadataType} size="small" />
            )}

            {editField.isFilter && (
              <>
                {editField.filterGroup && (
                  <Chip color="primary" label={groups[editField.filterGroup!]} size="small" />
                )}

                {editField.componentType && (
                  <Chip color="warning" label={components[editField.componentType!]} size="small" />
                )}

                <Tooltip
                  sx={{ alignItems: 'center', display: 'flex', p: 0 }}
                  title={
                    hasAccessApiFields[editField.name!]
                      ? 'This field is a filter field.'
                      : 'You can filter by this field, but it will be empty in the report. Since we do not have access to this field.'
                  }
                >
                  <IconButton sx={{ height: 30, width: 30 }}>
                    <FilterAltIcon
                      sx={{
                        color: hasAccessApiFields[editField.name!]
                          ? globalStyles.mainColors.blueColor
                          : globalStyles.mainColors.carminePinkColor,
                        fontSize: 20,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </>
            )}

            {editField.isGroupedBy && (
              <Tooltip
                sx={{ alignItems: 'center', display: 'flex', p: 0 }}
                title={
                  hasAccessApiFields[editField.name!]
                    ? 'This field using for narrow further search results.'
                    : 'This field using for narrow further search results, but it will be empty in the report. Since we do not have access to this field.'
                }
              >
                <IconButton sx={{ height: 30, width: 30 }}>
                  <LoupeIcon
                    sx={{
                      color: hasAccessApiFields[editField.name!]
                        ? globalStyles.mainColors.darkSpellColor
                        : globalStyles.mainColors.carminePinkColor,
                      fontSize: 20,
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}

            {includedInReport.includes(editField.name!) && (
              <Tooltip
                sx={{ alignItems: 'center', display: 'flex', p: 0 }}
                title={
                  hasAccessApiFields[editField.name!]
                    ? 'This field is included in the report.'
                    : 'This field is included in the report, but will be empty in the report. Since we do not have access to this field.'
                }
              >
                <IconButton sx={{ height: 30, width: 30 }}>
                  <SummarizeIcon
                    sx={{
                      color: hasAccessApiFields[editField.name!]
                        ? globalStyles.mainColors.greenColor
                        : globalStyles.mainColors.carminePinkColor,
                      fontSize: 20,
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}

            <Box
              onClick={(e) => {
                if (isDragging) return;
                e.stopPropagation();

                if (selectedField === editField.name) {
                  setSelectedField(null);
                } else {
                  setSelectedField(editField.name!);
                }
              }}
              sx={{ alignItems: 'center', display: 'flex' }}
            >
              {selectedField === editField.name ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      {selectedField === editField.name && (
        <AccordionDetails>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: 2,
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 6,
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  gap: 2,
                }}
              >
                <TextField
                  error={!editField.displayName}
                  label="Display Name"
                  onChange={(e) => {
                    if (!e.target.value) {
                      dispatch(snackbarErrorMessage('Please fill out the Display Name field.'));
                    }
                    updateFieldValueHandler('displayName', e.target.value);
                  }}
                  placeholder="Display Name"
                  size="small"
                  sx={{
                    background: globalStyles.mainColors.whiteColor,
                    flexGrow: 1,
                    maxWidth: 500,
                    minWidth: 300,
                  }}
                  value={editField.displayName}
                  variant="outlined"
                />

                <Tooltip
                  title={[
                    <p key={editField.name}>
                      API Field Name: {editField.name}
                      <br />
                      Data Type: {editField.dataType}
                    </p>,
                  ]}
                >
                  <IconButton sx={{ height: 40, width: 40 }}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                  justifyContent: 'flex-end',
                }}
              >
                <DataSourceDetailsApiFieldConfiguratorControls
                  deleteFieldHandler={deleteFieldHandler}
                  field={editField}
                  updateFieldValueHandler={updateFieldValueHandler}
                />
              </Box>
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
                justifyContent: 'flex-start',
              }}
            >
              <TextField
                error={editField.price === undefined || editField.price < 0}
                label="Price"
                onChange={(e) => {
                  updateFieldValueHandler('price', e.target.value);
                }}
                placeholder="Price"
                size="small"
                sx={{ background: globalStyles.mainColors.whiteColor, width: 100 }}
                type="number"
                value={editField.price}
                variant="outlined"
              />

              {editField.dataType === DataSourceFieldDataType.NUMBER && (
                <TextField
                  label="Units"
                  onChange={(e) => {
                    updateFieldValueHandler('units', e.target.value);
                  }}
                  placeholder="Units"
                  size="small"
                  sx={{ background: globalStyles.mainColors.whiteColor, width: 100 }}
                  value={editField.units}
                  variant="outlined"
                />
              )}

              <FormControl>
                <InputLabel id="separators-label" size="small">
                  Metadata Type
                </InputLabel>
                <Select
                  IconComponent={editField.metadataType !== undefined ? clearIcon : undefined}
                  label="Metadata Type"
                  onChange={(e) => {
                    updateFieldValueHandler(
                      'metadataType',
                      e.target.value === '' ? undefined : e.target.value,
                    );
                  }}
                  placeholder="Metadata Type"
                  size="small"
                  sx={{ background: globalStyles.mainColors.whiteColor, width: 150 }}
                  value={editField.metadataType || ''}
                  variant="outlined"
                >
                  {metadataTypes.map((x: FieldMetadataType) => {
                    return (
                      <MenuItem
                        disabled={selectedMetadataTypes.includes(x) && x !== editField.metadataType}
                        key={x}
                        value={x}
                      >
                        {x}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <TextField
                label="Description"
                onChange={(e) => {
                  updateFieldValueHandler('description', e.target.value);
                }}
                placeholder="Description"
                size="small"
                sx={{ background: globalStyles.mainColors.whiteColor, flexGrow: 1 }}
                value={editField.description}
                variant="outlined"
              />
            </Box>

            {editField.isFilter && (
              <DataSourceDetailsFieldConfiguratorFilter
                field={editField}
                updateFieldValueHandler={updateFieldValueHandler}
              />
            )}
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default DataSourceDetailsApiFieldConfigurator;
