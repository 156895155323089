import { IDataSourceSftp } from '@a-type/interfaces';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, TextField, Typography } from '@mui/material';

export interface DataSourceDetailsFileSFTPProps {
  setSftpData: (value: IDataSourceSftp) => void;
  sftpData: IDataSourceSftp;
}

const DataSourceDetailsFileSFTP = (props: DataSourceDetailsFileSFTPProps) => {
  const { setSftpData, sftpData } = props;

  const ftpInputsHandler = (keyName: string, value: any) => {
    setSftpData({ ...sftpData, [keyName]: value });
  };

  const { host, password, port, uploadDirectoryPath, user } = sftpData;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography
        component="span"
        sx={{
          display: 'block',
          fontSize: 12,
          mb: -2,
        }}
      >
        Fill in the details to connect to the SFTP server.
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
        <TextField
          key="host"
          label="SFTP Url"
          onChange={(e: any) => ftpInputsHandler('host', e.target.value)}
          placeholder="SFTP Url"
          sx={{ background: globalStyles.mainColors.whiteColor, width: '100%' }}
          value={host || ''}
          variant="outlined"
        />
        <TextField
          key="port"
          label="Port"
          onChange={(e: any) => ftpInputsHandler('port', e.target.value)}
          placeholder="Port"
          sx={{ background: globalStyles.mainColors.whiteColor, width: '100%' }}
          type="number"
          value={port || 22}
          variant="outlined"
        />
      </Box>
      <TextField
        key="user"
        label="User name"
        onChange={(e: any) => ftpInputsHandler('user', e.target.value)}
        placeholder="User"
        sx={{ background: globalStyles.mainColors.whiteColor, width: '100%' }}
        value={user || ''}
        variant="outlined"
      />
      <TextField
        key="password"
        label="Password"
        onChange={(e: any) => ftpInputsHandler('password', e.target.value)}
        placeholder="Password"
        sx={{ background: globalStyles.mainColors.whiteColor, width: '100%' }}
        value={password || ''}
        variant="outlined"
      />
      <TextField
        key="uploadDirectoryPath"
        label="Upload Directory Path"
        onChange={(e: any) => ftpInputsHandler('uploadDirectoryPath', e.target.value)}
        placeholder="Upload Directory Path"
        sx={{ background: globalStyles.mainColors.whiteColor, width: '100%' }}
        value={uploadDirectoryPath || ''}
        variant="outlined"
      />
    </Box>
  );
};

export default DataSourceDetailsFileSFTP;
