import { IDataSourceStatus } from '@a-type/dtos';
import { CombinedSearchType, DataSourceDslStatus } from '@a-type/enums';
import { ICombinedSearch } from '@a-type/interfaces';
import { StepNavigation, StepNavigationItem } from '@a-type/ui/components';
import { useDispatch, useQuery } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetCombinedSearchQuery, useGetCombinedSearchStatusQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Warning } from '@mui/icons-material';
import { Box, Chip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CombinedSearchBasic } from './combined-search-basic.component';
import { CombinedSearchFilters } from './combined-search-filters.component';
import { CombinedSearchStatus } from './combined-search-status.component';
import { CombinedSearchSteps } from './combined-search-steps.enum';

export const CombinedSearchPage: React.FC = () => {
  const params = useParams();
  const query = useQuery();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState<string>(
    query.get('step') ?? CombinedSearchSteps.Base,
  );
  const [steps, setSteps] = useState<StepNavigationItem[]>([
    { enabled: true, id: CombinedSearchSteps.Base, label: 'Basic Information' },
    { enabled: true, id: CombinedSearchSteps.Filters, label: 'Filters' },
    { enabled: true, id: CombinedSearchSteps.Status, label: 'Status' },
  ]);
  const [combinedSearch, setCombinedSearch] = useState<ICombinedSearch | undefined>();
  const [status, setStatus] = useState<IDataSourceStatus | null>(null);
  const { data: combinedSearchData, isLoading: isCombinedSearchLoading } =
    useGetCombinedSearchQuery(params.type as CombinedSearchType, {
      skip: !params.type,
    });
  const { data: statusData, isLoading: isStatusLoading } = useGetCombinedSearchStatusQuery(
    params.type as CombinedSearchType,
    {
      skip: !params.type,
    },
  );

  useEffect(() => {
    dispatch(pageContentLoad(!isCombinedSearchLoading && !isStatusLoading));
  }, [isCombinedSearchLoading, isStatusLoading]);

  useEffect(() => {
    if (combinedSearchData) {
      setCombinedSearch(combinedSearchData);
    }
  }, [combinedSearchData]);

  useEffect(() => {
    if (statusData) {
      setStatus(statusData);

      if (statusData.isRequiredBuildLookup || statusData.isRequiredReindex) {
        setSteps((x) =>
          x.map((y) => {
            if (y.id === CombinedSearchSteps.Status) {
              return {
                ...y,
                icon: <Warning color="error" fontSize="small" />,
              };
            }
            return y;
          }),
        );
      } else {
        setSteps((x) =>
          x.map((y) => {
            if (y.id === CombinedSearchSteps.Status) {
              return {
                ...y,
                icon: undefined,
              };
            }
            return y;
          }),
        );
      }
    }
  }, [statusData]);

  const updateCombinedSearch = (field: string, value: any) => {
    if (!combinedSearch) return;

    if (field === 'primaryDataSourceId') {
      setCombinedSearch({
        ...combinedSearch,
        primaryDataSourceId: value,
        secondaryDataSources: [],
      });
    } else {
      setCombinedSearch({
        ...combinedSearch,
        [field]: value,
      });
    }
  };

  const getStatusColor = () => {
    switch (combinedSearch?.status) {
      case DataSourceDslStatus.COMPLETED:
        return globalStyles.mainColors.greenColor;
      case DataSourceDslStatus.FAILED:
        return globalStyles.mainColors.redColor;
      case DataSourceDslStatus.PROCESSING:
        return globalStyles.mainColors.yellowColor;
      default:
        return globalStyles.mainColors.sootyColor;
    }
  };

  const getStatusLabel = () => {
    if (!combinedSearch) return '';

    switch (combinedSearch.status) {
      case DataSourceDslStatus.COMPLETED:
        return 'Completed';
      case DataSourceDslStatus.FAILED:
        return 'Failed';
      case DataSourceDslStatus.PROCESSING:
        return `Processing${
          combinedSearch.progress ? ` (${combinedSearch.progress.toFixed(2)}%)` : ''
        }`;
      default:
        return 'Unknown';
    }
  };

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 3,
          py: 1,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            justifyContent: 'space-between',
            pb: 2,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              {combinedSearch?.name ?? 'New Combined Search'}
            </Typography>
          </Box>

          {combinedSearch?.status && (
            <Typography
              component="div"
              sx={{
                alignItems: 'center',
                color: globalStyles.mainColors.sootyColor,
                display: 'flex',
                fontSize: 16,
                fontWeight: 500,
                gap: 1,
              }}
            >
              Status:
              <Chip
                color="primary"
                label={getStatusLabel()}
                size="small"
                sx={{
                  backgroundColor: getStatusColor(),
                  color: globalStyles.mainColors.whiteColor,
                  textTransform: 'uppercase',
                }}
              />
            </Typography>
          )}
        </Box>

        <StepNavigation
          activeStep={activeStep}
          defaultStep={CombinedSearchSteps.Base}
          items={steps}
          setActiveStep={setActiveStep}
        />

        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
          }}
        >
          {combinedSearch && activeStep === CombinedSearchSteps.Base && (
            <CombinedSearchBasic
              combinedSearch={combinedSearch}
              setActiveStep={setActiveStep}
              updateCombinedSearch={updateCombinedSearch}
            />
          )}

          {combinedSearch && activeStep === CombinedSearchSteps.Filters && (
            <CombinedSearchFilters combinedSearch={combinedSearch} setActiveStep={setActiveStep} />
          )}

          {combinedSearch && status && activeStep === CombinedSearchSteps.Status && (
            <CombinedSearchStatus
              combinedSearch={combinedSearch}
              setActiveStep={setActiveStep}
              status={status}
            />
          )}
        </Box>
      </Box>
    </AdminPageLayout>
  );
};
