import { ListTaskStatus, ListTaskType } from '@a-type/enums';
import { useDispatch, useQuery, useSelector } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { listService } from '@a-type/ui/services';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { HttpStatus } from '@a-type/ui/types';
import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridPagination } from '../../components';
import ListDocumentsEmptyComponent from './components/ListDocumentsEmpty.component';
import ListDocumentsHeaderComponent from './components/ListDocumentsHeader.component';
import { ListDocumentsOnlineAudienceComponent } from './components/ListDocumentsOnlineAudience.component';
import { ListRowsAndColumnsBuilder } from './utils/ListRowsAndColumnsBuilder.utils';

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 25;

const ListDocumentsComponent = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list } = useSelector((state) => state.list);
  const [builder, setBuilder] = useState<ListRowsAndColumnsBuilder | undefined>();
  const [documents, setDocuments] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [buyingDate, setBuyingDate] = useState<string>('all');
  const [pagination, setPagination] = useState({
    limit: DEFAULT_LIMIT,
    page: DEFAULT_PAGE,
  });
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [activeTab, setActiveTab] = useState(query.get('tab') ?? 'data');

  useEffect(() => {
    if (query.get('tab')) {
      switch (query.get('tab')) {
        case 'data':
          setActiveTab('data');
          break;
        case 'online-audience':
          setActiveTab('online-audience');
          break;
        default:
          setActiveTab('data');
          break;
      }
    }
  }, [query]);

  useEffect(() => {
    if (!list) return;

    const b = new ListRowsAndColumnsBuilder(list.fieldMetadata!);
    setBuilder(b);
    setColumns(b.buildColumns());
  }, [list]);

  useEffect(() => {
    let isMounted = true;

    // if list is not loaded or list has tasks that are not done
    if (
      !list ||
      (list.tasks || []).some(
        (task) =>
          (task.type === ListTaskType.BUY || task.type === ListTaskType.BUY_MORE) &&
          task.status !== ListTaskStatus.DONE,
      )
    )
      return () => {
        isMounted = false;
      };

    dispatch(pageContentLoad(false));
    listService
      .getListDocument(
        list._id,
        pagination.page,
        pagination.limit,
        buyingDate === 'all' ? undefined : buyingDate,
      )
      .then((response) => {
        if (isMounted && response.status === HttpStatus.OK) {
          const { data } = response;
          setDocuments(data.items);
          setCount(data.count);
          setPages(data.pages);
        }
      })
      .finally(() => {
        dispatch(pageContentLoad(true));
      });

    return () => {
      isMounted = false;
    };
  }, [pagination, buyingDate]);

  useEffect(() => {
    if (!builder) return;
    if (!documents?.length) return;

    setRows(builder.buildRows(documents));
  }, [documents]);

  const onChangePagination = (page: number, limit: number) => {
    if (page < 0) {
      return;
    }

    if (pagination.page === page && pagination.limit === limit) return;

    setPagination({
      limit: limit || DEFAULT_LIMIT,
      page: page || DEFAULT_PAGE,
    });
  };

  const paginator = () => {
    return (
      <GridPagination
        count={count}
        limit={pagination.limit}
        onChangePagination={(page, limit) => {
          setPagination({ limit, page });
        }}
        page={pagination.page}
        pages={pages}
      />
    );
  };
  return (
    <>
      <ListDocumentsHeaderComponent
        buyingDate={buyingDate}
        setBuyingDate={setBuyingDate}
        totalCount={count}
      />
      {activeTab === 'online-audience' && <ListDocumentsOnlineAudienceComponent list={list!} />}

      {activeTab === 'data' &&
        (!rows?.length ? (
          <ListDocumentsEmptyComponent />
        ) : (
          <Box sx={{ mt: 0.5 }}>
            <DataGrid
              columns={columns}
              disableRowSelectionOnClick
              hideFooterPagination={pages === 0}
              onPaginationModelChange={(model: GridPaginationModel) => {
                onChangePagination(pagination.page, model.pageSize);
              }}
              onRowClick={(row) => {
                if (list?.isTaxAssessor === true) {
                  navigate(`${AppRoutes.Lists}/${list._id}/tax-assessor/${row.id}`);
                }
              }}
              rowHeight={40}
              rows={rows}
              slots={{
                pagination: paginator,
              }}
              sx={{
                height: 'calc(100vh - 320px)',
              }}
            />
          </Box>
        ))}
    </>
  );
};

export default ListDocumentsComponent;
