import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Control, Controller, Validate } from 'react-hook-form';

interface IFormDatepickerProps {
  control: Control<any>;
  label: string;
  maxDate?: Date;
  minDate?: Date;
  name: string;
  required?: boolean;
  rules?: Record<string, Validate<string, any>>;
  sx?: SxProps<Theme>;
}

export const FormDatepicker: React.FC<IFormDatepickerProps> = ({
  control,
  label,
  maxDate,
  minDate,
  name,
  required = false,
  rules = {},
  sx,
}: IFormDatepickerProps) => {
  return (
    <Controller
      control={control}
      defaultValue={null}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DatePicker
          label={label}
          maxDate={maxDate}
          minDate={minDate}
          onChange={(date) => onChange(date?.toISOString() ?? null)}
          slotProps={{
            textField: {
              error: !!error,
              helperText: error?.message,
              required,
              sx,
            },
          }}
          value={value ? new Date(value) : null}
        />
      )}
      rules={{
        validate: {
          required: required ? (value) => !!value || 'Field is required' : () => true,
          ...rules,
        },
      }}
    />
  );
};
