import { IStripeSubscription } from '@a-type/dtos';
import { Dialog, PaymentMethods } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import {
  changeSubscriptionActivatedPopup,
  pageContentLoad,
  snackbarErrorMessage,
} from '@a-type/ui/stores/actions';
import { useSubscribeMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface SubscribeDialogProps {
  onClose: () => void;
  open: boolean;
  subscription: IStripeSubscription;
}

export const SubscribeDialog: React.FC<SubscribeDialogProps> = ({
  onClose,
  open,
  subscription,
}: SubscribeDialogProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<null | string>(null);
  const [subscribe, { isLoading: subscribeLoading }] = useSubscribeMutation();
  const [isAddingCard, setIsAddingCard] = useState(false);

  useEffect(() => {
    dispatch(pageContentLoad(!subscribeLoading));
  }, [subscribeLoading]);

  const handleSubscribe = async () => {
    if (!selectedPaymentMethod) {
      return;
    }

    const result = await subscribe({
      id: subscription.id,
      paymentMethodId: selectedPaymentMethod,
    });

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while creating card'));
    } else {
      dispatch(changeSubscriptionActivatedPopup(true));
      navigate(AppRoutes.HomePage);
    }

    onClose();
  };

  return (
    <Dialog
      cancelText="Cancel"
      disableCancel={subscribeLoading || isAddingCard}
      disableOk={subscribeLoading || isAddingCard}
      okText="Subscribe"
      onCancel={onClose}
      onClose={onClose}
      onOk={handleSubscribe}
      open={open}
      size="sm"
      title="Subscription"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 16,
          }}
        >
          You are about to subscribe to the {subscription.name} plan for{' '}
          <strong>${subscription.price}</strong> per month.
        </Typography>

        <Divider />

        <PaymentMethods
          onAddPaymentMethod={(show) => {
            setIsAddingCard(show);
          }}
          onSelectPaymentMethod={(payment) => {
            setSelectedPaymentMethod(payment.id);
          }}
        />
      </Box>
    </Dialog>
  );
};
