import { IChangePassword } from '@a-type/dtos';
import { Dialog, FormPasswordInput } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useChangePasswordMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface IChangePasswordProps {
  setShow: (show: boolean) => void;
  show: boolean;
}

export const ChangePassword: FC<IChangePasswordProps> = ({
  setShow,
  show,
}: IChangePasswordProps) => {
  const dispatch = useDispatch();
  const [changePassword, { isLoading: changePasswordLoading }] = useChangePasswordMutation();
  const { control, getValues, handleSubmit, watch } = useForm<IChangePassword>();
  const [isValid, setIsValid] = useState(false);
  const [validation, setValidation] = useState<{
    hasLetter: boolean;
    hasLowercase: boolean;
    hasNumber: boolean;
    hasSpecial: boolean;
    hasUppercase: boolean;
    isLongEnough: boolean;
  }>({
    hasLetter: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecial: false,
    hasUppercase: false,
    isLongEnough: false,
  });

  useEffect(() => {
    dispatch(pageContentLoad(!changePasswordLoading));
  }, [changePasswordLoading]);

  const handlePasswordChange = async () => {
    const result = await changePassword(getValues());

    if (result.data) {
      dispatch(snackbarSuccessMessage('Password changed successfully'));
      setShow(false);
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while changing password'));
    }
  };

  useEffect(() => {
    const password = getValues('newPassword');
    if (!password) {
      setIsValid(false);
      return;
    }

    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLetter = hasLowercase || hasUppercase;
    const hasNumber = /\d/.test(password);
    const hasSpecial = /[^a-zA-Z0-9]+/.test(password);
    const isLongEnough = password.length >= 8;

    setValidation({
      hasLetter,
      hasLowercase,
      hasNumber,
      hasSpecial,
      hasUppercase,
      isLongEnough,
    });

    setIsValid(hasLowercase && hasUppercase && hasNumber && hasSpecial && isLongEnough);
  }, [watch('newPassword')]);

  return (
    <Dialog
      cancelText="Cancel"
      disableOk={!isValid}
      okText="Change Password"
      onCancel={() => setShow(false)}
      onClose={() => setShow(false)}
      onOk={handleSubmit(handlePasswordChange)}
      open={show}
      size="sm"
      title="Change Password"
    >
      <Box
        component="form"
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3.5,
          p: 1,
        }}
      >
        <FormPasswordInput control={control} label="Old Password" name="oldPassword" />
        <FormPasswordInput
          control={control}
          label="New Password"
          name="newPassword"
          rules={{
            matchesOldPassword: (value) =>
              value !== getValues('oldPassword') ||
              'New password must be different from old password',
          }}
        />
        <FormPasswordInput
          control={control}
          label="Confirm Password"
          name="confirmPassword"
          rules={{
            matchesPassword: (value) =>
              value === getValues('newPassword') || 'Passwords do not match',
          }}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
              fontWeight: 500,
              mb: 1,
            }}
          >
            Your password must contains:
          </Typography>

          <Typography
            sx={{
              color: validation.isLongEnough
                ? globalStyles.mainColors.greenColor
                : globalStyles.mainColors.redColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {validation.isLongEnough ? '✓' : '✗'}&ensp;At least 8 characters
          </Typography>
          <Typography
            sx={{
              color:
                validation.hasLetter && validation.hasNumber
                  ? globalStyles.mainColors.greenColor
                  : globalStyles.mainColors.redColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {validation.hasLetter && validation.hasNumber ? '✓' : '✗'}&ensp;Mix of letters and
            numbers
          </Typography>
          <Typography
            sx={{
              color: validation.hasSpecial
                ? globalStyles.mainColors.greenColor
                : globalStyles.mainColors.redColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {validation.hasSpecial ? '✓' : '✗'}&ensp;At least 1 special character
          </Typography>

          <Typography
            sx={{
              color:
                validation.hasLowercase && validation.hasUppercase
                  ? globalStyles.mainColors.greenColor
                  : globalStyles.mainColors.redColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {validation.hasLowercase && validation.hasUppercase ? '✓' : '✗'}&ensp;At least 1
            lowercase letter and 1 uppercase letter
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};
