import { DataSourceFieldDataType } from '@a-type/enums';

class DataTypes {
  public static determineDataType = (values: string[]): DataSourceFieldDataType => {
    // check if all values are empty
    if (values.every((value) => value === '')) {
      return DataSourceFieldDataType.STRING;
    }

    // check if all values are numbers
    if (values.every((value) => !Number.isNaN(Number(value)))) {
      return DataSourceFieldDataType.NUMBER;
    }

    // check if all values are boolean
    if (values.every((value) => value === 'true' || value === 'false')) {
      return DataSourceFieldDataType.BOOLEAN;
    }

    // check if all values are dates
    if (values.every((value) => !Number.isNaN(Date.parse(value)))) {
      return DataSourceFieldDataType.DATE;
    }

    // default to string
    return DataSourceFieldDataType.STRING;
  };

  /**
   * convert snake_case, camelCase, or PascalCase to human readable string, but keep abvreviations
   * @param key key value to convert to display name
   * @returns Human readable string
   */
  public static toDisplayNameString = (key: string): string => {
    const rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    return key?.replace(rex, '$1$4 $2$3$5');
  };
}

export default DataTypes;
