import { ListTaskStatus } from '@a-type/enums';
import { IList, IListTask } from '@a-type/interfaces';
import { ActionButton, MoreActionItem, MoreActionsSelector } from '@a-type/ui/components';
import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { useDispatch } from '@a-type/ui/hooks';
import { ListTaskProgress } from '@a-type/ui/pages/lists/components';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { resetList } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Box, ListItemButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type ListsTableItemComponentTypes = {
  canAppendData: boolean;
  canBuyMoreData: boolean;
  index: number;
  list: IList;
  showCSVData: (list: IList) => void;
};

export const ListsTableItemComponent = (props: ListsTableItemComponentTypes) => {
  const { canAppendData, canBuyMoreData, index, list, showCSVData } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [task, setTask] = useState<IListTask | null>(null);
  const { _id, avilableRecords, name, tasks, totalPrice, totalRecords = 0, updatedAt } = list;

  useEffect(() => {
    // set last task as active task
    if (tasks && tasks.length > 0 && tasks[tasks.length - 1].status !== ListTaskStatus.DONE) {
      setTask(tasks[tasks.length - 1]);
    } else {
      setTask(null);
    }
  }, [tasks]);

  return (
    <ListItemButton
      key={index}
      onClick={() => {
        dispatch(resetList());
        navigate(`${AppRoutes.Lists}/${_id}`);
      }}
      sx={{
        display: 'flex',
        gap: 2.5,
        justifyContent: 'space-between',
        pl: 0,
        pr: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          pl: 2,
          width: '25%',
        }}
      >
        <Typography sx={{ fontSize: 16, fontWeight: 700 }}>{name}</Typography>
        <Typography sx={{ color: globalStyles.mainColors.siriyGrayColor, fontSize: 11 }}>
          Purchased on {new Date(updatedAt!).toLocaleDateString()}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pr: 2,
          width: `25%`,
        }}
      >
        <Typography
          sx={{
            color: globalStyles.mainColors.blackColor,
            fontSize: 16,
            fontWeight: 700,
            textAlign: 'start',
          }}
        >
          {Number(totalRecords).toLocaleString()}
        </Typography>
        {avilableRecords && avilableRecords > 0 && (list.appendTypes || []).length === 0 ? (
          <Typography
            sx={{ color: globalStyles.mainColors.siriyGrayColor, fontSize: 11, textAlign: 'end' }}
          >
            available {avilableRecords.toLocaleString()}
          </Typography>
        ) : null}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pr: 2,
          width: `25%`,
        }}
      >
        <Typography
          sx={{
            color: globalStyles.mainColors.greenColor,
            fontSize: 16,
            fontWeight: 700,
            textAlign: 'start',
          }}
        >
          {totalPrice?.toLocaleString() || 0}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'left',
          width: `25%`,
        }}
      >
        {task && <ListTaskProgress task={task} />}

        <ActionButton
          disabled={task !== null || !canAppendData}
          icon={<PostAddIcon />}
          label={ButtonDictionaryNames.APPEND_DATA}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${AppRoutes.Lists}/${_id}/append`);
          }}
        />

        <ActionButton
          disabled={
            list.avilableRecords === 0 ||
            task !== null ||
            (list.appendTypes || []).length > 0 ||
            !canBuyMoreData
          }
          icon={<PaidOutlinedIcon />}
          label={ButtonDictionaryNames.BUY_MORE_RECORDS}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${AppRoutes.BuyMoreDataPage}/${_id}`);
          }}
        />

        <MoreActionsSelector>
          <MoreActionItem
            disabled={task !== null}
            icon={<CreateOutlinedIcon fontSize="small" />}
            label={ButtonDictionaryNames.EDIT_LIST}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              navigate(`${AppRoutes.Lists}/${_id}/edit`);
            }}
          />
          <MoreActionItem
            disabled={task !== null}
            icon={<FileDownloadOutlinedIcon fontSize="small" />}
            label={ButtonDictionaryNames.DOWNLOAD_LIST}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              showCSVData(list);
            }}
          />
        </MoreActionsSelector>
      </Box>
    </ListItemButton>
  );
};
