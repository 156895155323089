import { IVersiumAppend } from '@a-type/interfaces';
import { DragAndDrop } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import {
  useGetAppendDataQuery,
  useUpdateAppendDataSortOrdersMutation,
} from '@a-type/ui/stores/apis';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { AdminAppendDataCard } from './components';

export const AdminAppendDataPage: React.FC = () => {
  const dispatch = useDispatch();
  const { data: appendsData, isLoading: isAppendsDataLoading } = useGetAppendDataQuery();
  const [updateAppendDataSortOrders, { isLoading: isIUpdateAppendDataSortOrderDtosLoading }] =
    useUpdateAppendDataSortOrdersMutation();

  const [appendsList, setAppendsList] = useState<IVersiumAppend[]>([]);

  useEffect(() => {
    if (!isAppendsDataLoading && appendsData) {
      setAppendsList([...appendsData].sort((a, b) => a.sortOrder - b.sortOrder));
    }
  }, [appendsData, isAppendsDataLoading]);

  useEffect(() => {
    dispatch(pageContentLoad(!isAppendsDataLoading && !isIUpdateAppendDataSortOrderDtosLoading));
  }, [isAppendsDataLoading, isIUpdateAppendDataSortOrderDtosLoading]);

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            pb: 1,
          }}
        >
          <Typography sx={{ fontSize: 24, fontWeight: 800, pl: 2 }}>Append Data</Typography>
        </Box>
        <DragAndDrop
          items={appendsList}
          renderItem={(data, index, p) => (
            <AdminAppendDataCard
              data={data}
              draggableProps={p.draggableProps}
              dragHandleProps={p.dragHandleProps}
              dragRef={p.innerRef}
              key={data._id}
            />
          )}
          setItems={(updated) => {
            setAppendsList([...updated]);
            updateAppendDataSortOrders(
              updated.map((data) => ({
                _id: data._id,
                sortOrder: data.sortOrder,
              })),
            );
          }}
        />
      </Box>
    </AdminPageLayout>
  );
};
