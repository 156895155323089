import { TargetType } from '@a-type/enums';
import { IBaseLookup } from '@a-type/interfaces';
import { ImageUpload } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import {
  pageContentLoad,
  setCurrentDataSource,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  Box,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';

const Targets: IBaseLookup[] = [
  { label: 'Residential', value: TargetType.CUSTOMER },
  { label: 'Business', value: TargetType.BUSINESS },
];

const DataSourceDetailsApiBasicStep = (props: any) => {
  const { setBasicInfoValid } = props;
  const dispatch = useDispatch();
  const { currentDataSource, dataSources } = useSelector((state) => state.dataSource);
  const [existDataSourceNames, setExistDataSourceNames] = useState<string[]>([]);

  useEffect(() => {
    // get all data sources except selected data source
    const dataSourceNames = dataSources
      .filter((dataSource) => dataSource._id !== currentDataSource?._id)
      .map((dataSource) => dataSource.name);
    setExistDataSourceNames(dataSourceNames);
  }, [dataSources]);

  useEffect(() => {
    setBasicInfoValid(
      !!currentDataSource &&
        currentDataSource.name !== '' &&
        !existDataSourceNames.some((x) => x === currentDataSource.name) &&
        currentDataSource.description !== '',
    );
  }, [currentDataSource]);

  const checkIsDublicateDataSourceName = (value: string) => {
    const isExist = existDataSourceNames.filter((item: string) => item === value);
    if (isExist.length) dispatch(snackbarErrorMessage('Data Source with this name already exist'));
  };

  const setDataSourceValueHandler = (value: string, targetKeyName: string) => {
    if (!currentDataSource) return;

    if (targetKeyName === 'name') checkIsDublicateDataSourceName(value);

    dispatch(setCurrentDataSource({ ...currentDataSource, [targetKeyName]: value }));
  };

  const updateImage = async (url: string) => {
    if (!currentDataSource) return;

    dispatch(pageContentLoad(false));
    const responce = await dataSourcesService.updateImage(currentDataSource, url);

    if (responce.status === 200) {
      dispatch(setCurrentDataSource({ ...responce.data }));
      dispatch(snackbarSuccessMessage('Image uploaded successfully'));
    }

    dispatch(pageContentLoad(true));
  };

  const theme: Theme = useTheme();
  const themeStylePalette = theme.palette;

  return (
    currentDataSource && (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          height: 'auto',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            gap: 2,
            justifyContent: 'space-start',
            pt: 2,
          }}
        >
          <FormGroup>
            <Typography
              sx={{
                color: themeStylePalette.text.primary,
                fontSize: 20,
                fontWeight: 700,
                paddingBottom: 0,
                pt: 0.5,
              }}
            >
              Title
              <Typography
                component="span"
                sx={{
                  display: 'block',
                  fontSize: 12,
                }}
              >
                The name of the data source must be unique and can&apos;t contain any blank spaces.
              </Typography>
            </Typography>
            <TextField
              error={
                existDataSourceNames.some((x) => x === currentDataSource.name) ||
                !currentDataSource.name
              }
              onChange={(e) => {
                const { value } = e.target;
                const maximumNameCharacters = 30;
                if (value.length > maximumNameCharacters) return;
                setDataSourceValueHandler(e.target.value, 'name');
              }}
              placeholder="Enter the name of you list must be unique"
              sx={{
                background: globalStyles.mainColors.whiteColor,
                width: '100%',
              }}
              value={currentDataSource.name}
            />
          </FormGroup>

          <FormGroup>
            <Typography
              sx={{
                color: themeStylePalette.text.primary,
                fontSize: 20,
                fontWeight: 700,
                paddingBottom: 0,
                pb: 1,
                pt: 0.5,
              }}
            >
              Description
              <Typography
                component="span"
                sx={{
                  display: 'block',
                  fontSize: 12,
                }}
              >
                A brief description of the data source. This will be displayed to users in the data
                source list.
              </Typography>
            </Typography>
            <TextField
              multiline
              onChange={(e) => {
                const { value } = e.target;
                const maxDescriptionCharacters = 256;
                if (value.length > maxDescriptionCharacters) return;
                setDataSourceValueHandler(e.target.value, 'description');
              }}
              placeholder="Description"
              rows={7}
              sx={{
                background: globalStyles.mainColors.whiteColor,
                width: '100%',
              }}
              value={currentDataSource.description}
            />
          </FormGroup>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            justifyContent: 'flex-start',
            pt: 2,
          }}
        >
          <FormGroup>
            <Typography
              sx={{
                color: themeStylePalette.text.primary,
                fontSize: 20,
                fontWeight: 700,
                paddingBottom: 0,
                pt: 0.5,
              }}
            >
              Audience Type
              <Typography
                component="span"
                sx={{
                  display: 'block',
                  fontSize: 12,
                }}
              >
                Data source audience type.
              </Typography>
            </Typography>
            <Select
              onChange={(e) => setDataSourceValueHandler(e.target.value, 'targetType')}
              sx={{
                background: globalStyles.mainColors.whiteColor,
                width: '100%',
              }}
              value={currentDataSource.targetType}
            >
              {Targets.map((target) => (
                <MenuItem key={target.value} value={target.value}>
                  {target.label}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                color: themeStylePalette.text.primary,
                fontSize: 20,
                fontWeight: 700,
                paddingBottom: 0,
                pb: 1,
                pt: 0.5,
              }}
            >
              Image
              <Typography
                component="span"
                sx={{
                  display: 'block',
                  fontSize: 12,
                }}
              >
                An image that will be displayed to users in the data source list.
              </Typography>
            </Typography>

            <Box
              sx={{
                backgroundImage: `url(${currentDataSource.imageUrl})`,
                backgroundSize: 'cover',
                border: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
                borderRadius: 2,
                height: 300,
                position: 'relative',
                width: 400,
              }}
            >
              <ImageUpload
                height={300}
                key={`${currentDataSource._id}`}
                name={`${currentDataSource._id}`}
                sx={{
                  bottom: 4,
                  position: 'absolute',
                  right: 4,
                }}
                updateImage={updateImage}
                width={400}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default DataSourceDetailsApiBasicStep;
