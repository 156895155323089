import { IList } from '@a-type/interfaces';
import { Dialog } from '@a-type/ui/components';
import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { listService } from '@a-type/ui/services';
import { pageContentLoad, setList, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { useGetUserBalanceQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { HttpStatus } from '@a-type/ui/types';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BuyListSummaryTableComponent from './ByyListSummaryTable.component';

export interface BuyListSummaryProps {
  disabled?: boolean;
  pricePerItem: number;
  recordsToBuy: number;
}

const BuyListSummaryComponent = ({ disabled = false, ...props }: BuyListSummaryProps) => {
  const { pricePerItem, recordsToBuy } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list } = useSelector((state) => state.list);
  const { refetch: refetchCredits } = useGetUserBalanceQuery();
  const [onBuyConfirmation, setOnBuyConfirmation] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (list?.name && /^[a-zA-Z0-9_-\s]*$/.test(list.name) && recordsToBuy > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [list, recordsToBuy]);

  const buyList = async () => {
    dispatch(pageContentLoad(false));
    const model = { ...list, totalRecords: recordsToBuy } as IList;
    const response = await listService.buyList(model);
    if (response.status === HttpStatus.OK) {
      await refetchCredits();
      dispatch(setList(response.data));
      dispatch(pageContentLoad(true));
      dispatch(
        snackbarSuccessMessage(
          'List purchased successfully! It will be available in a few minutes.',
        ),
      );
      navigate(`${AppRoutes.Lists}/${response.data._id}`);
    }
  };

  const buyMore = async () => {
    dispatch(pageContentLoad(false));

    const response = await listService.buyMore(list!, recordsToBuy);
    if (response.status === HttpStatus.OK) {
      await refetchCredits();
      dispatch(setList(response.data));
      dispatch(pageContentLoad(true));
      navigate(`${AppRoutes.Lists}/${response.data._id}`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        height: '100%',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Typography
        sx={{
          color: globalStyles.mainColors.sootyColor,
          fontSize: 24,
          fontWeight: 700,
        }}
      >
        Purchase Details
      </Typography>

      <Box
        sx={{
          backgroundColor: globalStyles.mainColors.gainsboroColor,
          height: '1px',
          width: '100%',
        }}
      />

      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
        }}
      >
        <BuyListSummaryTableComponent recordsToBuy={recordsToBuy} />
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          disabled={!isValid || disabled}
          onClick={() => setOnBuyConfirmation(true)}
          sx={{
            minWidth: '176px',
            py: 1.25,
          }}
          variant="contained"
        >
          {ButtonDictionaryNames.CONFIRM_PURCHASE}
        </Button>
      </Box>

      {onBuyConfirmation && (
        <Dialog
          cancelText="Cancel"
          okText="Confirm Purchase"
          onCancel={() => setOnBuyConfirmation(false)}
          onClose={() => setOnBuyConfirmation(false)}
          onOk={() => (list?.totalRecords && list.totalRecords > 0 ? buyMore() : buyList())}
          open={onBuyConfirmation}
          title="Purchase Confirmation"
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
              fontWeight: 400,
              width: '100%',
            }}
          >
            Do you confirm the purchase of {recordsToBuy} Records for&nbsp;
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.greenColor,
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {pricePerItem * recordsToBuy} Credits&nbsp;
            </Typography>
            and the creation of a new list titled&nbsp;
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              &lsquo;{list?.name}&lsquo;
            </Typography>
            ?
          </Typography>
        </Dialog>
      )}
    </Box>
  );
};

export default BuyListSummaryComponent;
