import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import ListDocumentsComponent from '@a-type/ui/pages/lists/ListDocuments.component';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { listService } from '@a-type/ui/services';
import {
  pageContentLoad,
  resetList,
  setList,
  snackbarErrorMessage,
} from '@a-type/ui/stores/actions';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ListDocumentsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { list } = useSelector((state) => state.list);

  const getListById = async (id: string) => {
    const response = await listService.getById(id);
    if (response.status === 200) {
      dispatch(setList(response.data));
      return response.data;
    }
    dispatch(snackbarErrorMessage('List not found'));
    navigate(AppRoutes.HomePage);
    return null;
  };

  useEffect(() => {
    dispatch(resetList());
    dispatch(pageContentLoad(false));
    const locationArray = location.pathname.split('/');
    const listId = locationArray[locationArray.length - 1];

    if (!listId) {
      dispatch(snackbarErrorMessage('List not found'));
      navigate(AppRoutes.HomePage);
      dispatch(pageContentLoad(true));
    } else {
      const loadList = async () => {
        await getListById(listId);
        dispatch(pageContentLoad(true));
      };

      loadList();
    }
  }, []);

  return <PageLayout container>{list && <ListDocumentsComponent />}</PageLayout>;
};

export default ListDocumentsPage;
