import { IStripeSubscription } from '@a-type/dtos';
import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { pageContentLoad, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { useActivateSubscriptionMutation } from '@a-type/ui/stores/apis';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';

interface ActivateSubscriptionDialogProps {
  onClose: () => void;
  open: boolean;
  subscription: IStripeSubscription;
}

export const ActivateSubscriptionDialog: React.FC<ActivateSubscriptionDialogProps> = ({
  onClose,
  open,
  subscription: { id: subscriptionId, name, productId },
}: ActivateSubscriptionDialogProps) => {
  const dispatch = useDispatch();
  const [ActivateSubscription, { isLoading: isActivateSubscriptionLoading }] =
    useActivateSubscriptionMutation();

  useEffect(() => {
    dispatch(pageContentLoad(!isActivateSubscriptionLoading));
  }, [isActivateSubscriptionLoading]);

  const handleActivateSubscription = async () => {
    const data = await ActivateSubscription({
      id: productId,
      subscriptionId,
    });

    if (data.data) {
      dispatch(snackbarSuccessMessage('Subscription set as default successfully'));
      onClose();
    }
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Activate"
      onCancel={onClose}
      onClose={onClose}
      onOk={handleActivateSubscription}
      open={open}
      title="Activate Subscription"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography>
          Are you sure you want to activate the subscription <strong>{name}</strong>?
        </Typography>
      </Box>
    </Dialog>
  );
};
