import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Button } from '@mui/material';

export interface UseIgnoreSwitchProps {
  /**
   * The checked state of the switch
   */
  checked?: boolean;
  /**
   * The Ignore color
   */
  ignoreColor?: string;
  /**
   * The Ignore label
   */
  ignoreLabel?: string;
  /**
   * The function to call when the switch is toggled
   */
  onChange?: (checked: boolean) => void;
  /**
   * The Use color
   */
  useColor?: string;
  /**
   * The Use label
   */
  useLabel?: string;
}

/**
 * A UseIgnoreSwitch component. It can be toggled on or off. It is used to switch between two states.
 */
export const UseIgnoreSwitch = ({
  checked = false,
  ignoreColor = globalStyles.mainColors.headTableGreyColor,
  ignoreLabel = 'Ignore',
  onChange,
  useColor = globalStyles.mainColors.blueColor,
  useLabel = 'Use',
}: UseIgnoreSwitchProps) => {
  return (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (onChange) onChange(false);
        }}
        sx={{
          ':hover': {
            background: useColor,
            color: globalStyles.mainColors.whiteColor,
          },
          background: !checked ? useColor : globalStyles.mainColors.whiteColor,
          borderRadius: '50px 0px 0px 50px',
          color: !checked ? globalStyles.mainColors.whiteColor : globalStyles.mainColors.blackColor,
          fontSize: 12,
          height: 30,
          minWidth: 65,
          px: 1,
        }}
        variant="contained"
      >
        {useLabel}
      </Button>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (onChange) onChange(true);
        }}
        sx={{
          ':hover': {
            background: ignoreColor,
            color: globalStyles.mainColors.whiteColor,
          },
          background: checked ? ignoreColor : globalStyles.mainColors.whiteColor,
          borderRadius: '0px 50px 50px 0px',
          color: checked ? globalStyles.mainColors.whiteColor : globalStyles.mainColors.blackColor,
          fontSize: 12,
          height: 30,
          minWidth: 65,
          px: 1,
        }}
        variant="contained"
      >
        {ignoreLabel}
      </Button>
    </Box>
  );
};

export default UseIgnoreSwitch;
