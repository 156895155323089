import { FieldMetadataType } from '@a-type/enums';
import { IVersiumAppend } from '@a-type/interfaces';
import globalStyles from '@a-type/ui/styles/global.styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Card, Chip, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';

export interface AppendDataCardProps {
  accentColor: string;
  alreadyBought?: boolean;
  appendData: IVersiumAppend;
  notEnoughInputs?: boolean;
  onClick: () => void;
  selected: boolean;
  selectedFields?: FieldMetadataType[];
}

const NAMES_MAP: Record<string, string> = {
  b2bOnlineAudience: 'Audience',
  b2cOnlineAudience: 'Audience',
  contact: 'Contact',
  demographic: 'Demographic',
};

export const AppendDataCard: React.FC<AppendDataCardProps> = ({
  accentColor,
  alreadyBought = false,
  appendData,
  notEnoughInputs = false,
  onClick,
  selected,
  selectedFields = [],
}: AppendDataCardProps) => {
  const showRootPrice = useMemo(() => {
    return (
      appendData.types.length === 1 &&
      (!appendData.types[0].subTypes || appendData.types[0].subTypes.length === 0)
    );
  }, [appendData]);

  const notMappedFields = useMemo(() => {
    const fields = appendData.types.reduce((acc, type) => {
      return acc.concat(
        type.subTypes && type.subTypes.length > 0
          ? type.subTypes.reduce((a, s) => a.concat(s.requiredFields), [] as FieldMetadataType[])
          : type.requiredFields,
      );
    }, [] as FieldMetadataType[]);

    return Array.from(new Set(fields)).filter((field) => !selectedFields.find((f) => f === field));
  }, [appendData, selectedFields]);

  return (
    <Card
      key={appendData._id}
      onClick={() => {
        if (!alreadyBought && !notEnoughInputs) {
          onClick();
        }
      }}
      sx={{
        '&:hover': {
          '.description_hint': {
            display: 'block',
          },

          border: `0.5px solid ${globalStyles.mainColors.blueColor}`,
        },
        background: selected
          ? globalStyles.mainColors.blueColor
          : globalStyles.mainColors.whiteColor,
        border: selected
          ? `0.5px solid ${globalStyles.mainColors.blueColor}`
          : `0.5px solid ${globalStyles.mainColors.silverColor}`,
        borderRadius: 2,
        boxShadow:
          'rgb(0 0 0 / 6%) 0px 3px 6px -1px, rgb(0 0 0 / 6%) 0px 0px 0px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',
        boxSizing: 'border-box',
        cursor: alreadyBought || notEnoughInputs ? 'not-allowed' : 'pointer',
        display: 'flex',
        flexDirection: 'column',

        minWidth: '287px',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${appendData.imageUrl})`,
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          height: 208,
          opacity: notEnoughInputs || alreadyBought ? 0.5 : 1,
        }}
      >
        <Box
          sx={{
            background: 'linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            height: '100%',
            justifyContent: 'space-between',
            p: 2,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
              justifyContent: 'space-between',
            }}
          >
            <Chip
              label={NAMES_MAP[appendData.code] || appendData.code}
              sx={{
                '.MuiChip-label': {
                  px: 1,
                },
                background: accentColor,
                color: globalStyles.mainColors.whiteColor,
                fontSize: '13px',
                fontWeight: '400',
                height: 24,
                p: 0.5,
              }}
            />

            <Tooltip placement="top" title={appendData.description}>
              <InfoOutlinedIcon
                className="description_hint"
                sx={{
                  color: globalStyles.mainColors.whiteColor,
                  display: 'none',
                }}
              />
            </Tooltip>
          </Box>

          <Typography
            sx={{
              color: globalStyles.mainColors.whiteColor,
              fontSize: '20px',
              fontWeight: '700',
              width: '65%',
            }}
          >
            {appendData.name}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          p: 2,
        }}
      >
        {alreadyBought && (
          <>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              You already have this data!
            </Typography>

            <Tooltip
              placement="top"
              title="You already have this data in your list. You can't buy it again."
            >
              <CheckCircleIcon
                fontSize="small"
                sx={{
                  color: globalStyles.mainColors.greenColor,
                }}
              />
            </Tooltip>
          </>
        )}

        {notEnoughInputs && !alreadyBought && (
          <>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              Not all required fields are mapped!
            </Typography>

            <Tooltip
              placement="top"
              title={`Please map all required fields before selecting this data. You can do this in the 'Edit List' page. Fields are not mapped: ${notMappedFields.join(', ')}`}
            >
              <WarningIcon
                fontSize="small"
                sx={{
                  color: globalStyles.mainColors.warningColor,
                }}
              />
            </Tooltip>
          </>
        )}

        {!alreadyBought && !notEnoughInputs && (
          <>
            {showRootPrice && (
              <Typography
                sx={{
                  color: selected
                    ? globalStyles.mainColors.whiteColor
                    : globalStyles.mainColors.sootyColor,
                  fontSize: '14px',
                  fontWeight: '700',
                  textWrap: 'nowrap',
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: selected
                      ? globalStyles.mainColors.whiteColor
                      : globalStyles.mainColors.greenColor,
                    fontSize: '14px',
                    fontWeight: '700',
                    textWrap: 'nowrap',
                  }}
                >
                  {appendData.types[0].price} Credits&nbsp;
                </Typography>
                / Match
              </Typography>
            )}
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {!selected && (
                <Typography
                  sx={{
                    color: globalStyles.navigation.textColor,
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  Press to select
                </Typography>
              )}

              {selected && (
                <>
                  <Typography
                    sx={{
                      color: globalStyles.mainColors.whiteColor,
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '18px',
                    }}
                  >
                    Selected
                  </Typography>

                  <CheckCircleIcon
                    fontSize="small"
                    sx={{
                      color: globalStyles.mainColors.whiteColor,
                    }}
                  />
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </Card>
  );
};

export default AppendDataCard;
