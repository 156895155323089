import { useQuery } from '@a-type/ui/hooks';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Step, StepButton, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface StepNavigationItem {
  enabled?: boolean;
  icon?: React.ReactNode;
  id: string;
  label: string;
}

interface StepNavigationProps {
  activeStep?: string;
  defaultStep: string;
  items: StepNavigationItem[];
  setActiveStep: (step: string) => void;
}

export const StepNavigation: React.FC<StepNavigationProps> = ({
  activeStep,
  defaultStep,
  items,
  setActiveStep,
}: StepNavigationProps) => {
  const query = useQuery();
  const navigate = useNavigate();
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

  useEffect(() => {
    setActiveStepIndex(items.findIndex((item) => item.id === activeStep));
  }, [activeStep, items]);

  useEffect(() => {
    if (!activeStep) {
      setActiveStep(defaultStep);
    }
  }, [activeStep, defaultStep]);

  useEffect(() => {
    if (activeStep) {
      query.set('step', activeStep);
      navigate({ search: query.toString() });
    }
  }, [activeStep]);

  return (
    <Stepper activeStep={activeStepIndex} sx={{ width: '100%' }}>
      {items.map((tabItem) => {
        return (
          <Step
            key={tabItem.id}
            onClick={() => {
              if (!tabItem.enabled) return;
              setActiveStep(tabItem.id);
            }}
            sx={{ cursor: tabItem.enabled ? 'pointer' : 'not-allowed' }}
          >
            <StepButton
              icon={tabItem.icon}
              sx={{
                '& .MuiStepIcon-text': {
                  fill: globalStyles.mainColors.whiteColor,
                },
              }}
            >
              {tabItem.label}
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
};
