import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Card, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface InsightCardProps {
  action?: ReactNode;
  border?: boolean;
  icon: ReactNode;
  insight: string;
  onClick?: () => void;
  title: string;
}

export const InsightCard = ({
  action = undefined,
  border = false,
  icon,
  insight,
  onClick = undefined,
  title,
}: InsightCardProps) => {
  return (
    <Card
      onClick={onClick}
      sx={{
        alignItems: 'center',
        backgroundColor: globalStyles.mainColors.whiteColor,
        border: border ? `1px solid ${globalStyles.mainColors.headTableGreyColor}` : 'none',
        borderRadius: 2,
        boxShadow: 'none',
        cursor: onClick ? 'pointer' : 'default',
        display: 'flex',
        flex: '0 1 32%',
        gap: 3,
        height: '128px',
        p: 3,
        position: 'relative',
      }}
    >
      {action && <Box sx={{ position: 'absolute', right: 12, top: 12 }}>{action}</Box>}

      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: globalStyles.mainColors.distantWindChimeColor,
          borderRadius: 1.5,
          display: 'flex',
          flexDirection: 'column',
          height: '80px',
          justifyContent: 'center',
          p: '26px',
          width: '80px',
        }}
      >
        {icon}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
        }}
      >
        <Typography
          sx={{
            color: globalStyles.navigation.textColor,
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '19px',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: '44px',
            fontWeight: '700',
            lineHeight: '52px',
          }}
        >
          {insight}
        </Typography>
      </Box>
    </Card>
  );
};
