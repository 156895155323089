import { IDataSourceFilterComponentDto, IDictionary } from '@a-type/dtos';
import { ICountry, IState } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const dictionariesApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    appendTypesDictionary: builder.query<IDictionary[], void>({
      providesTags: [{ id: 'APPEND_TYPES', type: 'Dictionaries' as const }],
      query: () => ({ url: '/dictionaries/append-types' }),
    }),
    components: builder.query<IDataSourceFilterComponentDto[], void>({
      providesTags: [{ id: 'COMPONENTS', type: 'Dictionaries' as const }],
      query: () => ({ url: '/dictionaries/filter-components' }),
    }),
    countries: builder.query<ICountry[], void>({
      providesTags: [{ id: 'COUNTRIES', type: 'Dictionaries' as const }],
      query: () => ({ url: '/dictionaries/countries' }),
    }),
    dataSourceDictionary: builder.query<IDictionary[], void>({
      providesTags: [{ id: 'DATASOURCES', type: 'Dictionaries' as const }],
      query: () => ({ url: '/dictionaries/datasources' }),
    }),
    states: builder.query<IState[], string>({
      providesTags: (result, error, countryCode) => [
        { id: `STATES_${countryCode}`, type: 'Dictionaries' as const },
      ],
      query: (countryCode) => ({ url: `/dictionaries/${countryCode}/states` }),
    }),
  }),
});

export const {
  useAppendTypesDictionaryQuery,
  useComponentsQuery,
  useCountriesQuery,
  useDataSourceDictionaryQuery,
  useStatesQuery,
} = dictionariesApi;
