import { IStripeProduct } from '@a-type/dtos';
import { ActionButton, Dialog, DragAndDrop } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import {
  useDeleteProductMutation,
  useGetProductsQuery,
  useUpdateProductSortOrderMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Card, Chip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const PlansPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState<IStripeProduct[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<IStripeProduct | null>(null);
  const { data, isLoading } = useGetProductsQuery();
  const [updateSortOrderMutation, { isLoading: isSortOrderLoading }] =
    useUpdateProductSortOrderMutation();
  const [deletePlanMutation, { isLoading: isDeletePlanLoading }] = useDeleteProductMutation();

  useEffect(() => {
    if (data) {
      setPlans([...data]);
    }
  }, [data]);

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading && !isSortOrderLoading && !isDeletePlanLoading));
  }, [isLoading, isSortOrderLoading, isDeletePlanLoading]);

  const updateSortOrder = async (items: IStripeProduct[]) => {
    await updateSortOrderMutation(items);
  };

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            pb: 1,
          }}
        >
          <Typography sx={{ fontSize: 24, fontWeight: 800, pl: 2 }}>Subscription plans</Typography>
          <Button
            disabled={plans.length >= 20}
            onClick={() => navigate(`${AppRoutes.AdminPlansPage}/new`)}
            startIcon={<AddCircleIcon />}
          >
            New subscription plan
          </Button>
        </Box>

        {plans.length === 0 && !isLoading && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              height: '100%',
              justifyContent: 'center',
              pt: 36,
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 24,
                fontWeight: 600,
              }}
            >
              No subscription plans
            </Typography>
          </Box>
        )}

        {plans.length > 0 && (
          <DragAndDrop
            items={plans}
            renderItem={(plan, index, provided) => {
              const subscription = plan.subscriptions.find(
                (sub) => sub.id === plan.defaultSubscriptionId,
              );

              return (
                <Card
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  key={plan._id}
                  sx={{
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'space-between',
                    my: 1,
                    p: 2,
                  }}
                  variant="outlined"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 2,
                      }}
                    >
                      <Button
                        {...provided.dragHandleProps}
                        startIcon={
                          <DragIndicatorIcon
                            sx={{
                              color: globalStyles.mainColors.grayColor,
                              fontSize: 20,
                            }}
                          />
                        }
                        sx={{
                          '.MuiButton-startIcon': {
                            mx: 0,
                          },
                          borderRadius: 2,
                          cursor: 'grab',
                          height: 30,
                          minWidth: 30,
                          px: 1,
                        }}
                      />
                      <Typography sx={{ fontSize: 18, fontWeight: 800 }}>{plan.name}</Typography>

                      {subscription && (
                        <Typography
                          sx={{
                            color: globalStyles.mainColors.sootyColor,
                            fontSize: 16,
                          }}
                        >
                          {subscription.credits} credits for{' '}
                          <strong>${subscription.price.toFixed(2)}</strong> / month
                        </Typography>
                      )}
                    </Box>
                    <Typography sx={{ color: globalStyles.mainColors.sootyColor, fontSize: 14 }}>
                      {plan.description}
                    </Typography>
                    <Typography sx={{ color: globalStyles.mainColors.sootyColor, fontSize: 16 }}>
                      <strong>${(plan.package.price / plan.package.credits).toFixed(3)}</strong> per
                      credit
                    </Typography>
                    <Typography sx={{ color: globalStyles.mainColors.sootyColor, fontSize: 16 }}>
                      Extra {plan.package.credits} credits for{' '}
                      <strong>${plan.package.price.toFixed(2)}</strong>
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      alignItems: 'flex-end',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>
                      {plan.isMostPopular && (
                        <Chip
                          label="Most Popular"
                          size="small"
                          sx={{
                            backgroundColor: globalStyles.mainColors.greenColor,
                            color: 'white',
                          }}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                      }}
                    >
                      <ActionButton
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={() => navigate(`${AppRoutes.AdminPlansPage}/${plan._id}`)}
                      />
                      <ActionButton
                        icon={
                          <DeleteIcon
                            sx={{
                              color: globalStyles.mainColors.redColor,
                            }}
                          />
                        }
                        label="Delete"
                        onClick={() => {
                          setSelectedPlan(plan);
                          setShowDeleteDialog(true);
                        }}
                      />
                    </Box>
                  </Box>
                </Card>
              );
            }}
            setItems={updateSortOrder}
          />
        )}
      </Box>

      <Dialog
        cancelText="Cancel"
        okText="Delete"
        onClose={() => setShowDeleteDialog(false)}
        onOk={() => {
          if (selectedPlan) {
            deletePlanMutation(selectedPlan._id);
          }
          setSelectedPlan(null);
          setShowDeleteDialog(false);
        }}
        open={showDeleteDialog}
        size="sm"
        title="Delete Plan"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
            }}
          >
            Are you sure you want to delete the plan{' '}
            <strong>{selectedPlan?.name ?? 'this plan'}</strong>?
          </Typography>
        </Box>
      </Dialog>
    </AdminPageLayout>
  );
};
