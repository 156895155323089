import { useDispatch, useSelector } from '@a-type/ui/hooks';
import {
  snackbarErrorMessage,
  snackbarInfoMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { Alert, Box, CircularProgress, Snackbar, Typography } from '@mui/material';

import globalStyles from '../styles/global.styles';

const LoadingPageComponent = () => {
  const { pageContentLoad } = useSelector((state) => state.appMain);
  const errorMessage = useSelector((state) => state.appMain.snackbarErrorMessage);
  const successMessage = useSelector((state) => state.appMain.snackbarSuccessMessage);
  const infoMessage = useSelector((state) => state.appMain.snackbarInfoMessage);

  const dispatch = useDispatch();
  const handleSnackbarClose = () => dispatch(snackbarErrorMessage(null));
  const handleSuccessSnackbarClose = () => dispatch(snackbarSuccessMessage(null));
  const handleInfoSnackbarClose = () => dispatch(snackbarInfoMessage(null));

  return (
    <>
      {!pageContentLoad ? (
        <Box
          sx={{
            alignContent: 'center',
            background: globalStyles.mainColors.lightGreenColor,
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            justifyItems: 'center',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 3000,
          }}
        >
          <Typography
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              justifyContent: 'center',
            }}
            variant="h3"
          >
            <CircularProgress
              size={100}
              sx={{
                color: globalStyles.mainColors.blueColor,
              }}
              thickness={2}
            />
            <Box
              sx={{
                color: globalStyles.mainColors.sootyColor,
                pl: 4,
                userSelect: 'none',
              }}
            >
              Loading...
            </Box>
          </Typography>
        </Box>
      ) : null}

      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        open={!!errorMessage}
        sx={{ width: 400, zIndex: 9999999 }}
      >
        <Alert severity="error" sx={{ width: '100%' }} variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        autoHideDuration={6000}
        onClose={handleSuccessSnackbarClose}
        open={!!successMessage}
        sx={{ width: 400, zIndex: 9999999 }}
      >
        <Alert severity="success" sx={{ width: '100%' }} variant="filled">
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        autoHideDuration={6000}
        onClose={handleInfoSnackbarClose}
        open={!!infoMessage}
        sx={{ width: 400, zIndex: 9999999 }}
      >
        <Alert severity="info" sx={{ width: '100%' }} variant="filled">
          {infoMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoadingPageComponent;
