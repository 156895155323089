import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { DataSourceUpdateStrategy } from '@a-type/enums';
import { IDataSourceField } from '@a-type/interfaces';
import { Dialog } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import {
  pageContentLoad,
  setCurrentDataSource,
  setUploadedCsvFile,
  snackbarErrorMessage,
} from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import { generateObjectId } from '@a-type/ui/utils/object-id.utils';
import CircleIcon from '@mui/icons-material/Circle';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Papa, { ParseResult } from 'papaparse';
import { useState } from 'react';

import DataTypes from '../../../utils/DataTypes.utils';

export const updateStrategyList = [
  {
    options: [
      'Insert all data from the new file into the existing data source',
      "Doesn't perform checks for existing data",
    ],
    title: 'Insert',
    type: DataSourceUpdateStrategy.INSERT,
  },
  {
    options: [
      'Update any matching rows to new data.',
      'Add a new row for anything not currently in the data set.',
    ],
    title: 'Update & Add',
    type: DataSourceUpdateStrategy.UPDATE_ADD,
  },
  {
    options: ['Remove any rows in this new file that are found in the data set.'],
    title: 'Remove',
    type: DataSourceUpdateStrategy.REMOVE,
  },
  {
    options: ['Remove all the old data and replace it with this new one.'],
    title: 'Complete replace',
    type: DataSourceUpdateStrategy.REPLACE,
  },
];

export type ExampleDataType = {
  examples: string[];
  name: string;
};

export interface DataSourceDetailsFileUploadProps {
  setDataSourceValueHandler: (value: any | string, targetKeyName: string) => void;
  setUploadFileDialog: (value: boolean) => void;
  uploadFileDialog: boolean;
}

const DataSourceDetailsFileUpload = (props: DataSourceDetailsFileUploadProps) => {
  const { setDataSourceValueHandler, setUploadFileDialog, uploadFileDialog } = props;
  const { currentDataSource, filtersComponents, uploadedCsvFile } = useSelector(
    (state) => state.dataSource,
  );
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<string[]>([]);
  const [examples, setExamples] = useState<{ [key: string]: ExampleDataType }>({});

  const uploadFileHandler = async (target: any) => {
    if (!currentDataSource) return;

    const acceptiveFields = ['text/csv'];
    const file: any = target?.files[0];
    const isAcceptiveFormat = acceptiveFields.some((fileType: string) => file?.type === fileType);

    if (!isAcceptiveFormat) {
      dispatch(snackbarErrorMessage(`Wrong file format, accept only csv file.`));
      dispatch(setUploadedCsvFile(null));
      return;
    }
    const numberOfExampleUsers = 5;
    dispatch(pageContentLoad(false));
    Papa.parse(file, {
      complete: (results: ParseResult<string[]>) => {
        const isEmpty = !results?.data.length;
        if (isEmpty) {
          dispatch(snackbarErrorMessage(`Error message: CSV file is empty.`));
          dispatch(setUploadedCsvFile(null));
          dispatch(pageContentLoad(true));
          return;
        }

        const c: string[] =
          results.data[0]?.map((x: string) => x?.trim()).filter((x: string) => x) || [];

        // if there is duplicate columns, show error message
        if (c.length !== new Set(c).size) {
          dispatch(snackbarErrorMessage(`Error message: Duplicate columns in CSV file.`));
          dispatch(setUploadedCsvFile(null));
          dispatch(pageContentLoad(true));
          return;
        }

        const data: string[][] = results.data.slice(1, numberOfExampleUsers + 1);
        const e = c.reduce(
          (acc, column, index) => {
            if (!column) return acc;
            acc[column] = {
              examples: data.map((row) => row[index]),
              name: column,
            };
            return acc;
          },
          {} as { [key: string]: ExampleDataType },
        );

        setColumns(c);
        setExamples(e);

        const copy = {
          ...currentDataSource,
          csvDelimiter: results.meta.delimiter,
        };

        // if there is no index fields, set the first column as index field
        if (copy.indexFields.length === 0) {
          copy.indexFields = [c[0]];
        }
        dispatch(setCurrentDataSource(copy));
        dispatch(setUploadedCsvFile(file));
        dispatch(pageContentLoad(true));
      },
      preview: numberOfExampleUsers + 1,
      worker: true,
    });
    dispatch(setUploadedCsvFile(file));
  };

  const selectedKeyFieldsHandler = (key: string) => {
    if (!currentDataSource) return;

    setDataSourceValueHandler(
      currentDataSource.indexFields.includes(key)
        ? currentDataSource.indexFields.filter((k: string) => k !== key)
        : [...currentDataSource.indexFields, key],
      'indexFields',
    );
  };

  const prepareDataSourceHandler = () => {
    if (!currentDataSource) return;

    // prepare data source for the first time
    let sortOrder = 0;
    const fields = columns.map((column) => {
      const dataType = DataTypes.determineDataType(examples[column].examples);
      sortOrder += 1;
      return {
        _id: generateObjectId(),
        componentType:
          filtersComponents.find((item: IDataSourceFilterComponentDto) =>
            item.dataTypes?.includes(dataType),
          )?.type ?? '',
        dataType,
        description: '',
        displayName: DataTypes.toDisplayNameString(column),
        examples: examples[column].examples,
        filterGroup: '',
        ignore: false,
        isFilter: false,
        isFreeText: false,
        isGroupedBy: false,
        name: column,
        options: [],
        prefetchAllValues: false,
        price: 0,
        sortOrder,
        units: '',
      } as IDataSourceField;
    });

    dispatch(
      setCurrentDataSource({
        ...currentDataSource,
        fields,
      }),
    );
    setUploadFileDialog(false);
  };

  const updateAndUploadHandler = async () => {
    if (!currentDataSource || !uploadedCsvFile) return;

    dispatch(pageContentLoad(false));
    const response = await dataSourcesService.updateFile(
      currentDataSource,
      uploadedCsvFile,
      currentDataSource.updateStrategy,
    );

    if (response.status === 200) {
      dispatch(setCurrentDataSource({ ...response.data }));
    }
    dispatch(pageContentLoad(true));
    setUploadFileDialog(false);
  };

  return (
    <Dialog
      cancelText="Cancel"
      disableOk={!uploadedCsvFile}
      okText={currentDataSource?._id === '0' ? 'Continue' : 'Upload to Data Source'}
      onCancel={() => setUploadFileDialog(false)}
      onClose={() => setUploadFileDialog(false)}
      onOk={() =>
        currentDataSource?._id === '0' ? prepareDataSourceHandler() : updateAndUploadHandler()
      }
      open={uploadFileDialog}
      title="Upload a new file"
    >
      <Typography
        component="span"
        sx={{
          display: 'block',
          fontSize: 12,
        }}
      >
        Select the csv file you want to upload. The file should be in the format of csv. We try to
        parse the file to show you the data structure.
      </Typography>
      <Box>
        <Button
          component="label"
          sx={{
            mb: 1,
            mt: 1,
            width: 225,
          }}
          variant="outlined"
        >
          Upload a new file
          <input
            accept=".csv"
            hidden
            onChange={(e: any) => {
              uploadFileHandler(e.target);
            }}
            type="file"
          />
        </Button>
        <Typography sx={{ fontSize: 12 }}>
          {!uploadedCsvFile ? '' : uploadedCsvFile?.name}
        </Typography>
      </Box>

      {!uploadedCsvFile ? null : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            pt: 3,
          }}
        >
          {currentDataSource?._id !== '0' && (
            <Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                How should this new file will affect the existing data source?
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  defaultValue={updateStrategyList[0].type}
                  name="radio-buttons-group"
                  onChange={(e) => setDataSourceValueHandler(e.target.value, 'updateStrategy')}
                  value={
                    currentDataSource?.updateStrategy
                      ? currentDataSource.updateStrategy
                      : updateStrategyList[0].type
                  }
                >
                  {updateStrategyList.map((item: any) => {
                    return (
                      <Box key={item.type}>
                        <FormControlLabel
                          control={<Radio />}
                          label={item.title}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: 15,
                              fontWeight: 'bold',
                            },
                          }}
                          value={item.type}
                        />
                        <Box>
                          {item.options.map((option: any) => {
                            return (
                              <Box
                                key={option}
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  fontSize: 15,
                                  pl: 5,
                                }}
                              >
                                <CircleIcon sx={{ pr: 2 }} />
                                {option}
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Box>
          )}

          <Typography sx={{ fontWeight: 'bold' }}>
            What combo of columns would be considered as an identifier for a row?
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {columns.map((column: string) => {
              const selected = currentDataSource?.indexFields.some((key: string) => key === column);
              return (
                <Chip
                  clickable
                  key={column}
                  label={column}
                  onClick={() => selectedKeyFieldsHandler(column)}
                  sx={{
                    background: selected ? globalStyles.mainColors.rareBlueColor : 'none',
                    color: selected ? globalStyles.mainColors.whiteColor : 'none',
                  }}
                  variant={selected ? 'filled' : 'outlined'}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </Dialog>
  );
};

export default DataSourceDetailsFileUpload;
