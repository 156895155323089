import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Link, Typography } from '@mui/material';

export const FooterComponent = () => {
  const fontSize = 12;
  return (
    <Box
      sx={{
        background: globalStyles.mainColors.whiteColor,
        borderTop: `1px solid rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        justifyContent: 'space-between',
        padding: 2,
        position: 'sticky',
        width: '100%',
        zIndex: 20,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', minWidth: 410, pl: 1 }}>
        <Typography
          sx={{
            color: globalStyles.mainColors.headTableGreyColor,
            fontSize,
            textDecoration: 'none',
          }}
        >
          Copyright © {new Date().getFullYear()} A Type Data
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', minWidth: 180 }}>
          <Link
            href="https://atypedata.com/privacy-policy/"
            sx={{
              borderRight: '1px solid #CDD3DE',
              color: globalStyles.mainColors.headTableGreyColor,
              fontSize,
              pr: 2,
              textDecoration: 'none',
            }}
            target="_blank"
          >
            Privacy
          </Link>
          <Typography
            sx={{
              color: globalStyles.mainColors.headTableGreyColor,
              fontSize,
              textDecoration: 'none',
            }}
          >
            Terms & Conditions
          </Typography>
        </Box>
      </Box>
      <Box sx={{ justifySelf: 'end', pr: 1 }}>
        <Typography
          sx={{
            color: globalStyles.mainColors.headTableGreyColor,
            fontSize,
            textDecoration: 'none',
          }}
        >
          Version {process.env.REACT_APP_SENTRY_VERSION ?? '1.0.0'}
        </Typography>
      </Box>
    </Box>
  );
};
