import { IChangeRole, IUserInfo } from '@a-type/dtos';
import { UserRoles } from '@a-type/enums';
import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useChangeRoleMutation } from '@a-type/ui/stores/apis';
import { getError } from '@a-type/ui/utils';
import { Box, FormGroup, InputLabel, MenuItem, Select } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface ChangeUserRoleProps {
  setShow: (show: boolean) => void;
  show: boolean;
  user: IUserInfo;
}

export const ChangeUserRole: FC<ChangeUserRoleProps> = ({
  setShow,
  show,
  user,
}: ChangeUserRoleProps) => {
  const dispatch = useDispatch();
  const [changeRole, { isLoading }] = useChangeRoleMutation();
  const [data, setData] = useState<IChangeRole>({
    role: UserRoles.USER,
  });

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  const handleChangeRole = async () => {
    const result = await changeRole({
      data,
      id: user._id,
    });

    if (result.data) {
      dispatch(snackbarSuccessMessage('User role changed successfully'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while changing role'));
    }

    setShow(false);
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Confirm"
      onCancel={() => setShow(false)}
      onClose={() => setShow(false)}
      onOk={handleChangeRole}
      open={show}
      title={`Change Role for ${user.fullName}`}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <FormGroup>
          <InputLabel htmlFor="role">User Role</InputLabel>
          <Select
            id="role"
            onChange={(e) => setData({ ...data, role: e.target.value as UserRoles })}
            value={data.role}
          >
            <MenuItem value={UserRoles.USER}>User</MenuItem>
            <MenuItem value={UserRoles.ADMIN}>Admin</MenuItem>
          </Select>
        </FormGroup>
      </Box>
    </Dialog>
  );
};
