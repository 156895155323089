import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DataSourceHeaderComponent = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: -2, pb: 2, top: 2 }}>
      <Typography sx={{ fontSize: 24, fontWeight: 800, pl: 2 }}>Data Sources</Typography>
      <Button
        onClick={() => {
          navigate(`${AppRoutes.AdminDataSourcesPage}/new`);
        }}
        startIcon={<AddCircleIcon />}
      >
        New Data Source
      </Button>
    </Box>
  );
};

export default DataSourceHeaderComponent;
