import { Theme } from '@emotion/react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SxProps } from '@mui/material';
import { Control, Controller, Validate } from 'react-hook-form';

interface IFormSelectProps<TOption> {
  control: Control<any>;
  disabled?: boolean;
  getOptionKey: (option: TOption) => string;
  getOptionLabel: (option: TOption) => string;
  label: string;
  name: string;
  options: ReadonlyArray<TOption>;
  required?: boolean;
  rules?: Record<string, Validate<string, any>>;
  sx?: SxProps<Theme>;
}

export const FormSelect = <TOption extends { [key: string]: any }>({
  control,
  disabled = false,
  getOptionKey,
  getOptionLabel,
  label,
  name,
  options,
  required = false,
  rules = {},
  sx,
}: IFormSelectProps<TOption>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl>
          <InputLabel className={error ? 'Mui-error' : ''} id={`${name}-label`} required={required}>
            {label}
          </InputLabel>
          <Select
            disabled={disabled}
            error={!!error}
            fullWidth
            label={label}
            labelId={`${name}-label`}
            onChange={(e) => onChange(e.target.value)}
            placeholder={label}
            required={required}
            sx={sx}
            value={value}
          >
            {options.map((option) => (
              <MenuItem key={getOptionKey(option)} value={getOptionKey(option)}>
                {getOptionLabel(option)}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
      rules={{
        validate: {
          required: required ? (value) => !!value || 'Field is required' : () => true,
          ...rules,
        },
      }}
    />
  );
};
