import { IUserInfo } from '@a-type/dtos';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { FC } from 'react';

interface IUserInformationProps {
  user: IUserInfo;
}

export const UserInformation: FC<IUserInformationProps> = ({ user }: IUserInformationProps) => {
  const renderItem = (label: string, value?: string) => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 17,
            fontWeight: 400,
            width: 150,
          }}
        >
          {label}
        </Typography>
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            flexGrow: 1,
            fontSize: 17,
            fontWeight: 600,
          }}
        >
          {value}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        width: '100%',
      }}
    >
      <Card variant="outlined">
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: 1,
            }}
          >
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 14,
                fontWeight: 700,
                textTransform: 'uppercase',
              }}
            >
              Contact Information
            </Typography>

            <Divider sx={{ mb: 1 }} />

            {renderItem('First Name', user.firstName)}
            {renderItem('Last Name', user.lastName)}
            {renderItem('Phone Number', user.mobile)}
            {renderItem('Company Name', user.companyName)}
            {renderItem('Email', user.email)}
            {renderItem(
              'Birth Date',
              user.birthday ? new Date(user.birthday).toLocaleDateString() : '',
            )}
            {renderItem('Gender', user.gender)}
          </Box>
        </CardContent>
      </Card>

      <Card variant="outlined">
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: 1,
            }}
          >
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 14,
                fontWeight: 700,
                textTransform: 'uppercase',
              }}
            >
              Address Information
            </Typography>

            <Divider sx={{ mb: 1 }} />

            {renderItem('Country', user.country)}
            {renderItem('Address', user.address)}
            {renderItem('Address 2', user.address2)}
            {renderItem('City', user.city)}
            {renderItem('State', user.state)}
            {renderItem('Zip Code', user.zip)}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
