import DataSourceDetailsApiBasicStep from './components/apiComponents/DataSourceDetailsApiBasicStep.components';
import DataSourceDetailsApiMapping from './components/apiComponents/DataSourceDetailsApiMappingStep.components';
import DataSourceDetailsPricingStep from './components/DataSourceDetailsPricingStep.components';
import { DATA_SOURCE_TABS_ENUM, DataSourceDetailsProps } from './utils/DataSourceDetailsProps';

const ApiDataSourceDetailsComponent = (props: DataSourceDetailsProps) => {
  const { currentStep, setBasicInfoValid, setMappingValid, setPricingValid } = props;

  return (
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.BASIC_DETAILS && (
      <DataSourceDetailsApiBasicStep setBasicInfoValid={setBasicInfoValid} />
    )) ||
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.MAPPING && (
      <DataSourceDetailsApiMapping setMappingValid={setMappingValid} />
    )) ||
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.PRICING && (
      <DataSourceDetailsPricingStep setIsPricingValid={setPricingValid} />
    )) ||
    null
  );
};

export default ApiDataSourceDetailsComponent;
