import { Box, Typography } from '@mui/material';

const DataSourceEmptyListComponent = () => {
  return (
    <Box sx={{ display: 'flex', marginLeft: 3, py: 24, top: 2 }}>
      <Typography sx={{ fontSize: 20, textAlign: 'center', width: '100%' }}>
        No DataSources Found
      </Typography>
    </Box>
  );
};

export default DataSourceEmptyListComponent;
