import {
  IBuyCredits,
  ICreateStripeProduct,
  ICreateStripeSubscription,
  IStripeActiveSubscription,
  IStripePagination,
  IStripePaymentHistory,
  IStripePaymentMethod,
  IStripeProduct,
  IStripeSubscription,
} from '@a-type/dtos';

import apiMiddleware from './api-middleware';

export const stripeApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    activateSubscription: builder.mutation<
      IStripeProduct,
      {
        id: string;
        subscriptionId: string;
      }
    >({
      invalidatesTags: (result) =>
        result
          ? [
              { id: 'PRODUCTS', type: 'Stripe/Product' as const },
              { id: result._id, type: 'Stripe/Product' as const },
            ]
          : [{ id: 'PRODUCTS', type: 'Stripe/Product' as const }],
      query: ({ id, subscriptionId }) => ({
        method: 'POST',
        url: `/stripe/products/${id}/subscription/${subscriptionId}/activate`,
      }),
    }),
    buyCredits: builder.mutation<boolean, IBuyCredits>({
      invalidatesTags: [
        { id: 'CREDIT_BALANCE', type: 'Account' as const },
        { id: 'TRANSACTIONS', type: 'Transactions/User' as const },
        { id: 'TRANSACTIONS', type: 'Transactions/All' as const },
        { id: 'HISTORY', type: 'Stripe/PaymentHistory' as const },
      ],
      query: (data) => ({ data, method: 'POST', url: '/stripe/subscription/credits' }),
    }),
    cancelSubscription: builder.mutation<boolean, void>({
      invalidatesTags: [{ id: 'ACTIVE', type: 'Stripe/Subscription' as const }],
      query: () => ({ method: 'POST', url: '/stripe/subscription/cancel' }),
    }),
    createCard: builder.mutation<void, string>({
      invalidatesTags: [{ id: 'CARDS', type: 'Stripe/PaymentMethod' as const }],
      query: (id) => ({ method: 'POST', url: `/stripe/payments/cards/${id}` }),
    }),
    createProduct: builder.mutation<IStripeProduct, ICreateStripeProduct>({
      invalidatesTags: (result) =>
        result
          ? [
              { id: 'PRODUCTS', type: 'Stripe/Product' as const },
              { id: result._id, type: 'Stripe/Product' as const },
            ]
          : [{ id: 'PRODUCTS', type: 'Stripe/Product' as const }],
      query: (data) => ({ data, method: 'POST', url: '/stripe/products' }),
    }),
    createSubscription: builder.mutation<
      IStripeProduct,
      { data: ICreateStripeSubscription; id: string }
    >({
      invalidatesTags: (result) =>
        result
          ? [
              { id: 'PRODUCTS', type: 'Stripe/Product' as const },
              { id: result._id, type: 'Stripe/Product' as const },
            ]
          : [{ id: 'PRODUCTS', type: 'Stripe/Product' as const }],
      query: ({ data, id }) => ({
        data,
        method: 'POST',
        url: `/stripe/products/${id}/subscription`,
      }),
    }),
    deactivateSubscription: builder.mutation<
      IStripeProduct,
      {
        id: string;
        subscriptionId: string;
      }
    >({
      invalidatesTags: (result) =>
        result
          ? [
              { id: 'PRODUCTS', type: 'Stripe/Product' as const },
              { id: result._id, type: 'Stripe/Product' as const },
            ]
          : [{ id: 'PRODUCTS', type: 'Stripe/Product' as const }],
      query: ({ id, subscriptionId }) => ({
        method: 'POST',
        url: `/stripe/products/${id}/subscription/${subscriptionId}/deactivate`,
      }),
    }),
    deleteCard: builder.mutation<void, string>({
      invalidatesTags: [{ id: 'CARDS', type: 'Stripe/PaymentMethod' as const }],
      query: (id) => ({ method: 'DELETE', url: `/stripe/payments/cards/${id}` }),
    }),
    deleteProduct: builder.mutation<IStripeProduct, string>({
      invalidatesTags: [{ id: 'PRODUCTS', type: 'Stripe/Product' as const }],
      query: (id) => ({ method: 'DELETE', url: `/stripe/products/${id}` }),
    }),
    getActiveSubscription: builder.query<IStripeActiveSubscription, void>({
      providesTags: [{ id: 'ACTIVE', type: 'Stripe/Subscription' as const }],
      query: () => ({ url: '/stripe/subscription/active' }),
    }),
    // Payments
    getCards: builder.query<IStripePaymentMethod[], void>({
      providesTags: [{ id: 'CARDS', type: 'Stripe/PaymentMethod' as const }],
      query: () => ({ url: '/stripe/payments/cards' }),
    }),
    // Payment history
    getPaymentHistory: builder.query<
      IStripePagination<IStripePaymentHistory>,
      {
        cursor?: string;
      } | void
    >({
      providesTags: [{ id: 'HISTORY', type: 'Stripe/PaymentHistory' as const }],
      query: (data) => ({ params: data, url: '/stripe/payments/history' }),
    }),
    getProduct: builder.query<IStripeProduct, string>({
      providesTags: (result) =>
        result ? [{ id: result._id, type: 'Stripe/Product' as const }] : [],
      query: (id) => ({ url: `/stripe/products/${id}` }),
    }),
    getProducts: builder.query<IStripeProduct[], void>({
      providesTags: [{ id: 'PRODUCTS', type: 'Stripe/Product' as const }],
      query: () => ({ url: '/stripe/products' }),
    }),
    // User Subscription
    getSuscriptions: builder.query<IStripeSubscription[], void>({
      providesTags: [{ id: 'PRODUCTS', type: 'Stripe/Product' as const }],
      query: () => ({ url: '/stripe/subscription' }),
    }),
    setDefaultCard: builder.mutation<void, string>({
      invalidatesTags: [{ id: 'CARDS', type: 'Stripe/PaymentMethod' as const }],
      query: (id) => ({ method: 'POST', url: `/stripe/payments/cards/${id}/set-default` }),
    }),
    setDefaultSubscription: builder.mutation<
      IStripeProduct,
      {
        id: string;
        subscriptionId: string;
      }
    >({
      invalidatesTags: (result) =>
        result
          ? [
              { id: 'PRODUCTS', type: 'Stripe/Product' as const },
              { id: result._id, type: 'Stripe/Product' as const },
            ]
          : [{ id: 'PRODUCTS', type: 'Stripe/Product' as const }],
      query: ({ id, subscriptionId }) => ({
        method: 'POST',
        url: `/stripe/products/${id}/subscription/${subscriptionId}/default`,
      }),
    }),
    subscribe: builder.mutation<
      IStripeActiveSubscription,
      {
        id: string;
        paymentMethodId: string;
      }
    >({
      invalidatesTags: [
        { id: 'CREDIT_BALANCE', type: 'Account' as const },
        { id: 'ACTIVE', type: 'Stripe/Subscription' as const },
        { id: 'TRANSACTIONS', type: 'Transactions/User' as const },
        { id: 'TRANSACTIONS', type: 'Transactions/All' as const },
        { id: 'HISTORY', type: 'Stripe/PaymentHistory' as const },
      ],
      query: (data) => ({
        method: 'POST',
        url: `/stripe/subscription/subscribe/${data.id}?paymentMethodId=${data.paymentMethodId}`,
      }),
    }),
    updateProduct: builder.mutation<IStripeProduct, { data: ICreateStripeProduct; id: string }>({
      invalidatesTags: (result) =>
        result
          ? [
              { id: 'PRODUCTS', type: 'Stripe/Product' as const },
              { id: result._id, type: 'Stripe/Product' as const },
            ]
          : [{ id: 'PRODUCTS', type: 'Stripe/Product' as const }],
      query: ({ data, id }) => ({ data, method: 'PUT', url: `/stripe/products/${id}` }),
    }),
    updateProductSortOrder: builder.mutation<IStripeProduct[], IStripeProduct[]>({
      invalidatesTags: [{ id: 'PRODUCTS', type: 'Stripe/Product' as const }],
      query: (data) => ({ data, method: 'POST', url: '/stripe/products/sort-order' }),
    }),
    updateSubscription: builder.mutation<
      IStripeActiveSubscription,
      {
        id: string;
        paymentMethodId: string;
      }
    >({
      invalidatesTags: [
        { id: 'CREDIT_BALANCE', type: 'Account' as const },
        { id: 'ACTIVE', type: 'Stripe/Subscription' as const },
        { id: 'TRANSACTIONS', type: 'Transactions/User' as const },
        { id: 'TRANSACTIONS', type: 'Transactions/All' as const },
        { id: 'HISTORY', type: 'Stripe/PaymentHistory' as const },
      ],
      query: ({ id, paymentMethodId }) => ({
        method: 'POST',
        url: `/stripe/subscription/update/${id}?paymentMethodId=${paymentMethodId}`,
      }),
    }),
    updateSubscriptionPaymentMethod: builder.mutation<
      boolean,
      { id: string; paymentMethodId: string }
    >({
      invalidatesTags: [
        { id: 'CREDIT_BALANCE', type: 'Account' as const },
        { id: 'ACTIVE', type: 'Stripe/Subscription' as const },
        { id: 'TRANSACTIONS', type: 'Transactions/User' as const },
        { id: 'TRANSACTIONS', type: 'Transactions/All' as const },
        { id: 'HISTORY', type: 'Stripe/PaymentHistory' as const },
      ],
      query: ({ id, paymentMethodId }) => ({
        method: 'POST',
        url: `/stripe/subscription/${id}/payment-method?paymentMethodId=${paymentMethodId}`,
      }),
    }),
  }),
});

export const {
  useActivateSubscriptionMutation,
  useBuyCreditsMutation,
  useCancelSubscriptionMutation,
  useCreateCardMutation,
  useCreateProductMutation,
  useCreateSubscriptionMutation,
  useDeactivateSubscriptionMutation,
  useDeleteCardMutation,
  useDeleteProductMutation,
  useGetActiveSubscriptionQuery,
  useGetCardsQuery,
  useGetPaymentHistoryQuery,
  useGetProductQuery,
  useGetProductsQuery,
  useGetSuscriptionsQuery,
  useSetDefaultCardMutation,
  useSetDefaultSubscriptionMutation,
  useSubscribeMutation,
  useUpdateProductMutation,
  useUpdateProductSortOrderMutation,
  useUpdateSubscriptionMutation,
  useUpdateSubscriptionPaymentMethodMutation,
} = stripeApi;
