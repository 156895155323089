import { IDataSourceField } from '@a-type/interfaces';
import { Dialog, Switch } from '@a-type/ui/components';
import globalStyles from '@a-type/ui/styles/global.styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

export interface DataSourceDetailsApiFieldConfiguratorControlsProps {
  deleteFieldHandler: (field: string) => void;
  field: IDataSourceField;
  updateFieldValueHandler: (key: string, value: any) => void;
}

const DataSourceDetailsApiFieldConfiguratorControls = (
  props: DataSourceDetailsApiFieldConfiguratorControlsProps,
) => {
  const { deleteFieldHandler, field, updateFieldValueHandler } = props;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const deleteField = () => {
    deleteFieldHandler(field.name!);
    setShowDeleteDialog(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <Switch
          checked={field.isFilter}
          label="Use as Filter"
          onChange={() => {
            updateFieldValueHandler('isFilter', !field.isFilter);
          }}
        />

        <Tooltip title="Delete this field">
          <IconButton
            onClick={() => {
              setShowDeleteDialog(true);
            }}
            sx={{ color: globalStyles.mainColors.blackColor, height: 40, width: 40 }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Dialog
        cancelText="Cancel"
        okText="Yes, Delete"
        onCancel={() => setShowDeleteDialog(false)}
        onClose={() => setShowDeleteDialog(false)}
        onOk={deleteField}
        open={showDeleteDialog}
        title="Delete Data Field"
      >
        <Typography sx={{ fontSize: 20 }}>
          Are you sure you want to delete &quot;{field.displayName}&quot; field?
        </Typography>
      </Dialog>
    </>
  );
};

export default DataSourceDetailsApiFieldConfiguratorControls;
