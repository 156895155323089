import { CombinedSearchType } from '@a-type/enums';
import { IDataSource, IView } from '@a-type/interfaces';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useGetCombinedSearchQuery,
  useGetDataSourcesQuery,
  useUpdateViewPricingMutation,
} from '@a-type/ui/stores/apis';
import { generateObjectId, getError } from '@a-type/ui/utils';
import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ViewPrice } from './components/view-price.component';
import { IViewPricingDetailed } from './types/view-pricing-detailed.interface';
import { ViewSteps } from './view-steps.enum';

interface IViewPricingProps {
  setActiveStep: (step: ViewSteps) => void;
  view: IView | null;
}

export const ViewPricing: React.FC<IViewPricingProps> = ({
  setActiveStep,
  view,
}: IViewPricingProps) => {
  const dispatch = useDispatch();
  const { data: combinedSearch } = useGetCombinedSearchQuery(
    view?.type ?? CombinedSearchType.ATTOM,
    {
      skip: !view?.type,
    },
  );
  const { data: dataSourcesData, isFetching: isFetchingDataSources } = useGetDataSourcesQuery();
  const [updateViewPricing, { isLoading: isUpdatingViewPricing }] = useUpdateViewPricingMutation();
  const [dataSources, setDataSources] = useState<IDataSource[]>([]);
  const [activePriceIndex] = useState<number>(0);
  const { control, getValues, handleSubmit, setValue, watch } = useForm<{
    pricing: IViewPricingDetailed[];
  }>({
    defaultValues: {
      pricing: [],
    },
  });
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    dispatch(pageContentLoad(!isUpdatingViewPricing && !isFetchingDataSources));
  }, [isUpdatingViewPricing, isFetchingDataSources]);

  useEffect(() => {
    if (view && combinedSearch) {
      if (view.pricing.length === 0) {
        setValue('pricing.0', {
          _id: generateObjectId(),
          fields: [
            {
              dataSourceId: combinedSearch.primaryDataSourceId,
              excludedFields: [],
              includedFields: [],
            },
            ...combinedSearch.secondaryDataSources.map((sds) => ({
              dataSourceId: sds.dataSourceId,
              excludedFields: [],
              includedFields: [],
            })),
          ],
          imageUrl: '',
          isNew: true,
          name: '',
          price: 1,
          sortOrder: 0,
        });
      } else {
        for (let i = 0; i < view.pricing.length; i += 1) {
          setValue(`pricing.${i}`, {
            ...view.pricing[i],
            isNew: false,
          });
        }
      }
    }
  }, [view, combinedSearch]);

  useEffect(() => {
    const { pricing } = getValues();
    if (!pricing || pricing.length === 0) {
      setIsValid(false);
      return;
    }

    const price = pricing[0];

    if (
      !price.name ||
      Number.isNaN(price.price) ||
      price.price < 1 ||
      Number.isNaN(price.sortOrder)
    ) {
      setIsValid(false);
      return;
    }

    const fields = price.fields.find((f) => f.dataSourceId === combinedSearch?.primaryDataSourceId);
    if (!fields || fields.includedFields.length === 0) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
  }, [watch()]);

  useEffect(() => {
    if (dataSourcesData && combinedSearch) {
      setDataSources([
        // primary data source
        ...dataSourcesData
          .filter((ds) => ds._id === combinedSearch.primaryDataSourceId)
          .filter(Boolean),
        // secondary data sources
        ...combinedSearch.secondaryDataSources
          .map((sds) => dataSourcesData.find((ds) => ds._id === sds.dataSourceId))
          .filter((ds) => ds)
          .map((ds) => ds as IDataSource),
      ]);
    }
  }, [dataSourcesData, combinedSearch]);

  const handleUpdatePricing = async () => {
    if (!view) return;

    const result = await updateViewPricing({ id: view._id, pricing: getValues().pricing });

    if (result.data) {
      dispatch(snackbarSuccessMessage('Pricing updated successfully'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while updating pricing'));
    }
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      {view && combinedSearch && (
        <ViewPrice
          combinedSearch={combinedSearch}
          control={control}
          dataSources={dataSources}
          getValue={getValues}
          index={activePriceIndex}
          setValue={setValue}
          view={view}
          watch={watch}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color="primary"
          disabled={isUpdatingViewPricing || !isValid}
          onClick={handleSubmit(handleUpdatePricing)}
          sx={{ minWidth: 120 }}
          variant="contained"
        >
          Update
        </Button>
        <Button color="primary" onClick={() => setActiveStep(ViewSteps.Filters)} variant="outlined">
          Back to Filters
        </Button>
      </Box>
    </Box>
  );
};
