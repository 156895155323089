import { DataSourceFieldComponentType } from '@a-type/enums';
import { ICombinedSearchFilter, IDataSource, IDataSourceField } from '@a-type/interfaces';
import { Dialog } from '@a-type/ui/components';
import globalStyles from '@a-type/ui/styles/global.styles';
import { generateObjectId } from '@a-type/ui/utils';
import { AddCircleOutline, FilterAlt } from '@mui/icons-material';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface CombinedSearchAddFilterProps {
  dataSources: IDataSource[];
  filters: ICombinedSearchFilter[];
  onAddFilter: (field: ICombinedSearchFilter) => void;
}

export const CombinedSearchAddFilter: React.FC<CombinedSearchAddFilterProps> = ({
  dataSources,
  filters,
  onAddFilter,
}: CombinedSearchAddFilterProps) => {
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState<IDataSource | null>(null);
  const [selectedField, setSelectedField] = useState<IDataSourceField | null>(null);
  const [fields, setFields] = useState<IDataSourceField[]>([]);

  useEffect(() => {
    if (selectedDataSource) {
      setFields(
        selectedDataSource.fields.filter(
          (field) =>
            !filters.some(
              (filter) =>
                filter.dataSourceId === selectedDataSource._id && filter.name === field.name,
            ),
        ),
      );
    } else {
      setFields([]);
    }
  }, [selectedDataSource, filters]);

  const handleAddFilter = (field: IDataSourceField) => {
    if (!selectedDataSource) return;
    if (!field) return;

    onAddFilter({
      _id: generateObjectId(),
      componentType: field.componentType || DataSourceFieldComponentType.TEXT,
      dataSourceId: selectedDataSource._id,
      dataType: field.dataType,
      description: field.description,
      displayName: field.displayName,
      filterGroup: field.filterGroup,
      isFreeText: field.isFreeText,
      isGroupedBy: field.isGroupedBy,
      name: field.name,
      options: field.options,
      prefetchAllValues: field.prefetchAllValues,
      price: field.price,
      sortOrder: filters.length + 1,
      units: field.units,
    } as ICombinedSearchFilter);
    setShowAddFilter(false);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setShowAddFilter(true);
          setSelectedDataSource(null);
          setSelectedField(null);
        }}
        startIcon={<AddCircleOutline />}
        sx={{ minWidth: 120 }}
        variant="text"
      >
        Add Filter
      </Button>

      {showAddFilter && (
        <Dialog
          cancelText="Cancel"
          disableOk={!selectedField}
          okText="Add Filter"
          onCancel={() => setShowAddFilter(false)}
          onClose={() => setShowAddFilter(false)}
          onOk={() => handleAddFilter(selectedField as IDataSourceField)}
          open={showAddFilter}
          title="Add Filter"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              Select data source and field to add as a filter
            </Typography>

            <Autocomplete
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                setSelectedField(null);
                setSelectedDataSource(value);
              }}
              options={dataSources}
              renderInput={(params) => <TextField {...params} label="Data Source" />}
              sx={{ width: '100%' }}
              value={selectedDataSource}
            />

            <Autocomplete
              getOptionLabel={(option) => option.displayName}
              onChange={(_, value) => setSelectedField(value)}
              options={fields}
              renderInput={(params) => <TextField {...params} label="Field" />}
              renderOption={(params, option) => (
                <Box
                  component="li"
                  {...params}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 2,
                    py: 1,
                    width: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      fontSize: 16,
                    }}
                  >
                    {option.displayName}
                  </Typography>

                  {option.isFilter && (
                    <FilterAlt
                      fontSize="small"
                      sx={{
                        color: globalStyles.mainColors.blueColor,
                      }}
                    />
                  )}
                </Box>
              )}
              sx={{ width: '100%' }}
              value={selectedField}
            />

            {selectedField && (
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'row wrap',
                  gap: 5,
                  justifyContent: 'flex-start',
                }}
              >
                <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                  Selected field
                  <Typography
                    component="span"
                    sx={{
                      display: 'block',
                      fontSize: 16,
                    }}
                  >
                    {selectedField?.displayName}
                  </Typography>
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                  Field name
                  <Typography
                    component="span"
                    sx={{
                      display: 'block',
                      fontSize: 16,
                    }}
                  >
                    {selectedField?.name}
                  </Typography>
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                  Type
                  <Typography
                    component="span"
                    sx={{
                      display: 'block',
                      fontSize: 16,
                    }}
                  >
                    {selectedField?.dataType}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
        </Dialog>
      )}
    </>
  );
};
