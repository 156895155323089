import { ICreatePassword } from '@a-type/dtos';
import { FormPasswordInput } from '@a-type/ui/components';
import globalStyles from '@a-type/ui/styles/global.styles';
import { East } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface CreatePasswordFormProps {
  loading?: boolean;
  onSubmit: (values: ICreatePassword) => void;
}

export const CreatePasswordForm: React.FC<CreatePasswordFormProps> = ({
  loading = false,
  onSubmit,
}: CreatePasswordFormProps) => {
  const { control, getValues, handleSubmit, watch } = useForm<ICreatePassword>();
  const [isValid, setIsValid] = useState(false);
  const [validation, setValidation] = useState<{
    hasLetter: boolean;
    hasLowercase: boolean;
    hasNumber: boolean;
    hasSpecial: boolean;
    hasUppercase: boolean;
    isLongEnough: boolean;
  }>({
    hasLetter: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecial: false,
    hasUppercase: false,
    isLongEnough: false,
  });

  useEffect(() => {
    const password = getValues('password');
    if (!password) {
      setIsValid(false);
      return;
    }

    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLetter = hasLowercase || hasUppercase;
    const hasNumber = /\d/.test(password);
    const hasSpecial = /[^a-zA-Z0-9]+/.test(password);
    const isLongEnough = password.length >= 8;

    setValidation({
      hasLetter,
      hasLowercase,
      hasNumber,
      hasSpecial,
      hasUppercase,
      isLongEnough,
    });

    setIsValid(hasLowercase && hasUppercase && hasNumber && hasSpecial && isLongEnough);
  }, [watch('password')]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3.5,
          width: '440px',
        }}
      >
        <FormPasswordInput control={control} label="Password" name="password" />
        <FormPasswordInput
          control={control}
          label="Confirm Password"
          name="confirmPassword"
          rules={{
            matchesPassword: (value) => value === getValues('password') || 'Passwords do not match',
          }}
        />

        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
              fontWeight: 500,
              mb: 1,
            }}
          >
            Your password must contains:
          </Typography>

          <Typography
            sx={{
              color: validation.isLongEnough
                ? globalStyles.mainColors.greenColor
                : globalStyles.mainColors.redColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {validation.isLongEnough ? '✓' : '✗'}&ensp;At least 8 characters
          </Typography>
          <Typography
            sx={{
              color:
                validation.hasLetter && validation.hasNumber
                  ? globalStyles.mainColors.greenColor
                  : globalStyles.mainColors.redColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {validation.hasLetter && validation.hasNumber ? '✓' : '✗'}&ensp;Mix of letters and
            numbers
          </Typography>
          <Typography
            sx={{
              color: validation.hasSpecial
                ? globalStyles.mainColors.greenColor
                : globalStyles.mainColors.redColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {validation.hasSpecial ? '✓' : '✗'}&ensp;At least 1 special character
          </Typography>

          <Typography
            sx={{
              color:
                validation.hasLowercase && validation.hasUppercase
                  ? globalStyles.mainColors.greenColor
                  : globalStyles.mainColors.redColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {validation.hasLowercase && validation.hasUppercase ? '✓' : '✗'}&ensp;At least 1
            lowercase letter and 1 uppercase letter
          </Typography>
        </Box>

        <LoadingButton
          disabled={!isValid}
          loading={loading}
          sx={{ marginTop: 1 }}
          type="submit"
          variant="contained"
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.whiteColor,
                fontSize: 16,
                fontWeight: 700,
                wordBreak: 'break-word',
              }}
            >
              Confirm
            </Typography>
            <East sx={{ fontSize: '20px' }} />
          </Box>
        </LoadingButton>
      </Box>
    </form>
  );
};
