import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Pagination, TablePagination, Typography } from '@mui/material';
import { FC } from 'react';

/**
 * Props for the GridPagination component.
 */
interface IGridPaginationProps {
  /**
   * Total count of items.
   */
  count: number;
  /**
   * Number of items per page.
   */
  limit: number;
  onChangePagination: (page: number, limit: number) => void;
  /**
   * Current page number.
   * Zero-based index.
   */
  page: number;
  /**
   * Total number of pages.
   */
  pages: number;
}

/**
 * GridPagination component provides pagination controls for a data grid.
 *
 * @component
 * @param {IGridPaginationProps} props - The properties for the GridPagination component.
 * @param {number} props.count - The total number of items.
 * @param {number} props.limit - The number of items per page.
 * @param {function} props.onChangePagination - Callback function to handle pagination changes.
 * @param {number} props.page - The current page number.
 * @param {number} props.pages - The total number of pages.
 *
 * @returns {JSX.Element} The rendered GridPagination component.
 */
export const GridPagination: FC<IGridPaginationProps> = ({
  count,
  limit,
  onChangePagination,
  page,
  pages,
}: IGridPaginationProps): JSX.Element => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: 4,
        justifyContent: 'space-between',
        pt: 1,
        px: 2,
        width: '100%',
      }}
    >
      <Pagination
        color="primary"
        count={pages}
        onChange={(event, value) => onChangePagination(value - 1, limit)}
        page={page + 1}
        sx={{
          '& .MuiPaginationItem-page': {
            '&.Mui-selected': {
              '&:hover': {
                opacity: 1,
              },
              background: globalStyles.mainColors.blueColor,
              color: globalStyles.mainColors.whiteColor,
              opacity: 0.8,
            },
            '&:hover': {
              background: globalStyles.mainColors.blueColor,
              color: globalStyles.mainColors.whiteColor,
            },
            background: globalStyles.mainColors.whiteColor,
            color: globalStyles.mainColors.blackColor,
            paddingTop: '2px',
          },
        }}
      />

      <Box sx={{ alignItems: 'center', display: 'flex', gap: 3 }}>
        <Typography
          sx={{
            color: globalStyles.mainColors.blackColor,
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          Total {count} items
        </Typography>
        <TablePagination
          component="div"
          count={count}
          labelRowsPerPage=""
          onPageChange={() => {}}
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            onChangePagination(0, Number(event.target.value))
          }
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[
            { label: '15 / page', value: 15 },
            { label: '25 / page', value: 25 },
            { label: '50 / page', value: 50 },
            { label: '100 / page', value: 100 },
          ]}
          slotProps={{ select: { sx: { padding: 0 } } }}
          sx={{
            '& .MuiTablePagination-actions': {
              display: 'none',
            },

            '& .MuiTablePagination-displayedRows': {
              display: 'none',
            },
            '& .MuiTablePagination-input': {
              '.MuiTablePagination-select': {
                '&:focus': {
                  backgroundColor: `${globalStyles.mainColors.blueColor}14`,
                },
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '22px',
                pb: 0.5,
                pl: 1.5,
                pr: 3,
                pt: 0.75,
              },
              border: `1px solid ${globalStyles.mainColors.grayColor}`,
              borderRadius: '6px',
              mx: 0,
            },
            border: 'none',
          }}
        />
      </Box>
    </Box>
  );
};
