import { ICount, IDataSourceField } from '@a-type/interfaces';
import { useDispatch } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import CountsComponent from '@a-type/ui/pages/counts/Counts.component';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { dataSourcesService } from '@a-type/ui/services';
import {
  pageContentLoad,
  resetCountState,
  setCount,
  setCurrentDataSource,
  setFiltersComponents,
  snackbarErrorMessage,
} from '@a-type/ui/stores/actions';
import { useGetFilterGroupsQuery } from '@a-type/ui/stores/apis';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CountsCreatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useGetFilterGroupsQuery();

  const getDataSourceById = async (id: string) => {
    const response = await dataSourcesService.getById(id);
    if (response.status === 200) {
      dispatch(setCurrentDataSource(response.data));
      return response.data;
    }

    dispatch(snackbarErrorMessage('Data source not found'));
    navigate(AppRoutes.HomePage);
    return null;
  };

  const loadFilterComponents = async () => {
    const response = await dataSourcesService.getFilterComponents();
    if (response.status === 200) {
      dispatch(setFiltersComponents([...response.data]));
    }
  };

  useEffect(() => {
    dispatch(resetCountState());
    dispatch(pageContentLoad(false));
    const locationArray = location.pathname.split('/');
    const id = locationArray[locationArray.length - 1];

    if (!id) {
      dispatch(snackbarErrorMessage('Data source not found'));
      dispatch(pageContentLoad(true));
      navigate(AppRoutes.HomePage);
    } else {
      const loadDataSource = async () => {
        const dataSource = await getDataSourceById(id);
        if (!dataSource) {
          dispatch(pageContentLoad(true));
          return;
        }

        const groupBy = [...dataSource.fields]
          .sort(
            (a: IDataSourceField, b: IDataSourceField) => Number(a.sortOrder) - Number(b.sortOrder),
          )
          .find((field) => field.isGroupedBy)?.name;
        const plan = dataSource.pricing?.length ? dataSource.pricing[0] : null;
        const counts = {
          dataSourceId: dataSource._id,
          dataSourceName: dataSource.name,
          filters: {},
          groupBy,
          name: `${dataSource.name} - ${new Date().toISOString().split('T')[0]}`,
          pricingPlanFields: plan?.includedFields,
          pricingPlanId: plan?._id,
          pricingPlanName: plan?.name,
          pricingPlanPrice: plan?.price,
          totalRecords: plan?.totalRecords,
        } as ICount;
        dispatch(setCount(counts));
        dispatch(pageContentLoad(true));
      };

      loadDataSource();
      loadFilterComponents();
    }
  }, []);

  return (
    <PageLayout container>
      <CountsComponent />
    </PageLayout>
  );
};

export default CountsCreatePage;
