import { IUserInfo } from '@a-type/dtos';
import { OperationType } from '@a-type/enums';
import { ITransaction } from '@a-type/interfaces';
import { GridPagination } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetTransactionsQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

interface IUserActivityProps {
  user: IUserInfo;
}

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 15;

export const UserActivity: React.FC<IUserActivityProps> = ({ user }: IUserActivityProps) => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    limit: DEFAULT_LIMIT,
    page: DEFAULT_PAGE,
  });
  const [activity, setActivity] = useState<ITransaction[]>([]);
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);
  const { data: activityData, isLoading } = useGetTransactionsQuery(
    {
      id: user._id,
      limit: pagination.limit,
      page: pagination.page,
    },
    { skip: !user._id },
  );

  useEffect(() => {
    if (activityData) {
      setActivity(activityData.items);
      setCount(activityData.count);
      setPages(activityData.pages);
    }
  }, [activityData]);

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  const paginator = () => {
    return (
      <GridPagination
        count={count}
        limit={pagination.limit}
        onChangePagination={(page, limit) => {
          setPagination({ limit, page });
        }}
        page={pagination.page}
        pages={pages}
      />
    );
  };

  return activity.length > 0 ? (
    <Box>
      <DataGrid
        columns={[
          {
            field: 'createdAt',
            headerName: 'Created At',
            renderCell: (params) =>
              params.row.createdAt ? new Date(params.row.createdAt).toLocaleString() : '',
            width: 180,
          },
          {
            field: 'operationName',
            flex: 1,
            headerName: 'Operation Name',
            minWidth: 250,
          },
          {
            field: 'operationValue',
            headerName: 'Credit Used',
            renderCell: (params) => {
              const type = params.row.operationType;
              const value = params.row.operationValue;

              if (type === OperationType.REDUCTION || type === OperationType.SUBSCRIPTION_CLEANUP) {
                return `-${value.toLocaleString()}`;
              }

              return value?.toLocaleString();
            },
            width: 150,
          },
          {
            field: 'userValueAfter',
            headerName: 'Credit Balance',
            renderCell: (params) => params.row.userValueAfter?.toLocaleString(),
            width: 150,
          },
        ]}
        disableColumnFilter
        disableColumnSorting
        disableRowSelectionOnClick
        getRowId={(row) => row._id}
        hideFooterPagination={pages === 0}
        rows={activity}
        slots={{
          pagination: paginator,
        }}
        sx={{
          height: 'calc(100vh - 540px)',
        }}
      />
    </Box>
  ) : (
    !isLoading && (
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: globalStyles.mainColors.whiteSmokeColor,
          borderRadius: '6px',
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          mt: 2,
        }}
      >
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 20,
            fontStyle: 'italic',
            fontWeight: 400,
          }}
        >
          Threre are no user activities
        </Typography>
      </Box>
    )
  );
};
