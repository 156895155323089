import {
  IChangeRole,
  IPaginationDto,
  IStripeActiveSubscription,
  ITopUpCredits,
  IUserAccessSettings,
  IUserInfo,
  IUserStatistics,
} from '@a-type/dtos';
import { UserStatus } from '@a-type/enums';
import { ITransaction } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const userApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    activateUser: builder.mutation<boolean, string>({
      invalidatesTags: (result, error, id) => [
        { id: 'LIST', type: 'Users' as const },
        { id, type: 'Users' as const },
        { id: `STATISTICS_${id}`, type: 'Users' as const },
        { id: `ACCESS_SETTINGS_${id}`, type: 'Users' as const },
      ],
      query: (id) => ({ method: 'POST', url: `/users/${id}/activate` }),
    }),
    approveUser: builder.mutation<boolean, string>({
      invalidatesTags: (result, error, id) => [
        { id: 'LIST', type: 'Users' as const },
        { id, type: 'Users' as const },
        { id: `STATISTICS_${id}`, type: 'Users' as const },
        { id: `ACCESS_SETTINGS_${id}`, type: 'Users' as const },
      ],
      query: (id) => ({ method: 'POST', url: `/users/${id}/approve` }),
    }),
    blockUser: builder.mutation<boolean, string>({
      invalidatesTags: (result, error, id) => [
        { id: 'LIST', type: 'Users' as const },
        { id, type: 'Users' as const },
      ],
      query: (id) => ({ method: 'POST', url: `/users/${id}/block` }),
    }),
    changeRole: builder.mutation<boolean, { data: IChangeRole; id: string }>({
      invalidatesTags: (result, error, { id }) => [
        { id: 'LIST', type: 'Users' as const },
        { id, type: 'Users' as const },
      ],
      query: ({ data, id }) => ({ data, method: 'POST', url: `/users/${id}/change-role` }),
    }),
    declineUser: builder.mutation<boolean, string>({
      invalidatesTags: (result, error, id) => [
        { id: 'LIST', type: 'Users' as const },
        { id, type: 'Users' as const },
      ],
      query: (id) => ({ method: 'POST', url: `/users/${id}/decline` }),
    }),
    getAccessSettings: builder.query<IUserAccessSettings, string>({
      providesTags: (result, error, id) => [
        { id: `ACCESS_SETTINGS_${id}`, type: 'Users' as const },
      ],
      query: (id) => ({ method: 'GET', url: `/users/${id}/access-settings` }),
    }),
    getTransactions: builder.query<
      IPaginationDto<ITransaction>,
      {
        id: string;
        limit?: number;
        page?: number;
      }
    >({
      query: ({ id, limit, page }) => ({
        method: 'GET',
        params: { limit, page },
        url: `/users/${id}/transactions`,
      }),
    }),
    getUser: builder.query<IUserInfo, string>({
      providesTags: (result) => (result ? [{ id: result._id, type: 'Users' as const }] : []),
      query: (id) => ({ method: 'GET', url: `/users/${id}` }),
    }),
    getUsers: builder.query<
      IPaginationDto<IUserInfo>,
      {
        limit?: number;
        page?: number;
        search?: string;
        status: UserStatus;
      }
    >({
      providesTags: [{ id: 'LIST', type: 'Users' as const }],
      query: (params) => ({ method: 'GET', params, url: '/users' }),
    }),
    getUserStatistics: builder.query<IUserStatistics, string>({
      providesTags: (result, error, id) => [{ id: `STATISTICS_${id}`, type: 'Users' as const }],
      query: (id) => ({ method: 'GET', url: `/users/${id}/statistics` }),
    }),
    getUserSubscribtion: builder.query<IStripeActiveSubscription, string>({
      query: (id) => ({ method: 'GET', url: `/users/${id}/subscription` }),
    }),
    topUpCredit: builder.mutation<boolean, { data: ITopUpCredits; id: string }>({
      invalidatesTags: (result, error, { id }) => [
        { id: 'LIST', type: 'Users' as const },
        { id, type: 'Users' as const },
        { id: `STATISTICS_${id}`, type: 'Users' as const },
      ],
      query: ({ data, id }) => ({ data, method: 'POST', url: `/users/${id}/top-up-credit` }),
    }),
    updateAccessSettings: builder.mutation<
      IUserAccessSettings,
      { data: IUserAccessSettings; id: string }
    >({
      invalidatesTags: (result, error, { id }) => [
        { id: `ACCESS_SETTINGS_${id}`, type: 'Users' as const },
      ],
      query: ({ data, id }) => ({ data, method: 'POST', url: `/users/${id}/access-settings` }),
    }),
  }),
});

export const {
  useActivateUserMutation,
  useApproveUserMutation,
  useBlockUserMutation,
  useChangeRoleMutation,
  useDeclineUserMutation,
  useGetAccessSettingsQuery,
  useGetTransactionsQuery,
  useGetUserQuery,
  useGetUsersQuery,
  useGetUserStatisticsQuery,
  useGetUserSubscribtionQuery,
  useTopUpCreditMutation,
  useUpdateAccessSettingsMutation,
} = userApi;
