import { Components, Theme } from '@mui/material';

import globalStyles from '../global.styles';

const style: Components<Omit<Theme, 'components'>> = {
  MuiDataGrid: {
    styleOverrides: {
      root: () => ({
        '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, & .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate':
          {
            color: globalStyles.mainColors.blueColor,
          },

        '& .MuiDataGrid-cell': {
          alignItems: 'center',
          display: 'flex',
          paddingTop: '2px',
        },
        '& .MuiDataGrid-cell, & .css-okt5j6-MuiDataGrid-columnHeaders, & .css-1ygg0wd-MuiDataGrid-root .MuiDataGrid-columnSeparator':
          {
            borderColor: globalStyles.mainColors.whiteColor,
          },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell--withRenderer': {
          '& div': {
            fontSize: '14px',
          },
        },
        '& .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
          borderBottom: `1px solid ${globalStyles.unActiveSideTabColor.color} !Important`,
          height: '52px',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontSize: '11px',
          fontWeight: 700,
          textTransform: 'uppercase',
        },
        '& .MuiDataGrid-footerContainer': {
          justifyContent: 'right',
        },
        '& .MuiDataGrid-selectedRowCount': {
          display: 'none',
        },
        '& .MuiDataGrid-virtualScroller': {
          fontSize: '14px',
        },
        borderColor: globalStyles.mainColors.whiteColor,
      }),
    },
  },
};

export default style;
