import { IUserInfo } from '@a-type/dtos';
import { UserStatus } from '@a-type/enums';
import { GridPagination } from '@a-type/ui/components';
import { useDispatch, useQuery } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetUsersQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  BlockOutlined,
  ManageAccountsOutlined,
  MonetizationOnOutlined,
  Search,
  VerifiedUserOutlined,
} from '@mui/icons-material';
import { Box, InputAdornment, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApproveUser } from './components/approve-user/approve-user.component';
import { BlockUser } from './components/block-user/block-user.component';
import { ChangeUserRole } from './components/change-user-role/change-user-role.component';
import { TopUpBalance } from './components/top-up-balance/top-up-balance.component';

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 15;

export const UsersPage: FC = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState<IUserInfo[]>([]);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>('');
  const [activeTab, setActiveTab] = useState((query.get('tab') as UserStatus) ?? UserStatus.ACTIVE);
  const [pagination, setPagination] = useState({
    limit: DEFAULT_LIMIT,
    page: DEFAULT_PAGE,
  });
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [user, setUser] = useState<IUserInfo | null>(null);
  const [showApproveUser, setShowApproveUser] = useState(false);
  const [showBlockUser, setShowBlockUser] = useState(false);
  const [showChangeUserRole, setShowChangeUserRole] = useState(false);
  const [showTopUpBalance, setShowTopUpBalance] = useState(false);
  const { data: usersData, isFetching: isUsersLoading } = useGetUsersQuery({
    limit: pagination.limit,
    page: pagination.page,
    search,
    status: activeTab,
  });

  useEffect(() => {
    if (usersData) {
      setUsers(usersData.items);
      setCount(usersData.count);
      setPages(usersData.pages);
    }
  }, [usersData]);

  useEffect(() => {
    dispatch(pageContentLoad(!isUsersLoading));
  }, [isUsersLoading]);

  useEffect(() => {
    if (query.get('tab')) {
      setActiveTab(query.get('tab') as UserStatus);
    } else {
      navigate(`?tab=${UserStatus.ACTIVE}`);
    }

    setPagination({
      limit: DEFAULT_LIMIT,
      page: DEFAULT_PAGE,
    });
    setSearchValue('');
  }, [query]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setSearch(searchValue || undefined);
      setPagination({
        limit: DEFAULT_LIMIT,
        page: DEFAULT_PAGE,
      });
    }, 250);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  const paginator = () => {
    return (
      <GridPagination
        count={count}
        limit={pagination.limit}
        onChangePagination={(page, limit) => {
          setPagination({ limit, page });
        }}
        page={pagination.page}
        pages={pages}
      />
    );
  };

  const handleApproveUser = (u: IUserInfo) => {
    setUser(u);
    setShowApproveUser(true);
  };

  const handleBlockUser = (u: IUserInfo) => {
    setUser(u);
    setShowBlockUser(true);
  };

  const handleTopUpBalance = (u: IUserInfo) => {
    setUser(u);
    setShowTopUpBalance(true);
  };

  const handleChangeUserRole = (u: IUserInfo) => {
    setUser(u);
    setShowChangeUserRole(true);
  };

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          maxHeight: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            pb: 1,
          }}
        >
          <Typography sx={{ fontSize: 24, fontWeight: 800, pl: 2 }}>Users</Typography>
        </Box>

        <Box
          sx={{
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Tabs
            onChange={(event, v: UserStatus) => {
              setActiveTab(v);
              setSearchValue('');
              navigate(`${AppRoutes.AdminUsersPage}?tab=${v}`);
            }}
            value={activeTab}
          >
            <Tab
              label="Active"
              sx={{ color: globalStyles.navigation.textColor }}
              value={UserStatus.ACTIVE}
            />
            <Tab
              label="Pending"
              sx={{ color: globalStyles.navigation.textColor }}
              value={UserStatus.PENDING}
            />
            <Tab
              label="Blocked"
              sx={{ color: globalStyles.navigation.textColor }}
              value={UserStatus.BLOCKED}
            />
          </Tabs>

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder="Search by name or email"
            size="small"
            sx={{
              width: '320px',
            }}
            value={searchValue}
            variant="outlined"
          />
        </Box>

        {users.length > 0 ? (
          <Box sx={{ mt: 0.5 }}>
            <DataGrid
              columns={[
                {
                  field: 'fullName',
                  flex: 1,
                  headerName: 'Full Name',
                  minWidth: 150,
                  renderCell: (params) => (
                    <Typography
                      sx={{
                        color: globalStyles.mainColors.sootyColor,
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      {params.row.fullName}
                    </Typography>
                  ),
                },
                { field: 'mobile', headerName: 'Phone Number', width: 150 },
                { field: 'email', flex: 1, headerName: 'Email', minWidth: 150 },
                { field: 'companyName', flex: 1, headerName: 'Company Name', minWidth: 150 },
                {
                  field: 'createdAt',
                  headerName: 'Created At',
                  renderCell: (params) =>
                    params.row.createdAt ? new Date(params.row.createdAt).toLocaleDateString() : '',
                  width: 150,
                },
                {
                  field: 'lastLogin',
                  headerName: 'Last Login',
                  renderCell: (params) =>
                    params.row.lastLogin ? new Date(params.row.lastLogin).toLocaleDateString() : '',
                  width: 150,
                },
                {
                  field: 'currency',
                  headerName: 'Balance',
                  renderCell: (params) => params.row.currency?.toLocaleString() ?? '0',
                  width: 100,
                },
                {
                  field: 'role',
                  headerName: 'Role',
                  renderCell: (params) => {
                    switch (params.row.role) {
                      case 'superadmin':
                        return 'Super Admin';
                      case 'admin':
                        return 'Admin';
                      case 'user':
                        return 'User';
                      default:
                        return '';
                    }
                  },
                  width: 150,
                },
                {
                  field: 'actions',
                  getActions: (params) => {
                    const actions = [];

                    if (params.row.status === UserStatus.ACTIVE) {
                      actions.push(
                        <GridActionsCellItem
                          icon={
                            <Tooltip placement="top" title="Top Up Balance">
                              <MonetizationOnOutlined />
                            </Tooltip>
                          }
                          key="top-up-balance"
                          label="Top Up Balance"
                          onClick={() => handleTopUpBalance(params.row)}
                        />,
                        <GridActionsCellItem
                          icon={<ManageAccountsOutlined />}
                          key="change-role"
                          label="Change User Role"
                          onClick={() => handleChangeUserRole(params.row)}
                          showInMenu
                        />,
                      );
                    } else {
                      actions.push(
                        <GridActionsCellItem
                          icon={
                            <Tooltip
                              placement="top"
                              title={
                                params.row.status === UserStatus.PENDING
                                  ? 'Approve User'
                                  : 'Activate User'
                              }
                            >
                              <VerifiedUserOutlined />
                            </Tooltip>
                          }
                          key="approve-user"
                          label={
                            params.row.status === UserStatus.PENDING
                              ? 'Approve User'
                              : 'Activate User'
                          }
                          onClick={() => handleApproveUser(params.row)}
                        />,
                      );
                    }

                    if (params.row.status !== UserStatus.BLOCKED) {
                      actions.push(
                        <GridActionsCellItem
                          icon={
                            <Tooltip
                              placement="top"
                              title={
                                params.row.status === UserStatus.PENDING ? 'Decline User' : null
                              }
                            >
                              <BlockOutlined />
                            </Tooltip>
                          }
                          key="block-user"
                          label={
                            params.row.status === UserStatus.PENDING ? 'Decline User' : 'Block User'
                          }
                          onClick={() => handleBlockUser(params.row)}
                          showInMenu={params.row.status === UserStatus.ACTIVE}
                        />,
                      );
                    }

                    return actions;
                  },
                  headerName: 'Actions',
                  type: 'actions',
                  width: 80,
                },
              ]}
              columnVisibilityModel={{
                currency: activeTab !== UserStatus.PENDING,
                lastLogin: activeTab === UserStatus.ACTIVE,
                role: activeTab !== UserStatus.PENDING,
              }}
              disableColumnFilter
              disableColumnSorting
              disableRowSelectionOnClick
              getRowId={(row) => row._id}
              hideFooterPagination={pages === 0}
              onRowClick={(params) => {
                navigate(`${AppRoutes.AdminUsersPage}/${params.row._id}`);
              }}
              rows={users}
              slots={{
                pagination: paginator,
              }}
              sx={{
                height: 'calc(100vh - 310px)',
              }}
            />
          </Box>
        ) : (
          !isUsersLoading && (
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: globalStyles.mainColors.whiteSmokeColor,
                borderRadius: '6px',
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 20,
                  fontStyle: 'italic',
                  fontWeight: 400,
                }}
              >
                Threre are no {activeTab} users.
              </Typography>
            </Box>
          )
        )}
      </Box>

      {showApproveUser && (
        <ApproveUser setShow={setShowApproveUser} show={showApproveUser} user={user!} />
      )}

      {showBlockUser && <BlockUser setShow={setShowBlockUser} show={showBlockUser} user={user!} />}

      {showTopUpBalance && (
        <TopUpBalance setShow={setShowTopUpBalance} show={showTopUpBalance} user={user!} />
      )}

      {showChangeUserRole && (
        <ChangeUserRole setShow={setShowChangeUserRole} show={showChangeUserRole} user={user!} />
      )}
    </AdminPageLayout>
  );
};
