import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { useSelector } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { useGetUserDataSourcesQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { Alert, AlertTitle, Box, Button, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CountsNarrowSearchHeader = () => {
  const navigate = useNavigate();
  const { count } = useSelector((state) => state.count);
  const { data: dataSources, isFetching: isLoading } = useGetUserDataSourcesQuery();
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);

  useEffect(() => {
    if (count && dataSources) {
      setHasAccess(dataSources.some((source) => source._id === count.dataSourceId));
    }
  }, [count, dataSources]);

  return (
    <>
      {hasAccess === false && !isLoading && (
        <Alert
          severity="warning"
          sx={{
            mb: 2,
            width: '100%',
          }}
        >
          <AlertTitle>You don&apos;t have access to this database!</AlertTitle>
          You don&apos;t have access to this database anymore. Please contact your administrator to
          get access.
        </Alert>
      )}

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          px: 1,
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
        >
          {hasAccess && (
            <IconButton onClick={() => navigate(`${AppRoutes.CountsUpdate}/${count?._id}`)}>
              <ChevronLeftOutlinedIcon />
            </IconButton>
          )}
          <Typography
            component="div"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: '24px',
              fontWeight: 700,
            }}
          >
            Build List
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button
            disabled={!hasAccess}
            onClick={() => navigate(`${AppRoutes.CountsUpdate}/${count?._id}`)}
            sx={{
              minWidth: '160px',
              py: 1.25,
            }}
            variant="outlined"
          >
            {ButtonDictionaryNames.RERUN_COUNT}
          </Button>
          <Button
            disabled={!hasAccess}
            onClick={() => navigate(`${AppRoutes.BuyListPage}/${count?._id}`)}
            sx={{
              minWidth: '160px',
              py: 1.25,
            }}
            variant="contained"
          >
            {ButtonDictionaryNames.BUY_LIST}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CountsNarrowSearchHeader;
