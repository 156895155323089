import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Typography } from '@mui/material';

const ActivityTableHeaderComponent = () => {
  return (
    <Box sx={{ display: 'flex', pb: 2, pt: 2.5 }}>
      <Typography
        sx={{
          color: globalStyles.mainColors.siriyGrayColor,
          fontSize: 12,
          textTransform: 'uppercase',
          width: '25%',
        }}
      >
        Created At
      </Typography>
      <Typography
        sx={{
          color: globalStyles.mainColors.siriyGrayColor,
          fontSize: 12,
          textTransform: 'uppercase',
          width: '45%',
        }}
      >
        Operation Name
      </Typography>
      <Typography
        sx={{
          color: globalStyles.mainColors.siriyGrayColor,
          fontSize: 12,
          textTransform: 'uppercase',
          width: '15%',
        }}
      >
        Credit Used
      </Typography>

      <Typography
        sx={{
          color: globalStyles.mainColors.siriyGrayColor,
          fontSize: 12,
          textTransform: 'uppercase',
          width: '15%',
        }}
      >
        Credit Balance
      </Typography>
    </Box>
  );
};
export default ActivityTableHeaderComponent;
