export enum FieldMetadataType {
  ADDRESS = 'Address',
  ADDRESS2 = 'Address 2',
  CITY = 'City',
  COUNTRY = 'Country',
  EMAIL = 'Email',
  // Customer
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  PHONE = 'Phone',
  STATE = 'State',
  ZIP_CODE = 'Zip Code',
}
