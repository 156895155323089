import { AdminSidebar } from '@a-type/ui/layout';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box } from '@mui/material';

interface AdminPageLayoutProps {
  children: React.ReactNode;
  container?: boolean;
}

export const AdminPageLayout: React.FC<AdminPageLayoutProps> = ({
  children,
  container = false,
}: AdminPageLayoutProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
      }}
    >
      <AdminSidebar />

      <Box
        sx={{
          flexGrow: 1,
          height: '100%',
          overflow: 'auto',
          pb: 3,
          pt: 5,
          px: 4,
          width: '100%',
        }}
      >
        {container ? (
          <Box
            sx={{
              background: globalStyles.mainColors.whiteColor,
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
              minHeight: '100%',
              px: 4,
              py: 2,
            }}
          >
            {children}
          </Box>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default AdminPageLayout;
