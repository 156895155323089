import { Components, hexToRgb, Theme } from '@mui/material';

import globalStyles from '../global.styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    action: true;
  }
}

const style: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        // basic styles
        textTransform: 'none',
        textWrap: 'nowrap',

        // small size
        ...(ownerState.size === 'small' &&
          ownerState.variant === 'text' && {
            fontSize: '14px',
            fontWeight: 600,
            padding: '4px 5px',
          }),

        ...(ownerState.size === 'small' &&
          (ownerState.variant === 'contained' || ownerState.variant === 'outlined') && {
            fontSize: '14px',
            fontWeight: 600,
            height: '40px',
            lineHeight: '20px',
            padding: '8px 16px',
          }),

        // medium size
        ...(ownerState.size === 'medium' &&
          ownerState.variant === 'text' && {
            fontSize: '16px',
            fontWeight: 700,
            padding: '8px 10px',
          }),

        ...(ownerState.size === 'medium' &&
          (ownerState.variant === 'contained' || ownerState.variant === 'outlined') && {
            fontSize: '16px',
            fontWeight: 700,
            height: '48px',
            lineHeight: '24px',
            padding: '12px 24px',
          }),

        // large size
        ...(ownerState.size === 'large' &&
          ownerState.variant === 'text' && {
            fontSize: '18px',
            fontWeight: 700,
            padding: '12px 15px',
          }),

        ...(ownerState.size === 'large' &&
          (ownerState.variant === 'contained' || ownerState.variant === 'outlined') && {
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '28px',
            padding: '16px 32px',
          }),

        // disabled styles
        ...(ownerState.disabled && {
          opacity: 0.5,
        }),

        // variant styles
        ...((ownerState.variant === 'contained' || ownerState.variant === 'outlined') && {
          borderRadius: '200px',
        }),

        // color styles
        // primary color
        // text variant
        ...(ownerState.color === 'primary' &&
          ownerState.variant === 'text' && {
            '&:hover': {
              color: globalStyles.mainColors.blueColor,
            },
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.blueColor,
            },

            color: globalStyles.mainColors.blueColor,
          }),

        // contained variant
        ...(ownerState.color === 'primary' &&
          ownerState.variant === 'contained' && {
            '&:hover': {
              backgroundColor: globalStyles.mainColors.blueColor,
            },
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.whiteColor,
            },
            backgroundColor: globalStyles.mainColors.blackColor,

            color: globalStyles.mainColors.whiteColor,
          }),

        // outlined variant
        ...(ownerState.color === 'primary' &&
          ownerState.variant === 'outlined' && {
            '&:hover': {
              background: globalStyles.mainColors.lightGrayColor,
              borderColor: globalStyles.mainColors.blackColor,
            },
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.blackColor,
            },
            background: globalStyles.mainColors.whiteColor,
            borderColor: globalStyles.mainColors.blackColor,

            color: globalStyles.mainColors.blackColor,
          }),

        // secondary color
        // text variant
        ...(ownerState.color === 'secondary' &&
          ownerState.variant === 'text' && {
            '&:hover': {
              color: globalStyles.mainColors.headTableGreyColor,
            },
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.headTableGreyColor,
            },

            color: globalStyles.mainColors.headTableGreyColor,
          }),

        // contained variant
        ...(ownerState.color === 'secondary' &&
          ownerState.variant === 'contained' && {
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.whiteColor,
            },
            backgroundColor: globalStyles.mainColors.headTableGreyColor,

            color: globalStyles.mainColors.whiteColor,
          }),

        // outlined variant
        ...(ownerState.color === 'secondary' &&
          ownerState.variant === 'outlined' && {
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.headTableGreyColor,
            },
            borderColor: globalStyles.mainColors.headTableGreyColor,

            color: globalStyles.mainColors.headTableGreyColor,
          }),

        // info color
        // text variant
        ...(ownerState.color === 'info' &&
          ownerState.variant === 'text' && {
            '&:hover': {
              color: globalStyles.mainColors.blueColor,
            },
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.blueColor,
            },

            color: globalStyles.mainColors.blueColor,
          }),

        // contained variant
        ...(ownerState.color === 'info' &&
          ownerState.variant === 'contained' && {
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.whiteColor,
            },
            backgroundColor: globalStyles.mainColors.blueColor,

            color: globalStyles.mainColors.whiteColor,
          }),

        // outlined variant
        ...(ownerState.color === 'info' &&
          ownerState.variant === 'outlined' && {
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.blueColor,
            },
            borderColor: globalStyles.mainColors.blueColor,

            color: globalStyles.mainColors.blueColor,
          }),

        // success color
        // text variant
        ...(ownerState.color === 'success' &&
          ownerState.variant === 'text' && {
            '&:hover': {
              color: globalStyles.mainColors.greenColor,
            },
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.greenColor,
            },

            color: globalStyles.mainColors.greenColor,
          }),

        // contained variant
        ...(ownerState.color === 'success' &&
          ownerState.variant === 'contained' && {
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.whiteColor,
            },
            backgroundColor: globalStyles.mainColors.greenColor,

            color: globalStyles.mainColors.whiteColor,
          }),

        // outlined variant
        ...(ownerState.color === 'success' &&
          ownerState.variant === 'outlined' && {
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.greenColor,
            },
            borderColor: globalStyles.mainColors.greenColor,

            color: globalStyles.mainColors.greenColor,
          }),

        // error color
        // text variant
        ...(ownerState.color === 'error' &&
          ownerState.variant === 'text' && {
            '&:hover': {
              color: globalStyles.mainColors.redColor,
            },
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.redColor,
            },

            color: globalStyles.mainColors.redColor,
          }),

        // contained variant
        ...(ownerState.color === 'error' &&
          ownerState.variant === 'contained' && {
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.whiteColor,
            },
            backgroundColor: globalStyles.mainColors.redColor,

            color: globalStyles.mainColors.whiteColor,
          }),

        // outlined variant
        ...(ownerState.color === 'error' &&
          ownerState.variant === 'outlined' && {
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.redColor,
            },
            borderColor: globalStyles.mainColors.redColor,

            color: globalStyles.mainColors.redColor,
          }),

        // warning color
        // text variant
        ...(ownerState.color === 'warning' &&
          ownerState.variant === 'text' && {
            '&:hover': {
              color: globalStyles.mainColors.yellowColor,
            },
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.yellowColor,
            },

            color: globalStyles.mainColors.yellowColor,
          }),

        // contained variant
        ...(ownerState.color === 'warning' &&
          ownerState.variant === 'contained' && {
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.whiteColor,
            },
            backgroundColor: globalStyles.mainColors.yellowColor,

            color: globalStyles.mainColors.whiteColor,
          }),

        // outlined variant
        ...(ownerState.color === 'warning' &&
          ownerState.variant === 'outlined' && {
            '.MuiLoadingButton-loadingIndicator': {
              color: globalStyles.mainColors.yellowColor,
            },
            borderColor: globalStyles.mainColors.yellowColor,

            color: globalStyles.mainColors.yellowColor,
          }),
      }),
    },
    variants: [
      {
        props: { variant: 'action' },
        style: {
          '& .MuiButton-startIcon': {
            '&:hover': {
              color: globalStyles.mainColors.blueColor,
            },
            color: globalStyles.mainColors.headTableGreyColor,

            margin: 0,
          },
          '&:hover': {
            backgroundColor: hexToRgb(`${globalStyles.mainColors.blueColor}1A`),
          },
          color: globalStyles.mainColors.blueColor,
          height: '32px',

          minWidth: '32px',
          padding: 1,

          width: '32px',
        },
      },
    ],
  },
};

export default style;
