import { ICreatePassword, ILogin, IRegister, ITokens } from '@a-type/dtos';
import JwtDecodeService from '@a-type/ui/services/jwt-decode.service';

import apiMiddleware from './api-middleware';

export const authApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    confirm: builder.mutation<boolean, { token: string }>({
      query: ({ token }) => ({ method: 'POST', url: `/auth/confirm?token=${token}` }),
    }),
    login: builder.mutation<ITokens, ILogin>({
      invalidatesTags: [
        'Account',
        'Transactions/User',
        'Stripe/PaymentMethod',
        'Stripe/Subscription',
        'Stripe/PaymentHistory',
        'List',
        'DataSource/User',
        'AppendData/User',
      ],
      query: (data) => ({ data, method: 'POST', url: '/auth/login' }),
      transformResponse: (response: ITokens) => {
        JwtDecodeService.saveUserFromJwt(response);
        return response;
      },
    }),
    logout: builder.mutation<boolean, void>({
      query: () => ({ method: 'POST', url: '/auth/logout' }),
      transformResponse: (response: boolean) => {
        if (response) JwtDecodeService.deleteUserFromStorage();
        return response;
      },
    }),
    passwordCreate: builder.mutation<boolean, { password: ICreatePassword; token: string }>({
      query: ({ password, token }) => ({
        data: password,
        method: 'POST',
        url: `/auth/password-create?token=${token}`,
      }),
    }),
    passwordForgot: builder.mutation<boolean, { email: string }>({
      query: ({ email }) => ({ method: 'POST', url: `/auth/password-forgot?email=${email}` }),
    }),
    refreshToken: builder.mutation<ITokens, string>({
      query: (refreshToken) => ({
        data: { refreshToken },
        method: 'POST',
        url: `/auth/refresh-token?token=${refreshToken}`,
      }),
      transformResponse: (response: ITokens) => {
        JwtDecodeService.saveUserFromJwt(response);
        return response;
      },
    }),
    register: builder.mutation<boolean, IRegister>({
      query: (data) => ({ data, method: 'POST', url: '/auth/register' }),
    }),
  }),
});

export const {
  useConfirmMutation,
  useLoginMutation,
  useLogoutMutation,
  usePasswordCreateMutation,
  usePasswordForgotMutation,
  useRefreshTokenMutation,
  useRegisterMutation,
} = authApi;
