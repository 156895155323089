import { Box, Container, Typography } from '@mui/material';

const HelpCenterComponent = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography component="div" variant="h1">
          Help page
        </Typography>
      </Box>
    </Container>
  );
};

export default HelpCenterComponent;
