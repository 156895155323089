import { ListTaskStatus } from '@a-type/enums';
import { IDocument } from '@a-type/interfaces';
import { ActionButton } from '@a-type/ui/components';
import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { useDispatch } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { listService } from '@a-type/ui/services';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import {
  useGetDocumentByIdWithNavigationQuery,
  useGetListQuery,
  useGetUserAppendDataQuery,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { ChevronLeftOutlined, FileDownloadOutlined, PostAdd } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ListTaskProgress } from '../components';
import { TaxAssessorsDocumentDetails } from './components/tax-assessors-document-details/tax-assessors-document-details.component';
import { TaxAssessorsDocumentInfo } from './components/tax-assessors-document-info/tax-assessors-document-info.component';
import { TaxAssessorsDocumentFields } from './enums/tax-assessors-document-fields.enum';

export const TaxAssessorsDocumentPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { documentId, listId } = useParams<{ documentId: string; listId: string }>();
  const { data: list, isFetching: isListLoading } = useGetListQuery(listId!, {
    skip: !listId,
  });
  const { data: appendDataUtils, isFetching: isAppendDataUtilsLoading } =
    useGetUserAppendDataQuery();
  const { data: documentData, isFetching: isDocumentLoading } =
    useGetDocumentByIdWithNavigationQuery(
      {
        documentId: documentId!,
        listId: listId!,
      },
      {
        skip: !listId || isListLoading,
      },
    );
  const [document, setDocument] = useState<IDocument | null>(null);
  const [next, setNext] = useState<null | string>(null);
  const [previous, setPrevious] = useState<null | string>(null);

  useEffect(() => {
    dispatch(pageContentLoad(!isDocumentLoading && !isListLoading && !isAppendDataUtilsLoading));
  }, [isDocumentLoading, isListLoading, isAppendDataUtilsLoading]);

  useEffect(() => {
    if (documentData) {
      setDocument(documentData.document);
      setNext(documentData.nextId ?? null);
      setPrevious(documentData.previousId ?? null);
    }
  }, [documentData]);

  const getTitle = useMemo(() => {
    if (document) {
      if (document.data[TaxAssessorsDocumentFields.PropertyAddressFull]) {
        return document.data[TaxAssessorsDocumentFields.PropertyAddressFull];
      }

      if (document.data[TaxAssessorsDocumentFields.PartyOwner1NameFull]) {
        return document.data[TaxAssessorsDocumentFields.PartyOwner1NameFull];
      }
    }

    return 'Tax Assessors Document';
  }, [document]);

  const canAppendData = useMemo(() => {
    if (!list || !appendDataUtils) return false;

    return appendDataUtils.some(
      (appendData) => appendData.isActive && appendData.targetType === list.targetType,
    );
  }, [list, appendDataUtils]);

  const task = useMemo(() => {
    if (document?.tasks && document.tasks.length > 0) {
      return (
        document.tasks.find(
          (t) => t.status === ListTaskStatus.IN_PROGRESS || t.status === ListTaskStatus.PENDING,
        ) ?? null
      );
    }

    return null;
  }, [document]);

  const downloadCSVData = async () => {
    if (!list) return;
    if (!document) return;

    dispatch(pageContentLoad(false));
    const type =
      document.data.permits?.length > 0 || document.data.recorders?.length > 0 ? 'zip' : 'csv';
    const name = `${getTitle.replace(/[^a-zA-Z0-9]/g, '_')}.${type}`;
    await listService.exportTaxAssessorsDocument(list._id, document._id, name);
    dispatch(pageContentLoad(true));
  };

  return (
    <PageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 2,
          height: '100%',
        }}
      >
        <Box
          sx={{
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
            pb: 1,
            width: '100%',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              gap: 1,
            }}
          >
            <IconButton
              onClick={() => {
                navigate(`${AppRoutes.Lists}/${listId}`);
              }}
            >
              <ChevronLeftOutlined />
            </IconButton>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              {getTitle}
            </Typography>
          </Box>

          {document && list && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
              }}
            >
              {task && <ListTaskProgress task={task} />}

              <ActionButton
                disabled={task !== null || !canAppendData}
                icon={<PostAdd />}
                label={ButtonDictionaryNames.APPEND_DATA}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`${AppRoutes.Lists}/${list._id}/append/${document._id}`);
                }}
              />

              <ActionButton
                disabled={task !== null}
                icon={<FileDownloadOutlined />}
                label={ButtonDictionaryNames.DOWNLOAD_DOCUMENT}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadCSVData();
                }}
              />
            </Box>
          )}
        </Box>

        {document && list && (
          <TaxAssessorsDocumentInfo
            document={document}
            list={list}
            next={next}
            previous={previous}
          />
        )}

        {document && list && <TaxAssessorsDocumentDetails document={document} list={list} />}
      </Box>
    </PageLayout>
  );
};
