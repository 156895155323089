import { ICreateStripeSubscription } from '@a-type/dtos';
import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { pageContentLoad, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { useCreateSubscriptionMutation } from '@a-type/ui/stores/apis';
import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

interface AddSubscriptionDialogProps {
  onClose: () => void;
  open: boolean;
  productId: string;
}

const MAX_CREDITS = 999999999999999;
const MAX_PRICE = 999999999;

export const AddSubscriptionDialog: React.FC<AddSubscriptionDialogProps> = ({
  onClose,
  open,
  productId,
}: AddSubscriptionDialogProps) => {
  const dispatch = useDispatch();
  const [createSubscription, { isLoading: isCreateSubscriptionLoading }] =
    useCreateSubscriptionMutation();
  const [formValues, setFormValues] = useState<ICreateStripeSubscription>({
    credits: 0,
    name: '',
    price: 0,
    productId,
  });

  useEffect(() => {
    dispatch(pageContentLoad(!isCreateSubscriptionLoading));
  }, [isCreateSubscriptionLoading]);

  const handleCreateSubscription = async () => {
    const data = await createSubscription({
      data: {
        credits: Number(formValues.credits),
        name: formValues.name,
        price: Number(formValues.price),
        productId,
      },
      id: productId,
    });

    if (data.data) {
      dispatch(snackbarSuccessMessage('Subscription created successfully'));
      onClose();
    }
  };

  const handleFormChange = (key: keyof ICreateStripeSubscription, value: number | string) => {
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Add"
      onCancel={onClose}
      onClose={onClose}
      onOk={handleCreateSubscription}
      open={open}
      title="Add Subscription Pricing"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <TextField
          error={!formValues.name}
          inputProps={{ maxLength: 50 }}
          label="Name"
          onChange={(e) => handleFormChange('name', e.target.value)}
          value={formValues.name}
        />

        <TextField
          error={formValues.credits < 0 || formValues.credits % 1 !== 0}
          inputProps={{ max: MAX_CREDITS, min: 0, step: 1 }}
          label="Credits"
          onChange={(e) => {
            const value = parseInt(e.target.value, 10);
            handleFormChange('credits', value > MAX_CREDITS ? MAX_CREDITS : value);
          }}
          type="number"
          value={formValues.credits}
        />

        <TextField
          error={Number.isNaN(formValues.price) || formValues.price < 0}
          inputProps={{ max: MAX_PRICE, min: 0, step: 0.01 }}
          label="Price"
          onChange={(e) => {
            const value = parseFloat(parseFloat(e.target.value).toFixed(2));
            handleFormChange('price', value > MAX_PRICE ? MAX_PRICE : value);
          }}
          type="number"
          value={formValues.price}
        />
      </Box>
    </Dialog>
  );
};
