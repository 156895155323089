import { AppendType, ListTaskStatus } from '@a-type/enums';
import { IListTask, IVersiumAppend } from '@a-type/interfaces';
import { ActionButton, MoreActionItem, MoreActionsSelector } from '@a-type/ui/components';
import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { useDispatch, useQuery, useSelector } from '@a-type/ui/hooks';
import { ListTaskProgress } from '@a-type/ui/pages/lists/components';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { listService } from '@a-type/ui/services';
import { pageContentLoad, setList } from '@a-type/ui/stores/actions';
import { useGetUserAppendDataQuery, useGetUserDataSourcesQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CachedIcon from '@mui/icons-material/Cached';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ListDocumentsHeaderProps {
  buyingDate: string;
  setBuyingDate: (date: string) => void;
  totalCount: number;
}

const ListDocumentsHeaderComponent = (props: ListDocumentsHeaderProps) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list } = useSelector((state) => state.list);
  const { buyingDate, setBuyingDate, totalCount } = props;
  const [activeTab, setActiveTab] = useState(query.get('tab') ?? 'data');
  const { data: appendDataUtilsData } = useGetUserAppendDataQuery();
  const { data: dataSources } = useGetUserDataSourcesQuery();
  const [appendDataUtils, setAppendDataUtils] = useState<IVersiumAppend[]>([]);

  useEffect(() => {
    if (appendDataUtilsData) {
      setAppendDataUtils(appendDataUtilsData);
    }
  }, [appendDataUtilsData]);

  const refreshAvailableRecords = async () => {
    if (!list) return;

    dispatch(pageContentLoad(false));
    const response = await listService.getByIdWithAvailableCount(list._id);
    if (response.status === 200) {
      dispatch(setList(response.data));
    }

    dispatch(pageContentLoad(true));
  };

  const [task, setTask] = useState<IListTask | null>(null);

  useEffect(() => {
    // set last task as active task
    if (
      list?.tasks &&
      list.tasks.length > 0 &&
      list.tasks[list.tasks.length - 1].status !== ListTaskStatus.DONE
    ) {
      setTask(list.tasks[list.tasks.length - 1]);
    } else {
      setTask(null);
    }
  }, [list]);

  const downloadCSVData = async () => {
    if (!list) return;

    dispatch(pageContentLoad(false));
    const date = buyingDate === 'all' ? '' : `_${new Date(buyingDate).toLocaleDateString()}`;
    const name = `${list.name}${date}`;
    await listService.export(list._id, name, buyingDate === 'all' ? undefined : buyingDate);
    dispatch(pageContentLoad(true));
  };

  const downloadOnlineAudienceCSVData = async () => {
    if (!list) return;

    dispatch(pageContentLoad(false));
    await listService.exportOnlineAudience(list._id, list.name);
    dispatch(pageContentLoad(true));
  };

  const canAppendData = () => {
    if (!list || !appendDataUtils) return false;

    return appendDataUtils.some(
      (appendData) => appendData.isActive && appendData.targetType === list.targetType,
    );
  };

  const canBuyMoreData = () => {
    if (!list || !dataSources) return false;

    return dataSources.some((source) => source._id === list.dataSourceId);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'space-between',
          pb: 2,
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 4,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              gap: 1,
            }}
          >
            <IconButton
              onClick={() => {
                navigate(`${AppRoutes.Lists}`);
              }}
            >
              <ChevronLeftOutlinedIcon />
            </IconButton>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              {list!.name}
            </Typography>
          </Box>

          {list!.reportsCount! > 1 && (
            <FormControl>
              <Select
                id="records-date-select"
                labelId="records-date-label"
                onChange={(e: any) => {
                  const { value } = e.target;
                  setBuyingDate(value);
                }}
                size="small"
                sx={{
                  borderRadius: 50,
                  color: globalStyles.mainColors.blackColor,
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: 1.5,
                  minWidth: 280,
                  pl: 2,
                  pr: 2,
                }}
                value={buyingDate}
              >
                <MenuItem
                  key="all"
                  sx={{
                    fontSize: 14,
                    fontWeight: buyingDate === 'all' ? 500 : 400,
                    lineHeight: 1.5,
                  }}
                  value="all"
                >
                  All Records
                </MenuItem>
                {list!.reports!.map((item) => {
                  return (
                    <MenuItem
                      key={item}
                      sx={{
                        fontSize: 14,
                        fontWeight: buyingDate === item ? 500 : 400,
                        lineHeight: 1.5,
                      }}
                      value={item}
                    >
                      {new Date(item).toLocaleString()}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            gap: 2,
            justifyContent: 'flex-end',
          }}
        >
          {task && <ListTaskProgress task={task} />}

          {(list?.appendTypes || []).length === 0 && list?.manualUpload === false && (
            <Button
              color="secondary"
              disabled={task !== null}
              onClick={refreshAvailableRecords}
              startIcon={<CachedIcon />}
            >
              {list?.avilableRecords?.toLocaleString() ?? 0} Records available
            </Button>
          )}

          <ActionButton
            disabled={task !== null || !canAppendData()}
            icon={<PostAddIcon />}
            label={ButtonDictionaryNames.APPEND_DATA}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`${AppRoutes.Lists}/${list!._id}/append`);
            }}
          />

          <ActionButton
            disabled={list?.avilableRecords === 0 || task !== null || !canBuyMoreData()}
            icon={<PaidOutlinedIcon />}
            label={ButtonDictionaryNames.BUY_MORE_RECORDS}
            onClick={() => {
              navigate(`${AppRoutes.BuyMoreDataPage}/${list!._id}`);
            }}
          />

          <ActionButton
            disabled={task !== null}
            icon={<CreateOutlinedIcon />}
            label={ButtonDictionaryNames.EDIT_LIST}
            onClick={() => {
              navigate(`${AppRoutes.Lists}/${list!._id}/edit`);
            }}
          />

          {list?.appendTypes?.includes(AppendType.B2C_ONLINE_AUDIENCE) ? (
            <MoreActionsSelector icon={<FileDownloadOutlinedIcon />} label="Download actions">
              <MoreActionItem
                disabled={task !== null}
                icon={<DescriptionIcon />}
                label={ButtonDictionaryNames.DOWNLOAD_DOCUMENT}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadCSVData();
                }}
              />

              <MoreActionItem
                disabled={task !== null}
                icon={<AdsClickIcon />}
                label={ButtonDictionaryNames.DOWNLOAD_ONLINE_AUDIENCE}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadOnlineAudienceCSVData();
                }}
              />
            </MoreActionsSelector>
          ) : (
            <ActionButton
              disabled={task !== null}
              icon={<FileDownloadOutlinedIcon />}
              label={ButtonDictionaryNames.DOWNLOAD_DOCUMENT}
              onClick={downloadCSVData}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          background: '#F7F9FB',
          color: '#8793A6',
          display: 'flex',
          gap: 2,
          height: 40,
          justifyContent: 'space-between',
          pr: 2,
        }}
      >
        <Box>
          {list?.appendTypes && list.appendTypes.includes(AppendType.B2C_ONLINE_AUDIENCE) && (
            <Tabs
              onChange={(event, v) => {
                setActiveTab(v);
                navigate(`${AppRoutes.Lists}/${list._id}?tab=${v}`);
              }}
              value={activeTab}
            >
              <Tab label="Data" sx={{ color: globalStyles.navigation.textColor }} value="data" />
              <Tab
                label="Online Audience"
                sx={{ color: globalStyles.navigation.textColor }}
                value="online-audience"
              />
            </Tabs>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          <Typography>Data Sets: {buyingDate === 'all' ? list!.reportsCount! : 1}</Typography>
          <Typography>Records Count: {totalCount}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default ListDocumentsHeaderComponent;
