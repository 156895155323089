import { ListTaskStatus, ListTaskType } from '@a-type/enums';
import { IListTask } from '@a-type/interfaces';
import globalStyles from '@a-type/ui/styles/global.styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export interface ListTaskProgressProps {
  task: IListTask;
}

const getTastType = (task: IListTask) => {
  switch (task.type) {
    case ListTaskType.BUY:
      return 'Buy Records';
    case ListTaskType.BUY_MORE:
      return 'Buy More Records';
    case ListTaskType.UPLOAD_LIST:
      return 'Upload List';
    case ListTaskType.APPEND_DATA:
      return 'Append Data';
    default:
      return '';
  }
};

export const ListTaskProgress = ({ task }: ListTaskProgressProps) => {
  const [icon, setIcon] = useState<React.ReactNode>(null);
  const [color, setColor] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [tooltip, setTooltip] = useState<string>('');

  useEffect(() => {
    switch (task.status) {
      case ListTaskStatus.DONE:
        setIcon(null);
        setColor(globalStyles.mainColors.greenColor);
        setText('Done');
        setTooltip('');
        break;
      case ListTaskStatus.FAILED:
        setIcon(<ErrorOutlinedIcon fontSize="small" />);
        setColor(globalStyles.mainColors.redColor);
        setText('Failed');
        setTooltip(`${getTastType(task)} failed: ${task.error}`);
        break;
      case ListTaskStatus.IN_PROGRESS:
        setIcon(<RefreshIcon fontSize="small" />);
        setColor(globalStyles.mainColors.greenColor);
        setText(`In progress${task.progress > 0 ? ` (${task.progress.toFixed(2)}%)` : ''}`);
        setTooltip(getTastType(task));
        break;
      case ListTaskStatus.PENDING:
        setIcon(<AccessTimeIcon fontSize="small" />);
        setColor(globalStyles.mainColors.yellowColor);
        setText('Pending');
        setTooltip(getTastType(task));
        break;
      default:
        setIcon(null);
        setColor('');
        setText('');
        setTooltip('');
        break;
    }
  }, [task.status]);

  return (
    <Tooltip placement="top" title={tooltip}>
      <Box
        sx={{
          alignItems: 'center',
          color: color || globalStyles.mainColors.blackColor,
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        }}
      >
        {icon}
        <Typography
          sx={{
            color: color || globalStyles.mainColors.blackColor,
            fontSize: 13,
            fontWeight: 400,
          }}
        >
          {text}
        </Typography>
      </Box>
    </Tooltip>
  );
};
