import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { DataSourceFieldComponentType, DataSourceFieldDataType } from '@a-type/enums';
import { IDataSource, IDataSourceField, IFilterGroup } from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import { pageContentLoad, setInitLookup } from '@a-type/ui/stores/actions';
import { useGetFilterGroupsQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import DateRangeSelectFilter from './inputs/DateRangeSelectFilter.component';
import FreeTextSelectFilter from './inputs/FreeTextSelectFilter.component';
import MultiSelectFilter from './inputs/MultiSelectFilter.component';
import OptionSelectFilter from './inputs/OptionSelectFilter.component';
import RangeSelectFilter from './inputs/RangeSelectFilter.component';
import TextFilter from './inputs/TextFilter.component';

export type FilterGrouped = {
  fields: IDataSourceField[];
  group: IFilterGroup;
};

const CountsFiltersList = () => {
  const { data: filtersGroups } = useGetFilterGroupsQuery();
  const { count, initLookup } = useSelector((state) => state.count);
  const { currentDataSource, filtersComponents } = useSelector((state) => state.dataSource);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<FilterGrouped[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<null | string>(null);
  const [selectedField, setSelectedField] = useState<null | string>(null);

  const loadLookup = (ds: IDataSource, field: string, prefetchAllValues: boolean) => {
    dataSourcesService
      .lookup(ds, field, undefined, prefetchAllValues)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setInitLookup({
              key: field,
              lookup: [
                ...response.data.map((x) => ({
                  ...x,
                  label: x.label.toString(),
                })),
              ],
            }),
          );
        }
        dispatch(pageContentLoad(true));
      })
      .catch(() => {
        dispatch(pageContentLoad(true));
      });
  };

  useEffect(() => {
    if (!currentDataSource?.fields?.length || !filtersGroups?.length) return;

    const groupedFilters = filtersGroups.reduce((acc: FilterGrouped[], group) => {
      const groupFilters = currentDataSource.fields
        .filter((field: IDataSourceField) => field.isFilter)
        .filter((field: IDataSourceField) => field.filterGroup === group.code)
        .sort(
          (a: IDataSourceField, b: IDataSourceField) => Number(a.sortOrder) - Number(b.sortOrder),
        );

      if (groupFilters.length) {
        return [
          ...acc,
          {
            fields: groupFilters,
            group,
          },
        ];
      }

      return acc;
    }, []);

    setFilters(groupedFilters);
  }, [filtersGroups, currentDataSource]);

  useEffect(() => {
    if (!selectedField) return;
    if (!initLookup) return;
    if (!currentDataSource) return;

    // Initialize filter lookup
    if (!initLookup[selectedField]) {
      const field = currentDataSource.fields?.find(
        (f: IDataSourceField) => f.name === selectedField,
      );
      if (
        field &&
        field.isFilter &&
        field.componentType === DataSourceFieldComponentType.MULTI_SELECT
      ) {
        if (field.prefetchAllValues) {
          dispatch(pageContentLoad(false));
          loadLookup(currentDataSource, field.name, field.prefetchAllValues || false);
        } else {
          dispatch(
            setInitLookup({
              key: field.name,
              lookup: [],
            }),
          );
        }
      }
    }
  }, [selectedField]);

  return filters.length ? (
    <Box
      sx={{
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Typography
        sx={{
          color: globalStyles.mainColors.blackColor,
          fontWeight: 'bold',
        }}
      >
        Filters
      </Typography>
      {filters.map((group: FilterGrouped) => {
        return (
          <Accordion
            expanded={selectedGroup === group.group.code}
            key={group.group.code}
            onChange={() => setSelectedGroup(group.group.code)}
            sx={{
              '&.Mui-expanded': {
                margin: 0,
              },
              '&:before': {
                display: 'none',
              },
              border: `1px solid ${globalStyles.mainColors.veryLightGrayColor}`,
              borderBottom: `none`,
              borderRadius: 1,
              boxShadow: 'none',
            }}
          >
            <AccordionSummary
              onClick={(e) => {
                e.stopPropagation();

                if (selectedGroup === group.group.code) {
                  setSelectedGroup(null);
                } else {
                  setSelectedGroup(group.group.code);
                }
              }}
              sx={{
                '&.Mui-expanded': {
                  minHeight: 56,
                },
                backgroundColor: globalStyles.mainColors.whiteColor,
                borderBottom: `1px solid ${globalStyles.mainColors.veryLightGrayColor}`,
                minHeight: 56,
                padding: '0 16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    alignContent: 'center',
                    color: globalStyles.mainColors.blackColor,
                    fontWeight: 400,
                  }}
                >
                  {group.group.name}
                </Typography>

                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedGroup(selectedGroup === group.group.code ? null : group.group.code);
                  }}
                  sx={{
                    p: 0.25,
                  }}
                >
                  <ExpandMoreIcon
                    sx={{
                      rotate: selectedGroup === group.group.code ? '180deg' : '0deg',
                    }}
                  />
                </IconButton>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                borderBottom: `none`,
                p: 0,
              }}
            >
              {selectedGroup !== group.group.code ? null : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {group.fields.map((field: IDataSourceField) => {
                    const component = filtersComponents.find(
                      (c: IDataSourceFilterComponentDto) => c.type === field.componentType,
                    );
                    return (
                      <Accordion
                        expanded={selectedField === field.name}
                        key={field.name}
                        onChange={() => setSelectedField(field.name)}
                        sx={{
                          '&.Mui-expanded': {
                            margin: 0,
                          },
                          '&:before': {
                            display: 'none',
                          },
                          borderBottom: `none`,
                          boxShadow: 'none',
                        }}
                      >
                        <AccordionSummary
                          onClick={(e) => {
                            e.stopPropagation();

                            if (selectedField === field.name) {
                              setSelectedField(null);
                            } else {
                              setSelectedField(field.name);
                            }
                          }}
                          sx={{
                            '&.Mui-expanded': {
                              minHeight: 56,
                            },
                            backgroundColor: globalStyles.mainColors.distantWindChimeColor,
                            borderBottom: `1px solid ${globalStyles.mainColors.veryLightGrayColor}`,
                            minHeight: 56,
                            padding: '0 16px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 3,
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: 1,
                              }}
                            >
                              <Typography
                                component="span"
                                sx={{
                                  color: globalStyles.mainColors.blackColor,
                                  fontWeight: count?.filters[field.name]?.values?.length
                                    ? 600
                                    : 400,
                                }}
                              >
                                {field.displayName}
                                {field.units && field.units.length > 0 && `, ${field.units}`}
                              </Typography>
                              {field.price && field.price > 0 ? (
                                <Typography
                                  component="span"
                                  sx={{
                                    color: globalStyles.mainColors.blackColor,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                  }}
                                >
                                  (
                                  <Typography
                                    component="span"
                                    sx={{
                                      color: globalStyles.mainColors.greenColor,
                                      fontSize: 14,
                                      fontWeight: 400,
                                      lineHeight: '24px',
                                    }}
                                  >
                                    {`+${field.price} Credit `}
                                  </Typography>
                                  / Record)
                                </Typography>
                              ) : null}
                            </Box>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedField(selectedField === field.name ? null : field.name);
                              }}
                              sx={{
                                p: 0.25,
                              }}
                            >
                              <ExpandMoreIcon
                                sx={{
                                  rotate: selectedField === field.name ? '180deg' : '0deg',
                                }}
                              />
                            </IconButton>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            borderBottom: `1px solid ${globalStyles.mainColors.veryLightGrayColor}`,
                            p: 0,
                          }}
                        >
                          {selectedField !== field.name ? null : (
                            <>
                              {component?.type === DataSourceFieldComponentType.MULTI_SELECT && (
                                <MultiSelectFilter field={field} />
                              )}
                              {component?.type ===
                                DataSourceFieldComponentType.FREE_TEXT_SELECT && (
                                <FreeTextSelectFilter field={field} />
                              )}
                              {component?.type === DataSourceFieldComponentType.OPTION_SELECT && (
                                <OptionSelectFilter field={field} />
                              )}

                              {component?.type === DataSourceFieldComponentType.RANGE_SELECT &&
                                field.dataType === DataSourceFieldDataType.NUMBER && (
                                  <RangeSelectFilter field={field} />
                                )}

                              {component?.type === DataSourceFieldComponentType.TEXT &&
                                field.dataType === DataSourceFieldDataType.STRING && (
                                  <TextFilter field={field} />
                                )}

                              {component?.type === DataSourceFieldComponentType.RANGE_SELECT &&
                                field.dataType === DataSourceFieldDataType.DATE && (
                                  <DateRangeSelectFilter field={field} />
                                )}
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  ) : null;
};

export default CountsFiltersList;
