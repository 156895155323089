import { ILogin } from '@a-type/dtos';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { userLocalStorageWorker } from '@a-type/ui/services';
import { loginSuccess, snackbarErrorMessage } from '@a-type/ui/stores/actions';
import { useLoginMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { LoginForm } from './components/login-form/login-form.component';

export const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();

  const loginHandler = async (values: ILogin) => {
    const response = await login(values);

    if (response.data) {
      dispatch(loginSuccess(userLocalStorageWorker.read()));
      navigate(AppRoutes.HomePage);
    }

    if (response.error) {
      dispatch(snackbarErrorMessage(getError(response.error) ?? 'Error while logging in'));
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        my: 4,
        overflow: 'auto',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
              width: '100%',
            }}
          >
            <Typography component="div" gutterBottom sx={{ fontSize: '32px', fontWeight: '900' }}>
              Login
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography component="span" gutterBottom sx={{ fontSize: '16px', fontWidth: '400' }}>
                Don&apos;t have account?
              </Typography>
              <Typography
                component="span"
                gutterBottom
                onClick={() => {
                  navigate(AppRoutes.RegistrationPage);
                }}
                sx={{
                  color: globalStyles.mainColors.blueColor,
                  cursor: 'pointer',
                  fontSize: '16px',
                  fontWidth: '400',
                }}
              >
                Register
              </Typography>
            </Box>
          </Box>
          <LoginForm loading={isLoading} onSubmit={loginHandler} />
        </Box>
      </Container>
    </Box>
  );
};
