import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import globalStyles from '@a-type/ui/styles/global.styles';
import { East } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotLoginComponent = () => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="sm"
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <LoadingButton
            onClick={() => navigate(AppRoutes.RegistrationPage)}
            sx={{
              width: 440,
            }}
            variant="contained"
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.whiteColor,
                  fontSize: 16,
                  fontWeight: 700,
                  wordBreak: 'break-word',
                }}
              >
                Register
              </Typography>
              <East sx={{ fontSize: '20px' }} />
            </Box>
          </LoadingButton>
          <LoadingButton
            onClick={() => navigate(AppRoutes.LoginPage)}
            sx={{
              width: 440,
            }}
            variant="outlined"
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 16,
                  fontWeight: 700,
                  wordBreak: 'break-word',
                }}
              >
                Login
              </Typography>
              <East sx={{ fontSize: '20px' }} />
            </Box>
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

export default NotLoginComponent;
