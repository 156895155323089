import DataSourceDetailsFileBasicStep from '@a-type/ui/pages/admin/dataSourceDetails/components/fileComponents/DataSourceDetailsFileBasicStep.components';
import DataSourceDetailsFileMapping from '@a-type/ui/pages/admin/dataSourceDetails/components/fileComponents/DataSourceDetailsFileMappingStep.components';

import DataSourceDetailsPricingStep from './components/DataSourceDetailsPricingStep.components';
import { DATA_SOURCE_TABS_ENUM, DataSourceDetailsProps } from './utils/DataSourceDetailsProps';

const FileDataSourceDetailsComponent = (props: DataSourceDetailsProps) => {
  const { currentStep, setBasicInfoValid, setMappingValid, setPricingValid } = props;

  return (
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.BASIC_DETAILS && (
      <DataSourceDetailsFileBasicStep setBasicInfoValid={setBasicInfoValid} />
    )) ||
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.MAPPING && (
      <DataSourceDetailsFileMapping setMappingValid={setMappingValid} />
    )) ||
    (currentStep.keyName === DATA_SOURCE_TABS_ENUM.PRICING && (
      <DataSourceDetailsPricingStep setIsPricingValid={setPricingValid} />
    )) ||
    null
  );
};

export default FileDataSourceDetailsComponent;
