import { DataSourceUploadStrategy, TargetType } from '@a-type/enums';
import { IBaseLookup, IDataSourceSftp } from '@a-type/interfaces';
import { Dialog, ImageUpload, Switch } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import {
  pageContentLoad,
  setCurrentDataSource,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import BpCheckedIcon from '@mui/icons-material/CheckBox';
import BpIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';

import DataSourceDetailsFileSFTP from './components/DataSourceDetailsFileSFTP.component';
import DataSourceDetailsFileUpload from './components/DataSourceDetailsFileUpload.component';

const csvDelimetrsList = ['","', ',', ';'];

const Targets: IBaseLookup[] = [
  { label: 'Residential', value: TargetType.CUSTOMER },
  { label: 'Business', value: TargetType.BUSINESS },
];

export interface DataSourceDetailsFileBasicStepProps {
  setBasicInfoValid: (value: boolean) => void;
}

const DataSourceDetailsFileBasicStep = (props: DataSourceDetailsFileBasicStepProps) => {
  const dispatch = useDispatch();
  const { currentDataSource, dataSources, uploadedCsvFile } = useSelector(
    (state) => state.dataSource,
  );
  const { setBasicInfoValid } = props;
  const [existDataSourceNames, setExistDataSourceNames] = useState<string[]>([]);
  const [uploadFileDialog, setUploadFileDialog] = useState(false);
  const [sftpFileDialog, setSftpFileDialog] = useState(false);
  const [sftpData, setSftpData] = useState<IDataSourceSftp | null>(null);

  useEffect(() => {
    // get all data sources except selected data source
    const dataSourceNames = dataSources
      .filter((dataSource) => dataSource._id !== currentDataSource?._id)
      .map((dataSource) => dataSource.name);
    setExistDataSourceNames(dataSourceNames);
  }, [dataSources, currentDataSource]);

  useEffect(() => {
    setBasicInfoValid(
      !!currentDataSource &&
        currentDataSource.name !== '' &&
        !existDataSourceNames.some((x) => x === currentDataSource.name) &&
        currentDataSource.description !== '' &&
        (currentDataSource._id !== '0' || uploadedCsvFile !== null) &&
        (!currentDataSource.isAttomDataSource || currentDataSource.attomPrefixComponent !== ''),
    );
  }, [currentDataSource]);

  const checkIsDublicateDataSourceName = (value: string) => {
    const isExist = existDataSourceNames.filter((item: string) => item === value);
    if (isExist.length) dispatch(snackbarErrorMessage('Data Source with this name already exist'));
  };

  const setDataSourceValueHandler = (value: any | string, targetKeyName: string) => {
    if (!currentDataSource) return;

    if (targetKeyName === 'name') checkIsDublicateDataSourceName(value);

    dispatch(setCurrentDataSource({ ...currentDataSource, [targetKeyName]: value }));
  };

  const updateImage = async (url: string) => {
    if (!url || !currentDataSource) return;

    if (currentDataSource._id === '0') {
      dispatch(
        setCurrentDataSource({
          ...currentDataSource,
          imageUrl: url,
        }),
      );
      dispatch(snackbarSuccessMessage('Image uploaded successfully'));
    } else {
      dispatch(pageContentLoad(false));

      const responce = await dataSourcesService.updateImage(currentDataSource, url);

      if (responce.status === 200) {
        dispatch(setCurrentDataSource({ ...responce.data }));
        dispatch(snackbarSuccessMessage('Image uploaded successfully'));
      }

      dispatch(pageContentLoad(true));
    }
  };

  const updateSFTPHandler = async (sftp: any) => {
    if (!currentDataSource) return;

    dispatch(pageContentLoad(false));
    const response = await dataSourcesService.updateFile(
      { ...currentDataSource, sftpData: sftp },
      undefined,
      currentDataSource.updateStrategy,
    );

    if (response.status === 200) {
      setSftpFileDialog(false);
      dispatch(pageContentLoad(true));
      dispatch(snackbarSuccessMessage('Data Source Updated Successfully'));
      dispatch(setCurrentDataSource({ ...response.data }));
    }
  };

  const history = [...(currentDataSource?.history || [])];
  const theme: Theme = useTheme();
  const themeStylePalette = theme.palette;

  return (
    currentDataSource && (
      <>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              gap: 2,
              justifyContent: 'space-start',
              pt: 2,
            }}
          >
            <FormGroup>
              <Typography
                sx={{
                  color: themeStylePalette.text.primary,
                  fontSize: 20,
                  fontWeight: 700,
                  paddingBottom: 0,
                  pt: 0.5,
                }}
              >
                Title
                <Typography
                  component="span"
                  sx={{
                    display: 'block',
                    fontSize: 12,
                  }}
                >
                  The name of the data source must be unique and can&apos;t contain any blank
                  spaces.
                </Typography>
              </Typography>
              <TextField
                error={
                  existDataSourceNames.some((x) => x === currentDataSource.name) ||
                  !currentDataSource.name
                }
                onChange={(e) => {
                  const { value } = e.target;
                  const maximumNameCharacters = 30;
                  if (value.length > maximumNameCharacters) return;
                  setDataSourceValueHandler(e.target.value, 'name');
                }}
                placeholder="Enter the name of you list must be unique"
                sx={{
                  background: globalStyles.mainColors.whiteColor,
                  width: '100%',
                }}
                value={currentDataSource.name}
              />
            </FormGroup>

            <FormGroup
              sx={{
                flexGrow: 1,
              }}
            >
              <Typography
                sx={{
                  color: themeStylePalette.text.primary,
                  fontSize: 20,
                  fontWeight: 700,
                  paddingBottom: 0,
                  pb: 1,
                  pt: 0.5,
                }}
              >
                Description
                <Typography
                  component="span"
                  sx={{
                    display: 'block',
                    fontSize: 12,
                  }}
                >
                  A brief description of the data source. This will be displayed to users in the
                  data source list.
                </Typography>
              </Typography>
              <TextField
                error={
                  currentDataSource.description === '' || currentDataSource.description.length > 256
                }
                multiline
                onChange={(e) => {
                  const { value } = e.target;
                  const maxDescriptionCharacters = 256;
                  if (value.length > maxDescriptionCharacters) return;
                  setDataSourceValueHandler(e.target.value, 'description');
                }}
                placeholder="Description"
                rows={6}
                sx={{
                  background: globalStyles.mainColors.whiteColor,
                  width: '100%',
                }}
                value={currentDataSource.description}
              />
            </FormGroup>

            <FormGroup>
              <Typography
                sx={{
                  color: themeStylePalette.text.primary,
                  fontSize: 20,
                  fontWeight: 700,
                  paddingBottom: 0,
                  pb: 1,
                  pt: 0.5,
                }}
              >
                Upload Strategy
                <Typography
                  component="span"
                  sx={{
                    display: 'block',
                    fontSize: 12,
                  }}
                >
                  Select the upload strategy for this data source. For first time uploads, you can
                  use only the manually upload option. For regular updates, you can switch to SFTP
                  upload later.
                </Typography>
              </Typography>

              <RadioGroup
                name="upload-type"
                onChange={(e) => {
                  setDataSourceValueHandler(e.target.value, 'selectUploadStrategy');
                }}
                row
                value={currentDataSource.selectUploadStrategy}
              >
                <FormControlLabel
                  control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />}
                  label="Manually Upload"
                  sx={{ pr: 2 }}
                  value="manually_upload"
                />
                <FormControlLabel
                  control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />}
                  disabled={currentDataSource._id === '0'}
                  label="SFTP Upload"
                  value="sftp_upload"
                />
              </RadioGroup>
            </FormGroup>
            <Box
              sx={{
                alignItems: 'end',
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
              }}
            >
              <Button
                component="label"
                onClick={() => {
                  if (
                    currentDataSource.selectUploadStrategy === DataSourceUploadStrategy.SFTP_UPLOAD
                  ) {
                    setSftpData(currentDataSource.sftpData);
                    setSftpFileDialog(true);
                  } else setUploadFileDialog(true);
                }}
                sx={{
                  width: 225,
                }}
                variant="contained"
              >
                {currentDataSource.selectUploadStrategy === DataSourceUploadStrategy.SFTP_UPLOAD
                  ? 'SFTP upload'
                  : 'Select a file'}
              </Button>
              <Typography sx={{ pl: 2 }}>
                {!uploadedCsvFile ? '' : uploadedCsvFile?.name}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              justifyContent: 'flex-start',
              pt: 2,
            }}
          >
            <FormGroup>
              <Typography
                sx={{
                  color: themeStylePalette.text.primary,
                  fontSize: 20,
                  fontWeight: 700,
                  paddingBottom: 0,
                  pt: 0.5,
                }}
              >
                Audience Type
                <Typography
                  component="span"
                  sx={{
                    display: 'block',
                    fontSize: 12,
                  }}
                >
                  Data source audience type.
                </Typography>
              </Typography>
              <Select
                onChange={(e) => setDataSourceValueHandler(e.target.value, 'targetType')}
                sx={{
                  background: globalStyles.mainColors.whiteColor,
                  width: '100%',
                }}
                value={currentDataSource.targetType}
              >
                {Targets.map((target) => (
                  <MenuItem key={target.value} value={target.value}>
                    {target.label}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  color: themeStylePalette.text.primary,
                  fontSize: 20,
                  fontWeight: 700,
                  paddingBottom: 0,
                  pb: 1,
                  pt: 0.5,
                }}
              >
                Image
                <Typography
                  component="span"
                  sx={{
                    display: 'block',
                    fontSize: 12,
                  }}
                >
                  An image that will be displayed to users in the data source list.
                </Typography>
              </Typography>

              <Box
                sx={{
                  backgroundImage: `url(${currentDataSource.imageUrl})`,
                  backgroundSize: 'cover',
                  border: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
                  borderRadius: 2,
                  height: 300,
                  position: 'relative',
                  width: 400,
                }}
              >
                <ImageUpload
                  height={300}
                  key={`${currentDataSource._id}`}
                  name={`${currentDataSource._id}`}
                  sx={{
                    bottom: 4,
                    position: 'absolute',
                    right: 4,
                  }}
                  updateImage={updateImage}
                  width={400}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            pt: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              gap: 6,
              pt: 2,
            }}
          >
            <FormControl>
              <InputLabel id="separators-label">CSV Delimiter</InputLabel>
              <Select
                id="separators-select"
                label="CSV Delimiter"
                labelId="separators-label"
                onChange={(e: any) => {
                  setDataSourceValueHandler(e.target.value, 'csvDelimiter');
                }}
                sx={{
                  backgroundColor: globalStyles.mainColors.whiteColor,
                  minWidth: 250,
                }}
                value={currentDataSource.csvDelimiter}
                variant="outlined"
              >
                {csvDelimetrsList.map((output: any) => {
                  return (
                    <MenuItem key={output} value={output}>
                      {output}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
              }}
            >
              <Switch
                checked={currentDataSource.isAttomDataSource}
                label="Attom Data Source"
                onChange={() => {
                  setDataSourceValueHandler(
                    !currentDataSource.isAttomDataSource,
                    'isAttomDataSource',
                  );
                }}
              />

              <TextField
                disabled={!currentDataSource.isAttomDataSource}
                error={
                  currentDataSource.attomPrefixComponent === '' &&
                  currentDataSource.isAttomDataSource
                }
                label="Attom Prefix"
                onChange={(e) => {
                  setDataSourceValueHandler(e.target.value, 'attomPrefixComponent');
                }}
                sx={{
                  background: globalStyles.mainColors.whiteColor,
                  minWidth: 250,
                }}
                value={currentDataSource.attomPrefixComponent}
                variant="outlined"
              />
            </Box>
          </Box>

          {!history.length ? null : (
            <Box
              sx={{
                height: 175,
                overflowY: 'auto',
              }}
            >
              <Typography
                sx={{
                  color: themeStylePalette.text.primary,
                  fontSize: 20,
                  fontWeight: 700,
                }}
              >
                History
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: `${100 / 3}%` }}>
                  <Typography>FILE</Typography>
                </Box>
                <Box sx={{ width: `${100 / 6}%` }}>
                  <Typography>UPLOAD TYPE</Typography>
                </Box>
                <Box sx={{ width: `${100 / 6}%` }}>
                  <Typography>TIMESTAMP</Typography>
                </Box>
              </Box>

              {history
                .sort(
                  (a: any, b: any) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime(),
                )
                .map((historyItem: any) => {
                  return (
                    <Box
                      key={historyItem.endDate}
                      sx={{
                        display: 'flex',
                        height: 50,
                        justifyContent: 'space-between',
                        pt: 2,
                        width: '100%',
                      }}
                    >
                      <Box sx={{ width: `${100 / 3}%` }}>
                        <Typography sx={{ fontWeight: 500 }}>{historyItem.fileName}</Typography>
                      </Box>
                      <Box sx={{ width: `${100 / 6}%` }}>
                        <Typography>{historyItem.updateStrategy}</Typography>
                      </Box>
                      <Box sx={{ width: `${100 / 6}%` }}>
                        <Typography>
                          {moment(historyItem.endDate).format('MM/DD/yyyy/hh:mm')}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          )}
        </Box>

        <DataSourceDetailsFileUpload
          setDataSourceValueHandler={setDataSourceValueHandler}
          setUploadFileDialog={setUploadFileDialog}
          uploadFileDialog={uploadFileDialog}
        />

        {sftpData && (
          <Dialog
            cancelText="Cancel"
            okText="Save"
            onCancel={() => setSftpFileDialog(false)}
            onClose={() => setSftpFileDialog(false)}
            onOk={() => updateSFTPHandler(sftpData)}
            open={sftpFileDialog}
            title="Configure SFTP file upload"
          >
            <DataSourceDetailsFileSFTP setSftpData={setSftpData} sftpData={sftpData} />
          </Dialog>
        )}
      </>
    )
  );
};

export default DataSourceDetailsFileBasicStep;
