import { IDataSource } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const dataSourcesApi = apiMiddleware.injectEndpoints({
  endpoints: (build) => ({
    getDataSource: build.query<IDataSource, string>({
      providesTags: (result, error, id) => [{ id, type: 'DataSource' as const }],
      query: (id) => ({ url: `/data-sources/${id}` }),
    }),
    getDataSources: build.query<IDataSource[], void>({
      providesTags: [{ id: 'LIST', type: 'DataSource' as const }],
      query: () => ({ url: '/data-sources' }),
    }),
  }),
});

export const { useGetDataSourceQuery, useGetDataSourcesQuery } = dataSourcesApi;
