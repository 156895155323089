export enum AppendType {
  ADDRESS = "address",
  B2C_ONLINE_AUDIENCE = "b2c_online_audience",
  DEMOGRAPHIC = "demographic",
  EMAIL = "email",
  EMAIL_MULTIPLE = "email_multiple",
  FINANCIAL = "financial",
  FULL_DEMOGRAPHIC = "full_demographic",
  LIFESTYLE = "lifestyle",
  PHONE = "phone",
  PHONE_MOBILE = "phone_mobile",
  PHONE_MULTIPLE = "phone_multiple",
  POLITICAL = "political",
}
