import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import AppBodyComponent from './AppBody.component';
import themesBuilder from './styles/theme';

const App = () => {
  const lightTheme = createTheme(themesBuilder());
  return (
    <ThemeProvider theme={lightTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AppBodyComponent />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
