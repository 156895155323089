import { IStripeSubscription } from '@a-type/dtos';
import { Dialog, PaymentMethods, SubscriptionCard } from '@a-type/ui/components';
import { AccountPageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import {
  useGetActiveSubscriptionQuery,
  useGetSuscriptionsQuery,
  useUpdateSubscriptionMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const AccountUpdatePlanPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isLoading } = useGetSuscriptionsQuery();
  const { data: activeSubscription, isLoading: activeSubscriptionLoading } =
    useGetActiveSubscriptionQuery();
  const [subscriptions, setSubscriptions] = useState<IStripeSubscription[]>([]);
  const [updateSubscription, { isLoading: updateSubscriptionLoading }] =
    useUpdateSubscriptionMutation();
  const [selectedSubscription, setSelectedSubscription] = useState<IStripeSubscription | null>(
    null,
  );
  const [open, setOpen] = useState(false);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<null | string>(null);
  const [canUpdateSubscription, setCanUpdateSubscription] = useState(false);

  useEffect(() => {
    dispatch(
      pageContentLoad(!isLoading && !activeSubscriptionLoading && !updateSubscriptionLoading),
    );
  }, [isLoading, activeSubscriptionLoading, updateSubscriptionLoading]);

  useEffect(() => {
    setCanUpdateSubscription(!!paymentMethod && !!selectedSubscription && !isAddingCard);
  }, [isAddingCard, paymentMethod]);

  useEffect(() => {
    if (data) {
      setSubscriptions([...data]);
    }
  }, [data]);

  return (
    <AccountPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          height: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            justifyContent: 'space-between',
            pb: 2,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
            }}
          >
            <IconButton onClick={() => navigate(AppRoutes.AccountPlanBillingPage)}>
              <ChevronLeftOutlinedIcon />
            </IconButton>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              Change Subscription Plan
            </Typography>
          </Box>
        </Box>
        {!isLoading && subscriptions.length > 0 && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              gap: 5,
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {subscriptions
              ?.sort((a, b) => a.sortOrder - b.sortOrder)
              .map((subscription) => (
                <SubscriptionCard
                  key={subscription.id}
                  nextPaymentDate={
                    activeSubscription?.productId === subscription.productId
                      ? activeSubscription?.currentPeriodEnd
                      : undefined
                  }
                  notSelectedText={
                    (activeSubscription?.credits ?? 0) > subscription.credits
                      ? 'Downgrade'
                      : 'Upgrade'
                  }
                  onSelect={() => {
                    setSelectedSubscription(subscription);
                    setOpen(true);
                  }}
                  selected={activeSubscription?.productId === subscription.productId}
                  selectedText="Your Active Plan!"
                  subscription={subscription}
                />
              ))}
          </Box>
        )}
      </Box>

      {activeSubscription && selectedSubscription && (
        <Dialog
          cancelText="Cancel"
          disableCancel={isAddingCard}
          disableOk={!canUpdateSubscription}
          okText={
            activeSubscription.credits > selectedSubscription.credits ? 'Downgrade' : 'Upgrade'
          }
          onCancel={() => setOpen(false)}
          onClose={() => setOpen(false)}
          onOk={async () => {
            if (selectedSubscription && paymentMethod) {
              await updateSubscription({
                id: selectedSubscription.id,
                paymentMethodId: paymentMethod,
              });
              setOpen(false);
              navigate(AppRoutes.AccountPlanBillingPage);
            }
          }}
          open={open}
          size="sm"
          title="Change Subscription Plan"
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
            }}
          >
            {activeSubscription.credits > selectedSubscription.credits
              ? 'You are about to downgrade your subscription plan to '
              : 'You are about to upgrade your subscription plan to '}
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontWeight: 700,
              }}
            >
              {selectedSubscription.name}
            </Typography>
            ?
          </Typography>

          {activeSubscription.credits > 0 &&
            activeSubscription.credits > activeSubscription.usedCredits && (
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 16,
                  mt: 2,
                }}
              >
                You have&nbsp;
                <Typography
                  component="span"
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontWeight: 700,
                  }}
                >
                  {activeSubscription.credits - activeSubscription.usedCredits} unused credits
                </Typography>
                &nbsp;left on your current plan. These credits will be lost if you proceed.
              </Typography>
            )}

          <Divider
            sx={{
              backgroundColor: globalStyles.mainColors.gainsboroColor,
              my: 2,
            }}
          />

          <PaymentMethods
            onAddPaymentMethod={(isAdding) => setIsAddingCard(isAdding)}
            onSelectPaymentMethod={(pm) => {
              setPaymentMethod(pm.id);
            }}
          />
        </Dialog>
      )}
    </AccountPageLayout>
  );
};
