import { SxProps, Theme } from '@mui/material';

export const styles: SxProps<Theme> = {
  '& .card-cvc-wrapper': {
    '& .card-cvc': {
      padding: '20px 45px 20px 66px !important',
    },
    '& .info-card-icon': {
      '& svg': {
        '& path': {
          fill: 'rgba(0, 0, 0, 0.4)',
        },
      },
      alignItems: 'center',
      display: 'flex',
      height: '16px',
      justifyContent: 'center',
      position: 'absolute',
      right: '16px',
      top: '30px',
      transform: 'translate(0, -50%)',

      width: '16px',
    },
    '& .pure-card-icon': {
      '& svg': {
        '& path': {
          fill: 'rgba(0, 0, 0, 0.4)',
        },
        height: '100%',

        width: '100%',
      },
      height: '24px',
      left: '16px',
      position: 'absolute',
      top: '22px',
      transform: 'translate(0, -50%)',

      width: '24px',
    },

    flexBasis: '57%',

    height: 'fit-content',

    position: 'relative',
  },
  '& .card-exp-wrapper': {
    flexBasis: '37%',
    height: 'fit-content',
    position: 'relative',
  },
  '& .card-number-wrapper': {
    '& .type-card-icon': {
      alignItems: 'center',
      display: 'flex',
      height: '39px',
      justifyContent: 'center',
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translate(0, -50%)',
      width: '39px',
    },
    height: 'fit-content',
    position: 'relative',

    width: '100%',
  },
  '& .StripeElement': {
    '& .__PrivateStripeElement': {
      alignItems: 'center',
      display: 'flex !important',
      fontSize: '17px',
      fontWeight: '400',
      height: '100%',
      width: '100%',
    },
    background: '#FFFFFF',
    border: '1px solid #B6B6B6',
    borderRadius: '5px',
    boxSizing: 'border-box',
    height: '56px',
    padding: '20px 16px 20px !important',

    width: '100%',
  },

  '& .StripeElement--invalid': {
    borderColor: 'red',
  },

  display: 'flex',

  flexWrap: 'wrap',

  gap: 2,

  justifyContent: 'space-between',
};
