import { useDispatch, useSelector } from '@a-type/ui/hooks';
import AppNavigationSwitchRouterComponent from '@a-type/ui/router/AppNavigationSwitchRouter.component';
import SetupInterceptors from '@a-type/ui/services/axios-interceptors';
import { logout, pageContentLoad } from '@a-type/ui/stores/actions';
import { Box } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { BuyMoreCreditsDialog, SubscriptionActivatedDialog } from './components';
import { FooterComponent, HeaderComponent } from './layout';

const AppBodyComponent = () => {
  const dispatch = useDispatch();
  const { isExtraCreditPopupActive, isSubscriptionActivatedPopupActive } = useSelector(
    (state) => state.appMain,
  );

  const logoutHandler = () => {
    dispatch(logout());
    dispatch(pageContentLoad(true));
  };

  const refreshTokenHandler = async () => {
    await SetupInterceptors({ logoutHandler });
  };

  refreshTokenHandler();

  const theme: Theme = useTheme();
  const themeStylePalette = theme.palette;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <HeaderComponent />
        <Box
          sx={{
            background: themeStylePalette.background.default,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: 'fit-content',
            overflow: 'hidden',
            width: '100vw',
          }}
        >
          <AppNavigationSwitchRouterComponent />
        </Box>
        <FooterComponent />
      </Box>

      {isExtraCreditPopupActive && <BuyMoreCreditsDialog />}

      {isSubscriptionActivatedPopupActive && <SubscriptionActivatedDialog />}
    </>
  );
};

export default AppBodyComponent;
