import { DataSourceLookupStatus } from '@a-type/enums';
import { IDataSourceLookup } from '@a-type/interfaces';
import { useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Info as InfoIcon } from '@mui/icons-material';
import AppsIcon from '@mui/icons-material/Apps';
import BuildIcon from '@mui/icons-material/Build';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import { Box, Button, Chip, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

import DataSourceDetailsBuildLookupsDialog from './DataSourceDetailsBuildLookupsDialog.component';
import DataSourceDetailsReindexDialog from './DataSourceDetailsReindexDialog.component';

const DataSourceDetailsTitleIndexStatus = () => {
  const { currentDataSource } = useSelector((state) => state.dataSource);
  const [lookups, setLookups] = useState<IDataSourceLookup[]>([]);
  const [reindexDialog, setReindexDialog] = useState(false);
  const [buildLookupsDialog, setBuildLookupsDialog] = useState(false);
  const [indexStatus, setIndexStatus] = useState<{
    indexes: string[];
    isRequiedBuildLookup: boolean;
    isRequiredReindex: boolean;
    operations: {
      field: string;
      id: string;
      progress: {
        done: number;
        total: number;
      };
    }[];
    status: string;
  }>();

  useEffect(() => {
    if (currentDataSource && currentDataSource._id !== '0' && !currentDataSource.isApi) {
      setLookups(currentDataSource.lookups || []);

      dataSourcesService.indexStatus(currentDataSource._id!).then((res) => {
        if (res.status === 200) {
          setIndexStatus(res.data);
        }
      });
    }
  }, []);

  let indexColor = 'green';
  switch (indexStatus?.status) {
    case 'IN_PROGRESS':
      indexColor = 'orange';
      break;
    case 'ERROR':
      indexColor = 'red';
      break;
    default:
      indexColor = 'green';
  }

  return (
    <>
      {!indexStatus ? null : (
        <>
          <Box
            sx={{
              alignItems: 'center',
              color: globalStyles.mainColors.sootyColor,
              display: 'flex',
            }}
          >
            Indexes:
            <Tooltip
              sx={{ alignItems: 'center', display: 'flex', p: 0 }}
              title={[
                <p
                  key="index_msg"
                  style={{ marginBottom: '3px', marginTop: 0, whiteSpace: 'pre-line' }}
                >
                  Regular indexes:
                </p>,
                ...(indexStatus.indexes || []).map((index: string) => {
                  const progress = indexStatus.operations.find(
                    (op) => op.field === index,
                  )?.progress;

                  const result = progress
                    ? `${+((progress.done / progress.total) * 100).toFixed(2)} %`
                    : 'Steady';

                  return (
                    <p key={`${index}`} style={{ margin: 0, padding: 0, whiteSpace: 'pre-line' }}>
                      {index}: {result}
                    </p>
                  );
                }),
              ]}
            >
              <IconButton sx={{ height: 40, width: 40 }}>
                <AppsIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {!indexStatus.isRequiedBuildLookup ||
          indexStatus.status === 'IN_PROGRESS' ||
          lookups.some((lookup) => lookup.status !== DataSourceLookupStatus.COMPLETED) ||
          indexStatus.operations.length ? null : (
            <Box sx={{ display: 'flex' }}>
              <Tooltip
                color="error"
                sx={{ color: globalStyles.mainColors.redColor }}
                title={[
                  <p key="index_msg">
                    Missing lookups. Build lookups to update the multi select fields with the latest
                    values.
                  </p>,
                ]}
              >
                <IconButton sx={{ height: 40, width: 40 }}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
              <Button onClick={() => setBuildLookupsDialog(true)} startIcon={<BuildIcon />}>
                Build Lookups
              </Button>
            </Box>
          )}

          <Box
            sx={{
              alignItems: 'center',
              color: globalStyles.mainColors.sootyColor,
              display: 'flex',
            }}
          >
            Search Index:
            <Chip
              label={indexStatus.status}
              size="small"
              sx={{
                backgroundColor: indexColor,
                color: 'white',
                ml: 1,
              }}
            />
          </Box>

          {!indexStatus.isRequiredReindex ||
          indexStatus.status === 'IN_PROGRESS' ||
          lookups.some((lookup) => lookup.status !== DataSourceLookupStatus.COMPLETED) ||
          indexStatus.operations.length ? null : (
            <Box sx={{ display: 'flex' }}>
              <Tooltip
                color="error"
                sx={{ color: globalStyles.mainColors.redColor }}
                title={[
                  <p key="index_msg">
                    Index and field mappings are out of sync. Reindexing is required to update the
                    index with the latest field mappings.
                  </p>,
                ]}
              >
                <IconButton sx={{ height: 40, width: 40 }}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
              <Button onClick={() => setReindexDialog(true)} startIcon={<FindReplaceIcon />}>
                Reindex
              </Button>
            </Box>
          )}
        </>
      )}

      <DataSourceDetailsReindexDialog
        reindexDialog={reindexDialog}
        setReindexDialog={setReindexDialog}
      />

      <DataSourceDetailsBuildLookupsDialog
        buildLookupsDialog={buildLookupsDialog}
        setBuildLookupsDialog={setBuildLookupsDialog}
      />
    </>
  );
};

export default DataSourceDetailsTitleIndexStatus;
