import { IStripePaymentMethod } from '@a-type/dtos';
import { useDispatch } from '@a-type/ui/hooks';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetCardsQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { AddPaymentMethod } from './AddPaymentMethod.component';
import { PaymentMethodSelector } from './PaymentMethodSelector.component';

interface PaymentMethodsProps {
  onAddPaymentMethod?: (isAdding: boolean) => void;
  onSelectPaymentMethod: (paymentMethod: IStripePaymentMethod) => void;
}

export const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  onAddPaymentMethod,
  onSelectPaymentMethod,
}: PaymentMethodsProps) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useGetCardsQuery();
  const [paymentMethods, setPaymentMethods] = useState<IStripePaymentMethod[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<null | string>(null);
  const [showAddCard, setShowAddCard] = useState(false);

  useEffect(() => {
    if (data) {
      setPaymentMethods([...data]);

      // if there is no payment methods, show the add card form
      if (data.length === 0) {
        setShowAddCard(true);
      } else {
        const defaultPaymentMethod =
          (data.find((pm) => pm.isDefault) ?? data.length > 0) ? data[0] : null;
        if (defaultPaymentMethod) {
          setSelectedPaymentMethod(defaultPaymentMethod.id);
          onSelectPaymentMethod(defaultPaymentMethod);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (onAddPaymentMethod) {
      onAddPaymentMethod(showAddCard);
    }
  }, [showAddCard]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {!showAddCard && (
        <>
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 17,
              fontWeight: 700,
            }}
          >
            Select payment method
          </Typography>

          <PaymentMethodSelector
            onSelectPaymentMethod={(paymentMethod) => {
              setSelectedPaymentMethod(paymentMethod.id);
              onSelectPaymentMethod(paymentMethod);
            }}
            paymentMethods={paymentMethods}
            selectedPaymentMethodId={selectedPaymentMethod ?? ''}
          />

          {paymentMethods.length < 10 && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                color="primary"
                onClick={() => setShowAddCard(true)}
                size="small"
                startIcon={<AddIcon fontSize="small" />}
                variant="text"
              >
                Add new card
              </Button>
            </Box>
          )}
        </>
      )}

      {showAddCard && (
        <>
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 17,
              fontWeight: 700,
            }}
          >
            Add payment method
          </Typography>

          <AddPaymentMethod
            onCancel={() => setShowAddCard(false)}
            onSuccess={(id) => {
              setSelectedPaymentMethod(id);
              setShowAddCard(false);
            }}
          />
        </>
      )}
    </Box>
  );
};
