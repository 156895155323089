import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, SxProps, TextField, Theme } from '@mui/material';
import { AnimationEvent, Dispatch, SetStateAction, useState } from 'react';
import { Control, Controller, Validate } from 'react-hook-form';

interface IFormPasswordInputProps {
  control: Control<any>;
  disablePasswordStrength?: boolean;
  label: string;
  name: string;
  rules?: Record<string, Validate<string, any>>;
  sx?: SxProps<Theme>;
}

export const FormPasswordInput: React.FC<IFormPasswordInputProps> = ({
  control,
  disablePasswordStrength = false,
  label,
  name,
  rules = {},
  sx,
}: IFormPasswordInputProps) => {
  const [hasValue, setHasValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // For re-usability, I made this a function that accepts a useState set function
  // and returns a handler for each input to use, since you have at least two
  // TextFields to deal with.
  const makeAnimationStartHandler =
    (stateSetter: Dispatch<SetStateAction<boolean>>) =>
    (e: AnimationEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const target = e.target as HTMLInputElement | HTMLTextAreaElement;
      const autofilled = !!target?.matches('*:-webkit-autofill');
      if (e.animationName === 'mui-auto-fill') {
        stateSetter(autofilled);
      }

      if (e.animationName === 'mui-auto-fill-cancel') {
        stateSetter(autofilled);
      }
    };

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          error={!!error}
          fullWidth
          helperText={error?.message}
          InputLabelProps={{
            shrink: hasValue || !!value,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            inputProps: { maxLength: 50, onAnimationStart: makeAnimationStartHandler(setHasValue) },
          }}
          label={label}
          onChange={onChange}
          required
          sx={sx}
          type={showPassword ? 'text' : 'password'}
          value={value}
        />
      )}
      rules={{
        validate: {
          required: (value) => !!value || 'Password is required',
          ...rules,

          // Password strength validation
          ...(disablePasswordStrength
            ? {}
            : {
                length: (value) =>
                  value.length >= 8 || 'Password must be at least 8 characters long',
                minLowercase: (value) =>
                  /[a-z]/.test(value) || 'Password must contain at least one lowercase letter',
                minNumbers: (value) =>
                  /\d/.test(value) || 'Password must contain at least one number',
                minSymbols: (value) =>
                  /[^a-zA-Z0-9]/.test(value) || 'Password must contain at least one symbol',
                minUppercase: (value) =>
                  /[A-Z]/.test(value) || 'Password must contain at least one uppercase letter',
              }),
        },
      }}
    />
  );
};
