import { ISettings } from '@a-type/interfaces';
import { useDispatch } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useAppendTypesDictionaryQuery,
  useDataSourceDictionaryQuery,
  useGetSettingsQuery,
  useGetViewsQuery,
  useUpdateSettingsMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { EmailsInput } from './components/emails-input.component';

export const SettingsPage = () => {
  const dispatch = useDispatch();
  const { data: datasources } = useDataSourceDictionaryQuery();
  const { data: appends } = useAppendTypesDictionaryQuery();
  const { data: views } = useGetViewsQuery();
  const { data, isLoading } = useGetSettingsQuery();
  const [updatedSettings, { isLoading: isUpdating }] = useUpdateSettingsMutation();
  const [settings, setSettings] = useState<ISettings>({
    appends: [],
    databases: [],
    userSignupNotificationEmails: [],
    views: [],
  });

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading && !isUpdating));
  }, [isLoading, isUpdating]);

  useEffect(() => {
    if (data) {
      setSettings({
        appends: data.appends ?? [],
        databases: data.databases ?? [],
        userSignupNotificationEmails: data.userSignupNotificationEmails,
        views: data.views ?? [],
      });
    }
  }, [data]);

  const handleUpdateSettings = async () => {
    const result = await updatedSettings(settings);

    if (result.data) {
      dispatch(snackbarSuccessMessage('User access settings updated successfully'));
    }

    if (result.error) {
      dispatch(
        snackbarErrorMessage(getError(result.error) ?? 'Error while updating access settings'),
      );
    }
  };

  const updateDatabaseAccess = (databaseId: string, access: boolean) => {
    const items = access
      ? [...settings.databases, databaseId]
      : settings.databases.filter((id) => id !== databaseId);

    setSettings((prev) => ({ ...prev, databases: items }));
  };

  const updateAppendAccess = (appendId: string, access: boolean) => {
    const items = access
      ? [...settings.appends, appendId]
      : settings.appends.filter((id) => id !== appendId);

    setSettings((prev) => ({ ...prev, appends: items }));
  };

  const updateViewAccess = (viewId: string, access: boolean) => {
    const items = access
      ? [...settings.views, viewId]
      : settings.views.filter((id) => id !== viewId);

    setSettings((prev) => ({ ...prev, views: items }));
  };

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            gap: 1,
            pb: 1,
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 900,
            }}
          >
            Settings
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            gap: 2,
            mt: 3,
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            User Signup Notification Emails
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                display: 'block',
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              After a user signs up and confirms their email, these emails will be notified.
            </Typography>
          </Typography>
          <EmailsInput
            emails={settings?.userSignupNotificationEmails || []}
            label="Admin Emails"
            onChange={(emails) => {
              setSettings((prev) => ({ ...prev, userSignupNotificationEmails: emails }));
            }}
          />
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            New Users Access Settings
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                display: 'block',
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              After approval, users will have access to these databases and appends.
            </Typography>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              gap: 4,
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                minWidth: 250,
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 18,
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                Databases
              </Typography>
              {datasources?.map((ds) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings.databases.includes(ds.key)}
                      onChange={(e) => updateDatabaseAccess(ds.key, e.target.checked)}
                    />
                  }
                  key={ds.key}
                  label={ds.value}
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                />
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                minWidth: 250,
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 18,
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                Append Data
              </Typography>
              {appends?.map((append) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings.appends.includes(append.key)}
                      onChange={(e) => updateAppendAccess(append.key, e.target.checked)}
                    />
                  }
                  key={append.key}
                  label={append.value}
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                />
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                minWidth: 250,
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 18,
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                Views
              </Typography>
              {views?.map((view) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings.views.includes(view._id)}
                      onChange={(e) => updateViewAccess(view._id, e.target.checked)}
                    />
                  }
                  key={view._id}
                  label={view.name}
                  sx={{
                    color: globalStyles.mainColors.sootyColor,
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                />
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              mb: 2,
            }}
          >
            <Button
              color="primary"
              disabled={isUpdating}
              onClick={handleUpdateSettings}
              sx={{
                minWidth: 160,
              }}
              variant="contained"
            >
              Update
            </Button>
          </Box>
        </Box>
      </Box>
    </AdminPageLayout>
  );
};
