import { AdminPageLayout } from '@a-type/ui/layout';
import DataSourceDetailsComponent from '@a-type/ui/pages/admin/dataSourceDetails/DataSourceDetails.component';

const AdminDataSourceDetailsPage = () => {
  return (
    <AdminPageLayout>
      <DataSourceDetailsComponent />
    </AdminPageLayout>
  );
};

export default AdminDataSourceDetailsPage;
