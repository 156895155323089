import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { DataSourceFieldComponentType } from '@a-type/enums';
import { ICombinedSearchFilter } from '@a-type/interfaces';
import { Dialog, Switch } from '@a-type/ui/components';
import { useComponentsQuery, useGetFilterGroupsQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';
import { ArrowDropUp, Delete, DragIndicator, Info, Loupe } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';

import { CombinedSearchFilterOptions } from './combined-search-filter-options.component';

interface ICombinedSearchFilterProps {
  components: { [key: string]: string };
  dataSources: { [key: string]: string };
  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  dragRef: (element: HTMLElement | null) => void;
  filter: ICombinedSearchFilter;
  groups: { [key: string]: string };
  isDragDisabled: boolean;
  isDragging: boolean;
  onFilterChange: (key: string, value: any) => void;
  onFilterDelete: () => void;
  selectedFilter: null | string;
  setSelectedFilter: (value: null | string) => void;
}

export const CombinedSearchFilter: React.FC<ICombinedSearchFilterProps> = ({
  components,
  dataSources,
  draggableProps,
  dragHandleProps,
  dragRef,
  filter,
  groups,
  isDragDisabled,
  isDragging,
  onFilterChange,
  onFilterDelete,
  selectedFilter,
  setSelectedFilter,
}: ICombinedSearchFilterProps) => {
  const { data: filtersComponentsData } = useComponentsQuery();
  const { data: filtersGroups } = useGetFilterGroupsQuery();
  const [filtersComponents, setFiltersComponents] = useState<IDataSourceFilterComponentDto[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    if (!filter || !filtersComponentsData) {
      return;
    }

    setFiltersComponents(
      filtersComponentsData.filter((item: IDataSourceFilterComponentDto) =>
        item.dataTypes?.includes(filter.dataType),
      ),
    );
  }, [filter, filtersComponentsData]);

  const getFieldBackgroundColor = () => {
    if (selectedFilter === filter._id) {
      return globalStyles.mainColors.whiteColor;
    }

    return globalStyles.mainColors.whiteColor;
  };

  const deleteFilter = () => {
    setShowDeleteDialog(false);
    setSelectedFilter(null);
    onFilterDelete();
  };

  return (
    <>
      <Accordion
        ref={dragRef}
        {...draggableProps}
        expanded={selectedFilter === filter._id}
        key={filter._id}
        onChange={() => {
          if (isDragging) return;
          setSelectedFilter(filter._id);
        }}
        sx={{
          background: getFieldBackgroundColor(),
          border: `0.5px solid ${globalStyles.mainColors.veryLightGrayColor}`,
        }}
      >
        <AccordionSummary
          onClick={(e) => {
            if (isDragging) return;
            e.stopPropagation();

            if (selectedFilter === filter._id) {
              setSelectedFilter(null);
            } else {
              setSelectedFilter(filter._id);
            }
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                justifyContent: 'flex-start',
              }}
            >
              <Button
                {...dragHandleProps}
                disabled={isDragDisabled}
                startIcon={
                  <DragIndicator
                    sx={{
                      color: globalStyles.mainColors.grayColor,
                      fontSize: 20,
                    }}
                  />
                }
                sx={{
                  '.MuiButton-startIcon': {
                    marginRight: 0,
                  },
                  borderRadius: 2,
                  cursor: 'grab',
                  height: 30,
                  minWidth: 30,
                  px: 1,
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontSize: 15,
                    mt: 0.25,
                  }}
                >
                  {filter.sortOrder}
                </Typography>
              </Button>

              <Chip
                color="primary"
                label={dataSources[filter.dataSourceId]}
                size="small"
                variant="outlined"
              />

              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.blackColor,
                  fontSize: 16,
                  fontWeight: 700,
                }}
              >
                {filter.displayName}
              </Typography>

              <Tooltip
                title={[
                  <p key={filter._id} style={{ fontWeight: 400 }}>
                    Field Name:
                    <br /> <b>{filter.name}</b>
                    <br /> Data Type:
                    <br /> <b>{filter.dataType}</b>
                  </p>,
                ]}
              >
                <IconButton sx={{ height: 40, width: 40 }}>
                  <Info />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                justifyContent: 'flex-end',
              }}
            >
              {filter.filterGroup && (
                <Chip color="primary" label={groups[filter.filterGroup]} size="small" />
              )}

              {filter.componentType && (
                <Chip color="warning" label={components[filter.componentType]} size="small" />
              )}

              {filter.isGroupedBy && (
                <Tooltip
                  sx={{ alignItems: 'center', display: 'flex', p: 0 }}
                  title="This field using for narrow further search results."
                >
                  <IconButton sx={{ height: 30, width: 30 }}>
                    <Loupe
                      sx={{
                        color: globalStyles.mainColors.darkSpellColor,
                        fontSize: 20,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Delete this filter">
                <IconButton
                  onClick={() => {
                    setShowDeleteDialog(true);
                  }}
                  sx={{ color: globalStyles.mainColors.redColor, height: 40, width: 40 }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>

              <Box
                onClick={(e) => {
                  if (isDragging) return;
                  e.stopPropagation();

                  if (selectedFilter === filter._id) {
                    setSelectedFilter(null);
                  } else {
                    setSelectedFilter(filter._id);
                  }
                }}
                sx={{ alignItems: 'center', display: 'flex' }}
              >
                {selectedFilter === filter._id ? <ArrowDropUp /> : <ArrowDropDownIcon />}
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        {selectedFilter === filter._id && (
          <AccordionDetails>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                p: 2,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{ display: 'flex', flexBasis: '50%', flexDirection: 'column', gap: 2, pt: 1 }}
                >
                  <Switch
                    checked={filter.isGroupedBy}
                    label="Use for Narrowing"
                    onChange={() => {
                      onFilterChange('isGroupedBy', !filter.isGroupedBy);
                    }}
                  />

                  {(filtersGroups || []).length > 0 && (
                    <FormControl>
                      <InputLabel id={`filter-group-${filter.name}-label`} size="small">
                        Filter Group
                      </InputLabel>
                      <Select
                        error={!filter.filterGroup}
                        id={`filter-group-${filter.name}`}
                        label="Filter Group"
                        labelId={`filter-group-${filter.name}-label`}
                        onChange={(e: any) => {
                          const { value } = e.target;
                          onFilterChange('filterGroup', value);
                        }}
                        placeholder="Select Filter Group"
                        size="small"
                        sx={{ background: globalStyles.mainColors.whiteColor }}
                        value={filter.filterGroup || ''}
                      >
                        {(filtersGroups || []).map((item: any) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {(filtersComponents || []).length > 0 && (
                    <FormControl
                      sx={{
                        width: '100%',
                      }}
                    >
                      <InputLabel id={`filter-component-${filter.name}-label`} size="small">
                        Filter Component
                      </InputLabel>
                      <Select
                        disabled={(filtersComponents || []).length === 1}
                        error={!filter.componentType}
                        id={`filter-component-${filter.name}`}
                        label="Filter Component"
                        labelId={`filter-component-${filter.name}-label`}
                        onChange={(e: any) => {
                          const { value } = e.target;
                          onFilterChange('componentType', value);
                        }}
                        placeholder="Select Filter Component"
                        size="small"
                        sx={{ background: globalStyles.mainColors.whiteColor }}
                        value={filter.componentType || ''}
                      >
                        {(filtersComponents || []).map((item: any) => (
                          <MenuItem key={item.type} value={item.type}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Box>

                {(filter.componentType === DataSourceFieldComponentType.MULTI_SELECT ||
                  filter.componentType === DataSourceFieldComponentType.OPTION_SELECT) && (
                  <Box sx={{ flexBasis: '50%' }}>
                    <CombinedSearchFilterOptions filter={filter} onFilterChange={onFilterChange} />
                  </Box>
                )}
              </Box>
            </Box>
          </AccordionDetails>
        )}
      </Accordion>

      <Dialog
        cancelText="Cancel"
        okText="Yes, Delete"
        onCancel={() => setShowDeleteDialog(false)}
        onClose={() => setShowDeleteDialog(false)}
        onOk={deleteFilter}
        open={showDeleteDialog}
        title="Delete Data Field"
      >
        <Typography sx={{ fontSize: 20 }}>
          Are you sure you want to delete &quot;{filter.displayName}&quot; filter?
        </Typography>
      </Dialog>
    </>
  );
};
