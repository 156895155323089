import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { countsService } from '@a-type/ui/services';
import {
  pageContentLoad,
  setCount,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useGetUserDataSourcesQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CountsHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { count } = useSelector((state) => state.count);
  const { data: dataSources, isFetching: isLoading } = useGetUserDataSourcesQuery();
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (count?.name && /^[a-zA-Z0-9_-\s]*$/.test(count.name)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [count]);

  useEffect(() => {
    if (count && dataSources) {
      setHasAccess(dataSources.some((source) => source._id === count.dataSourceId));
    }
  }, [count, dataSources]);

  async function saveCounts() {
    if (!count) return;

    try {
      dispatch(pageContentLoad(false));
      if (!count._id) {
        const result = await countsService.create(count);
        if (result.status === 200) {
          dispatch(snackbarSuccessMessage('Count created successfully'));
          dispatch(setCount(result.data));
          navigate(`${AppRoutes.CountsUpdate}/${result.data._id}`);
        }
      } else {
        const result = await countsService.update(count);
        if (result.status === 200) {
          dispatch(snackbarSuccessMessage('Count saved successfully'));
          dispatch(setCount(result.data));
        }
      }
    } catch (error) {
      dispatch(snackbarErrorMessage('Error saving count'));
    } finally {
      dispatch(pageContentLoad(true));
    }
  }

  async function buyList() {
    if (!count) return;

    dispatch(pageContentLoad(false));

    if (!count._id) {
      try {
        const result = await countsService.create(count);
        if (result.status === 200) {
          dispatch(setCount(result.data));
          navigate(`${AppRoutes.CountsList}/${result.data._id}`);
        }
      } catch (error) {
        dispatch(snackbarErrorMessage('Error saving count'));
      }
    } else {
      const result = await countsService.update(count);
      if (result.status === 200) {
        dispatch(setCount(result.data));
      }

      navigate(`${AppRoutes.CountsList}/${count._id}`);
    }
  }

  return (
    <>
      {hasAccess === false && !isLoading && (
        <Alert
          severity="warning"
          sx={{
            mb: 2,
            width: '100%',
          }}
        >
          <AlertTitle>You don&apos;t have access to this database!</AlertTitle>
          You don&apos;t have access to this database anymore. Please contact your administrator to
          get access.
        </Alert>
      )}

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          px: 1,
          width: '100%',
        }}
      >
        <Typography
          component="div"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: '24px',
            fontWeight: 700,
          }}
        >
          Build List
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button
            disabled={!isValid || !hasAccess}
            onClick={() => saveCounts()}
            sx={{
              minWidth: '160px',
              py: 1.25,
            }}
            variant="outlined"
          >
            {ButtonDictionaryNames.SAVE_COUNT}
          </Button>
          <Button
            disabled={!isValid || !hasAccess}
            onClick={() => buyList()}
            sx={{
              minWidth: '160px',
              py: 1.25,
            }}
            variant="contained"
          >
            {ButtonDictionaryNames.BUY_LIST}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CountsHeader;
