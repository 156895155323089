import { IUpdateAppendDataSortOrderDto } from '@a-type/dtos';
import { IVersiumAppend } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const appendDataApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    getAppendData: builder.query<IVersiumAppend[], void>({
      providesTags: [{ id: 'LIST', type: 'AppendData' as const }],
      query: () => ({ url: '/append-data' }),
    }),
    getAppendDataById: builder.query<IVersiumAppend, string>({
      providesTags: (result) => [{ id: result?._id, type: 'AppendData' as const }],
      query: (id) => ({ url: `/append-data/${id}` }),
    }),
    updateAppendData: builder.mutation<IVersiumAppend, Partial<IVersiumAppend>>({
      invalidatesTags: (result, error, { _id }) => [
        { id: _id, type: 'AppendData' as const },
        { id: 'LIST', type: 'AppendData' as const },
      ],
      query: (data) => ({ data, method: 'POST', url: `/append-data/${data._id}` }),
    }),
    updateAppendDataActive: builder.mutation<IVersiumAppend, { id: string; isActive: boolean }>({
      invalidatesTags: [{ id: 'LIST', type: 'AppendData' as const }],
      query: ({ id, isActive }) => ({
        data: { isActive },
        method: 'POST',
        url: `/append-data/${id}/active`,
      }),
    }),
    updateAppendDataImage: builder.mutation<IVersiumAppend, { id: string; image: string }>({
      invalidatesTags: (result, error, { id }) => [
        { id, type: 'AppendData' as const },
        { id: 'LIST', type: 'AppendData' as const },
      ],
      query: ({ id, image }) => ({
        data: { url: image },
        method: 'POST',
        url: `/append-data/${id}/image`,
      }),
    }),
    updateAppendDataSortOrders: builder.mutation<IVersiumAppend[], IUpdateAppendDataSortOrderDto[]>(
      {
        invalidatesTags: [{ id: 'LIST', type: 'AppendData' as const }],
        query: (data) => ({ data, method: 'PUT', url: '/append-data/sort-orders' }),
      },
    ),
  }),
});

export const {
  useGetAppendDataByIdQuery,
  useGetAppendDataQuery,
  useUpdateAppendDataActiveMutation,
  useUpdateAppendDataImageMutation,
  useUpdateAppendDataMutation,
  useUpdateAppendDataSortOrdersMutation,
} = appendDataApi;
