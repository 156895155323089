import { Dialog } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import {
  pageContentLoad,
  setListUploadedCsvFile,
  setListUploadedCsvFileData,
  snackbarErrorMessage,
} from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Button, Typography } from '@mui/material';
import Papa, { ParseResult } from 'papaparse';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface UploadListDialogComponentProps {
  onClose: () => void;
  open: boolean;
}

export const UploadListDialogComponent = ({ onClose, open }: UploadListDialogComponentProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uploadedCsvFile } = useSelector((state) => state.list);
  const [disableOk, setDisableOk] = useState(true);

  // clear uploaded file when dialog is opened
  useEffect(() => {
    if (open) {
      dispatch(setListUploadedCsvFile(null));
      setDisableOk(true);
    }
  }, [open, dispatch]);

  const uploadFileHandler = (target: EventTarget & HTMLInputElement) => {
    if (!target?.files?.length) {
      dispatch(snackbarErrorMessage(`Error message: No file selected.`));
      dispatch(setListUploadedCsvFile(null));
      setDisableOk(true);
      return;
    }

    const acceptiveFields = ['text/csv'];
    const file: File = target?.files[0];
    const isAcceptiveFormat = acceptiveFields.some((fileType: string) => file?.type === fileType);

    if (!isAcceptiveFormat) {
      dispatch(snackbarErrorMessage(`Wrong file format, accept only csv file.`));
      dispatch(setListUploadedCsvFile(null));
      setDisableOk(true);
      return;
    }

    const numberOfRow = 5;
    dispatch(pageContentLoad(false));

    Papa.parse(file, {
      complete: (results: ParseResult<string[]>) => {
        const isEmpty = !results?.data.length;
        if (isEmpty) {
          dispatch(snackbarErrorMessage(`Error message: CSV file is empty.`));
          dispatch(setListUploadedCsvFile(null));
          dispatch(pageContentLoad(true));
          setDisableOk(true);
          return;
        }

        const headers: string[] = results.data[0]?.map((x: string) => x?.trim());
        // if there is duplicate columns, show error message
        if (headers.length !== new Set(headers).size) {
          dispatch(snackbarErrorMessage(`Error message: Duplicate columns in CSV file.`));
          dispatch(setListUploadedCsvFile(null));
          dispatch(pageContentLoad(true));
          setDisableOk(true);
          return;
        }

        const data: string[][] = results.data.slice(1);
        const examples = headers.reduce(
          (acc, header, r) => {
            acc[header] = data.map((row, d) => ({
              data: row[r],
              key: `${header}-${d}`,
            }));
            return acc;
          },
          {} as Record<
            string,
            {
              data: string;
              key: string;
            }[]
          >,
        );

        dispatch(setListUploadedCsvFile(file));
        dispatch(
          setListUploadedCsvFileData({
            csvDelimiter: results.meta?.delimiter || ',',
            examples,
            headers,
            numberOfRow: results.data.length,
          }),
        );
        dispatch(pageContentLoad(true));
        setDisableOk(false);
      },
      preview: numberOfRow + 1, // add 1 to include header
      worker: true,
    });
  };

  return (
    <Dialog
      cancelText="Cancel"
      disableOk={disableOk}
      okText="Upload"
      onCancel={() => {
        dispatch(setListUploadedCsvFile(null));
        setDisableOk(true);
        onClose();
      }}
      onClose={onClose}
      onOk={() => {
        onClose();
        navigate(`${AppRoutes.Lists}/upload`);
      }}
      open={open}
      title="Upload List"
    >
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography>
          Select the csv file you want to upload. The file should be in the format of csv. We try to
          parse the file to show you the data structure.
        </Typography>
        {uploadedCsvFile === null ? (
          <Button component="label" variant="outlined">
            Upload a new file
            <input
              accept=".csv"
              hidden
              onChange={(e) => {
                uploadFileHandler(e.target);
              }}
              type="file"
            />
          </Button>
        ) : (
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              display: 'flex',
              fontSize: 14,
              fontWeight: 700,
              gap: 1,
            }}
          >
            File uploaded:
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.blackColor,
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {uploadedCsvFile.name}
            </Typography>
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};
