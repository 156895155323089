import { IDataSource } from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import { pageContentLoad, setDataSources } from '@a-type/ui/stores/actions';
import { Box } from '@mui/material';
import { useEffect } from 'react';

import DataSourceEmptyListComponent from './components/DataSourceEmptyList.component';
import DataSourceHeaderComponent from './components/DataSourceHeader.component';
import DataSourceTableApiItemComponent from './components/DataSourceTableApiItem.component';
import DataSourceTableHeaderComponent from './components/DataSourceTableHeader.component';
import DataSourceTableItemComponent from './components/DataSourceTableItem.component';

const DataSourcesListComponent = () => {
  const { dataSources } = useSelector((state) => state.dataSource);
  const dispatch = useDispatch();

  const getAllDataSources = async () => {
    dispatch(pageContentLoad(false));
    const response = await dataSourcesService.getAll();
    if (response.status === 200) {
      dispatch(setDataSources([...response.data]));
    }
    dispatch(pageContentLoad(true));
  };

  useEffect(() => {
    dispatch({ type: 'RESET' });
    getAllDataSources();
  }, []);

  return (
    <>
      <DataSourceHeaderComponent />
      {!dataSources?.length ? (
        <DataSourceEmptyListComponent />
      ) : (
        <>
          <DataSourceTableHeaderComponent />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {dataSources.map((dataSource: IDataSource, index: number) => {
              return dataSource.isApi ? (
                <DataSourceTableApiItemComponent
                  dataSource={dataSource}
                  index={index}
                  key={dataSource._id}
                />
              ) : (
                <DataSourceTableItemComponent
                  dataSource={dataSource}
                  index={index}
                  key={dataSource._id}
                />
              );
            })}
          </Box>
        </>
      )}
    </>
  );
};

export default DataSourcesListComponent;
