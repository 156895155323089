import { OperationType } from '@a-type/enums';
import { ITransaction } from '@a-type/interfaces';
import { GridPagination } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetAllTransactionsQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Chip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 25;

export const TransactionsPage = () => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    limit: DEFAULT_LIMIT,
    page: DEFAULT_PAGE,
  });
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const { data, isFetching } = useGetAllTransactionsQuery({
    limit: pagination.limit,
    page: pagination.page,
  });

  useEffect(() => {
    if (data) {
      setTransactions(data.items);
      setCount(data.count);
      setPages(data.pages);
    }
  }, [data]);

  useEffect(() => {
    dispatch(pageContentLoad(!isFetching));
  }, [isFetching]);

  const getOperationTypeLabel = (type: OperationType) => {
    switch (type) {
      case OperationType.ADDITION:
        return 'Addition';
      case OperationType.REDUCTION:
        return 'Reduction';
      case OperationType.REFUND:
        return 'Refund';
      case OperationType.SUBSCRIPTION_CLEANUP:
        return 'Unused';
      default:
        return '';
    }
  };

  const getOperationTypeColor = (type: OperationType) => {
    switch (type) {
      case OperationType.ADDITION:
        return globalStyles.mainColors.greenColor;
      case OperationType.REDUCTION:
        return globalStyles.mainColors.redColor;
      case OperationType.REFUND:
        return globalStyles.mainColors.warningColor;
      case OperationType.SUBSCRIPTION_CLEANUP:
        return globalStyles.mainColors.blueColor;
      default:
        return globalStyles.mainColors.sootyColor;
    }
  };

  const paginator = () => {
    return (
      <GridPagination
        count={count}
        limit={pagination.limit}
        onChangePagination={(page, limit) => {
          setPagination({ limit, page });
        }}
        page={pagination.page}
        pages={pages}
      />
    );
  };

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            gap: 1,
            pb: 1,
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 900,
            }}
          >
            Transactions
          </Typography>
        </Box>

        {transactions.length > 0 ? (
          <Box>
            <DataGrid
              columns={[
                {
                  field: 'ownerName',
                  headerName: 'Owner',
                  maxWidth: 300,
                  minWidth: 180,
                  renderCell: (params) => (
                    <Typography
                      sx={{
                        color: globalStyles.mainColors.sootyColor,
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      {params.row.ownerName}
                    </Typography>
                  ),
                },
                {
                  field: 'creatorName',
                  headerName: 'Creator',
                  maxWidth: 300,
                  minWidth: 180,
                },
                {
                  field: 'operationName',
                  flex: 1,
                  headerName: 'Operation',
                  minWidth: 150,
                },
                {
                  field: 'operationType',
                  headerName: 'Type',
                  renderCell: (params) => (
                    <Chip
                      label={getOperationTypeLabel(params.row.operationType)}
                      size="small"
                      sx={{
                        backgroundColor: getOperationTypeColor(params.row.operationType),
                        color: globalStyles.mainColors.whiteColor,
                        fontSize: 11,
                        fontWeight: 600,
                      }}
                    />
                  ),
                  width: 100,
                },
                {
                  field: 'operationValue',
                  headerName: 'Amount',
                  renderCell: (params) => (
                    <Typography
                      sx={{
                        color: globalStyles.mainColors.sootyColor,
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      {params.row.operationValue.toLocaleString()}
                    </Typography>
                  ),
                  width: 120,
                },
                {
                  field: 'userValueBefore',
                  headerName: 'Balance Before',
                  renderCell: (params) => params.row.userValueBefore.toLocaleString(),
                  width: 120,
                },
                {
                  field: 'userValueAfter',
                  headerName: 'Balance After',
                  renderCell: (params) => params.row.userValueAfter.toLocaleString(),
                  width: 120,
                },
                {
                  field: 'createdAt',
                  headerName: 'Created At',
                  renderCell: (params) =>
                    params.row.createdAt ? new Date(params.row.createdAt).toLocaleString() : '',
                  width: 160,
                },
              ]}
              disableColumnFilter
              disableColumnSorting
              disableRowSelectionOnClick
              getRowId={(row) => row._id}
              hideFooterPagination={pages === 0}
              rows={transactions}
              slots={{
                pagination: paginator,
              }}
              sx={{
                height: 'calc(100vh - 255px)',
              }}
            />
          </Box>
        ) : (
          !isFetching && (
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: globalStyles.mainColors.whiteSmokeColor,
                borderRadius: '6px',
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 20,
                  fontStyle: 'italic',
                  fontWeight: 400,
                }}
              >
                Threre are no transactions.
              </Typography>
            </Box>
          )
        )}
      </Box>
    </AdminPageLayout>
  );
};
