import { Button, Tooltip } from '@mui/material';

export interface ActionButtonProps {
  disabled?: boolean;
  icon: React.ReactNode;
  label?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ActionButton = ({ disabled = false, icon, label, onClick }: ActionButtonProps) => {
  if (disabled) return <Button color="primary" disabled startIcon={icon} variant="action" />;

  return (
    <Tooltip placement="top" title={label}>
      <Button
        color="primary"
        disabled={disabled}
        onClick={onClick}
        startIcon={icon}
        variant="action"
      />
    </Tooltip>
  );
};

export default ActionButton;
