import { ICombinedSearch, IDataSource, IView, IViewPricingFields } from '@a-type/interfaces';
import { FormInput, ImageUpload } from '@a-type/ui/components';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useUpdateViewPricingImageMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { IViewPricingDetailed } from '../types/view-pricing-detailed.interface';
import { ViewPriceFields } from './view-price-fields.component';

interface IViewPriceProps {
  combinedSearch: ICombinedSearch;
  control: Control<{
    pricing: IViewPricingDetailed[];
  }>;
  dataSources: IDataSource[];
  getValue: (name: string) => any;
  index: number;
  setValue: UseFormSetValue<{
    pricing: IViewPricingDetailed[];
  }>;
  view: IView;
  watch: (name: string) => any;
}

export const ViewPrice: React.FC<IViewPriceProps> = ({
  combinedSearch,
  control,
  dataSources,
  getValue,
  index,
  setValue,
  view,
  watch,
}: IViewPriceProps) => {
  const dispatch = useDispatch();
  const [primaryDataSource, setPrimaryDataSource] = useState<IDataSource | null>(null);
  const [updateViewPricingImage, { isLoading: isUpdatingViewPricingImage }] =
    useUpdateViewPricingImageMutation();

  useEffect(() => {
    dispatch(pageContentLoad(!isUpdatingViewPricingImage));
  }, [isUpdatingViewPricingImage]);

  useEffect(() => {
    if (combinedSearch && dataSources) {
      const dataSource = dataSources.find((ds) => ds._id === combinedSearch.primaryDataSourceId);
      if (dataSource) {
        setPrimaryDataSource(dataSource);
      }
    }
  }, [combinedSearch, dataSources]);

  const price = useWatch({ control, name: `pricing.${index}` });

  const imageUrl = useMemo(() => {
    return getValue(`pricing.${index}.imageUrl`) ?? null;
  }, [watch(`pricing.${index}.imageUrl`), price]);

  const isNew = useMemo(() => {
    return getValue(`pricing.${index}.isNew`) ?? false;
  }, [watch(`pricing.${index}.isNew`), price]);

  const fields = useMemo<IViewPricingFields[]>(() => {
    return getValue(`pricing.${index}.fields`) ?? [];
  }, [watch(`pricing.${index}.fields`), price]);

  const handleUpdatePricingImage = async (url: string) => {
    if (!view) return;

    const result = await updateViewPricingImage({
      id: view._id,
      imageUrl: url,
      pricingId: getValue(`pricing.${index}._id`),
    });

    if (result.data) {
      dispatch(snackbarSuccessMessage('Image uploaded successfully'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while updating image'));
    }
  };

  const handlePricingFieldsChange = (dataSourceId: string, data: IViewPricingFields) => {
    const newFields = fields.map((f) => {
      if (f.dataSourceId === dataSourceId) {
        return data;
      }

      return f;
    });

    setValue(`pricing.${index}.fields`, newFields);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            gap: 3.5,
          }}
        >
          <FormInput
            control={control}
            label="Name"
            maxLength={50}
            name={`pricing.${index}.name`}
            required
          />
          <FormInput
            control={control}
            label="Price"
            max={100000}
            min={1}
            name={`pricing.${index}.price`}
            required
            step={0.01}
            type="number"
          />
          <FormInput
            control={control}
            label="Sort Order"
            max={1000000}
            min={-1000000}
            name={`pricing.${index}.sortOrder`}
            required
            step={1}
            type="number"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: 400,
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: 'cover',
              border: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
              borderRadius: 2,
              height: 300,
              position: 'relative',
              width: 400,
            }}
          >
            <ImageUpload
              disabled={isNew}
              height={300}
              key={`${view._id}`}
              name={`${view._id}`}
              sx={{
                bottom: 4,
                position: 'absolute',
                right: 4,
              }}
              updateImage={handleUpdatePricingImage}
              width={400}
            />
          </Box>
        </Box>
      </Box>

      {fields.length > 0 && primaryDataSource && (
        <ViewPriceFields
          dataSource={primaryDataSource}
          fields={fields.find((f) => f.dataSourceId === combinedSearch.primaryDataSourceId)!}
          onPricingFieldsChange={(data) => handlePricingFieldsChange(primaryDataSource._id, data)}
          required
        />
      )}

      {fields.length > 0 &&
        combinedSearch.secondaryDataSources.map((sds) => {
          const dataSource = dataSources.find((ds) => ds._id === sds.dataSourceId);
          if (!dataSource) return null;

          const dsf = fields.find((f) => f.dataSourceId === sds.dataSourceId);
          if (!dsf) return null;

          return (
            <ViewPriceFields
              dataSource={dataSource}
              fields={dsf}
              key={dataSource._id}
              onPricingFieldsChange={(data) => handlePricingFieldsChange(dataSource._id, data)}
            />
          );
        })}
    </Box>
  );
};
