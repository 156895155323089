import { ICount, IFilterModel } from '@a-type/interfaces';

class CountsUtils {
  static calculateFiltersHash = (counts: ICount) => {
    const props = {
      filters: counts.filters
        ? Object.values(counts.filters)
            .filter((filter: IFilterModel) => filter.values && filter.values.length > 0)
            .map((filter: IFilterModel) => ({
              field: filter._id,
              mode: filter.mode,
              values: filter.values!.map((value: any) => ({
                from: value.from,
                to: value.to,
                value: value.value,
              })),
            }))
        : [],
      plan: counts.pricingPlanId,
    };

    return JSON.stringify(props);
  };

  static isFilterEmpty = (counts: ICount) => {
    return (
      !counts.filters ||
      Object.values(counts.filters).filter(
        (filter: IFilterModel) => filter.values && filter.values.length > 0,
      ).length === 0
    );
  };
}

export default CountsUtils;
