import { IUpdateUserAddress, IUserInfo } from '@a-type/dtos';
import { Dialog, FormAutocomplete, FormInput } from '@a-type/ui/components';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useCountriesQuery,
  useStatesQuery,
  useUpdateAddressInfoMutation,
} from '@a-type/ui/stores/apis';
import { getError } from '@a-type/ui/utils';
import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

interface IUpdateAddressInfoProps {
  setShow: (show: boolean) => void;
  show: boolean;
  user: IUserInfo;
}

export const UpdateAddressInfo: FC<IUpdateAddressInfoProps> = ({
  setShow,
  show,
  user,
}: IUpdateAddressInfoProps) => {
  const dispatch = useDispatch();
  const [updateAddressInfo, { isLoading }] = useUpdateAddressInfoMutation();
  const { control, getValues, handleSubmit, setValue, watch } = useForm<IUpdateUserAddress>({
    defaultValues: user,
  });
  const { data: countries = [], isFetching: countriesLoading } = useCountriesQuery();
  const { data: states = [], isFetching: statesLoading } = useStatesQuery(watch('country'));

  useEffect(() => {
    setValue('state', '');
  }, [watch('country')]);

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  const handleUpdateAddressInfo = async () => {
    const result = await updateAddressInfo(getValues());

    if (result.data) {
      dispatch(snackbarSuccessMessage('Address info updated successfully'));
      setShow(false);
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while updating address info'));
    }
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Save"
      onCancel={() => setShow(false)}
      onClose={() => setShow(false)}
      onOk={handleSubmit(handleUpdateAddressInfo)}
      open={show}
      size="sm"
      title="Edit Address Info"
    >
      <Box
        component="form"
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3.5,
          p: 1,
        }}
      >
        <FormAutocomplete
          control={control}
          getOptionKey={(option) => option.code}
          getOptionLabel={(option) => option.name}
          label="Country"
          loading={countriesLoading}
          name="country"
          options={countries}
          required
        />
        <FormInput control={control} label="Address" name="address" required />
        <FormInput control={control} label="Address 2 (Optional)" name="address2" />
        <FormInput control={control} label="City" name="city" required />
        <Box
          sx={{
            display: 'flex',
            gap: 3,
          }}
        >
          <FormAutocomplete
            control={control}
            getOptionKey={(option) => option.code}
            getOptionLabel={(option) => option.name}
            label="State"
            loading={statesLoading}
            name="state"
            options={states}
            required
            sx={{ flex: 1 }}
          />
          <FormInput
            control={control}
            label="Zip"
            name="zip"
            required
            sx={{ flexBasis: '120px' }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
