import { IRegister } from '@a-type/dtos';
import { FormAutocomplete, FormInput } from '@a-type/ui/components';
import { useCountriesQuery, useStatesQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { East } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface RegisterFormProps {
  loading?: boolean;
  onSubmit: (values: IRegister) => void;
}

const defaultValues: IRegister = {
  address: '',
  address2: '',
  city: '',
  companyName: '',
  country: 'US',
  email: '',
  firstName: '',
  lastName: '',
  mobile: '',
  state: '',
  zip: '',
};

export const RegisterForm: React.FC<RegisterFormProps> = ({
  loading = false,
  onSubmit,
}: RegisterFormProps) => {
  const { control, handleSubmit, setValue, watch } = useForm<IRegister>({ defaultValues });
  const { data: countries = [], isFetching: countriesLoading } = useCountriesQuery();
  const { data: states = [], isFetching: statesLoading } = useStatesQuery(watch('country'));
  const [aceptedTerms, setAceptedTerms] = useState(false);

  useEffect(() => {
    setValue('state', '');
  }, [watch('country')]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '768px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 6,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 3.5,
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 20,
                fontWeight: 600,
                mb: -2,
                textAlign: 'left',
                wordBreak: 'break-word',
              }}
            >
              Contact Information
            </Typography>
            <FormInput control={control} label="First Name" name="firstName" required />
            <FormInput control={control} label="Last Name" name="lastName" required />
            <FormInput
              control={control}
              label="Mobile"
              maxLength={16}
              name="mobile"
              required
              type="tel"
            />
            <FormInput control={control} label="Email" name="email" required type="email" />
            <FormInput control={control} label="Company Name" name="companyName" required />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 3.5,
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 20,
                fontWeight: 600,
                mb: -2,
                textAlign: 'left',
                wordBreak: 'break-word',
              }}
            >
              Address Information
            </Typography>
            <FormAutocomplete
              control={control}
              getOptionKey={(option) => option.code}
              getOptionLabel={(option) => option.name}
              label="Country"
              loading={countriesLoading}
              name="country"
              options={countries}
              required
            />
            <FormInput control={control} label="Address" name="address" required />
            <FormInput control={control} label="Address 2 (Optional)" name="address2" />
            <FormInput control={control} label="City" name="city" required />
            <Box
              sx={{
                display: 'flex',
                gap: 3,
              }}
            >
              <FormAutocomplete
                control={control}
                getOptionKey={(option) => option.code}
                getOptionLabel={(option) => option.name}
                label="State"
                loading={statesLoading}
                name="state"
                options={states}
                required
                sx={{ flex: 1 }}
              />
              <FormInput
                control={control}
                label="Zip"
                name="zip"
                required
                sx={{ flexBasis: '120px' }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={aceptedTerms}
                onChange={(e) => setAceptedTerms(e.target.checked)}
              />
            }
            label={
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 16,
                  fontWeight: 400,
                  wordBreak: 'break-word',
                }}
              >
                I have read and aggred to the{' '}
                <Typography
                  component="span"
                  sx={{
                    color: globalStyles.mainColors.blueColor,
                    cursor: 'pointer',
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  Terms and Conditions
                </Typography>
              </Typography>
            }
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
            width: '100%',
          }}
        >
          <LoadingButton
            color="primary"
            disabled={!aceptedTerms}
            loading={loading}
            sx={{ width: '392px' }}
            type="submit"
            variant="contained"
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: globalStyles.mainColors.whiteColor,
                  fontSize: 16,
                  fontWeight: 700,
                  wordBreak: 'break-word',
                }}
              >
                Request Access
              </Typography>
              <East sx={{ fontSize: '20px' }} />
            </Box>
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};
