import { IUserInfo } from '@a-type/dtos';
import { UserStatus } from '@a-type/enums';
import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useBlockUserMutation, useDeclineUserMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Typography } from '@mui/material';
import { FC, useEffect } from 'react';

interface BlockUserProps {
  setShow: (show: boolean) => void;
  show: boolean;
  user: IUserInfo;
}

export const BlockUser: FC<BlockUserProps> = ({ setShow, show, user }: BlockUserProps) => {
  const dispatch = useDispatch();
  const [blockUser, { isLoading: blockUserLoading }] = useBlockUserMutation();
  const [declineUser, { isLoading: declineUserLoading }] = useDeclineUserMutation();

  useEffect(() => {
    dispatch(pageContentLoad(!blockUserLoading && !declineUserLoading));
  }, [blockUserLoading, declineUserLoading]);

  const handleBlock = async () => {
    const result = await blockUser(user._id);

    if (result.data) {
      dispatch(snackbarSuccessMessage('User blocked successfully'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while blocking user'));
    }

    setShow(false);
  };

  const handleDecline = async () => {
    const result = await declineUser(user._id);

    if (result.data) {
      dispatch(snackbarSuccessMessage('User declined successfully'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while declining user'));
    }

    setShow(false);
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText={user.status === UserStatus.PENDING ? 'Yes, Decline' : 'Yes, Block'}
      onCancel={() => setShow(false)}
      onClose={() => setShow(false)}
      onOk={user.status === UserStatus.PENDING ? handleDecline : handleBlock}
      open={show}
      title={user.status === UserStatus.PENDING ? 'Decline User' : 'Block User'}
    >
      <Typography
        component="span"
        sx={{
          color: globalStyles.mainColors.sootyColor,
          fontSize: 16,
          fontWeight: 400,
        }}
      >
        Are you sure you want to {user.status === UserStatus.PENDING ? 'decline ' : 'block '}
        user&nbsp;
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {user.fullName}
        </Typography>
        ?
      </Typography>
    </Dialog>
  );
};
