import { IDataSourceStatus } from '@a-type/dtos';
import { DataSourceDslStatus, DataSourceLookupStatus } from '@a-type/enums';
import { ICombinedSearch } from '@a-type/interfaces';
import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useRebuildCombinedSearchLookupsMutation,
  useRebuildCombinedSearchMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { CheckCircle, Warning } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { CombinedSearchSteps } from './combined-search-steps.enum';

interface ICombinedSearchStatusProps {
  combinedSearch: ICombinedSearch;
  setActiveStep: (step: string) => void;
  status: IDataSourceStatus;
}

export const CombinedSearchStatus: React.FC<ICombinedSearchStatusProps> = ({
  combinedSearch,
  setActiveStep,
  status,
}: ICombinedSearchStatusProps) => {
  const dispatch = useDispatch();
  const [rebuildCombinedSearch, { isLoading: isRebuilding }] = useRebuildCombinedSearchMutation();
  const [rebuildLookup, { isLoading: isRebuildingLookup }] =
    useRebuildCombinedSearchLookupsMutation();
  const [showRebuildDialog, setShowRebuildDialog] = useState(false);
  const [showRebuildLookupDialog, setShowRebuildLookupDialog] = useState(false);

  useEffect(() => {
    dispatch(pageContentLoad(!isRebuilding && !isRebuildingLookup));
  }, [isRebuilding, isRebuildingLookup]);

  const getStatusColor = (st: string) => {
    switch (st) {
      case 'error':
        return globalStyles.mainColors.redColor;
      case 'IN_PROGRESS':
        return globalStyles.mainColors.yellowColor;
      case 'STEADY':
        return globalStyles.mainColors.greenColor;
      default:
        return globalStyles.mainColors.blueColor;
    }
  };

  const getLookupStatusColor = (st: DataSourceLookupStatus) => {
    switch (st) {
      case DataSourceLookupStatus.COMPLETED:
        return globalStyles.mainColors.greenColor;
      case DataSourceLookupStatus.FAILED:
        return globalStyles.mainColors.redColor;
      case DataSourceLookupStatus.PROCESSING:
        return globalStyles.mainColors.blueColor;
      case DataSourceLookupStatus.QUEUED:
        return globalStyles.mainColors.yellowColor;
      default:
        return globalStyles.mainColors.blueColor;
    }
  };

  const rebuild = async () => {
    if (!combinedSearch) return;

    const result = await rebuildCombinedSearch(combinedSearch.type);
    setShowRebuildDialog(false);

    if (result.data) {
      dispatch(snackbarSuccessMessage('Rebuilding combined search'));
    }

    if (result.error) {
      dispatch(
        snackbarErrorMessage(
          getError(result.error) ?? 'Error while starting rebuild combined search',
        ),
      );
    }
  };

  const rebuildLookups = async () => {
    if (!combinedSearch) return;

    const result = await rebuildLookup(combinedSearch.type);
    setShowRebuildLookupDialog(false);

    if (result.data) {
      dispatch(snackbarSuccessMessage('Rebuilding combined search lookups'));
    }

    if (result.error) {
      dispatch(
        snackbarErrorMessage(
          getError(result.error) ?? 'Error while starting rebuild combined search lookups',
        ),
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            component="div"
            sx={{
              alignItems: 'center',
              color: globalStyles.mainColors.sootyColor,
              display: 'flex',
              fontSize: 18,
              fontWeight: 500,
              gap: 1,
            }}
          >
            Mongo atlas search status:
            <Chip
              label={status.status ?? 'Unknown'}
              size="small"
              sx={{
                backgroundColor: getStatusColor(status.status),
                color: globalStyles.mainColors.whiteColor,
                textTransform: 'uppercase',
              }}
            />
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: 1.5,
            }}
          >
            <Button
              disabled={combinedSearch?.status === DataSourceDslStatus.PROCESSING}
              onClick={() => setShowRebuildDialog(true)}
              size="small"
              variant="outlined"
            >
              Rebuild
            </Button>

            <Button
              disabled={combinedSearch?.status === DataSourceDslStatus.PROCESSING}
              onClick={() => setShowRebuildLookupDialog(true)}
              size="small"
              variant="outlined"
            >
              Rebuild Lookups
            </Button>
          </Box>
        </Box>

        {status.isRequiredReindex && (
          <Typography
            component="div"
            sx={{
              alignItems: 'center',
              color: globalStyles.mainColors.sootyColor,
              display: 'flex',
              fontSize: 16,
              fontWeight: 500,
              gap: 1,
            }}
          >
            <Warning
              sx={{
                color: globalStyles.mainColors.redColor,
                fontSize: 20,
              }}
            />
            Required rebuild to reflect changes in the mongo atlas search index
          </Typography>
        )}

        {!status.isRequiredReindex && (
          <Typography
            component="div"
            sx={{
              alignItems: 'center',
              color: globalStyles.mainColors.sootyColor,
              display: 'flex',
              fontSize: 16,
              fontWeight: 500,
              gap: 1,
            }}
          >
            <CheckCircle
              sx={{
                color: globalStyles.mainColors.greenColor,
                fontSize: 20,
              }}
            />
            Mongo atlas search index is up to date
          </Typography>
        )}

        {status.isRequiredBuildLookup && (
          <Typography
            component="div"
            sx={{
              alignItems: 'center',
              color: globalStyles.mainColors.sootyColor,
              display: 'flex',
              fontSize: 16,
              fontWeight: 500,
              gap: 1,
            }}
          >
            <Warning
              sx={{
                color: globalStyles.mainColors.redColor,
                fontSize: 20,
              }}
            />
            Required rebuild to reflect changes in the lookup collections
          </Typography>
        )}

        {!status.isRequiredBuildLookup && (
          <Typography
            component="div"
            sx={{
              alignItems: 'center',
              color: globalStyles.mainColors.sootyColor,
              display: 'flex',
              fontSize: 16,
              fontWeight: 500,
              gap: 1,
            }}
          >
            <CheckCircle
              sx={{
                color: globalStyles.mainColors.greenColor,
                fontSize: 20,
              }}
            />
            Lookup is up to date
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 18,
              fontWeight: 500,
            }}
          >
            Lookups Status
          </Typography>

          <List>
            {status.lookups.map((lookup) => (
              <ListItem
                key={lookup.name}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemIcon>
                  <Tooltip placement="top" title={`"${lookup.name}" field index status`}>
                    {status.indexes.find((index) => index === lookup.name) ? (
                      <CheckCircle
                        sx={{
                          color: globalStyles.mainColors.greenColor,
                          fontSize: 20,
                        }}
                      />
                    ) : (
                      <Warning
                        sx={{
                          color: globalStyles.mainColors.redColor,
                          fontSize: 20,
                        }}
                      />
                    )}
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={lookup.name} />

                <Chip
                  label={lookup.status}
                  size="small"
                  sx={{
                    backgroundColor: getLookupStatusColor(lookup.status),
                    color: globalStyles.mainColors.whiteColor,
                    textTransform: 'uppercase',
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color="primary"
          onClick={() => setActiveStep(CombinedSearchSteps.Filters)}
          variant="outlined"
        >
          Back to Filters
        </Button>
      </Box>

      {showRebuildDialog && (
        <Dialog
          cancelText="Cancel"
          okText="Rebuild"
          onCancel={() => setShowRebuildDialog(false)}
          onClose={() => setShowRebuildDialog(false)}
          onOk={rebuild}
          open={showRebuildDialog}
          size="sm"
          title="Rebuild Combined Search"
        >
          <Typography>Are you sure you want to rebuild the combined search?</Typography>
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontWeight: 500,
              mt: 2,
            }}
          >
            This will remove all existing data and rebuild the combined search from scratch.
          </Typography>
        </Dialog>
      )}

      {showRebuildLookupDialog && (
        <Dialog
          cancelText="Cancel"
          okText="Rebuild"
          onCancel={() => setShowRebuildLookupDialog(false)}
          onClose={() => setShowRebuildLookupDialog(false)}
          onOk={rebuildLookups}
          open={showRebuildLookupDialog}
          size="sm"
          title="Rebuild Combined Search Lookups"
        >
          <Typography>Are you sure you want to rebuild the combined search lookups?</Typography>
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontWeight: 500,
              mt: 2,
            }}
          >
            This will remove all existing lookup data and rebuild the combined search lookups from
            scratch.
          </Typography>
        </Dialog>
      )}
    </Box>
  );
};
