import {
  IDataSourceStatus,
  IUpdateCombinedSearchBasic,
  IUpdateCombinedSearchFilters,
} from '@a-type/dtos';
import { CombinedSearchType } from '@a-type/enums';
import { ICombinedSearch } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const combinedSearchApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    getCombinedSearch: builder.query<ICombinedSearch, CombinedSearchType>({
      providesTags: (result, error, type) => [{ id: type, type: 'CombinedSearch' as const }],
      query: (type) => ({ url: `/combined-search/${type}` }),
    }),
    getCombinedSearches: builder.query<ICombinedSearch[], void>({
      providesTags: () => [{ id: 'LIST', type: 'CombinedSearch' as const }],
      query: () => ({ url: '/combined-search' }),
    }),
    getCombinedSearchStatus: builder.query<IDataSourceStatus, CombinedSearchType>({
      providesTags: (result, error, type) => [
        { id: `${type}-status`, type: 'CombinedSearch' as const },
      ],
      query: (type) => ({ url: `/combined-search/${type}/status` }),
    }),
    rebuildCombinedSearch: builder.mutation<void, CombinedSearchType>({
      invalidatesTags: (result, error, type) => [
        { id: type, type: 'CombinedSearch' as const },
        { id: 'LIST', type: 'CombinedSearch' as const },
        { id: `${type}-status`, type: 'CombinedSearch' as const },
      ],
      query: (type) => ({
        method: 'POST',
        url: `/combined-search/${type}/rebuild`,
      }),
    }),
    rebuildCombinedSearchLookups: builder.mutation<void, CombinedSearchType>({
      invalidatesTags: (result, error, type) => [
        { id: type, type: 'CombinedSearch' as const },
        { id: 'LIST', type: 'CombinedSearch' as const },
        { id: `${type}-status`, type: 'CombinedSearch' as const },
      ],
      query: (type) => ({
        method: 'POST',
        url: `/combined-search/${type}/rebuild-lookups`,
      }),
    }),
    updateCombinedSearch: builder.mutation<
      ICombinedSearch,
      {
        data: ICombinedSearch;
        type: CombinedSearchType;
      }
    >({
      invalidatesTags: (result, error, { type }) => [
        { id: type, type: 'CombinedSearch' as const },
        { id: 'LIST', type: 'CombinedSearch' as const },
        { id: `${type}-status`, type: 'CombinedSearch' as const },
      ],
      query: ({ data, type }) => ({
        body: data,
        method: 'POST',
        url: `/combined-search/${type}`,
      }),
    }),
    updateCombinedSearchBasic: builder.mutation<
      ICombinedSearch,
      {
        data: IUpdateCombinedSearchBasic;
        type: CombinedSearchType;
      }
    >({
      invalidatesTags: (result, error, { type }) => [
        { id: type, type: 'CombinedSearch' as const },
        { id: 'LIST', type: 'CombinedSearch' as const },
      ],
      query: ({ data, type }) => ({
        data,
        method: 'POST',
        url: `/combined-search/${type}/basic`,
      }),
    }),
    updateCombinedSearchFilters: builder.mutation<
      ICombinedSearch,
      {
        data: IUpdateCombinedSearchFilters;
        type: CombinedSearchType;
      }
    >({
      invalidatesTags: (result, error, { type }) => [
        { id: type, type: 'CombinedSearch' as const },
        { id: 'LIST', type: 'CombinedSearch' as const },
        { id: `${type}-status`, type: 'CombinedSearch' as const },
      ],
      query: ({ data, type }) => ({
        data,
        method: 'POST',
        url: `/combined-search/${type}/filters`,
      }),
    }),
  }),
});

export const {
  useGetCombinedSearchesQuery,
  useGetCombinedSearchQuery,
  useGetCombinedSearchStatusQuery,
  useRebuildCombinedSearchLookupsMutation,
  useRebuildCombinedSearchMutation,
  useUpdateCombinedSearchBasicMutation,
  useUpdateCombinedSearchFiltersMutation,
  useUpdateCombinedSearchMutation,
} = combinedSearchApi;
