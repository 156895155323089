import { IStripeActiveSubscription } from '@a-type/dtos';
import globalStyles from '@a-type/ui/styles/global.styles';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Chip, Tooltip } from '@mui/material';

interface ISubscriptionStatusProps {
  subscription: IStripeActiveSubscription;
}

export const SubscriptionStatus: React.FC<ISubscriptionStatusProps> = ({
  subscription,
}: ISubscriptionStatusProps) => {
  return (
    <>
      {subscription && subscription.status === 'active' && (
        <>
          <Chip
            label="Active"
            size="small"
            sx={{
              background: globalStyles.mainColors.greenColor,
              color: globalStyles.mainColors.whiteColor,
              fontSize: 13,
              fontWeight: 500,
            }}
          />

          {subscription.cancelAtPeriodEnd && (
            <Chip
              label={`Will Cancel on ${new Date(subscription.currentPeriodEnd).toLocaleDateString()}`}
              size="small"
              sx={{
                background: globalStyles.mainColors.warningColor,
                color: globalStyles.mainColors.whiteColor,
                fontSize: 13,
                fontWeight: 500,
              }}
            />
          )}
        </>
      )}

      {subscription && subscription.status === 'cancelled' && (
        <Chip
          label="Cancelled"
          size="small"
          sx={{
            background: globalStyles.mainColors.redColor,
            color: globalStyles.mainColors.whiteColor,
            fontSize: 13,
            fontWeight: 500,
          }}
        />
      )}

      {subscription && subscription.status === 'past_due' && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
        >
          <Chip
            label="Paymant Failed"
            size="small"
            sx={{
              background: globalStyles.mainColors.redColor,
              color: globalStyles.mainColors.whiteColor,
              fontSize: 13,
              fontWeight: 500,
            }}
          />

          {subscription.error && (
            <Tooltip placement="top" title={subscription.error}>
              <InfoOutlined
                fontSize="small"
                sx={{
                  color: globalStyles.mainColors.headTableGreyColor,
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};
