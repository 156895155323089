import { DataSourceDslStatus } from '@a-type/enums';
import { useSelector } from '@a-type/ui/hooks';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Typography } from '@mui/material';

const DataSourceDetailsStatusLineComponent = () => {
  const { currentDataSource } = useSelector((state) => state.dataSource);

  const history = currentDataSource?.history || [];
  const status = currentDataSource?.status;

  let statusColor = globalStyles.mainColors.grayColor;
  switch (status) {
    case DataSourceDslStatus.COMPLETED:
      statusColor = globalStyles.mainColors.emeraldReflectionColor;
      break;
    case DataSourceDslStatus.FAILED:
      statusColor = globalStyles.mainColors.carminePinkColor;
      break;
    case DataSourceDslStatus.PROCESSING:
      statusColor = globalStyles.mainColors.lerryLemonColor;
      break;
    default:
      break;
  }

  const messageList = {
    COMPLETED: `File ${history[history.length - 1]?.fileName} has been successfully uploaded.`,
    DEFAULT: 'No Data Set yet',
    FAILED: `There was an Error uploading file: ${
      history[history.length - 1]?.fileName
    }. Please contact administrator.`,
    PROCESSING: `File ${history[history.length - 1]?.fileName} is being processed.`,
  };

  let currentMessage = messageList.DEFAULT;
  switch (status) {
    case DataSourceDslStatus.COMPLETED:
      currentMessage = messageList.COMPLETED;
      break;
    case DataSourceDslStatus.FAILED:
      currentMessage = messageList.FAILED;
      break;
    case DataSourceDslStatus.PROCESSING:
      currentMessage = messageList.PROCESSING;
      break;
    default:
      break;
  }

  return (
    (history.length > 0 && (
      <Box
        sx={{
          alignItems: 'center',
          background: statusColor,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          display: 'flex',
          height: 40,
          width: '100%',
        }}
      >
        <Typography
          sx={{ color: globalStyles.mainColors.whiteColor, textAlign: 'center', width: '100%' }}
        >
          {currentMessage}
        </Typography>
      </Box>
    )) ||
    null
  );
};

export default DataSourceDetailsStatusLineComponent;
