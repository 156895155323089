import { UserStatus } from '@a-type/enums';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import DashboardComponent from '@a-type/ui/pages/dashboard/components/Dashboard.component';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import {
  useGetActiveSubscriptionQuery,
  useGetUserBalanceQuery,
  useGetUserInfoQuery,
} from '@a-type/ui/stores/apis';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BlockedUserComponent from './components/blockedUser/BlockedUser.compoent';
import NotLoginComponent from './components/notLogin/NotLogin.component';

export const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogin } = useSelector((state) => state.auth);
  const { data: userInfo } = useGetUserInfoQuery(undefined, { skip: !isLogin });
  const { data: creditsBalance = 0 } = useGetUserBalanceQuery();
  const {
    data: subscription,
    isFetching,
    isLoading,
  } = useGetActiveSubscriptionQuery(undefined, {
    skip: !isLogin,
  });

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  if (userInfo?.status === UserStatus.BLOCKED)
    return <BlockedUserComponent fullName={userInfo?.fullName} />;

  if (!isLogin) return <NotLoginComponent />;

  // if user has no subscription or subscription is canceled and user has no currency
  if (!subscription && !isFetching && creditsBalance === 0) navigate(AppRoutes.SubscribePage);

  return <PageLayout>{!isFetching && <DashboardComponent />}</PageLayout>;
};
