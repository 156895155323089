/**
 * Generates a unique object ID similar to MongoDB's ObjectId.
 *
 * The generated ID consists of a timestamp and a random hexadecimal string.
 * The timestamp is based on the current time in seconds since the Unix epoch.
 *
 * @returns {string} A unique object ID.
 */
export const generateObjectId = () => {
  // eslint-disable-next-line no-bitwise
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/x/g, () => {
        const randomValues = new Uint8Array(1);
        crypto.getRandomValues(randomValues);
        return (randomValues[0] % 16).toString(16);
      })
      .toLowerCase()
  );
};
