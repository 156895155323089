/**
 * Utility class for generating human-readable labels.
 * This class provides static methods for generating labels for date ranges, quantities, and ranges.
 */
export class LabelsUtils {
  /**
   * Generates a date range label based on the provided `from` and `to` dates.
   *
   * @param from - The start date of the range. Optional and can be null.
   * @param to - The end date of the range. Optional and can be null.
   * @returns A string representing the date range. If both dates are provided,
   *          it returns a string in the format "MM/DD/YYYY - MM/DD/YYYY". If only
   *          the `from` date is provided, it returns "From MM/DD/YYYY". If only
   *          the `to` date is provided, it returns "To MM/DD/YYYY". If neither
   *          date is provided, it returns an empty string.
   */
  static readonly getDateRangeLabel = (from?: Date | null, to?: Date | null) => {
    if (from && to) {
      return `${from.toLocaleDateString()} - ${to.toLocaleDateString()}`;
    }
    if (from) {
      return `From ${from.toLocaleDateString()}`;
    }
    if (to) {
      return `To ${to.toLocaleDateString()}`;
    }

    return '';
  };

  /**
   * Converts a numeric quantity into a human-readable string with appropriate units.
   *
   * - Quantities less than 1,000 are returned as-is.
   * - Quantities from 1,000 to less than 1,000,000 are converted to thousands (K).
   * - Quantities from 1,000,000 to less than 1,000,000,000 are converted to millions (M).
   * - Quantities of 1,000,000,000 and above are converted to billions (B).
   *
   * @param quantity - The numeric quantity to be converted.
   * @returns A string representing the quantity with appropriate units.
   */
  static readonly getQuantityLabel = (quantity: number) => {
    if (quantity < 1000) {
      return quantity.toString();
    }
    if (quantity < 1000000) {
      return `${(quantity / 1000).toFixed(1)}K`;
    }
    if (quantity < 1000000000) {
      return `${(quantity / 1000000).toFixed(1)}M`;
    }
    return `${(quantity / 1000000000).toFixed(1)}B`;
  };

  /**
   * Generates a range label based on the provided `from` and `to` values.
   *
   * @param from - The starting value of the range (optional).
   * @param to - The ending value of the range (optional).
   * @returns A string representing the range label. If both `from` and `to` are provided,
   *          it returns a label in the format "from - to". If only `from` is provided,
   *          it returns a label in the format "> from". If only `to` is provided,
   *          it returns a label in the format "< to". If neither is provided, it returns an empty string.
   */
  static readonly getRangeLabel = (from?: number, to?: number) => {
    if (from && to) {
      return `${LabelsUtils.getQuantityLabel(from)} - ${LabelsUtils.getQuantityLabel(to)}`;
    }
    if (from) {
      return `> ${LabelsUtils.getQuantityLabel(from)}`;
    }
    if (to) {
      return `< ${LabelsUtils.getQuantityLabel(to)}`;
    }

    return '';
  };

  /**
   * Generates a year label based on the provided `from` and `to` years.
   *
   * @param from - The starting year of the range (optional).
   * @param to - The ending year of the range (optional).
   * @returns A string representing the year label. If both `from` and `to` are provided,
   *          it returns a label in the format "from - to". If only `from` is provided,
   *          it returns a label in the format "From from". If only `to` is provided,
   *          it returns a label in the format "To to". If neither is provided, it returns an empty string.
   */
  static getYearLabel(from?: number, to?: number): string {
    if (from && to) {
      return `${from} - ${to}`;
    }
    if (from) {
      return `From ${from}`;
    }
    if (to) {
      return `To ${to}`;
    }

    return '';
  }
}
