import { IUpdateViewBasic, IUpdateViewPricing, IViewShort } from '@a-type/dtos';
import { IView, IViewFilter } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const viewsApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    createView: builder.mutation<IView, IUpdateViewBasic>({
      invalidatesTags: (result, error, { _id }) => [
        { id: 'LIST', type: 'Views' as const },
        { id: _id, type: 'Views' as const },
      ],
      query: (data) => ({ data, method: 'POST', url: '/views' }),
    }),
    deleteView: builder.mutation<void, string>({
      invalidatesTags: (result, error, id) => [
        { id: 'LIST', type: 'Views' as const },
        { id, type: 'Views' as const },
      ],
      query: (id) => ({ method: 'DELETE', url: `/views/${id}` }),
    }),
    getView: builder.query<IView, string>({
      providesTags: (result, error, id) => [{ id, type: 'Views' as const }],
      query: (id) => ({ method: 'GET', url: `/views/${id}` }),
    }),
    getViews: builder.query<IViewShort[], void>({
      providesTags: () => [{ id: 'LIST', type: 'Views' as const }],
      query: () => ({ method: 'GET', url: '/views' }),
    }),
    updateViewBasic: builder.mutation<IView, IUpdateViewBasic>({
      invalidatesTags: (result, error, { _id }) => [
        { id: 'LIST', type: 'Views' as const },
        { id: _id, type: 'Views' as const },
      ],
      query: (data) => ({ data, method: 'POST', url: `/views/${data._id}/basic` }),
    }),
    updateViewFilters: builder.mutation<IView, { filters: IViewFilter[]; id: string }>({
      invalidatesTags: (result, error, { id }) => [
        { id: 'LIST', type: 'Views' as const },
        { id, type: 'Views' as const },
      ],
      query: ({ filters, id }) => ({
        data: filters,
        method: 'POST',
        url: `/views/${id}/filters`,
      }),
    }),
    updateViewImage: builder.mutation<IView, { id: string; imageUrl: string }>({
      invalidatesTags: (result, error, { id }) => [
        { id: 'LIST', type: 'Views' as const },
        { id, type: 'Views' as const },
      ],
      query: ({ id, imageUrl }) => ({
        data: { imageUrl },
        method: 'POST',
        url: `/views/${id}/image`,
      }),
    }),
    updateViewPricing: builder.mutation<IView, { id: string; pricing: IUpdateViewPricing[] }>({
      invalidatesTags: (result, error, { id }) => [
        { id: 'LIST', type: 'Views' as const },
        { id, type: 'Views' as const },
      ],
      query: ({ id, pricing }) => ({
        data: pricing,
        method: 'POST',
        url: `/views/${id}/pricing`,
      }),
    }),
    updateViewPricingImage: builder.mutation<
      IView,
      { id: string; imageUrl: string; pricingId: string }
    >({
      invalidatesTags: (result, error, { id }) => [
        { id: 'LIST', type: 'Views' as const },
        { id, type: 'Views' as const },
      ],
      query: ({ id, imageUrl, pricingId }) => ({
        data: { imageUrl },
        method: 'POST',
        url: `/views/${id}/pricing/${pricingId}/image`,
      }),
    }),
  }),
});

export const {
  useCreateViewMutation,
  useDeleteViewMutation,
  useGetViewQuery,
  useGetViewsQuery,
  useUpdateViewBasicMutation,
  useUpdateViewFiltersMutation,
  useUpdateViewImageMutation,
  useUpdateViewPricingImageMutation,
  useUpdateViewPricingMutation,
} = viewsApi;
