import { IViewShort } from '@a-type/dtos';
import { useDispatch } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetCombinedSearchesQuery, useGetViewsQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { AddCircle } from '@mui/icons-material';
import { Box, Button, Chip, ListItemButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const ViewsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: viewsData, isLoading: isViewsLoading } = useGetViewsQuery();
  const { data: combinedSearchesData } = useGetCombinedSearchesQuery();
  const [views, setViews] = useState<IViewShort[]>([]);
  const [combinedSearches, setCombinedSearches] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    dispatch(pageContentLoad(!isViewsLoading));
  }, [isViewsLoading]);

  useEffect(() => {
    if (viewsData) {
      setViews(viewsData);
    }
  }, [viewsData]);

  useEffect(() => {
    if (combinedSearchesData) {
      const combinedSearchesMap = combinedSearchesData.reduce(
        (acc, combinedSearch) => {
          acc[combinedSearch.type] = combinedSearch.name;
          return acc;
        },
        {} as { [key: string]: string },
      );

      setCombinedSearches(combinedSearchesMap);
    }
  }, [combinedSearchesData]);

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 3,
          py: 1,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            justifyContent: 'space-between',
            pb: 2,
          }}
        >
          <Typography sx={{ fontSize: 24, fontWeight: 800, pl: 2 }}>Views</Typography>

          <Button
            color="primary"
            onClick={() => navigate(`${AppRoutes.AdminViewsPage}/create`)}
            startIcon={<AddCircle />}
            variant="text"
          >
            Create View
          </Button>
        </Box>

        <Box sx={{ display: 'flex', pb: 1 }}>
          <Typography
            sx={{
              color: globalStyles.mainColors.siriyGrayColor,
              fontSize: 12,
              pl: 2,
              textTransform: 'uppercase',
              width: '40%',
            }}
          >
            Name
          </Typography>
          <Typography
            sx={{
              color: globalStyles.mainColors.siriyGrayColor,
              fontSize: 12,
              textAlign: 'end',
              textTransform: 'uppercase',
              width: '30%',
            }}
          >
            Type
          </Typography>
          <Typography
            sx={{
              color: globalStyles.mainColors.siriyGrayColor,
              fontSize: 12,
              textAlign: 'end',
              textTransform: 'uppercase',
              width: '15%',
            }}
          >
            Filters
          </Typography>
          <Typography
            sx={{
              color: globalStyles.mainColors.siriyGrayColor,
              fontSize: 12,
              textAlign: 'end',
              textTransform: 'uppercase',
              width: '15%',
            }}
          >
            Total Records
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          {views.map((view) => (
            <ListItemButton
              key={view._id}
              onClick={() => {
                navigate(`${AppRoutes.AdminViewsPage}/${view._id}`);
              }}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                pr: 0,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  width: '40%',
                }}
              >
                <Typography sx={{ fontWeight: 800 }}>{view.name}</Typography>
              </Box>
              <Box
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 16,
                  textAlign: 'end',
                  width: '30%',
                }}
              >
                {combinedSearches[view.type] ? (
                  <Chip
                    label={combinedSearches[view.type]}
                    sx={{
                      textTransform: 'capitalize',
                    }}
                  />
                ) : (
                  ''
                )}
              </Box>
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 16,
                  textAlign: 'end',
                  width: '15%',
                }}
              >
                {view.totalFilters?.toLocaleString() || ''}
              </Typography>
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 16,
                  textAlign: 'end',
                  width: '15%',
                }}
              >
                {view.totalRecords?.toLocaleString() || ''}
              </Typography>
            </ListItemButton>
          ))}
        </Box>
      </Box>
    </AdminPageLayout>
  );
};
