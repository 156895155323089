import { FormInput } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { snackbarErrorMessage, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { usePasswordForgotMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { East } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const ForgetPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<{
    email: string;
  }>();
  const [forgotPassword, { isLoading }] = usePasswordForgotMutation();
  const [success, setSuccess] = useState(false);

  const forgotPasswordHandler = async (values: { email: string }) => {
    // encode the email to preserve special characters like + in playload
    const encodedEmail = encodeURIComponent(values.email);

    const response = await forgotPassword({ email: encodedEmail });

    if (response.data) {
      dispatch(
        snackbarSuccessMessage("Check your email - we've sent you a link to reset your password"),
      );
      setSuccess(true);
      return;
    }

    if (response.error) {
      dispatch(snackbarErrorMessage(getError(response.error) ?? 'Error while logging in'));
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        my: 4,
        overflow: 'auto',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: success ? 'flex-start' : 'center',
          textAlign: 'center',
        }}
      >
        {success && (
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: globalStyles.mainColors.whiteColor,
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 4,
              p: 4,
              width: '100%',
            }}
          >
            <Typography
              component="span"
              gutterBottom
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '32px',
                fontWeight: '600',
                wordWrap: 'break-word',
              }}
            >
              Check your email - we&apos;ve sent you a link to reset your password
            </Typography>
            <Divider
              sx={{
                backgroundColor: globalStyles.mainColors.gainsboroColor,
                height: '1px',
                width: '100%',
              }}
            />
            <Typography
              component="span"
              gutterBottom
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: '24px',
                fontWeight: '400',
                mt: 1,
                wordWrap: 'break-word',
              }}
            >
              If you don&apos;t receive an email, please check your spam folder.
            </Typography>
          </Box>
        )}

        {!success && (
          <Box maxWidth="sm">
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
                width: '100%',
              }}
            >
              <Typography component="div" gutterBottom sx={{ fontSize: '32px', fontWeight: '900' }}>
                Restore Password
              </Typography>
              <Typography
                component="span"
                gutterBottom
                onClick={() => {
                  navigate(AppRoutes.LoginPage);
                }}
                sx={{
                  color: globalStyles.mainColors.blueColor,
                  cursor: 'pointer',
                  fontSize: '16px',
                  fontWidth: '400',
                }}
              >
                Back to log in
              </Typography>
            </Box>
            <form noValidate onSubmit={handleSubmit(forgotPasswordHandler)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3.5,
                  width: '440px',
                }}
              >
                <FormInput control={control} label="Email" name="email" required type="email" />
                <LoadingButton loading={isLoading} sx={{ mt: 1 }} type="submit" variant="contained">
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        color: globalStyles.mainColors.whiteColor,
                        fontSize: 16,
                        fontWeight: 700,
                        wordBreak: 'break-word',
                      }}
                    >
                      Reset Password
                    </Typography>
                    <East sx={{ fontSize: '20px' }} />
                  </Box>
                </LoadingButton>
              </Box>
            </form>
          </Box>
        )}
      </Container>
    </Box>
  );
};
