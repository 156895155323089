import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../router/AppRoutes';
import globalStyles from '../styles/global.styles';

export const Page404 = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        height: '100vh',
        justifyContent: 'center',
        position: 'relative',
        verticalAlign: 'center',
        width: '100%',
      }}
    >
      <Typography
        component="span"
        sx={{
          color: globalStyles.mainColors.lightBlueGrayColor,
          fontSize: 400,
          fontWeight: 700,
          position: 'absolute',
          zIndex: 0,
        }}
      >
        404
      </Typography>

      <Typography
        component="span"
        sx={{
          color: globalStyles.mainColors.sootyColor,
          fontSize: 24,
          fontWeight: 500,
          textAlign: 'center',
          zIndex: 1,
        }}
      >
        So sorry,
        <br />
        We coudn&apos;t find that page...
      </Typography>

      <Button
        onClick={() => navigate(AppRoutes.HomePage)}
        sx={{
          width: 240,
          zIndex: 1,
        }}
        variant="contained"
      >
        Back to the Homepage
      </Button>
    </Box>
  );
};
