import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { FieldMetadataType } from '@a-type/enums';
import { IAxleField, IDataSourceField, IDataSourcePricing } from '@a-type/interfaces';
import { DragAndDrop } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { dataSourcesService } from '@a-type/ui/services';
import {
  pageContentLoad,
  setCurrentDataSource,
  setLookupsMapping,
} from '@a-type/ui/stores/actions';
import { useGetFilterGroupsQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, Button, ButtonGroup, TextField, Theme, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import DataSourceDetailsAddNewApiFieldDialog from './components/DataSourceDetailsAddNewApiFieldDialog.components';
import DataSourceDetailsApiFieldConfigurator from './components/DataSourceDetailsApiFieldConfigurator.component';

export const styles = {
  filterButton: {
    '&:hover': {
      backgroundColor: globalStyles.mainColors.blueColor,
      borderColor: globalStyles.mainColors.blueColor,
      color: globalStyles.mainColors.whiteColor,
    },
    borderColor: globalStyles.mainColors.headTableGreyColor,
    borderRadius: '8px',
    color: globalStyles.mainColors.headTableGreyColor,
    lineHeight: '1',
    px: '12px',
    py: '4px',
    'span:first-of-type': {
      mr: '4px',
    },
  },
};

const DataSourceDetailsApiMapping = (props: any) => {
  const dispatch = useDispatch();
  const { setMappingValid } = props;
  const { data: filtersGroups } = useGetFilterGroupsQuery();
  const { currentDataSource, filtersComponents } = useSelector((state) => state.dataSource);
  const [apiFields, setApiFields] = useState<IAxleField[]>([]);
  const [availableFields, setAvailableFields] = useState<IAxleField[]>([]);
  const [hasAccessApiFields, setHasAccessApiFields] = useState<{ [key: string]: boolean }>({});
  const [addNewFieldDialog, setAddNewFieldDialog] = useState(false);
  const [selectedField, setSelectedField] = useState<null | string>(null);
  const [includedInReport, setIncludedInReport] = useState<string[]>([]);
  const [fields, setFields] = useState<IDataSourceField[]>([]);
  const [filteredFields, setFilteredFields] = useState<IDataSourceField[]>(fields);
  const [searchText, setSearchText] = useState('');
  const [fieldsIsValid, setFieldsIsValid] = useState<{ [key: string]: boolean }>({});
  const [selectedMetadataTypes, setSelectedMetadataTypes] = useState<FieldMetadataType[]>([]);

  const components = filtersComponents.reduce((acc: any, item: IDataSourceFilterComponentDto) => {
    return {
      ...acc,
      [item.type]: item.name,
    };
  }, {});

  const groups = (filtersGroups || []).reduce((acc: any, item) => {
    return {
      ...acc,
      [item.code]: item.name,
    };
  }, {});

  const loadApiFields = async () => {
    if (!currentDataSource) return;

    const response = await dataSourcesService.getApiFields(currentDataSource);
    if (response.status === 200) {
      setApiFields([...response.data]);
    }
  };

  const loadLookupsMappping = async () => {
    if (!currentDataSource) return;

    const response = await dataSourcesService.getLookupsMapping(currentDataSource);
    if (response.status === 200) {
      dispatch(setLookupsMapping({ ...response.data }));
    }
  };

  // load api fields
  async function loadData() {
    dispatch(pageContentLoad(false));
    await loadApiFields();
    await loadLookupsMappping();
    dispatch(pageContentLoad(true));
  }

  useEffect(() => {
    if (!currentDataSource) return;

    loadData();

    if (currentDataSource?.fields) {
      setFields([...currentDataSource.fields]);
    }

    if (currentDataSource?.pricing) {
      const f: string[] = currentDataSource.pricing
        .map((p: IDataSourcePricing) => p.includedFields)
        .reduce((acc: string[], val: string[]) => [...acc, ...val], []);

      setIncludedInReport(Array.from(new Set(f)));
    }
  }, []);

  useEffect(() => {
    if (apiFields.length) {
      setAvailableFields(
        apiFields.filter((field: IAxleField) => !fields.find((f) => f.name === field.name)),
      );
      setHasAccessApiFields(
        apiFields.reduce((acc: any, field: IAxleField) => {
          return {
            ...acc,
            [field.name]: field.hasAccess,
          };
        }, {}),
      );
    }
  }, [apiFields, fields]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (searchText === '') {
        setFilteredFields([...fields].sort((a, b) => a.sortOrder - b.sortOrder));
      } else {
        const f = fields.filter(
          (field: IDataSourceField) =>
            field.name?.toLowerCase().includes(searchText.toLowerCase()) ||
            field.description?.toLowerCase().includes(searchText.toLowerCase()) ||
            field.displayName?.toLowerCase().includes(searchText.toLowerCase()),
        );
        setFilteredFields(f.sort((a, b) => a.sortOrder - b.sortOrder));
      }
    }, 250);
    return () => clearTimeout(timeOutId);
  }, [searchText, fields]);

  useEffect(() => {
    if (Object.keys(fieldsIsValid).length > 0) {
      const isValid = Object.values(fieldsIsValid).every((value) => value);
      setMappingValid(isValid);
    } else {
      setMappingValid(false);
    }
  }, [fieldsIsValid]);

  useEffect(() => {
    if (!currentDataSource) return;

    setSelectedMetadataTypes(fields.filter((f) => f.metadataType).map((f) => f.metadataType!));
    dispatch(setCurrentDataSource({ ...currentDataSource, fields }));
  }, [fields]);

  const updateFieldIsValidHandler = (fieldName: string, isValid: boolean) => {
    setFieldsIsValid((prev) => {
      return {
        ...prev,
        [fieldName]: isValid,
      };
    });
  };

  const updateFieldHandler = (field: IDataSourceField) => {
    const updatedFields = fields.map((f: IDataSourceField) => {
      if (f.name === field.name) {
        return field;
      }
      return f;
    });
    setFields(updatedFields);
  };

  const deleteFieldHandler = (fieldName: string) => {
    const field = fields.find((f: IDataSourceField) => f.name === fieldName);
    if (field) {
      const updatedFields = fields
        .filter((f: IDataSourceField) => f.name !== fieldName)
        .map((f: IDataSourceField) => {
          if (f.sortOrder > field.sortOrder) {
            return { ...f, sortOrder: f.sortOrder - 1 };
          }
          return f;
        });
      setFields(updatedFields);
    }
  };

  const addNewField = () => {
    setAddNewFieldDialog(true);
  };

  const addFieldHandler = (field: IDataSourceField) => {
    const updatedFields = [...fields, field];
    setSearchText('');
    setFields(updatedFields);
    setFilteredFields(updatedFields);
    setAddNewFieldDialog(false);
    setSelectedField(field.name);
  };

  const theme: Theme = useTheme();
  const themeStylePalette = theme.palette;
  return (
    <>
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          pb: 2,
        }}
      >
        <Typography
          sx={{
            color: themeStylePalette.text.primary,
            fontSize: 20,
            fontWeight: 700,
            paddingBottom: 0,
            pt: 0.5,
          }}
        >
          Select the fields you want to show in your data source
          <Typography
            component="span"
            sx={{
              display: 'block',
              fontSize: 12,
            }}
          >
            You can select the fields you want to show in your data source. You can also change the
            order of the fields and select the fields you want to be searchable.
          </Typography>
        </Typography>

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <ButtonGroup
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              maxWidth: '400px',
              width: '100%',
            }}
          >
            <TextField
              label="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder="Search"
              size="small"
              sx={{
                '.MuiOutlinedInput-root': {
                  borderEndEndRadius: 0,
                  borderStartEndRadius: 0,
                },
                flexGrow: 1,
              }}
              value={searchText}
              variant="outlined"
            />
            <Button
              onClick={() => setSearchText('')}
              size="small"
              startIcon={<CancelOutlinedIcon />}
              sx={{
                ...styles.filterButton,
                borderEndStartRadius: 0,
                borderStartStartRadius: 0,
              }}
              variant="outlined"
            >
              Clear
            </Button>
          </ButtonGroup>

          {availableFields.length > 0 && (
            <Button
              onClick={() => {
                addNewField();
              }}
              startIcon={<AddCircleIcon />}
            >
              Add New Section
            </Button>
          )}
        </Box>

        <DragAndDrop
          getKey={(field) => field.name}
          items={filteredFields}
          renderItem={(field, index, p, s) => (
            <DataSourceDetailsApiFieldConfigurator
              components={components}
              deleteFieldHandler={deleteFieldHandler}
              draggableProps={p.draggableProps}
              dragHandleProps={p.dragHandleProps}
              dragRef={p.innerRef}
              field={field}
              groups={groups}
              hasAccessApiFields={hasAccessApiFields}
              includedInReport={includedInReport}
              isDragDisabled={searchText !== ''}
              isDragging={s.isDragging}
              key={field.name}
              selectedField={selectedField}
              selectedMetadataTypes={selectedMetadataTypes}
              setSelectedField={setSelectedField}
              updateFieldHandler={updateFieldHandler}
              updateFieldIsValidHandler={updateFieldIsValidHandler}
            />
          )}
          setItems={(updatedFields) => {
            setFields([...updatedFields]);
            setFilteredFields([...updatedFields]);
          }}
        />
      </Box>

      <DataSourceDetailsAddNewApiFieldDialog
        addFieldHandler={addFieldHandler}
        addNewFieldDialog={addNewFieldDialog}
        fields={availableFields}
        setAddNewFieldDialog={setAddNewFieldDialog}
      />
    </>
  );
};

export default DataSourceDetailsApiMapping;
