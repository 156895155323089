import { IFieldMetadata } from '@a-type/interfaces';
import { GridColDef } from '@mui/x-data-grid';

import { RowsAndColumnsBuilderMethods } from './RowsAndColumnsBuilder.utils';

const buildFieldsValues = (builder: ListRowsAndColumnsBuilder, columns: GridColDef[], row: any) => {
  return columns.map((field: GridColDef<any, any, any>) => {
    return builder.renderCell({ field: field.field, row });
  });
};

export const convertObjectToCSV = (builder: ListRowsAndColumnsBuilder, documents: any[]): any => {
  const rows = builder.buildRows(documents);
  const columns = builder.buildColumns();
  const headerArray = columns.map((headerItem: any) => headerItem?.headerName);
  const allRowsValue = rows.map((row: any) => buildFieldsValues(builder, columns, row));

  return [headerArray, ...allRowsValue];
};

export class ListRowsAndColumnsBuilder extends RowsAndColumnsBuilderMethods {
  readonly extraLargeSizeFieldsList = ['location', 'primary_contacts'];

  readonly largeSizeFieldsList = [
    'state',
    'state_name',
    'city',
    'postal_code',
    'total_credits',
    'first_name',
    'last_name',
    'estimated_square_footage',
    'employee_count',
    'operationValue',
  ];

  readonly mediumSizeFieldsList = ['gender', 'price', 'operationValue', 'userValueAfter', 'income'];

  ROW_SIZES: {
    EXTRA_LARGE: number;
    LARGE: number;
    MEDIUM: number;
    SMALL: number;
  };

  rows: any[] = [];

  readonly smallSizeFieldsList: any = ['age', 'postal_code'];

  constructor(fieldsNames: { [key: string]: IFieldMetadata }) {
    super();
    this.fieldsNames = fieldsNames || {};
    this.ROW_SIZES = {
      EXTRA_LARGE: 400,
      LARGE: 250,
      MEDIUM: 150,
      SMALL: 100,
    };
  }

  buildColumns(): GridColDef[] {
    const columns: GridColDef[] = [];

    Object.values(this.fieldsNames)
      .sort((a: IFieldMetadata, b: IFieldMetadata) => a.sortOrder - b.sortOrder)
      .forEach((f: IFieldMetadata) => {
        if (
          f.hideInReport ||
          RowsAndColumnsBuilderMethods.removeForbiddenColumnName(f.name) ||
          !!f.type
        )
          return;

        let size = this.ROW_SIZES.MEDIUM;
        if (this.extraLargeSizeFieldsList.some((field: string) => field === f.name)) {
          size = this.ROW_SIZES.EXTRA_LARGE;
        } else if (this.largeSizeFieldsList.some((field: string) => field === f.name)) {
          size = this.ROW_SIZES.LARGE;
        } else if (this.mediumSizeFieldsList.some((field: string) => field === f.name)) {
          size = this.ROW_SIZES.MEDIUM;
        } else if (this.smallSizeFieldsList.some((field: string) => field === f.name)) {
          size = this.ROW_SIZES.SMALL;
        }

        columns.push({
          editable: false,
          field: f.name,
          filterable: false,
          flex: 1,
          headerName: f.displayName + (f.units ? ` (${f.units})` : ''),
          minWidth: size,
          renderCell: (params: any) => this.renderCell(params),
          sortable: false,
          type: RowsAndColumnsBuilderMethods.getColumnType(f.dataType),
        });
      });
    return columns;
  }

  buildRows(rows: any[]) {
    if (!rows?.length) return [];

    return rows.map((doc: any) => {
      return Object.values(this.fieldsNames).reduce(
        (acc: any, field: IFieldMetadata) => {
          const value = RowsAndColumnsBuilderMethods.access(field.name, doc);
          acc[field.name] = RowsAndColumnsBuilderMethods.parseValue(value, field.dataType);
          return acc;
        },
        { id: doc._id },
      );
    });
  }
}
