import { IDataSourceFilterComponentDto } from '@a-type/dtos';
import { CombinedSearchType } from '@a-type/enums';
import { IDataSource, IView } from '@a-type/interfaces';
import { DragAndDrop } from '@a-type/ui/components';
import { styles } from '@a-type/ui/pages/counts/components/filters/inputs/SelectedValuesControl.component';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useComponentsQuery,
  useGetCombinedSearchQuery,
  useGetDataSourcesQuery,
  useGetFilterGroupsQuery,
  useUpdateViewFiltersMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { CancelOutlined, WarningOutlined } from '@mui/icons-material';
import { Box, Button, ButtonGroup, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ViewAddFilter } from './components/view-add-filter.component';
import { ViewFilter } from './components/view-filter.component';
import { IViewFilterDetailed } from './types/view-filter-detailed.interface';
import { ViewSteps } from './view-steps.enum';

interface IViewFiltersProps {
  setActiveStep: (step: ViewSteps) => void;
  view: IView | null;
}

export const ViewFilters: React.FC<IViewFiltersProps> = ({
  setActiveStep,
  view,
}: IViewFiltersProps) => {
  const dispatch = useDispatch();
  const { data: filtersComponents } = useComponentsQuery();
  const { data: filtersGroups } = useGetFilterGroupsQuery();
  const { data: combinedSearch } = useGetCombinedSearchQuery(
    view?.type ?? CombinedSearchType.ATTOM,
    {
      skip: !view?.type,
    },
  );
  const { data: dataSourcesData, isFetching: isFetchingDataSources } = useGetDataSourcesQuery();
  const [updateViewFilters, { isLoading: isUpdatingViewFilters }] = useUpdateViewFiltersMutation();
  const [dataSources, setDataSources] = useState<IDataSource[]>([]);
  const [dataSourcesMap, setDataSourcesMap] = useState<{ [key: string]: string }>({});
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState<IViewFilterDetailed[]>([]);
  const [filteredFilters, setFilteredFilters] = useState<IViewFilterDetailed[]>([]);

  const components = (filtersComponents || []).reduce(
    (acc: any, item: IDataSourceFilterComponentDto) => {
      return {
        ...acc,
        [item.type]: item.name,
      };
    },
    {},
  );

  const groups = (filtersGroups || []).reduce((acc: any, item) => {
    return {
      ...acc,
      [item.code]: item.name,
    };
  }, {});

  useEffect(() => {
    dispatch(pageContentLoad(!isUpdatingViewFilters && !isFetchingDataSources));
  }, [isUpdatingViewFilters, isFetchingDataSources]);

  useEffect(() => {
    if (view && combinedSearch) {
      setFilters(
        view.filters.map((filter) => ({
          ...filter,
          filter: combinedSearch.filters.find((f) => f._id === filter.filterId)!,
        })),
      );
    }
  }, [view, combinedSearch]);

  useEffect(() => {
    if (dataSourcesData && combinedSearch) {
      setDataSources([
        // primary data source
        ...dataSourcesData
          .filter((ds) => ds._id === combinedSearch.primaryDataSourceId)
          .filter(Boolean),
        // secondary data sources
        ...combinedSearch.secondaryDataSources
          .map((sds) => dataSourcesData.find((ds) => ds._id === sds.dataSourceId))
          .filter((ds) => ds)
          .map((ds) => ds as IDataSource),
      ]);
    }
  }, [dataSourcesData, combinedSearch]);

  useEffect(() => {
    const map: { [key: string]: string } = {};
    dataSources.forEach((ds) => {
      map[ds._id] = ds.name;
    });

    setDataSourcesMap(map);
  }, [dataSources]);

  useEffect(() => {
    const filteredFields = filters.filter((field) => {
      if (searchText) {
        return field.filter.name.toLowerCase().includes(searchText.toLowerCase());
      }
      return true;
    });

    setFilteredFilters(filteredFields);
  }, [filters, searchText]);

  const handleAddFilter = (filter: IViewFilterDetailed) => {
    setFilters([...filters, filter]);
  };

  const handleDeleteFilter = (id: string) => {
    setFilters(
      filters
        .filter((filter) => filter._id !== id)
        .map((filter, index) => ({ ...filter, sortOrder: index + 1 })),
    );
  };

  const handleUpdateFilter = (id: string, key: string, value: any) => {
    const newFilters = filters.map((filter) => {
      if (filter._id === id) {
        return {
          ...filter,
          [key]: value,
        };
      }
      return filter;
    });

    setFilters(newFilters);
  };

  const handleUpdateFilters = async () => {
    if (!view) return;

    const result = await updateViewFilters({
      filters: filters.map((filter) => ({
        _id: filter._id,
        filterId: filter.filterId,
        price: filter.price,
        sortOrder: filter.sortOrder,
      })),
      id: view._id,
    });

    if (result.data) {
      dispatch(snackbarSuccessMessage('View filters updated successfully'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while updating view filters'));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
          }}
        >
          <ButtonGroup
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              maxWidth: '400px',
              width: '100%',
            }}
          >
            <TextField
              label="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder="Search"
              size="small"
              sx={{
                '.MuiOutlinedInput-root': {
                  borderEndEndRadius: 0,
                  borderStartEndRadius: 0,
                },
                flexGrow: 1,
              }}
              value={searchText}
              variant="outlined"
            />
            <Button
              onClick={() => setSearchText('')}
              size="small"
              startIcon={<CancelOutlined />}
              sx={{
                ...styles.filterButton,
                borderEndStartRadius: 0,
                borderStartStartRadius: 0,
              }}
              variant="outlined"
            >
              Clear
            </Button>
          </ButtonGroup>

          <ViewAddFilter
            combinedSearchFilters={combinedSearch?.filters ?? []}
            dataSourceMap={dataSourcesMap}
            filters={filters}
            onAddFilter={handleAddFilter}
          />
        </Box>

        {filters.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <DragAndDrop
              getKey={(filter) => filter._id}
              items={filteredFilters}
              renderItem={(filter, index, p, s) => (
                <ViewFilter
                  components={components}
                  dataSources={dataSourcesMap}
                  draggableProps={p.draggableProps}
                  dragHandleProps={p.dragHandleProps}
                  dragRef={p.innerRef}
                  filter={filter}
                  groups={groups}
                  isDragDisabled={searchText !== ''}
                  isDragging={s.isDragging}
                  key={filter._id}
                  onFilterChange={(key, value) => handleUpdateFilter(filter._id, key, value)}
                  onFilterDelete={() => handleDeleteFilter(filter._id)}
                />
              )}
              setItems={(updatedFilters) => {
                setFilters([...updatedFilters]);
                setFilteredFilters([...updatedFilters]);
              }}
            />
          </Box>
        )}

        {filters.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                display: 'flex',
                fontSize: 16,
                fontWeight: 400,
                justifyContent: 'center',
                py: 1,
                width: '100%',
              }}
            >
              <WarningOutlined sx={{ color: globalStyles.mainColors.redColor, mr: 1 }} />
              No filters added
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color="primary"
          disabled={isUpdatingViewFilters || !filters.length}
          onClick={handleUpdateFilters}
          sx={{ minWidth: 120 }}
          variant="contained"
        >
          Update
        </Button>
        <Button color="primary" onClick={() => setActiveStep(ViewSteps.Base)} variant="outlined">
          Back to Basic Information
        </Button>
        <Button color="primary" onClick={() => setActiveStep(ViewSteps.Pricing)} variant="outlined">
          Continue to Pricing
        </Button>
      </Box>
    </Box>
  );
};
