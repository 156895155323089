import { IUpdateUserContact, IUserInfo } from '@a-type/dtos';
import { Gender } from '@a-type/enums';
import { Dialog, FormDatepicker, FormInput, FormSelect } from '@a-type/ui/components';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useUpdateContactInfoMutation } from '@a-type/ui/stores/apis';
import { getError } from '@a-type/ui/utils';
import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

interface IUpdateContactInfoProps {
  setShow: (show: boolean) => void;
  show: boolean;
  user: IUserInfo;
}

export const UpdateContactForm: FC<IUpdateContactInfoProps> = ({
  setShow,
  show,
  user,
}: IUpdateContactInfoProps) => {
  const dispatch = useDispatch();
  const [updateContractInfo, { isLoading }] = useUpdateContactInfoMutation();
  const { control, getValues, handleSubmit } = useForm<IUpdateUserContact>({ defaultValues: user });

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  const handleUpdateContractInfo = async () => {
    const result = await updateContractInfo(getValues());

    if (result.data) {
      dispatch(snackbarSuccessMessage('Contract info updated successfully'));
      setShow(false);
    }

    if (result.error) {
      dispatch(
        snackbarErrorMessage(getError(result.error) ?? 'Error while updating contract info'),
      );
    }
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Save"
      onCancel={() => setShow(false)}
      onClose={() => setShow(false)}
      onOk={handleSubmit(handleUpdateContractInfo)}
      open={show}
      size="sm"
      title="Edit Contract Info"
    >
      <Box
        component="form"
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3.5,
          p: 1,
        }}
      >
        <FormInput control={control} label="First Name" name="firstName" required />
        <FormInput control={control} label="Last Name" name="lastName" required />
        <FormInput
          control={control}
          label="Phone Number"
          maxLength={16}
          name="mobile"
          required
          type="tel"
        />
        <FormInput control={control} label="Email" name="email" required />
        <FormInput control={control} label="Company Name" name="companyName" required />
        <FormDatepicker control={control} label="Birth Date" maxDate={new Date()} name="birthday" />
        <FormSelect
          control={control}
          getOptionKey={(option) => option.code}
          getOptionLabel={(option) => option.name}
          label="Gender"
          name="gender"
          options={[
            { code: Gender.UNKNOWN, name: 'Unknown' },
            { code: Gender.FEMALE, name: 'Female' },
            { code: Gender.MALE, name: 'Male' },
          ]}
        />
      </Box>
    </Dialog>
  );
};
