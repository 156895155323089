import { userLocalStorageWorker } from '@a-type/ui/services/local-storage.service';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const isUser = userLocalStorageWorker.read();

export interface AuthInitialState {
  baseInfo: any | null;
  errorMessage: null | string;
  isLogin: boolean;
  isLoginError: boolean;
  isRegisterError: boolean;
}

export const initialState: AuthInitialState = {
  baseInfo: isUser || null,
  errorMessage: null,
  isLogin: !!isUser,
  isLoginError: false,
  isRegisterError: false,
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    clearAuthErrorMessage: (state) => {
      return {
        ...state,
        errorMessage: null,
        isLoginError: false,
        isRegisterError: false,
      };
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        errorMessage: action.payload,
        isLogin: false,
        isLoginError: true,
      };
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      const user = action.payload;

      return {
        ...state,
        baseInfo: user || state.baseInfo,
        errorMessage: null,
        isLogin: true,
        isLoginError: false,
      };
    },
    logout: (state) => {
      return {
        ...state,
        baseInfo: null,
        errorMessage: null,
        isLogin: false,
        isLoginError: false,
        isRegisterError: false,
      };
    },
    registerFailure: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        errorMessage: action.payload,
        isRegisterError: true,
      };
    },
    registerSuccess: (state) => {
      return {
        ...state,
        errorMessage: null,
        isRegisterError: false,
      };
    },
  },
});

export const {
  clearAuthErrorMessage,
  loginFailure,
  loginSuccess,
  logout,
  registerFailure,
  registerSuccess,
} = authSlice.actions;

export default authSlice.reducer;
