export enum ColorConfig {
  ALICE_BLUE = '#F6FAFF',
  BACKGROUND_EDIT_CARD = '#1976d214',
  BLACK = '#000000',
  BLACK_PANTER = '#434343',
  BLEACHED_WHITE = '#dce0e6',
  BlOOMING_ASTER = '#d8e3ee',
  BLUE = '#009EF6',
  BLUISH_GRAY = '#00466D',
  BOWEN_BLUE = '#106ba3',
  BRIQUETTE = '#E36565',
  CARMINE_PINK = '#EB4C42',
  CARRIER_PIGEON = '#8B959A',
  CHILD_OF_LIGHT = '#F7F9FB',
  CHILD_OF_LIGHT_SECOND = '#f5f8fa',
  DARK_SPELL = '#30404d',
  DARK_SUMMONING = '#39393D',
  DELI_YELLOW = '#E8B229',
  DISTANT_WIND_CHIME = '#F4F6F8',
  EERIE_BLACK = '#1c1c1c',
  EMERALD_REFLECTION = '#50C878',
  ERRIGAL_WHITE = '#f5f5f7',
  GAINSBORO = '#E6E6E6',
  GLAZED_GRANITE = '#5B5D5F',
  GLEN_FALLS = '#aeb8c2',
  GLUON_GRAY = '#1d1e1f',
  GRAY_OF_DARKNESS = '#A2A2A2',
  GRAY_SEVEN_FOUR = '#BDBDBD',
  GREEN = '#0DA983',
  GREY = '#bbb',
  HAWKES_BLUE = '#E5F1FD',
  HAWKES_BLUE_SECOND = '#D3E7F6',
  HEAD_TABLE_GREY = '#8793A6',
  HOUSE_STARK_GREY = '#4C4A59',
  INSIGNIA_WHITE = '#EFF5FA',
  ITALIAN_GRAPE = '#413F4B',
  LAVENDER_SPARKLE = '#CCCBDA',
  LEERY_LEMON = '#F4CA16',
  LIGHT_BLUE = '#B9E6FF',
  LIGHT_BLUE_GRAY = '#DADFE4',
  LIGHT_FOG = '#FAFAFA',
  LIGHT_GRAY = '#E3E8ED',
  LIGHT_GREEN = '#E3E8EDBB',
  LIGHT_RED = '#FF7276',
  LIGHT_UNAVAILABLE_RANGE_GRAY = '#DFE7EB',
  LUSCIOUS_PURPLE = '#605D70',
  MIDDLE_GREEN = '#00BA61',
  MIDNIGHT_DREAM = '#394b59',
  MOON_LILY = '#E9E9EA',
  MORE_THAN_A_WEEK = '#8C8C8C',
  NERO = '#262626',
  NICE_BLUE = '#137cbd',
  PAISLEY = '#706E7A',
  PALLADIUM = '#B2B2B2',
  PENCIL_POINT = '#595D61',
  POWDER_WHITE_COLOR = '#ebf1f5',
  PULM_SHADE = '#76738A',
  RARE_BLUE = '#0047FF',
  RED = '#d52330',
  SERIY_GREY = '#9BA8AC',
  SHADOW_MOUNTAIN = '#595959',
  SILVER = '#bfbfbf',
  SLIDER_RAIL_COLOR = '#009ef645',
  SOLITUDE = '#EFF3FF',
  SOOTY_COLOR = '#141414',
  STAINLESS_STELL = '#B5BFC9',
  STAINLESS_STELL_SECOND = '#B7BFC8',
  TRANQUIL_POND = '#778494',
  UNEXPLAINED = '#6A687A',
  VERY_LIGHT_GRAY = '#CECECE',
  VIOLET_EXTRACT = '#DEE3EC',
  WARNING = '#EF6C00',
  WHITE = '#FFFFFF',
  WHITE_GRAY = '#E0E0E0',
  WHITE_SMOKE = '#f5f5f5',
  YELLOW = '#FFC477',
}

export default ColorConfig;
