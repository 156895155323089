import { UserStatus } from '@a-type/enums';
import { useSelector } from '@a-type/ui/hooks/use-selector';
import { Navigate } from 'react-router-dom';

import { useGetUserInfoQuery } from '../stores/apis';

export const RouteUnauthenticatedOrBlockedComponent: React.FC<{ children: JSX.Element }> = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { isLogin } = useSelector((state) => state.auth);
  const { data: userInfo } = useGetUserInfoQuery(undefined, { skip: !isLogin });

  return isLogin || userInfo?.status === UserStatus.BLOCKED ? <Navigate to="/" /> : children;
};

export const RouteAuthenticatedOrBlockedComponent: React.FC<{ children: JSX.Element }> = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { isLogin } = useSelector((state) => state.auth);
  const { data: userInfo } = useGetUserInfoQuery(undefined, { skip: !isLogin });

  return !isLogin || userInfo?.status === UserStatus.BLOCKED ? <Navigate to="/login" /> : children;
};

export const RouteSuperadminComponent: React.FC<{ children: JSX.Element }> = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { baseInfo, isLogin } = useSelector((state) => state.auth);

  if (!isLogin) {
    return <Navigate to="/login" />;
  }

  if (baseInfo?.role !== 'superadmin' && baseInfo?.role !== 'admin') {
    return <Navigate to="/" />;
  }

  return children;
};
