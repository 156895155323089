import { GridPagination } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetUserTransactionsQuery } from '@a-type/ui/stores/apis';
import { useEffect, useState } from 'react';

import ActivityEmptyComponent from './ActivityEmpty.component';
import ActivityHeaderComponent from './ActivityHeader.component';
import ActivityTableHeaderComponent from './ActivityTableHeader.component';
import ActivityTableItemComponent from './ActivityTableItem';

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 25;

const ActivityComponent = () => {
  const [pagination, setPagination] = useState({
    limit: DEFAULT_LIMIT,
    page: DEFAULT_PAGE,
  });
  const dispatch = useDispatch();
  const {
    data: { count = 0, items: activities = [], pages = 0 } = {
      count: 0,
      items: [],
      pages: 0,
    },
    isLoading,
  } = useGetUserTransactionsQuery({
    limit: pagination.limit,
    page: pagination.page,
  });

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  return (
    <>
      <ActivityHeaderComponent />
      {!activities?.length ? (
        <ActivityEmptyComponent />
      ) : (
        <>
          <ActivityTableHeaderComponent />
          {activities.map((transaction) => {
            return <ActivityTableItemComponent key={transaction._id} transaction={transaction} />;
          })}
          <GridPagination
            count={count}
            limit={pagination.limit}
            onChangePagination={(page, limit) => {
              setPagination({ limit, page });
            }}
            page={pagination.page}
            pages={pages}
          />
        </>
      )}
    </>
  );
};

export default ActivityComponent;
