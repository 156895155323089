import { ICreatePassword } from '@a-type/dtos';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { snackbarErrorMessage, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { usePasswordCreateMutation } from '@a-type/ui/stores/apis';
import { getError } from '@a-type/ui/utils';
import { Box, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { CreatePasswordForm } from './components/create-password-form/create-password-form.component';

export const ResetPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetPasswordToken } = useParams();
  const [resetPassword, { isLoading }] = usePasswordCreateMutation();

  const resetPasswordHandler = async (values: ICreatePassword) => {
    if (!resetPasswordToken) {
      dispatch(snackbarErrorMessage('Invalid password token'));
      navigate(AppRoutes.LoginPage);
      return;
    }

    const response = await resetPassword({
      password: values,
      token: resetPasswordToken,
    });

    if (response.data) {
      dispatch(snackbarSuccessMessage('New password created successfully'));
      navigate(AppRoutes.LoginPage);
    }

    if (response.error) {
      dispatch(
        snackbarErrorMessage(getError(response.error) ?? 'Error while creating new password'),
      );
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        my: 4,
        overflow: 'auto',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
              width: '100%',
            }}
          >
            <Typography component="div" gutterBottom sx={{ fontSize: '32px', fontWeight: '900' }}>
              Create new password
            </Typography>
          </Box>
          <CreatePasswordForm loading={isLoading} onSubmit={resetPasswordHandler} />
        </Box>
      </Container>
    </Box>
  );
};
