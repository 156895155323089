import { configureStore } from '@reduxjs/toolkit';

import apiMiddleware from './apis/api-middleware';
import AppMainReducer from './reducers/App.reducer';
import AuthReducer from './reducers/Auth.reducer';
import CountReducer from './reducers/Count.reducer';
import DataSourceReducer from './reducers/DataSource.reducer';
import ListReducer from './reducers/List.reducer';

// Automatically adds the thunk middleware and the Redux DevTools extension
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['list/setListUploadedCsvFile'],
        // Ignore these paths in the state
        ignoredPaths: ['list.uploadedCsvFile'],
      },
    }).concat(
      // API middleware
      apiMiddleware.middleware,
    ),
  // Automatically calls `combineReducers`
  reducer: {
    [apiMiddleware.reducerPath]: apiMiddleware.reducer,
    appMain: AppMainReducer,
    auth: AuthReducer,
    count: CountReducer,
    dataSource: DataSourceReducer,
    list: ListReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
