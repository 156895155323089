import { Dialog } from '@a-type/ui/components';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';
import { Delete, DragIndicator, Loupe } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

import { IViewFilterDetailed } from '../types/view-filter-detailed.interface';

interface IViewFilterProps {
  components: { [key: string]: string };
  dataSources: { [key: string]: string };
  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  dragRef: (element: HTMLElement | null) => void;
  filter: IViewFilterDetailed;
  groups: { [key: string]: string };
  isDragDisabled: boolean;
  isDragging: boolean;
  onFilterChange: (key: string, value: any) => void;
  onFilterDelete: (filter: IViewFilterDetailed) => void;
}

export const ViewFilter: React.FC<IViewFilterProps> = ({
  components,
  dataSources,
  draggableProps,
  dragHandleProps,
  dragRef,
  filter,
  groups,
  isDragDisabled,
  isDragging,
  onFilterChange,
  onFilterDelete,
}: IViewFilterProps) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <Box
      ref={dragRef}
      {...draggableProps}
      sx={{
        alignItems: 'center',
        border: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
        borderRadius: 2,
        display: 'flex',
        gap: 1,
        my: 0.5,
        px: 1,
        py: 1.5,
        ...(isDragging && {
          backgroundColor: globalStyles.mainColors.lightBlueGrayColor,
          border: `1px solid ${globalStyles.mainColors.blueColor}`,
        }),
      }}
    >
      <Button
        {...dragHandleProps}
        disabled={isDragDisabled}
        startIcon={
          <DragIndicator
            sx={{
              color: globalStyles.mainColors.grayColor,
              fontSize: 20,
            }}
          />
        }
        sx={{
          '.MuiButton-startIcon': {
            marginRight: 0,
          },
          borderRadius: 2,
          cursor: 'grab',
          height: 30,
          minWidth: 30,
          px: 1,
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: 15,
            mt: 0.25,
          }}
        >
          {filter.sortOrder}
        </Typography>
      </Button>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        <Typography
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          {filter.filter.name}
        </Typography>
        <Chip label={dataSources[filter.filter.dataSourceId]} size="small" />
        <Chip color="primary" label={groups[filter.filter.filterGroup]} size="small" />
        <Chip color="warning" label={components[filter.filter.componentType]} size="small" />
        {filter.filter.isGroupedBy && (
          <Tooltip
            sx={{ alignItems: 'center', display: 'flex', p: 0 }}
            title="This field using for narrow further search results."
          >
            <IconButton sx={{ height: 24, width: 24 }}>
              <Loupe
                sx={{
                  color: globalStyles.mainColors.darkSpellColor,
                  fontSize: 20,
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
          marginLeft: 'auto',
        }}
      >
        <TextField
          label="Price"
          onChange={(e) => onFilterChange('price', e.target.value)}
          size="small"
          type="number"
          value={filter.price}
          variant="outlined"
        />
        <Tooltip title="Delete this filter">
          <IconButton
            onClick={() => {
              setShowDeleteDialog(true);
            }}
            sx={{ color: globalStyles.mainColors.redColor, height: 40, width: 40 }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>

      <Dialog
        cancelText="Cancel"
        okText="Yes, Delete"
        onCancel={() => setShowDeleteDialog(false)}
        onClose={() => setShowDeleteDialog(false)}
        onOk={() => {
          setShowDeleteDialog(false);
          onFilterDelete(filter);
        }}
        open={showDeleteDialog}
        title="Delete Data Field"
      >
        Are you sure you want to delete &quot;{filter.filter.displayName}&quot; filter?
      </Dialog>
    </Box>
  );
};
