import { IStripeActiveSubscription } from '@a-type/dtos';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, LinearProgress, Typography } from '@mui/material';

interface ISubscriptionDetailsProps {
  subscription: IStripeActiveSubscription;
}

export const SubscriptionDetails: React.FC<ISubscriptionDetailsProps> = ({
  subscription,
}: ISubscriptionDetailsProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
          borderTop: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
          display: 'flex',
          gap: 4,
          justifyContent: 'space-between',
          py: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.navigation.textColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            Subscription
          </Typography>
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 20,
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            {subscription.name}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.navigation.textColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            Payment
          </Typography>
          <Typography
            component="span"
            sx={{
              alignItems: 'baseline',
              color: globalStyles.mainColors.sootyColor,
              display: 'flex',
              fontSize: 20,
              fontWeight: 700,
              gap: 0.5,
              textTransform: 'uppercase',
            }}
          >
            ${subscription.price.toFixed(2)}
            <Typography
              component="span"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                textTransform: 'none',
              }}
            >
              / month
            </Typography>
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.navigation.textColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            Price / Credit
          </Typography>
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 20,
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            ${subscription.creditPrice.toFixed(3)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.navigation.textColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            Credits / Month
          </Typography>
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 20,
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            {subscription.credits.toLocaleString()}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.navigation.textColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            Next Payment
          </Typography>
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 20,
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
          </Typography>
        </Box>
      </Box>

      {subscription.credits > 0 && subscription.credits >= subscription.usedCredits && (
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            pt: 2.5,
          }}
        >
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            Credits Used
          </Typography>

          <LinearProgress
            sx={{
              backgroundColor: globalStyles.mainColors.lightBlue,
              borderRadius: 4,
              height: 7,
              width: '100%',
            }}
            value={
              subscription.credits > 0 ? (subscription.usedCredits / subscription.credits) * 100 : 0
            }
            variant="determinate"
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {subscription.usedCredits.toLocaleString()} (
              {subscription.credits > 0
                ? ((subscription.usedCredits / subscription.credits) * 100).toFixed(2)
                : 0}
              %)
            </Typography>

            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {subscription.credits.toLocaleString()}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
