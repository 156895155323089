export enum TaxAssessorsDocumentLabels {
  '[ATTOM ID]' = 'Id',
  OwnerTypeDescription1 = 'Owner Type',
  PartyOwner1NameFull = 'Owner Name',
  PartyOwner2NameFull = 'Owner Name 2',
  PartyOwner3NameFull = 'Owner Name 3',
  PartyOwner4NameFull = 'Owner Name 4',
  PropertyAddressFull = 'Property Address',
  PropertyLatitude = 'Latitude',
  PropertyLongitude = 'Longitude',
  PropertyUseGroup = 'Property Use Group',
  PropertyUseStandardized = 'Property Use Standardized',
  TaxAssessedImprovementsPerc = 'Tax Assessed Improvements Percents',
  TaxAssessedValueImprovements = 'Tax Assessed Value Improvements',
  TaxAssessedValueLand = 'Tax Assessed Value Land',
  TaxAssessedValueTotal = 'Tax Assessed Value Total',
  TaxBilledAmount = 'Tax Billed Amount',
  TaxFiscalYear = 'Tax Fiscal Year',
  TaxMarketImprovementsPerc = 'Tax Market Improvements Percents',
  TaxMarketValueImprovements = 'Tax Market Value Improvements',
  TaxMarketValueLand = 'Tax Market Value Land',
  TaxMarketValueTotal = 'Tax Market Value Total',
  TaxMarketValueYear = 'Tax Market Value Year',
  TaxRateArea = 'Tax Rate Area',
  TaxYearAssessed = 'Tax Year Assessed',
}
