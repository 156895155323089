import { IStripeSubscription } from '@a-type/dtos';
import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { pageContentLoad, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { useDeactivateSubscriptionMutation } from '@a-type/ui/stores/apis';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';

interface DeactivateSubscriptionDialogProps {
  onClose: () => void;
  open: boolean;
  subscription: IStripeSubscription;
}

export const DeactivateSubscriptionDialog: React.FC<DeactivateSubscriptionDialogProps> = ({
  onClose,
  open,
  subscription: { id: subscriptionId, name, productId },
}: DeactivateSubscriptionDialogProps) => {
  const dispatch = useDispatch();
  const [DeactivateSubscription, { isLoading: isDeactivateSubscriptionLoading }] =
    useDeactivateSubscriptionMutation();

  useEffect(() => {
    dispatch(pageContentLoad(!isDeactivateSubscriptionLoading));
  }, [isDeactivateSubscriptionLoading]);

  const handleDeactivateSubscription = async () => {
    const data = await DeactivateSubscription({
      id: productId,
      subscriptionId,
    });

    if (data.data) {
      dispatch(snackbarSuccessMessage('Subscription set as default successfully'));
      onClose();
    }
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Deactivate"
      onCancel={onClose}
      onClose={onClose}
      onOk={handleDeactivateSubscription}
      open={open}
      title="Deactivate Subscription"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography>
          Are you sure you want to Deactivate the subscription <strong>{name}</strong>?
        </Typography>
      </Box>
    </Dialog>
  );
};
