import { IFilterGroup } from '@a-type/interfaces';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CircleCheckIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

interface FilterGroupItemProps {
  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  dragRef: (element: HTMLElement | null) => void;
  filterGroup: IFilterGroup;
  inUse: boolean;
  isCodeValid: boolean;
  // drag and drop
  isDragging: boolean;
  isValid: boolean;

  onDeleteFilterGroup: (fg: IFilterGroup) => void;
  selected: null | string;
  setSelected: (id: null | string) => void;
  updateFilterGroupField: (id: string, key: string, value: number | string) => void;
}

export const FilterGroupItem = ({
  draggableProps,
  dragHandleProps,
  dragRef,
  filterGroup,
  inUse,
  isCodeValid,
  isDragging,
  isValid,
  onDeleteFilterGroup,
  selected,
  setSelected,
  updateFilterGroupField,
}: FilterGroupItemProps) => {
  return (
    <Accordion
      ref={dragRef}
      {...draggableProps}
      expanded={selected === filterGroup._id}
      key={filterGroup._id}
      onChange={() => {
        if (isDragging) return;
        setSelected(filterGroup._id);
      }}
      sx={{
        border:
          isValid && isCodeValid
            ? `1px solid ${globalStyles.mainColors.veryLightGrayColor}`
            : `1px solid ${globalStyles.mainColors.redColor}`,
        my: 1,
      }}
    >
      <AccordionSummary
        onClick={(e) => {
          if (isDragging) return;
          e.stopPropagation();

          if (selected === filterGroup._id) {
            setSelected(null);
          } else {
            setSelected(filterGroup._id);
          }
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
            }}
          >
            <Button
              {...dragHandleProps}
              startIcon={
                <DragIndicatorIcon
                  sx={{
                    color: globalStyles.mainColors.grayColor,
                    fontSize: 20,
                  }}
                />
              }
              sx={{
                '.MuiButton-startIcon': {
                  marginRight: 0,
                },
                borderRadius: 2,
                cursor: 'grab',
                height: 30,
                minWidth: 30,
                px: 1,
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontSize: 15,
                  mt: 0.25,
                }}
              >
                {filterGroup.sortOrder}
              </Typography>
            </Button>

            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.blackColor,
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              {filterGroup.name}
            </Typography>
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.grayColor,
                display: 'block',
                fontSize: 12,
              }}
            >
              ({filterGroup.code})
            </Typography>

            {inUse && (
              <Tooltip title="This filter group is in use and cannot be deleted">
                <CircleCheckIcon color="success" sx={{ fontSize: 20 }} />
              </Tooltip>
            )}
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
              justifyContent: 'flex-end',
            }}
          >
            {!(inUse && isCodeValid) && (
              <Tooltip title="Delete this filter group">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteFilterGroup(filterGroup);
                  }}
                  sx={{ color: globalStyles.mainColors.blackColor, height: 40, width: 40 }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}

            <Box
              onClick={(e) => {
                if (isDragging) return;
                e.stopPropagation();

                if (selected === filterGroup._id) {
                  setSelected(null);
                } else {
                  setSelected(filterGroup._id);
                }
              }}
              sx={{ alignItems: 'center', display: 'flex' }}
            >
              {selected === filterGroup._id ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      {selected === filterGroup._id && (
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                error={!isValid}
                fullWidth
                inputProps={{ maxLength: 50 }}
                label="Name"
                onChange={(e) => updateFilterGroupField(filterGroup._id, 'name', e.target.value)}
                value={filterGroup.name}
                variant="outlined"
              />
              <TextField
                disabled={inUse && isValid}
                error={!isCodeValid}
                fullWidth
                helperText='Code must be unique and contain only letters, numbers, and underscores "_"'
                inputProps={{ maxLength: 50 }}
                label="Code"
                onChange={(e) => updateFilterGroupField(filterGroup._id, 'code', e.target.value)}
                value={filterGroup.code}
                variant="outlined"
              />
            </Box>
            <TextField
              fullWidth
              inputProps={{
                error:
                  filterGroup.description && filterGroup.description.length > 500
                    ? 'Description must be less than 500 characters'
                    : null,
                maxLength: 500,
              }}
              label="Description"
              multiline
              onChange={(e) =>
                updateFilterGroupField(filterGroup._id, 'description', e.target.value)
              }
              rows={3}
              sx={{ marginTop: 1 }}
              value={filterGroup.description || ''}
              variant="outlined"
            />
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default FilterGroupItem;
