import { ImageErrors } from '@a-type/ui/constant/dictionary/errors.constant';
import { UserImageInput } from '@a-type/ui/constant/dictionary/user-input-types.constant';
import { useDispatch } from '@a-type/ui/hooks';
import { pageContentLoad, snackbarErrorMessage } from '@a-type/ui/stores/actions';
import { useUploadImageMutation } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { PhotoCameraOutlined } from '@mui/icons-material';
import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material';
import { ChangeEvent, useRef, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';

import { Dialog } from '../dialog/Dialog.component';

function readFile(file: any): Promise<ArrayBuffer | null | string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

function isValidImageFile(file: File): boolean {
  const validImageTypes = UserImageInput.VALID_IMAGE_FORMATS;
  return validImageTypes.includes(file.type);
}

interface IImageUploadProps {
  disabled?: boolean;
  height?: number;
  name: string;
  shape?: 'rect' | 'round';
  sx?: SxProps<Theme>;
  updateImage: (image: string) => void;
  width?: number;
}

export const ImageUpload: React.FC<IImageUploadProps> = ({
  disabled = false,
  height = 300,
  name,
  shape = 'rect',
  sx = {},
  updateImage,
  width = 400,
}: IImageUploadProps) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageSrc, setImageSrc] = useState<null | string>(null);
  const [showUpload, setShowUpload] = useState(false);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [uploadImageToServer] = useUploadImageMutation();

  const onSelectFile = () => {
    if (fileInputRef.current) {
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (!isValidImageFile(file)) {
        dispatch(snackbarErrorMessage(ImageErrors.INVALID_FORMAT));
        return;
      }

      const imageDataUrl = await readFile(file);
      if (typeof imageDataUrl === 'string') {
        setImageSrc(imageDataUrl);
        setShowUpload(true);
      }
    }
  };

  const onCropComplete = (croppedArea: Area, ca: Area) => {
    setCroppedAreaPixels(ca);
  };

  const handleCancel = () => {
    setImageSrc(null);
    setShowUpload(false);
  };

  const handleUpload = () => {
    if (!croppedAreaPixels || !imageSrc) return;

    dispatch(pageContentLoad(false));
    const canvas = document.createElement('canvas');
    const image = new Image();
    image.src = imageSrc;
    image.onload = async () => {
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(
          image,
          croppedAreaPixels.x * scaleX,
          croppedAreaPixels.y * scaleY,
          croppedAreaPixels.width * scaleX,
          croppedAreaPixels.height * scaleY,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
        );
      }

      // resizes the image to the desired width and height if needed
      if (width && height) {
        const resizedCanvas = document.createElement('canvas');
        const resizedCtx = resizedCanvas.getContext('2d');
        if (resizedCtx) {
          resizedCanvas.width = width;
          resizedCanvas.height = height;
          resizedCtx.drawImage(canvas, 0, 0, width, height);
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d')?.drawImage(resizedCanvas, 0, 0, width, height);
        }
      }

      const base64Image = canvas.toDataURL('image/png');
      const responce = await uploadImageToServer({ base64: base64Image, name });
      if (responce.data) {
        const url = responce.data;
        updateImage(url);
        setImageSrc(null);
        setShowUpload(false);
        dispatch(pageContentLoad(true));
      }

      if (responce.error) {
        dispatch(snackbarErrorMessage(getError(responce.error) ?? 'Error during image upload'));
      }
    };
  };

  return (
    <>
      <input accept="image/*" hidden onChange={onFileChange} ref={fileInputRef} type="file" />
      <IconButton
        disabled={disabled}
        onClick={onSelectFile}
        sx={{
          '&:hover': {
            backgroundColor: globalStyles.mainColors.childOfLightColor,
          },
          backgroundColor: globalStyles.mainColors.whiteColor,
          border: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
          ...sx,
        }}
      >
        <PhotoCameraOutlined
          sx={{
            color: disabled ? globalStyles.mainColors.grayColor : globalStyles.mainColors.blueColor,
            fontSize: '24px',
          }}
        />
      </IconButton>

      <Dialog
        cancelText="Cancel"
        okText="Upload"
        onCancel={handleCancel}
        onClose={handleCancel}
        onOk={handleUpload}
        open={showUpload}
        title="Upload Image"
      >
        <Typography
          component="span"
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: '16px',
          }}
        >
          Use scroll to zoom in and out. Drag the image to move it.
        </Typography>

        {!!imageSrc && (
          <Box
            sx={{
              background: globalStyles.mainColors.whiteColor,
              height: '300px',
              mt: 1,
              position: 'relative',
              width: '100%',
            }}
          >
            <Cropper
              aspect={width / height}
              crop={crop}
              cropShape={shape}
              image={imageSrc}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              zoom={zoom}
              zoomSpeed={0.25}
            />
          </Box>
        )}
      </Dialog>
    </>
  );
};
