import globalStyles from '@a-type/ui/styles/global.styles';
import styled, { CSSObject } from '@emotion/styled';
import { FormControlLabel, Switch as MuiSwitch, SxProps, Theme } from '@mui/material';

const base = (): CSSObject => ({
  '& .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb': {
    color: globalStyles.mainColors.blueColor,
  },
  '& .MuiButtonBase-root.Mui-checked+.MuiSwitch-track': {
    background: globalStyles.mainColors.blueColor,
    opacity: 0.5,
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    height: 16,
    margin: 2,
    width: 16,
  },
  '& .MuiSwitch-track': {
    '&:before, &:after': {
      content: '""',
      height: 16,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
    },
    borderRadius: 22 / 2,
  },
});

const padding = () => ({
  padding: 8,
});

const StyledSwitch = styled(MuiSwitch)(base, padding);

export interface SwitchProps {
  /**
   * The checked state of the switch
   */
  checked?: boolean;
  /**
   * The switch label
   */
  label: string;
  /**
   * The function to call when the switch is toggled
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;

  sx?: SxProps<Theme>;
}

/**
 * A switch component. It can be toggled on or off. It is used to switch between two states.
 */
export const Switch = ({ checked = false, label, onChange, sx }: SwitchProps) => {
  return (
    <FormControlLabel
      control={<StyledSwitch checked={checked} onChange={onChange} />}
      label={label}
      onClick={(e) => {
        e.stopPropagation();
      }}
      sx={{
        ...sx,
        '& .MuiFormControlLabel-label': {
          marginTop: '0.1rem',
        },
        color: globalStyles.mainColors.headTableGreyColor,
        textTransform: 'uppercase',
      }}
    />
  );
};

export default Switch;
