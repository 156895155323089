import globalStyles from '@a-type/ui/styles/global.styles';
import { Theme } from '@emotion/react';
import { Checkbox, FormControlLabel, FormGroup, FormHelperText, SxProps } from '@mui/material';
import { Control, Controller, Validate } from 'react-hook-form';

interface IFormCheckboxProps {
  control: Control<any>;
  label: string;
  name: string;
  required?: boolean;
  rules?: Record<string, Validate<string, any>>;
  sx?: SxProps<Theme>;
}

export const FormCheckbox: React.FC<IFormCheckboxProps> = ({
  control,
  label,
  name,
  required = false,
  rules = {},
  sx,
}: IFormCheckboxProps) => {
  return (
    <Controller
      control={control}
      defaultValue={false}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormGroup
          sx={{
            position: 'relative',
          }}
        >
          <FormControlLabel
            control={<Checkbox checked={value} onChange={onChange} required={required} />}
            label={label}
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
              fontWeight: 400,
              ...sx,
            }}
          />
          {error && (
            <FormHelperText
              component="span"
              sx={{
                bottom: '-12px',
                color: globalStyles.mainColors.redColor,
                position: 'absolute',
                textWrap: 'nowrap',
              }}
            >
              {error.message}
            </FormHelperText>
          )}
        </FormGroup>
      )}
      rules={{
        validate: {
          required: required ? (value) => !!value || 'Field is required' : () => true,
          ...rules,
        },
      }}
    />
  );
};
