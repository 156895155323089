import { CustomStripeElement, Dialog, styles } from '@a-type/ui/components';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarInfoMessage,
} from '@a-type/ui/stores/actions';
import { useCreateCardMutation, useGetCardsQuery } from '@a-type/ui/stores/apis';
import { CardInfoTypes } from '@a-type/ui/types';
import { getError } from '@a-type/ui/utils';
import { Box } from '@mui/material';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface AddPaymentMethodDialogProps {
  elements: null | StripeElements;
  onClose: () => void;
  open: boolean;
  stripe: null | Stripe;
}

const STRIPE_ELEMENTS_IDS = {
  CARD_CVC: 'cardCvc',
  CARD_EXPIRY: 'cardExpiry',
  CARD_NUMBER: 'cardNumber',
};

export const AddPaymentMethodDialog: React.FC<AddPaymentMethodDialogProps> = ({
  elements,
  onClose,
  open,
  stripe,
}: AddPaymentMethodDialogProps) => {
  const dispatch = useDispatch();
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);
  const [createCard, { isLoading: createCardLoading }] = useCreateCardMutation();
  const { isLoading: cardsLoading, refetch } = useGetCardsQuery();
  const [cardNumber, setCardNumber] = useState<CardInfoTypes>({
    brand: 'unknown',
    complete: false,
    elementType: 'cardNumber',
    empty: true,
    error: false,
  });
  const [cardExpiry, setCardExpiry] = useState<CardInfoTypes>({
    complete: false,
    elementType: 'cardExpiry',
    empty: true,
    error: false,
  });
  const [cardCvc, setCardCvc] = useState<CardInfoTypes>({
    complete: false,
    elementType: 'cardCvc',
    empty: true,
    error: false,
  });

  useEffect(() => {
    dispatch(pageContentLoad(!stripeLoading && !createCardLoading && !cardsLoading));
  }, [stripeLoading, createCardLoading, cardsLoading]);

  const isSaveButtonDisabled = () => {
    return [cardNumber, cardExpiry, cardCvc].some(
      (item: any) => item.error || item.empty || !item.complete,
    );
  };

  const onSaveHandler = async () => {
    if (!stripe || !elements) {
      dispatch(snackbarErrorMessage('Stripe is not loaded'));
      return;
    }

    const cardNumberElement = elements.getElement('cardNumber');
    if (!cardNumberElement) {
      dispatch(snackbarErrorMessage('Card number is not valid'));
      return;
    }

    setStripeLoading(true);
    const payment = await stripe.createPaymentMethod({
      card: cardNumberElement,
      type: 'card',
    });

    if (payment.error) {
      dispatch(
        snackbarErrorMessage(payment.error.message ?? 'Error while creating payment method'),
      );
      setStripeLoading(false);
      return;
    }

    const result = await createCard(payment.paymentMethod.id);

    // wait 1sec to let the card be added to the list
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    setStripeLoading(false);
    dispatch(pageContentLoad(true));

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while creating card'));
      onClose();
      return;
    }

    // Refetch cards list
    await refetch();

    dispatch(snackbarInfoMessage('Card successfully added.'));
    dispatch(pageContentLoad(true));

    onClose();
  };

  return (
    <Dialog
      disableOk={isSaveButtonDisabled()}
      okText="Save"
      onClose={onClose}
      onOk={onSaveHandler}
      open={open}
      size="sm"
      title="Add Payment Method"
    >
      <Box sx={styles}>
        <CustomStripeElement
          brand={cardNumber.brand}
          className="card-number"
          classNameWrapper="card-number-wrapper"
          complete={cardNumber.complete}
          component={CardNumberElement}
          empty={cardNumber.empty}
          error={cardNumber.error}
          id={STRIPE_ELEMENTS_IDS.CARD_NUMBER}
          onChange={(data) =>
            setCardNumber((c) => ({
              ...c,
              brand: data.brand,
              complete: data.complete,
              empty: data.empty,
              error: data.error,
            }))
          }
        />
        <CustomStripeElement
          brand={cardExpiry.brand}
          className="card-exp"
          classNameWrapper="card-exp-wrapper"
          complete={cardExpiry.complete}
          component={CardExpiryElement}
          empty={cardExpiry.empty}
          error={cardExpiry.error}
          id={STRIPE_ELEMENTS_IDS.CARD_EXPIRY}
          onChange={(data) =>
            setCardExpiry((c) => ({
              ...c,
              brand: data.brand,
              complete: data.complete,
              empty: data.empty,
              error: data.error,
            }))
          }
        />
        <CustomStripeElement
          brand={cardCvc.brand}
          className="card-cvc"
          classNameWrapper="card-cvc-wrapper"
          complete={cardCvc.complete}
          component={CardCvcElement}
          empty={cardCvc.empty}
          error={cardCvc.error}
          id={STRIPE_ELEMENTS_IDS.CARD_CVC}
          onChange={(data) =>
            setCardCvc((c) => ({
              ...c,
              brand: data.brand,
              complete: data.complete,
              empty: data.empty,
              error: data.error,
            }))
          }
        />
      </Box>
    </Dialog>
  );
};
