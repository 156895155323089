import { Components, Theme } from '@mui/material';

import globalStyles from '../global.styles';

const style: Components<Omit<Theme, 'components'>> = {
  MuiMenu: {
    styleOverrides: {
      root: () => ({
        ul: {
          '&[role="menu"]': {
            backgroundColor: globalStyles.mainColors.whiteColor,
            borderRadius: '8px',
            minWidth: '184px',
          },
        },
      }),
    },
  },
};

export default style;
