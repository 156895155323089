import { IDataSource, IViewPricingFields } from '@a-type/interfaces';
import { Dialog, UseIgnoreSwitch } from '@a-type/ui/components';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Edit, WarningOutlined } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FixedSizeList } from 'react-window';

interface IViewPriceFieldsProps {
  dataSource: IDataSource;
  fields: IViewPricingFields;
  onPricingFieldsChange: (fields: IViewPricingFields) => void;
  required?: boolean;
}

export const ViewPriceFields: React.FC<IViewPriceFieldsProps> = ({
  dataSource,
  fields,
  onPricingFieldsChange,
  required = false,
}: IViewPriceFieldsProps) => {
  const [showEdit, setShowEdit] = useState(false);

  const isCheckboxChecked = (field: string) => {
    return fields.includedFields.includes(field) || fields.excludedFields.includes(field);
  };

  const handleCheckboxChange = (field: string) => {
    if (fields.includedFields.includes(field)) {
      const includedFields = fields.includedFields.filter((f) => f !== field);

      onPricingFieldsChange({
        ...fields,
        includedFields,
      });
    } else if (fields.excludedFields.includes(field)) {
      const excludedFields = fields.excludedFields.filter((f) => f !== field);

      onPricingFieldsChange({
        ...fields,
        excludedFields,
      });
    } else {
      onPricingFieldsChange({
        ...fields,
        includedFields: [...fields.includedFields, field],
      });
    }
  };

  const handleIgnoreChange = (field: string) => {
    const includedFields = fields.includedFields.filter((f) => f !== field);
    const excludedFields = [...fields.excludedFields, field];

    onPricingFieldsChange({
      ...fields,
      excludedFields,
      includedFields,
    });
  };

  const handleUseChange = (field: string) => {
    const excludedFields = fields.excludedFields.filter((f) => f !== field);
    const includedFields = [...fields.includedFields, field];

    onPricingFieldsChange({
      ...fields,
      excludedFields,
      includedFields,
    });
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        border: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        px: 2,
        py: 1.5,
      }}
    >
      <Box
        sx={{
          alignItems: 'flex-end',
          borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
          display: 'flex',
          gap: 2,
          justifyContent: 'space-between',
          pb: 1,
          width: '100%',
        }}
      >
        <Typography
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 20,
            fontWeight: 500,
          }}
        >
          {dataSource.name}
        </Typography>

        <IconButton onClick={() => setShowEdit(!showEdit)} size="small">
          <Edit
            fontSize="small"
            sx={{
              color: globalStyles.mainColors.blueColor,
            }}
          />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          width: '100%',
        }}
      >
        {fields.includedFields.length > 0 && (
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            Included Fields:
          </Typography>
        )}

        {fields.includedFields.map((f) => (
          <Chip color="info" key={f} label={f} size="small" />
        ))}

        {required && fields.includedFields.length === 0 && (
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              display: 'flex',
              fontSize: 16,
              fontWeight: 400,
              justifyContent: 'center',
              py: 1,
              width: '100%',
            }}
          >
            <WarningOutlined sx={{ color: globalStyles.mainColors.redColor, mr: 1 }} />
            You must include at least one field
          </Typography>
        )}

        <Box sx={{ height: '0px', width: '100%' }} />

        {fields.excludedFields.length > 0 && (
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            Excluded Fields:
          </Typography>
        )}

        {fields.excludedFields.map((f) => (
          <Chip color="error" key={f} label={f} size="small" />
        ))}

        {fields.includedFields.length === 0 && fields.excludedFields.length === 0 && (
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              display: 'flex',
              fontSize: 16,
              fontWeight: 400,
              justifyContent: 'center',
              py: 1,
              width: '100%',
            }}
          >
            No fields selected
          </Typography>
        )}
      </Box>

      {showEdit && (
        <Dialog
          okText="Close"
          onClose={() => setShowEdit(false)}
          onOk={() => setShowEdit(false)}
          open={showEdit}
          size="md"
          title={`Edit ${dataSource.name} Fields`}
        >
          {dataSource?.fields && (
            <Box sx={{ height: 500, width: '100%' }}>
              <FixedSizeList
                height={500}
                itemCount={dataSource.fields.length}
                itemSize={50}
                overscanCount={5}
                width="100%"
              >
                {({ index, style }) => {
                  const f = dataSource.fields[index];
                  const labelId = `checkbox-list-label-${f.name}`;

                  return (
                    <ListItem
                      disablePadding
                      key={f.name}
                      onClick={() => handleCheckboxChange(f.name)}
                      secondaryAction={
                        isCheckboxChecked(f.name) && (
                          <UseIgnoreSwitch
                            checked={fields.excludedFields.includes(f.name)}
                            ignoreColor={globalStyles.mainColors.redColor}
                            ignoreLabel="Exclude"
                            onChange={(checked) =>
                              checked ? handleIgnoreChange(f.name) : handleUseChange(f.name)
                            }
                            useLabel="Include"
                          />
                        )
                      }
                      style={style}
                    >
                      <ListItemButton dense>
                        <ListItemIcon>
                          <Checkbox
                            checked={isCheckboxChecked(f.name)}
                            disableRipple
                            edge="start"
                            inputProps={{ 'aria-labelledby': labelId }}
                            tabIndex={-1}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={f.displayName} />
                      </ListItemButton>
                    </ListItem>
                  );
                }}
              </FixedSizeList>
            </Box>
          )}
        </Dialog>
      )}
    </Box>
  );
};
