import logoForDarkDesign from '@a-type/ui/assets/branding/logo.png';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import globalStyles from '@a-type/ui/styles/global.styles';
import { AppBar, Box, CardMedia, CssBaseline, Toolbar, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavigationComponent } from './navigation.component';

export const HeaderComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const onLogoClick: () => void = () => {
    if (location.pathname !== AppRoutes.HomePage) {
      dispatch({ type: 'RESET' });
    }

    navigate(AppRoutes.HomePage);
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: globalStyles.mainColors.whiteColor,
          boxShadow: '2px 2px 4px 0px rgba(34, 60, 80, 0.1)',
          display: 'flex',
          height: 'fit-content',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ width: '100%' }}>
          <Box
            onClick={() => {
              onLogoClick();
            }}
            sx={{ cursor: 'pointer', display: 'flex', width: '300px' }}
          >
            <CardMedia
              alt="logo"
              component="img"
              height="20"
              sizes="2"
              src={logoForDarkDesign}
              sx={{ height: 30, width: 60 }}
              width="30"
            />
            <Typography
              component="div"
              noWrap
              sx={{
                color: globalStyles.mainColors.blackColor,
                marginLeft: 2,
              }}
              variant="h5"
            >
              A Type Data
            </Typography>
          </Box>
          <NavigationComponent />
        </Toolbar>
      </AppBar>
    </>
  );
};
