import { Components, Theme } from '@mui/material';

import globalStyles from '../global.styles';

const style: Components<Omit<Theme, 'components'>> = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: () => ({
        backgroundColor: globalStyles.mainColors.whiteColor,
        borderRadius: '8px',
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.08)',
        color: globalStyles.mainColors.sootyColor,
        fontSize: '12px',
        gap: '2px',
        padding: '8px 12px 12px 12px',
      }),
    },
  },
};

export default style;
