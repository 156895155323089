import { SerializedError } from '@reduxjs/toolkit';

import { BaseQueryError } from '../stores/axios-base-query';

/**
 * Extracts a message from an array of error objects or strings.
 *
 * @param messageArray - An array containing error messages or objects.
 * @returns A string representing the error message, or `undefined` if no valid message is found.
 *
 * The function processes the first item in the array:
 * - If the item is a string, it returns the string.
 * - If the item is an object with `property` and `message` properties, it returns a formatted validation error message.
 * - If the item is an object with other properties, it returns the first key-value pair as a string.
 */
const getMessageFromArray = (messageArray: any[]): string | undefined => {
  const item = messageArray[0];

  if (typeof item === 'string') return item;

  if (item.property && item.message) {
    return item.message;
  }

  if (typeof item === 'object') {
    const keys = Object.keys(item);

    if (keys.length > 0) {
      return `${keys[0]}: ${item[keys[0]]}`;
    }
  }

  return undefined;
};

/**
 * Extracts a message from the provided data.
 *
 * @param message - The data from which to extract the message. It can be of any type.
 * @returns The extracted message as a string if the input is a string or a non-empty array; otherwise, returns undefined.
 */
const getMessageFromData = (message: any): string | undefined => {
  if (typeof message === 'string') return message;

  if (Array.isArray(message) && message.length > 0) {
    return getMessageFromArray(message);
  }

  return undefined;
};

/**
 * Extracts an error message from a given error object.
 *
 * @param error - The error object which can be either a `BaseQueryError` or a `SerializedError`.
 * @returns The extracted error message as a string, or `undefined` if no message is found.
 */
export const getError = (error: BaseQueryError | SerializedError): string | undefined => {
  const e = error as BaseQueryError;

  if (e?.data?.message) {
    return getMessageFromData(e.data.message);
  }

  if (typeof e === 'string') return e;

  return undefined;
};
