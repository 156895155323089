import { IPaginationDto } from '@a-type/dtos';
import { ITransaction } from '@a-type/interfaces';

import apiMiddleware from './api-middleware';

export const transactionsApi = apiMiddleware.injectEndpoints({
  endpoints: (builder) => ({
    getAllTransactions: builder.query<
      IPaginationDto<ITransaction>,
      {
        limit?: null | number;
        page?: null | number;
      }
    >({
      providesTags: [{ id: 'TRANSACTIONS', type: 'Transactions/All' as const }],
      query: ({ limit, page }) => ({ params: { limit, page }, url: '/transactions/all' }),
    }),
    getUserTransactions: builder.query<
      IPaginationDto<ITransaction>,
      {
        limit?: null | number;
        page?: null | number;
      }
    >({
      providesTags: [{ id: 'TRANSACTIONS', type: 'Transactions/User' as const }],
      query: ({ limit, page }) => ({ params: { limit, page }, url: '/transactions' }),
    }),
  }),
});

export const { useGetAllTransactionsQuery, useGetUserTransactionsQuery } = transactionsApi;
