export class LocalStorageService {
  localStorage: any;

  storageName: string;

  constructor(storageName: string) {
    this.storageName = storageName;
    this.localStorage = window.localStorage;
  }

  create(data: string) {
    return this.localStorage.setItem(this.storageName, JSON.stringify(data));
  }

  delete() {
    return this.localStorage.removeItem(this.storageName);
  }

  read() {
    const storageData = this.localStorage.getItem(this.storageName);
    return JSON.parse(storageData);
  }
}

export const authAccessLocalStorageWorker = new LocalStorageService('access_token');
export const authRefreshLocalStorageWorker = new LocalStorageService('refresh_token');
export const userLocalStorageWorker = new LocalStorageService('user');
