import { Components, Theme } from '@mui/material';

import globalStyles from '../global.styles';

const style: Components<Omit<Theme, 'components'>> = {
  MuiFormControlLabel: {
    styleOverrides: {
      root: () => ({
        '.MuiFormControlLabel-asterisk': {
          color: globalStyles.mainColors.redColor,
        },
      }),
    },
  },
  MuiFormGroup: {
    styleOverrides: {
      root: () => ({
        gap: 6,
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: () => ({
        '&.MuiFormHelperText-sizeMedium': {
          bottom: '-22px',
          position: 'absolute',
          textWrap: 'nowrap',
        },
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: () => ({
        '&.MuiError': {
          color: globalStyles.mainColors.redColor,
        },

        '.MuiFormLabel-asterisk': {
          color: globalStyles.mainColors.redColor,
        },
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: () => ({
        '&:not(.MuiInputLabel-outlined)': {
          color: globalStyles.mainColors.sootyColor,
          fontSize: '17px',
          fontWeight: 700,
        },
      }),
    },
  },
};

export default style;
