import { IStripeSubscription } from '@a-type/dtos';
import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { pageContentLoad, snackbarSuccessMessage } from '@a-type/ui/stores/actions';
import { useSetDefaultSubscriptionMutation } from '@a-type/ui/stores/apis';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';

interface SetDefaultSubscriptionDialogProps {
  onClose: () => void;
  open: boolean;
  subscription: IStripeSubscription;
}

export const SetDefaultSubscriptionDialog: React.FC<SetDefaultSubscriptionDialogProps> = ({
  onClose,
  open,
  subscription: { id: subscriptionId, name, productId },
}: SetDefaultSubscriptionDialogProps) => {
  const dispatch = useDispatch();
  const [setDefaultSubscription, { isLoading: isSetDefaultSubscriptionLoading }] =
    useSetDefaultSubscriptionMutation();

  useEffect(() => {
    dispatch(pageContentLoad(!isSetDefaultSubscriptionLoading));
  }, [isSetDefaultSubscriptionLoading]);

  const handleSetDefaultSubscription = async () => {
    const data = await setDefaultSubscription({
      id: productId,
      subscriptionId,
    });

    if (data.data) {
      dispatch(snackbarSuccessMessage('Subscription set as default successfully'));
      onClose();
    }
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Set as Default"
      onCancel={onClose}
      onClose={onClose}
      onOk={handleSetDefaultSubscription}
      open={open}
      title="Set Default Subscription"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography>
          Are you sure you want to set the subscription <strong>{name}</strong> as default?
        </Typography>
      </Box>
    </Dialog>
  );
};
