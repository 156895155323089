import { useDispatch } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import DashboardDatabaseCard from '@a-type/ui/pages/dashboard/components/DashboardDatabaseCard.component';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';

import { useGetUserDataSourcesQuery } from '../../stores/apis';

const DataSourcesListPage = () => {
  const { data: dataSources = [], isLoading } = useGetUserDataSourcesQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  return (
    <PageLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          sx={{
            color: globalStyles.mainColors.blackColor,
            fontSize: '24px',
            fontWeight: '900',
            margin: 0,
          }}
          variant="h3"
        >
          Databases
        </Typography>

        <Box
          sx={{
            display: 'grid',
            gap: 4,
            gridTemplateColumns: 'repeat(auto-fill, minmax(287px, 1fr))',
          }}
        >
          {dataSources.length > 0 &&
            !isLoading &&
            dataSources.map((dataSource) => (
              <DashboardDatabaseCard dataSource={dataSource} key={dataSource._id} />
            ))}
        </Box>

        {dataSources.length === 0 && !isLoading && (
          <Box
            sx={{
              alignItems: 'center',
              background: globalStyles.mainColors.whiteColor,
              borderRadius: 2,
              display: 'flex',
              height: '400px',
              justifyContent: 'center',
              px: 4,
              py: 1,
            }}
          >
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 20,
                fontWeight: '400',
              }}
            >
              You don&apos;t have access to any databases yet. Please contact administator to get
              access.
            </Typography>
          </Box>
        )}
      </Box>
    </PageLayout>
  );
};

export default DataSourcesListPage;
