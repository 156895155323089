import { IPaginationDto } from '@a-type/dtos';
import { IList } from '@a-type/interfaces';
import axios, { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

class ListService {
  constructor(public config: any) {}

  async buyList(list: IList): Promise<AxiosResponse<IList, any>> {
    try {
      const response = await axios.post(`${this.config.baseUrl}${this.config.patch.buy}`, list);
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }

  async buyMore(list: IList, records: number): Promise<AxiosResponse<IList, any>> {
    try {
      const response = await axios.post(
        `${this.config.baseUrl}${this.config.patch.buyMore}${list._id}/records/${records}`,
      );
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }

  async export(id: string, name: string, buyingDate?: string): Promise<void> {
    try {
      return await axios
        .get(`${this.config.exportBaseUrl}/${id}`, {
          params: buyingDate ? { buyingDate } : {},
          responseType: 'blob',
        })
        .then((response) => {
          const filename = response.headers['content-disposition']?.split('=')[1] ?? `${name}.csv`;
          // Let the user save the file.
          saveAs(response.data, filename);
        });
    } catch (error: any) {
      return error.response;
    }
  }

  async exportOnlineAudience(id: string, name: string): Promise<void> {
    try {
      return await axios
        .get(`${this.config.exportBaseUrl}/${id}/online-audience`, {
          responseType: 'blob',
        })
        .then((response) => {
          const filename =
            response.headers['content-disposition']?.split('=')[1] ?? `${name}_online_audience.zip`;
          // Let the user save the file.
          saveAs(response.data, filename);
        });
    } catch (error: any) {
      return error.response;
    }
  }

  async exportOnlineAudienceByType(id: string, name: string, type: string): Promise<void> {
    try {
      return await axios
        .get(`${this.config.exportBaseUrl}/${id}/online-audience/${type}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const filename =
            response.headers['content-disposition']?.split('=')[1] ?? `${name}_${type}.zip`;
          // Let the user save the file.
          saveAs(response.data, filename);
        });
    } catch (error: any) {
      return error.response;
    }
  }

  async exportTaxAssessorsDocument(id: string, documentId: string, name: string): Promise<void> {
    try {
      return await axios
        .get(`${this.config.exportBaseUrl}/${id}/documents/${documentId}/tax-assessors`, {
          responseType: 'blob',
        })
        .then((response) => {
          const filename = response.headers['content-disposition']?.split('=')[1] ?? name;
          // Let the user save the file.
          saveAs(response.data, filename);
        });
    } catch (error: any) {
      return error.response;
    }
  }

  async exportTaxAssessorsPermits(id: string, documentId: string, name: string): Promise<void> {
    try {
      return await axios
        .get(`${this.config.exportBaseUrl}/${id}/documents/${documentId}/tax-assessors-permits`, {
          responseType: 'blob',
        })
        .then((response) => {
          const filename = response.headers['content-disposition']?.split('=')[1] ?? name;
          // Let the user save the file.
          saveAs(response.data, filename);
        });
    } catch (error: any) {
      return error.response;
    }
  }

  async exportTaxAssessorsRecorders(id: string, documentId: string, name: string): Promise<void> {
    try {
      return await axios
        .get(`${this.config.exportBaseUrl}/${id}/documents/${documentId}/tax-assessors-recorders`, {
          responseType: 'blob',
        })
        .then((response) => {
          const filename = response.headers['content-disposition']?.split('=')[1] ?? name;
          // Let the user save the file.
          saveAs(response.data, filename);
        });
    } catch (error: any) {
      return error.response;
    }
  }

  async getAll(
    page: number,
    limit: number,
    manualUpload: boolean,
  ): Promise<AxiosResponse<IPaginationDto<IList>, any>> {
    try {
      const params: any = {
        params: {
          limit,
          manualUpload,
          page,
        },
      };
      const response = await axios.get(`${this.config.baseUrl}${this.config.patch.getAll}`, params);
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }

  async getById(id: string): Promise<AxiosResponse<IList, any>> {
    try {
      const response = await axios.get(`${this.config.baseUrl}${this.config.patch.getById}${id}`);
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }

  async getByIdWithAvailableCount(id: string): Promise<AxiosResponse<IList, any>> {
    try {
      const response = await axios.get(
        `${this.config.baseUrl}${this.config.patch.withAvailableCount}${id}`,
      );
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }

  async getListDocument(
    id: string,
    page: number,
    limit: number,
    buyingDate: string | undefined,
  ): Promise<AxiosResponse<IPaginationDto<any>, any>> {
    try {
      const params: any = {
        params: {
          buyingDate,
          limit,
          page,
        },
      };

      const response = await axios.get(
        `${this.config.documentBaseUrl}${this.config.patch.getById}${id}`,
        params,
      );
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }

  async getTotalLists(): Promise<AxiosResponse<any, any>> {
    try {
      const response = await axios.get(`${this.config.accountUrl}/list-count`);
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }
}

const userConfig = {
  accountUrl: `${process.env.REACT_APP_API_HOST}/account`,
  baseUrl: `${process.env.REACT_APP_API_HOST}/list`,
  documentBaseUrl: `${process.env.REACT_APP_API_HOST}/list-documents`,
  exportBaseUrl: `${process.env.REACT_APP_API_HOST}/list-export`,
  patch: {
    buy: '/buy',
    buyMore: '/buy-more/',
    getAll: '/',
    getById: '/',
    total: '/total',
    withAvailableCount: '/with-available-count/',
  },
};

export const listService = new ListService(userConfig);
export default listService;
