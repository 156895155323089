import { CombinedSearchJoinType } from '@a-type/enums';
import { ICombinedSearch, IDataSource } from '@a-type/interfaces';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useGetDataSourcesQuery,
  useUpdateCombinedSearchBasicMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { generateObjectId, getError } from '@a-type/ui/utils';
import { AddCircle, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { CombinedSearchSteps } from './combined-search-steps.enum';

interface ICombinedSearchBasicProps {
  combinedSearch: ICombinedSearch;
  setActiveStep: (step: string) => void;
  updateCombinedSearch: (field: string, value: any) => void;
}

export const CombinedSearchBasic: React.FC<ICombinedSearchBasicProps> = ({
  combinedSearch,
  setActiveStep,
  updateCombinedSearch,
}: ICombinedSearchBasicProps) => {
  const dispatch = useDispatch();
  const { data: dataSourcesData, isFetching: isFetchingDataSources } = useGetDataSourcesQuery();
  const [updateBasic, { isLoading: isUpdatingBasic }] = useUpdateCombinedSearchBasicMutation();
  const [dataSources, setDataSources] = useState<IDataSource[]>([]);

  useEffect(() => {
    dispatch(pageContentLoad(!isFetchingDataSources && !isUpdatingBasic));
  }, [isFetchingDataSources, isUpdatingBasic]);

  useEffect(() => {
    if (dataSourcesData) {
      setDataSources(dataSourcesData.filter((ds) => !ds.isApi));
    }
  }, [dataSourcesData]);

  const getDataSourceFields = (dataSourceId: string) => {
    if (!dataSources) return [];

    const dataSource = dataSources.find((ds) => ds._id === dataSourceId);

    return (
      dataSource?.fields?.map((field) => ({
        label: field.displayName,
        value: field.name,
      })) ?? []
    );
  };

  const handleUpdate = async () => {
    const result = await updateBasic({
      data: combinedSearch,
      type: combinedSearch.type,
    });

    if (result.data) {
      dispatch(snackbarSuccessMessage('Combined search updated successfully'));
    }

    if (result.error) {
      dispatch(
        snackbarErrorMessage(getError(result.error) ?? 'Error while updating combined search'),
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        width: '100%',
      }}
    >
      <FormGroup>
        <Typography
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          Title
        </Typography>
        <TextField
          inputProps={{
            maxLength: 50,
          }}
          onChange={(e) => updateCombinedSearch('name', e.target.value)}
          placeholder="The name of the combined search."
          sx={{
            background: globalStyles.mainColors.whiteColor,
            width: '100%',
          }}
          value={combinedSearch.name ?? ''}
        />
      </FormGroup>

      <FormGroup sx={{ width: '100%' }}>
        <Typography
          sx={{
            color: globalStyles.mainColors.sootyColor,
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          Description
        </Typography>
        <TextField
          inputProps={{
            maxLength: 500,
          }}
          multiline
          onChange={(e) => updateCombinedSearch('description', e.target.value)}
          placeholder="A brief description of the combined search."
          rows={3}
          sx={{
            background: globalStyles.mainColors.whiteColor,
            width: '100%',
          }}
          value={combinedSearch.description ?? ''}
        />
      </FormGroup>

      {dataSources.length > 0 && (
        <>
          <FormGroup sx={{ width: '50%' }}>
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              Primary Data Source
            </Typography>
            <Select
              onChange={(e) => {
                const primaryDataSourceId = e.target.value;

                if (combinedSearch.primaryDataSourceId !== primaryDataSourceId) {
                  updateCombinedSearch('primaryDataSourceId', primaryDataSourceId);
                }
              }}
              size="small"
              sx={{
                background: globalStyles.mainColors.whiteColor,
                width: '100%',
              }}
              value={combinedSearch.primaryDataSourceId ?? ''}
            >
              {dataSources?.map((dataSource) => (
                <MenuItem key={dataSource._id} value={dataSource._id}>
                  {dataSource.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>

          <Box
            sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              sx={{
                color: globalStyles.mainColors.sootyColor,
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              Secondary Data Sources
            </Typography>

            <Button
              onClick={() => {
                if (
                  combinedSearch.secondaryDataSources.some((secondary) => !secondary.dataSourceId)
                )
                  return;

                updateCombinedSearch('secondaryDataSources', [
                  ...combinedSearch.secondaryDataSources,
                  {
                    _id: generateObjectId(),
                    dataSourceId: '',
                  },
                ]);
              }}
              size="small"
              startIcon={<AddCircle />}
              variant="text"
            >
              Add Data Source
            </Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              width: '100%',
            }}
          >
            {combinedSearch.secondaryDataSources.map((secondary, index) => (
              <Box
                key={secondary._id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'baseline',
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{
                      color: globalStyles.mainColors.sootyColor,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {secondary.dataSourceId
                      ? dataSources?.find((ds) => ds._id === secondary.dataSourceId)?.name
                      : `Secondary Data Source ${index + 1}`}
                  </Typography>

                  <Button
                    color="error"
                    onClick={() =>
                      updateCombinedSearch(
                        'secondaryDataSources',
                        combinedSearch.secondaryDataSources.filter((s) => s._id !== secondary._id),
                      )
                    }
                    size="small"
                    startIcon={<Delete />}
                    variant="text"
                  >
                    Remove
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    width: '100%',
                  }}
                >
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <InputLabel id={`secondary-data-source-${secondary._id}`} size="small">
                      Data Source
                    </InputLabel>
                    <Select
                      fullWidth
                      label="Data Source"
                      labelId={`secondary-data-source-${secondary._id}`}
                      onChange={(e) =>
                        updateCombinedSearch('secondaryDataSources', [
                          ...combinedSearch.secondaryDataSources.map((secondaryDataSource) => {
                            if (secondaryDataSource._id === secondary._id) {
                              return {
                                ...secondaryDataSource,
                                dataSourceId: e.target.value,
                              };
                            }

                            return secondaryDataSource;
                          }),
                        ])
                      }
                      size="small"
                      value={secondary.dataSourceId ?? ''}
                    >
                      {dataSources?.map((dataSource) => (
                        <MenuItem
                          disabled={
                            combinedSearch.primaryDataSourceId === dataSource._id ||
                            combinedSearch.secondaryDataSources.some(
                              (s) =>
                                s.dataSourceId === dataSource._id &&
                                dataSource._id !== secondary.dataSourceId,
                            )
                          }
                          key={dataSource._id}
                          value={dataSource._id}
                        >
                          {dataSource.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <InputLabel id={`secondary-jointype-${secondary._id}`} size="small">
                      Join Type
                    </InputLabel>
                    <Select
                      label="Join Type"
                      labelId={`secondary-jointype-${secondary._id}`}
                      onChange={(e) =>
                        updateCombinedSearch('secondaryDataSources', [
                          ...combinedSearch.secondaryDataSources.map((secondaryDataSource) => {
                            if (secondaryDataSource._id === secondary._id) {
                              return {
                                ...secondaryDataSource,
                                joinType: e.target.value,
                              };
                            }

                            return secondaryDataSource;
                          }),
                        ])
                      }
                      size="small"
                      value={secondary.joinType ?? ''}
                    >
                      <MenuItem value={CombinedSearchJoinType.ONE_TO_ONE}>One to One</MenuItem>
                      <MenuItem value={CombinedSearchJoinType.ONE_TO_MANY}>One to Many</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <TextField
                  inputProps={{
                    maxLength: 50,
                  }}
                  label="Field Name in Combined Search"
                  onChange={(e) =>
                    updateCombinedSearch('secondaryDataSources', [
                      ...combinedSearch.secondaryDataSources.map((secondaryDataSource) => {
                        if (secondaryDataSource._id === secondary._id) {
                          return {
                            ...secondaryDataSource,
                            name: e.target.value,
                          };
                        }

                        return secondaryDataSource;
                      }),
                    ])
                  }
                  placeholder="The name of field in the combined search."
                  size="small"
                  sx={{
                    background: globalStyles.mainColors.whiteColor,
                    width: '100%',
                  }}
                  value={secondary.name ?? ''}
                />

                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    width: '100%',
                  }}
                >
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <InputLabel id={`secondary-sourcefield-${secondary._id}`} size="small">
                      Source Field Key (Primary Data Source)
                    </InputLabel>
                    <Select
                      label="Source Field Key (Primary Data Source)"
                      labelId={`secondary-sourcefield-${secondary._id}`}
                      onChange={(e) =>
                        updateCombinedSearch('secondaryDataSources', [
                          ...combinedSearch.secondaryDataSources.map((secondaryDataSource) => {
                            if (secondaryDataSource._id === secondary._id) {
                              return {
                                ...secondaryDataSource,
                                sourceField: e.target.value,
                              };
                            }

                            return secondaryDataSource;
                          }),
                        ])
                      }
                      size="small"
                      value={secondary.sourceField ?? ''}
                    >
                      {getDataSourceFields(combinedSearch.primaryDataSourceId).map((field) => (
                        <MenuItem key={field.value} value={field.value}>
                          {field.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <InputLabel id={`secondary-targetfield-${secondary._id}`} size="small">
                      Target Field Key (Secondary Data Source)
                    </InputLabel>

                    <Select
                      label="Target Field Key (Secondary Data Source)"
                      labelId={`secondary-targetfield-${secondary._id}`}
                      onChange={(e) =>
                        updateCombinedSearch('secondaryDataSources', [
                          ...combinedSearch.secondaryDataSources.map((secondaryDataSource) => {
                            if (secondaryDataSource._id === secondary._id) {
                              return {
                                ...secondaryDataSource,
                                targetField: e.target.value,
                              };
                            }

                            return secondaryDataSource;
                          }),
                        ])
                      }
                      size="small"
                      value={secondary.targetField ?? ''}
                    >
                      {getDataSourceFields(secondary.dataSourceId).map((field) => (
                        <MenuItem key={field.value} value={field.value}>
                          {field.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                {index !== combinedSearch.secondaryDataSources.length - 1 && (
                  <Divider
                    sx={{
                      mb: 2,
                      mt: 0,
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Button color="primary" onClick={handleUpdate} sx={{ minWidth: 120 }} variant="contained">
          Update
        </Button>
        <Button
          color="primary"
          onClick={() => setActiveStep(CombinedSearchSteps.Filters)}
          variant="outlined"
        >
          Continue to Filters
        </Button>
      </Box>
    </Box>
  );
};
