import { UserStatus } from '@a-type/enums';
import { useDispatch, useQuery } from '@a-type/ui/hooks';
import { AdminPageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import { useGetUserQuery } from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { Box, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { UserAccessSettings } from './components/user-access-settings/user-access-settings.component';
import { UserActivity } from './components/user-activity/user-activity.component';
import { UserCard } from './components/user-card/user-card.components';
import { UserInformation } from './components/user-information/user-information.component';
import { UserStatistics } from './components/user-statistics/user-statistics.component';

enum TabTypes {
  Access = 'access',
  Activity = 'activity',
  Info = 'info',
  Statistics = 'statistics',
}

export const UserDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: user, isLoading } = useGetUserQuery(id!, { skip: !id });
  const [activeTab, setActiveTab] = useState<TabTypes | undefined>(undefined);

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading, dispatch]);

  useEffect(() => {
    if (!user) return;

    if (user.status === UserStatus.PENDING) {
      setActiveTab(TabTypes.Info);
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;

    if (query.get('tab')) {
      setActiveTab(query.get('tab') as TabTypes);
    } else {
      navigate(`?tab=${user.status === UserStatus.PENDING ? TabTypes.Info : TabTypes.Statistics}`);
    }
  }, [query, user]);

  return (
    <AdminPageLayout container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 4,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
            display: 'flex',
            gap: 1,
            pb: 1,
          }}
        >
          <IconButton
            onClick={() => {
              navigate(`${AppRoutes.AdminUsersPage}`);
            }}
          >
            <ChevronLeftOutlined />
          </IconButton>
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 24,
              fontWeight: 900,
            }}
          >
            Profile Details
          </Typography>
        </Box>

        {user && (
          <>
            <UserCard user={user} />

            {activeTab && (
              <Tabs
                onChange={(event, v: TabTypes) => {
                  setActiveTab(v);
                  navigate(`${AppRoutes.AdminUsersPage}/${id}?tab=${v}`);
                }}
                sx={{
                  borderBottom: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
                }}
                value={activeTab}
              >
                {user?.status !== UserStatus.PENDING && [
                  <Tab
                    key={TabTypes.Statistics}
                    label="statistics"
                    sx={{ color: globalStyles.navigation.textColor, textTransform: 'uppercase' }}
                    value={TabTypes.Statistics}
                  />,
                  <Tab
                    key={TabTypes.Access}
                    label="databases & Append data settings"
                    sx={{ color: globalStyles.navigation.textColor, textTransform: 'uppercase' }}
                    value={TabTypes.Access}
                  />,
                  <Tab
                    key={TabTypes.Activity}
                    label="activity"
                    sx={{ color: globalStyles.navigation.textColor, textTransform: 'uppercase' }}
                    value={TabTypes.Activity}
                  />,
                ]}
                <Tab
                  label="user information"
                  sx={{ color: globalStyles.navigation.textColor, textTransform: 'uppercase' }}
                  value={TabTypes.Info}
                />
              </Tabs>
            )}

            {activeTab === TabTypes.Statistics && <UserStatistics user={user} />}
            {activeTab === TabTypes.Access && <UserAccessSettings user={user} />}
            {activeTab === TabTypes.Activity && <UserActivity user={user} />}
            {activeTab === TabTypes.Info && <UserInformation user={user} />}
          </>
        )}
      </Box>
    </AdminPageLayout>
  );
};
