import { IDraggable } from './drag-and-drop.interface';

export const resort = <T extends IDraggable>(
  list: T[],
  source: T,
  destination: T,
  getKey: (item: T) => string = (item) => item._id,
): T[] => {
  const sourceSortOrder = source.sortOrder;
  const destinationSortOrder = destination.sortOrder;

  // update sortOrder from source to destination
  if (source.sortOrder > destination.sortOrder) {
    // update sortOrder from between source and destination
    return [
      ...list
        .map((item) => {
          if (getKey(item) === getKey(source))
            return {
              ...item,
              sortOrder: destinationSortOrder,
            } as T;

          if (item.sortOrder >= destinationSortOrder && item.sortOrder < sourceSortOrder)
            return {
              ...item,
              sortOrder: item.sortOrder + 1,
            } as T;

          return item;
        })
        .sort((a, b) => a.sortOrder - b.sortOrder),
    ];
  }

  // update sortOrder from between source and destination
  return [
    ...list
      .map((item) => {
        if (getKey(item) === getKey(source))
          return {
            ...item,
            sortOrder: destinationSortOrder,
          } as T;

        if (item.sortOrder > sourceSortOrder && item.sortOrder <= destinationSortOrder)
          return {
            ...item,
            sortOrder: item.sortOrder - 1,
          } as T;

        return item;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder),
  ];
};
