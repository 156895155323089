import type { BaseQueryFn } from '@reduxjs/toolkit/query/react';

import axios, { AxiosError, AxiosRequestConfig } from 'axios';

export interface IBaseQuery {
  data?: any;
  headers?: AxiosRequestConfig['headers'];
  method?: 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT';
  params?: AxiosRequestConfig['params'];
  url: string;
}
export type BaseQueryError = { data: any; status?: number };
export type BaseQuery = BaseQueryFn<IBaseQuery, unknown, BaseQueryError>;

const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string }): BaseQuery =>
  async ({ data, headers, method, params, url }) => {
    try {
      const result = await axios({
        data,
        headers,
        method,
        params,
        url: baseUrl + url,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          data: err.response?.data || { message: err.message },
          status: err.response?.status,
        },
      };
    }
  };

export default axiosBaseQuery;
