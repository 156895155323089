import { GridPagination } from '@a-type/ui/components';
import { Tooltip, Typography } from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { FC } from 'react';

interface ITaxAssessorsDocumentPermitsProps {
  permits: any[];
}

export const TaxAssessorsDocumentPermits: FC<ITaxAssessorsDocumentPermitsProps> = ({
  permits,
}: ITaxAssessorsDocumentPermitsProps) => {
  const usePaginator = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

    return (
      <GridPagination
        count={permits.length ?? 0}
        limit={pageSize}
        onChangePagination={(p, l) => {
          apiRef.current.setPage(p);
          apiRef.current.setPageSize(l);
        }}
        page={page}
        pages={pageCount}
      />
    );
  };

  return (
    <DataGrid
      autoHeight
      columns={[
        {
          field: 'EffectiveDate',
          headerName: 'Effective Date',
          renderCell: (params) =>
            params.value ? new Date(params.value).toLocaleDateString() : '-',
          width: 160,
        },
        { field: 'PermitNumber', headerName: 'Permit Number', width: 160 },
        { field: 'Status', headerName: 'Status', width: 120 },
        {
          field: 'Type',
          headerName: 'Type',
          renderCell: (params) => {
            return params.value ? (
              <Tooltip placement="top" title={params.value}>
                <Typography
                  component="span"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {params.value}
                </Typography>
              </Tooltip>
            ) : (
              '-'
            );
          },
          width: 240,
        },
        { field: 'SubType', headerName: 'Sub Type', width: 200 },
        {
          field: 'BuildingPermitClassifiers',
          headerName: 'Building Permit Classifiers',
          width: 200,
        },
        {
          field: 'Description',
          headerName: 'Description',
          renderCell: (params) => {
            return params.value ? (
              <Tooltip placement="top" title={params.value}>
                <Typography
                  component="span"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {params.value}
                </Typography>
              </Tooltip>
            ) : (
              '-'
            );
          },
          width: 240,
        },
        {
          field: 'JobValue',
          headerName: 'Job Value',
          renderCell: (params) =>
            params.value ? `$${Number(params.value).toLocaleString()}` : '-',
          width: 120,
        },
        {
          field: 'ProjectName',
          headerName: 'Project Name',
          renderCell: (params) => {
            return params.value ? (
              <Tooltip placement="top" title={params.value}>
                <Typography
                  component="span"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {params.value}
                </Typography>
              </Tooltip>
            ) : (
              '-'
            );
          },
          width: 200,
        },
        {
          field: 'BusinessName',
          headerName: 'Business Name',
          renderCell: (params) => {
            return params.value ? (
              <Tooltip placement="top" title={params.value}>
                <Typography
                  component="span"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {params.value}
                </Typography>
              </Tooltip>
            ) : (
              '-'
            );
          },
          width: 200,
        },
      ]}
      disableColumnMenu
      getRowId={(row) => row.BuildingPermitID}
      paginationModel={{
        page: 0,
        pageSize: 15,
      }}
      rows={permits}
      slots={{
        pagination: usePaginator,
      }}
    />
  );
};
