import globalStyles from '@a-type/ui/styles/global.styles';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { Box, Button, SxProps, Typography } from '@mui/material';

interface NumberInputProps {
  max?: number;
  min?: number;
  onChange: (value: number) => void;
  step?: number;
  value: number;
}

const buttonStyles: SxProps = {
  borderRadius: '4px',
  flexShrink: 0,
  height: '32px',
  margin: 0,
  minWidth: '32px',
  padding: 0,
  width: '32px',
};

export const NumberInput: React.FC<NumberInputProps> = ({
  max,
  min,
  onChange,
  step = 1,
  value,
}: NumberInputProps) => {
  const handleIncrement = () => {
    onChange(value + step);
  };

  const handleDecrement = () => {
    onChange(value - step);
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        border: `1px solid ${globalStyles.mainColors.veryLightGrayColor}`,
        borderRadius: '5px',
        display: 'flex',
        gap: 1,
        height: '56px',
        justifyContent: 'justify-between',
        p: '16px',
      }}
    >
      <Button
        color="secondary"
        data-testid="decrement-button"
        disabled={min !== undefined && value <= min}
        onClick={handleDecrement}
        sx={buttonStyles}
      >
        <RemoveIcon />
      </Button>
      <Typography
        component="span"
        sx={{
          flexGrow: 1,
          fontSize: '17px',
          fontWeight: 400,
          lineHeight: '20px',
          minWidth: 120,
          textAlign: 'center',
          userSelect: 'none',
        }}
      >
        {value.toLocaleString()}
      </Typography>
      <Button
        color="secondary"
        data-testid="increment-button"
        disabled={max !== undefined && value >= max}
        onClick={handleIncrement}
        sx={buttonStyles}
      >
        <AddIcon />
      </Button>
    </Box>
  );
};
