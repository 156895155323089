import { IPaginationDto } from '@a-type/dtos';
import { ICount } from '@a-type/interfaces';
import axios, { AxiosResponse } from 'axios';

class CountsService {
  constructor(public config: any) {}

  async create(data: ICount): Promise<AxiosResponse<ICount, any>> {
    try {
      const response = await axios.put(`${this.config.baseUrl}${this.config.patch.create}`, data);
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }

  async deleteById(id: string): Promise<AxiosResponse<any, any>> {
    try {
      const response = await axios.delete(
        `${this.config.baseUrl}${this.config.patch.deleteById}${id}`,
      );
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }

  async getAll(page: number, limit: number): Promise<AxiosResponse<IPaginationDto<ICount>, any>> {
    try {
      const bodyParams: any = {
        params: {
          limit,
          page,
        },
      };
      const response = await axios.get<IPaginationDto<ICount>>(
        `${this.config.baseUrl}${this.config.patch.getAll}`,
        bodyParams,
      );
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }

  async getById(id: string): Promise<AxiosResponse<ICount, any>> {
    try {
      const response = await axios.get(`${this.config.baseUrl}${this.config.patch.getById}${id}`);
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }

  async getTotalCounts(): Promise<AxiosResponse<any, any>> {
    try {
      const response = await axios.get(`${this.config.accountUrl}/count-count`);
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }

  async update(data: ICount): Promise<AxiosResponse<ICount, any>> {
    try {
      const response = await axios.put(`${this.config.baseUrl}${this.config.patch.update}`, data);
      return response as any;
    } catch (error: any) {
      return error.response;
    }
  }
}

const userConfig = {
  accountUrl: `${process.env.REACT_APP_API_HOST}/account`,
  baseUrl: `${process.env.REACT_APP_API_HOST}/counts`,
  patch: {
    create: '/create',
    deleteById: '/',
    getAll: '/',
    getById: '/',
    total: '/total',
    update: '/update',
  },
};

export const countsService = new CountsService(userConfig);
export default countsService;
