import globalStyles from '@a-type/ui/styles/global.styles';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

export interface MoreActionItemProps {
  disabled?: boolean;
  icon: React.ReactNode;
  label: string;
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

export const MoreActionItem = ({ disabled = false, icon, label, onClick }: MoreActionItemProps) => {
  return (
    <MenuItem
      disabled={disabled}
      onClick={onClick}
      sx={{
        '&:hover': {
          '.MuiListItemIcon-root': {
            color: globalStyles.mainColors.blueColor,
          },

          '.MuiTypography-root': {
            color: globalStyles.mainColors.blueColor,
          },

          backgroundColor: '#EFF7FF',
        },
        '.MuiListItemIcon-root': {
          color: globalStyles.navigation.textColor,
        },
        '.MuiTypography-root': {
          color: globalStyles.navigation.textColor,
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '24px',
        },
        color: globalStyles.navigation.blueColor,

        minHeight: '35px !important',

        my: 0.25,

        py: 0,
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
};
