import { ICombinedSearchFilter } from '@a-type/interfaces';
import { Dialog } from '@a-type/ui/components';
import { generateObjectId } from '@a-type/ui/utils';
import { AddCircleOutline } from '@mui/icons-material';
import { Autocomplete, Box, Button, Chip, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { IViewFilterDetailed } from '../types/view-filter-detailed.interface';

interface IViewAddFilterProps {
  combinedSearchFilters: ICombinedSearchFilter[];
  dataSourceMap: { [key: string]: string };
  filters: IViewFilterDetailed[];
  onAddFilter: (filter: IViewFilterDetailed) => void;
}

export const ViewAddFilter: React.FC<IViewAddFilterProps> = ({
  combinedSearchFilters,
  dataSourceMap,
  filters,
  onAddFilter,
}: IViewAddFilterProps) => {
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<ICombinedSearchFilter | null>(null);
  const [options, setOptions] = useState<ICombinedSearchFilter[]>([]);

  useEffect(() => {
    if (!combinedSearchFilters || !filters) return;

    setOptions(
      combinedSearchFilters
        .filter((filter) => !filters.some((f) => f.filterId === filter._id))
        .sort((a, b) => a.sortOrder - b.sortOrder),
    );
  }, [combinedSearchFilters, filters]);

  const handleAddFilter = () => {
    if (!selectedFilter) return;

    onAddFilter({
      _id: generateObjectId(),
      filter: selectedFilter,
      filterId: selectedFilter._id,
      price: 0,
      sortOrder: filters.length + 1,
    } as IViewFilterDetailed);

    setShowAddFilter(false);
    setSelectedFilter(null);
  };

  return (
    <>
      <Button
        disabled={options.length === 0}
        onClick={() => setShowAddFilter(true)}
        startIcon={<AddCircleOutline />}
        variant="text"
      >
        Add Filter
      </Button>

      {showAddFilter && (
        <Dialog
          cancelText="Cancel"
          disableOk={!selectedFilter}
          okText="Add"
          onCancel={() => setShowAddFilter(false)}
          onClose={() => setShowAddFilter(false)}
          onOk={handleAddFilter}
          open={showAddFilter}
          title="Add Filter"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {options.length > 0 && (
              <Autocomplete
                getOptionDisabled={(option) => filters.some((f) => f.filterId === option._id)}
                getOptionLabel={(option) => option.displayName}
                onChange={(_, value) => setSelectedFilter(value)}
                options={options}
                renderInput={(params) => <TextField {...params} label="Filter" />}
                renderOption={(params, option) => (
                  <Box
                    component="li"
                    {...params}
                    key={option._id}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      px: 2,
                      py: 1,
                      width: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        flexGrow: 1,
                        fontSize: 16,
                      }}
                    >
                      {option.displayName}
                    </Typography>
                    <Chip label={dataSourceMap[option.dataSourceId]} size="small" />
                  </Box>
                )}
                value={selectedFilter}
              />
            )}

            {selectedFilter && (
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'row wrap',
                  gap: 5,
                  justifyContent: 'flex-start',
                }}
              >
                <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                  Filter field
                  <Typography
                    component="span"
                    sx={{
                      display: 'block',
                      fontSize: 16,
                    }}
                  >
                    {selectedFilter?.displayName}
                  </Typography>
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                  Filter name
                  <Typography
                    component="span"
                    sx={{
                      display: 'block',
                      fontSize: 16,
                    }}
                  >
                    {selectedFilter?.name}
                  </Typography>
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                  Type
                  <Typography
                    component="span"
                    sx={{
                      display: 'block',
                      fontSize: 16,
                    }}
                  >
                    {selectedFilter?.dataType}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
        </Dialog>
      )}
    </>
  );
};
