import { OperationType } from '@a-type/enums';
import { ITransaction } from '@a-type/interfaces';
import globalStyles from '@a-type/ui/styles/global.styles';
import { ListItemButton, Typography } from '@mui/material';

type ActivityTableItemComponentTypes = {
  transaction: ITransaction;
};

const ActivityTableItemComponent = (props: ActivityTableItemComponentTypes) => {
  const { transaction } = props;
  const { _id, createdAt, operationName, operationType, operationValue, userValueAfter } =
    transaction;

  return (
    <ListItemButton
      key={_id}
      sx={{
        display: 'flex',
        px: 0,
      }}
    >
      <Typography
        sx={{
          color: globalStyles.mainColors.sootyColor,
          fontSize: 16,
          width: '25%',
        }}
      >
        {new Date(createdAt!).toLocaleString()}
      </Typography>
      <Typography
        sx={{
          color: globalStyles.mainColors.sootyColor,
          fontSize: 16,
          width: `45%`,
        }}
      >
        {operationName}
      </Typography>

      <Typography
        sx={{
          color: globalStyles.mainColors.sootyColor,
          fontSize: 16,
          width: `15%`,
        }}
      >
        {operationType === OperationType.REDUCTION ||
        operationType === OperationType.SUBSCRIPTION_CLEANUP
          ? '-'
          : ''}
        {Number(operationValue).toLocaleString()}
      </Typography>
      <Typography
        sx={{
          color: globalStyles.mainColors.greenColor,
          fontSize: 16,
          width: `15%`,
        }}
      >
        {Number(userValueAfter).toLocaleString()}
      </Typography>
    </ListItemButton>
  );
};

export default ActivityTableItemComponent;
