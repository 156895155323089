export enum TaxAssessorsDocumentFields {
  ATTOMID = '[ATTOM ID]',
  OwnerTypeDescription1 = 'OwnerTypeDescription1',
  PartyOwner1NameFull = 'PartyOwner1NameFull',
  PartyOwner2NameFull = 'PartyOwner2NameFull',
  PartyOwner3NameFull = 'PartyOwner3NameFull',
  PartyOwner4NameFull = 'PartyOwner4NameFull',
  PropertyAddressFull = 'PropertyAddressFull',
  PropertyLatitude = 'PropertyLatitude',
  PropertyLongitude = 'PropertyLongitude',
  PropertyUseGroup = 'PropertyUseGroup',
  PropertyUseStandardized = 'PropertyUseStandardized',
  TaxAssessedImprovementsPerc = 'TaxAssessedImprovementsPerc',
  TaxAssessedValueImprovements = 'TaxAssessedValueImprovements',
  TaxAssessedValueLand = 'TaxAssessedValueLand',
  TaxAssessedValueTotal = 'TaxAssessedValueTotal',
  TaxBilledAmount = 'TaxBilledAmount',
  TaxFiscalYear = 'TaxFiscalYear',
  TaxMarketImprovementsPerc = 'TaxMarketImprovementsPerc',
  TaxMarketValueImprovements = 'TaxMarketValueImprovements',
  TaxMarketValueLand = 'TaxMarketValueLand',
  TaxMarketValueTotal = 'TaxMarketValueTotal',
  TaxMarketValueYear = 'TaxMarketValueYear',
  TaxRateArea = 'TaxRateArea',
  TaxYearAssessed = 'TaxYearAssessed',
}
