import { Theme } from '@emotion/react';
import { Autocomplete, SxProps, TextField } from '@mui/material';
import { Control, Controller, Validate } from 'react-hook-form';

interface IFormAutocompleteProps<TOption> {
  control: Control<any>;
  getOptionKey: (option: TOption) => string;
  getOptionLabel: (option: TOption) => string;
  label: string;
  loading?: boolean;
  name: string;
  options: ReadonlyArray<TOption>;
  required?: boolean;
  rules?: Record<string, Validate<string, any>>;
  sx?: SxProps<Theme>;
}

export const FormAutocomplete = <TOption extends { [key: string]: any }>({
  control,
  getOptionKey,
  getOptionLabel,
  label,
  loading = false,
  name,
  options,
  required = false,
  rules = {},
  sx,
}: IFormAutocompleteProps<TOption>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          getOptionKey={getOptionKey}
          getOptionLabel={getOptionLabel}
          loading={loading}
          onChange={(_, x) => onChange(x?.code)}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              fullWidth
              helperText={error?.message}
              inputProps={{ ...params.inputProps, maxLength: 60 }}
              label={label}
              required={required}
            />
          )}
          sx={sx}
          value={options.find((x) => x.code === value) ?? null}
        />
      )}
      rules={{
        validate: {
          required: required ? (value) => !!value || 'Field is required' : () => true,
          ...rules,
        },
      }}
    />
  );
};
